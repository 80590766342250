import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {TabularLobComponent} from './home/tabular-lob/tabular-lob.component';
import {DashBoardLoginComponent} from './dash-board-login/dash-board-login.component';
import {PoPredictionDetailsComponent} from './home/po-prediction-details/po-prediction-details.component';
import {IncorrectRouteComponent} from './incorrect-route/incorrect-route.component';
import {AdminSettingsComponent} from './home/admin-settings/admin-settings.component';
import {ChartViewComponent} from './home/chart-view/chart-view.component';
import { ResetPasswordComponent } from './dash-board-login/reset-password/reset-password.component';
import { UserProfileComponent } from './home/user-profile/user-profile.component';
import { SupplierTrendChartComponent } from './home/supplier-trend-chart/supplier-trend-chart.component';
import { SupplierDetailComponent } from './home/po-prediction-details/supplier-detail/supplier-detail.component';
import { UpgradeSubscriptionComponent } from './home/upgrade-subscription/upgrade-subscription.component';
import { SelectedSubscriptionPlanComponent } from './home/selected-subscription-plan/selected-subscription-plan.component';

const routes: Routes = [
    {path: '', component: DashBoardLoginComponent},
    {path: 'login', component: DashBoardLoginComponent},
    {path: 'userManagement', component: ResetPasswordComponent},
    {
        path: 'home', component: HomeComponent,
        children: [
            {path: 'tabularLob', component: TabularLobComponent},
            {path: 'scores', component: ChartViewComponent},
            
            {path: 'roster', component: PoPredictionDetailsComponent
            },
            {path: 'supplierDetail/:supplierName', component: SupplierDetailComponent},                    
            {path: 'adminSettings', component: AdminSettingsComponent},
            {path: 'myProfile', component: UserProfileComponent},
            {path: 'trendChart', component: SupplierTrendChartComponent},
            {path: 'trendChart/:supplierName', component: SupplierTrendChartComponent},
            {path: 'upgradeSubscription', component: UpgradeSubscriptionComponent},
            {path: 'changeSubscription/:subscriptionToChange', component: UpgradeSubscriptionComponent},
            {path: 'selectedSubscriptionPlan/:selectedSubscription', component: SelectedSubscriptionPlanComponent}, 
            {path: 'changeSelectedSubscriptionPlan/:selectedSubscription', component: SelectedSubscriptionPlanComponent},         
           
            
        ]
    },
    {path: '**', component: IncorrectRouteComponent, pathMatch: 'full'}
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
