import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SupplierDataService } from 'src/app/services/supplier-data.service';
import { cloneDeep } from "lodash";
import { ActivatedRoute } from '@angular/router';
import * as c3 from 'c3';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-supplier-analysis',
    templateUrl: './supplier-analysis.component.html',
    styleUrls: ['./supplier-analysis.component.css']
})
export class SupplierAnalysisComponent implements OnInit {

    impactToggleForm: FormGroup;
    SlideOptions = {
        items: 1, dots: false, nav: true,
        onChange: () => {
        }
    };
    CarouselOptions = { items: 3, dots: false, nav: true };
    chartList = [];
    @Input('dataObj') dataObj: any = [];
    @Output() stopLoader = new EventEmitter<string>();
    @Output() showPartBreakdown: EventEmitter<any> = new EventEmitter<any>();
    newList = [];
    impactToggle: any;
    impact_type: string;
    authtoken: string;
    trustScores: any = [];
    impactedTrustScores: any = [];
    loader: boolean = false;
    parts: any = [];
    supplierName: any;
    supplierData: any = [];
    chartCount: any = 0;
    chartData = [];
    noRecordsChart = true;
    carouselCharts = true;

    constructor(private supplierService: SupplierDataService, private fb: FormBuilder, private matDialog: MatDialog,
                private firebaseAuthService: StratizantFirebaseAuthService,
                private route: ActivatedRoute, private cookieServiceVariable: CookieService) {
        this.chartList = [];
        this.chartCount = 0;
    }

    ngOnInit() {
        this.impact_type = localStorage.getItem('impact_type');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        //$('#oneMonth').addClass('active');
        this.chartList = [];
        this.chartCount = 0;
        this.impactToggle = true;
        this.route.params.subscribe(params => {
            this.supplierName = params['supplierName'];
            this.loader = true;
            this.impactToggleForm = this.fb.group({
                impact: [true],
            });
        });
        this.getAllSuppliersData('1D');
        const duration = sessionStorage.getItem("duration");
        if (duration) {
            sessionStorage.removeItem("duration");
        }

        this.impactToggleForm = this.fb.group({
            impact: [true],
        });
    }



    ngOnChanges(changes: SimpleChanges) {
        if (!changes.dataObj.isFirstChange() && changes.dataObj.currentValue.sortedList && changes.dataObj.currentValue.sortedList.length != 0) {
            this.carouselCharts = false;
            this.noRecordsChart = true;
            this.chartList = [];
            this.chartList = changes.dataObj.currentValue.chartList;
            this.impactToggle = changes.dataObj.currentValue.impactScore;
            this.newList = changes.dataObj.currentValue.sortedList;
            const loader_val = changes.dataObj.currentValue.loader ? changes.dataObj.currentValue.loader : "";
            this.formatDataForChart(changes.dataObj.currentValue.sortedList, loader_val);
        } else if (!changes.dataObj.isFirstChange()) {
            this.carouselCharts = true;
            this.noRecordsChart = false;
            this.parts = [];
            this.trustScores = [];
            this.impactedTrustScores = [];
            this.parts[0] = 'x';
            this.trustScores[0] = 'Trust Score';
            this.impactedTrustScores[0] = 'Impacted Trust Score';
            this.generateNorecordsChart();
            this.stopLoader.emit('false');
        }
    }



    formatDataForChart(sortedData, loader_flag) {
        let cnt = 0;
        let num = 1;
        // this.chartList.push("barchart1");
        this.chartList = [];
        const div = Math.ceil(sortedData.length / 10)
        for (let i = 1; i <= div; i++) {
            this.chartList.push("barchart" + i);
        }
        this.parts[num] = [];
        this.trustScores[num] = [];
        this.impactedTrustScores[num] = [];
        this.parts[num][0] = 'x';
        this.trustScores[num][0] = 'Trust Score';
        this.impactedTrustScores[num][0] = 'Impacted Trust Score';
        let flag = 0;
        let cnt1 = 0;
        //const newData = [];
        this.chartData = sortedData;
        for (const j in sortedData) {
            cnt++;
            cnt1++;

            this.trustScores[num].push(sortedData[j]['value']['Trust_Score']);
            this.parts[num].push(sortedData[j]['value']['Material']);
            this.impactedTrustScores[num].push(sortedData[j]['value']['Impacted_Trust_Score']);
            if (sortedData.length < 10 && cnt == sortedData.length) {
                this.generateBarChart(num);
            } else if (cnt == 10) {
                //this.chartList.push(num);
                this.generateBarChart(num);
                const num1 = sortedData.length - 10;
                num++;
                this.parts[num] = [];
                this.trustScores[num] = [];
                this.impactedTrustScores[num] = [];
                this.parts[num][0] = 'x';
                this.trustScores[num][0] = 'Trust Score';
                this.impactedTrustScores[num][0] = 'Impacted Trust Score';

                if (num1 > 10) {
                    flag = 1;
                    cnt = 0;
                } else {
                    flag = 2;
                    cnt = 0;
                }
            } else if (sortedData.length == cnt1 && flag == 2) {
                this.generateBarChart(num);
            }
        }
        if (!loader_flag) {
            this.stopLoader.emit('false');
        }


    }


    getAllSuppliersData(selectedPeriod) {
        this.carouselCharts = true;
        this.noRecordsChart = false;
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };
        //this.loader = true;

        this.supplierService.getSupplierDataByDuration(data, selectedPeriod).subscribe(response => {

            const newData = [];
            if (Object.keys(response['prediction_summary']).length != 0) {
                let new_array = Object.entries(response['prediction_summary']).map(([type, value]) => ({ type, value }));
                new_array = new_array.filter(res => res['value']['Supplier'] === this.supplierName);
                const clonedObject = cloneDeep(new_array);
                for (const sup in clonedObject) {
                    let cnt = 0;
                    //if (clonedObject[sup]['Supplier'] == this.supplierName) {
                    this.impact_type = clonedObject[sup]['value']['Impact_Type'][0];

                    if (newData.length != 0) {
                        for (const key in newData) {
                            if (newData[key]['value']['Material'] == clonedObject[sup]['value']['Material']) {
                                newData[key]['total_count'] = newData[key]['total_count'] + 1;
                                newData[key]['value']['Trust_Score'] = newData[key]['value']['Trust_Score'] + clonedObject[sup]['value']['Trust_Score'];
                                if (newData[key]['value']['Impacted_Delivery_Date'] || newData[key]['value']['Impacted_Trust_Score']) {
                                    newData[key]['impact_total_count'] = newData[key]['impact_total_count'] + 1;
                                    newData[key]['value']['Impacted_Trust_Score'] = newData[key]['value']['Impacted_Trust_Score'] + clonedObject[sup]['value']['Impacted_Trust_Score'];
                                }
                            } else {
                                cnt++;
                            }
                            if (cnt == newData.length) {
                                newData.push(clonedObject[sup]);
                                newData[newData.length - 1]['total_count'] = 1;
                                //this.parts.push(clonedObject[sup]['Material']);
                                newData[newData.length - 1]['impact_total_count'] = 1;
                            }
                        }
                    } else {
                        newData.push(clonedObject[sup]);
                        newData[0]['total_count'] = 1;
                        //this.parts.push(clonedObject[sup]['Material']);
                        if (clonedObject[sup]['value']['Impacted_Delivery_Date'] || clonedObject[sup]['value']['Impacted_Trust_Score']) {
                            newData[0]['impact_total_count'] = 1;
                        }
                    }

                    //}

                }

                let cnt = 0;
                let num = 1;
                // this.chartList.push("barchart1");
                this.parts[num] = [];
                this.trustScores[num] = [];
                this.impactedTrustScores[num] = [];
                this.parts[num][0] = 'x';
                this.trustScores[num][0] = 'Trust Score';
                this.impactedTrustScores[num][0] = 'Impacted Trust Score';
                //this.chartList = [];
                const div = Math.round(newData.length / 20);
                for (let i = 1; i <= div; i++) {
                    this.chartList.push("barchart" + i);
                }
                let flag = 0;
                let cnt1 = 0;
                this.chartData = newData;
                for (const j in newData) {
                    cnt++;
                    cnt1++;
                    // newData[j]['Trust_Score'] = newData[j]['Trust_Score'];
                    newData[j]['value']['Trust_Score'] = (parseInt(newData[j]['value']['Trust_Score'])) / (newData[j]['total_count']);
                    newData[j]['value']['Trust_Score'] = JSON.stringify(Math.round((newData[j]['value']['Trust_Score'])));
                    // newData[j]['Trust_Score'] = newData[j]['Trust_Score'] +'%'
                    this.trustScores[num].push(newData[j]['value']['Trust_Score']);
                    this.parts[num].push(newData[j]['value']['Material']);
                    newData[j]['value']['Impacted_Trust_Score'] = parseInt(newData[j]['value']['Impacted_Trust_Score']) / newData[j]['impact_total_count'];
                    newData[j]['value']['Impacted_Trust_Score'] = JSON.stringify(Math.round((newData[j]['value']['Impacted_Trust_Score'])));
                    this.impactedTrustScores[num].push(newData[j]['value']['Impacted_Trust_Score']);
                    if (newData.length < 10 && cnt == newData.length) {
                        this.generateBarChart(num);
                    } else if (cnt == 10) {
                        //this.chartList.push(num);
                        this.generateBarChart(num);

                        const num1 = newData.length - 10;
                        num++;
                        this.parts[num] = [];
                        this.trustScores[num] = [];
                        this.impactedTrustScores[num] = [];
                        this.parts[num][0] = 'x';
                        this.trustScores[num][0] = 'Trust Score';
                        this.impactedTrustScores[num][0] = 'Impacted Trust Score';

                        if (num1 > 10) {
                            flag = 1;
                            cnt = 0;
                        } else {
                            flag = 2;
                            cnt = 0;
                        }
                    } else if (newData.length == cnt1 && flag == 2) {
                        this.generateBarChart(num);

                    }
                }



            } else {
                this.supplierData = '';
                this.loader = false;
                this.stopLoader.emit('false');
                //this.supDetailloader = false;
            }


        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }


    generateNorecordsChart() {
        //const vm = this;

        const chart = c3.generate({
            bindto: '#barchart',
            size: {
                height: 260
            },
            data: {
                x: 'x',
                columns: [
                    this.parts
                ],
                type: 'bar',

            },
            transition: {
                duration: 2000
            },
            // tooltip: {
            //     show: true
            // },
            axis: {
                rotated: false,
                x: {
                    type: 'category',
                    tick: {
                        outer: false,
                        rotate: 90,
                        multiline: false
                    },
                    height: 110,
                    label: {
                        text: 'Supplier',
                        position: 'outer-center',
                    }
                },
                y: {
                    max: 100,
                    tick: {
                        values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                    },
                    show: true,
                    padding: { top: 0.3, bottom: 0 },
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle'
                    }
                },


            },

            grid: {
                lines: {
                    front: false
                },
                y: {
                    lines: [
                        { value: 20 },
                        { value: 40 },
                        { value: 80 },
                    ]
                }
            },

            bar: {
                width: 10

            },
            legend: {
                hide: true,
                show: false
            },
        });

        const sup = this.parts;
        const trustScore = this.trustScores;
        const imp_trust_score = this.impactedTrustScores;
        //const borderData = this.borderList;

        if (this.impactToggle === true) {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore,
                        imp_trust_score
                    ]
                });
            }, 500);
        } else {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore
                    ]
                });
            }, 500);
        }

        setTimeout(() => {
            chart.regions(
                [{ axis: 'y', start: 0, end: 20, class: 'region1' },
                    { axis: 'y', start: 20, end: 40, class: 'region2' },
                    { axis: 'y', start: 40, end: 80, class: 'region3' },
                    { axis: 'y', start: 80, class: 'region4' }]);
        }, 500);


    }


    generateBarChart(num) {
        if (this.chartList && this.chartList.length != 0) {
            this.chartCount++;
        } else {
            this.chartList.push('barchart1');
            this.chartCount++;
        }

        const chartid = '#barchart' + num;
        const chart = c3.generate({
            bindto: chartid,
            size: {
                height: 260
            },
            data: {
                x: 'x',
                columns: [
                    this.parts[num]
                ],
                type: 'bar',
                color: function (inColor, data) {
                    if (data['id'] !== undefined && data['id'] === 'Trust Score') {
                        return data['value'] <= 20 ? 'var(--secondary-red)' : data['value'] >= 21 && data['value'] <= 40 ? 'var(--secondary-orange)' : data['value'] >= 41 && data['value'] <= 80 ? 'var(--secondary-yellow)' : 'var(--primary-green)';

                    }
                    return inColor;
                },
                colors: {
                    //'Trust_Score': 'darkgrey',
                    'Impacted Trust Score': 'var(--secondary-grey)'
                },
                onclick: (d) => {
                    this.selectedBar(d, num);

                }
            },
            transition: {
                duration: 2000
            },

            tooltip: {
                show: true
            },
            axis: {
                rotated: false,
                x: {
                    type: 'category',
                    tick: {
                        outer: false,
                        rotate: 90,
                        multiline: false
                    },
                    height: 80,
                    label: {
                        text: 'Parts',
                        position: 'outer-center'
                    }
                },
                y: {
                    max: 100,
                    tick: {
                        values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                    },
                    show: true,
                    padding: { top: 0.3, bottom: 0 },
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle'
                    }
                },


            },

            bar: {
                width: 15

            },
            legend: {
                hide: true,
                show: false
            },
        });

        const sup = this.parts[num];
        const trustScore = this.trustScores[num];
        const imp_trust_score = this.impactedTrustScores[num];
        if (imp_trust_score[1] != 'null' && this.impactToggle === true) {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore,
                        imp_trust_score
                    ]
                });
            }, 500);
            setTimeout(() => {
                chart.regions(
                    [{ axis: 'y', start: 0, end: 20, class: 'region1' },
                        { axis: 'y', start: 20, end: 40, class: 'region2' },
                        { axis: 'y', start: 40, end: 80, class: 'region3' },
                        { axis: 'y', start: 80, class: 'region4' }]);
            }, 500);

            if (this.chartCount == this.chartList.length && this.chartData.length > 0) {

                this.stopLoader.emit('false');
            }
        } else {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore
                    ]
                });
            }, 500);

            setTimeout(() => {
                chart.regions(
                    [{ axis: 'y', start: 0, end: 20, class: 'region1' },
                        { axis: 'y', start: 20, end: 40, class: 'region2' },
                        { axis: 'y', start: 40, end: 80, class: 'region3' },
                        { axis: 'y', start: 80, class: 'region4' }]);
            }, 500);
            if (this.chartCount == this.chartList.length && this.chartData.length > 0) {
                this.stopLoader.emit('false');
            }
        }

        //this.loader = false;
    }


    selectedBar(val, num) {
        const data: any = {
            selectedPart: val,
            index: num,
            parts: this.parts[num]
        }
        //const dataString = JSON.stringify(data);
        this.showPartBreakdown.emit(data);
    }


}
