import {Component} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from './shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from './services/stratizant-firebase-auth.service';
import { CookieService } from 'ngx-cookie-service';
declare let gtag: (config, gaId, page_path) => void;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'SupplierWatchFirebaseUI';
    refreshToken: boolean;
    authtoken: any;

    constructor(public router: Router,   private cookieServiceVariable: CookieService,private firebaseAuthService: StratizantFirebaseAuthService, private userIdle: UserIdleService, private matDialog: MatDialog) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'UA-166206285-1',
                     {
                         page_path: event.urlAfterRedirects
                     }
                );
            }
        });

        // this.authtoken = localStorage.getItem('auth_token');
        this.authtoken = this.cookieServiceVariable.get('auth_token_1');

        // if(this.authtoken){
        //     this.userIdle.startWatching();
        //     // Start watching when user idle is starting and reset if user action i  s there.
        //     this.userIdle.onTimerStart().subscribe(count => {
        //         const eventList = ['click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel',
        //             'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'];
        //         for (const event of eventList) {
        //             window.addEventListener(event, () => this.userIdle.resetTimer());
        //         }
        //         console.log("count", count);
        //         if(count == 1){
        //             this.refreshToken = true;
        //             console.log("refreshtoken", this.refreshToken);
        //         }
        //     });
        //     // Start watch when time is up.
        //     this.userIdle.onTimeout().subscribe(() => {
        //         // alert('Your session has expired click on OK to resume the application.');
        //         this.openAuthDialog();
        //         this.userIdle.stopWatching();
                    
        //     })
        // }

        //  Start watching for user inactivity.
      
      
    }


    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }
}
