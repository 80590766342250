import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfileService } from '../user-profile.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { CookieService } from 'ngx-cookie-service';

declare const $ : any;


@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

    changePasswordForm: FormGroup;
    userDetails: any;
    mismatchMsg: any;
    loader = false;
    authtoken: any;
    successMsg: string;
    errorMsg: string;
    hide = true;
    formValueChanged: boolean = false;

    passLowerCasePatt: any;
    passUpeerCasePatt: any;
    passNumberPatt: any;
    passSpecialCharacterPatt: any;
    passLength: any;
    lastloginatTimeDifference: any;

    constructor(private fb: FormBuilder, private userProfileService: UserProfileService,
        private cookieServiceVariable: CookieService,
         private firebaseAuthService: StratizantFirebaseAuthService,
                private matDialog: MatDialog) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken =  this.cookieServiceVariable.get('auth_token_1');
        this.changePasswordForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        });


      

        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if(this.userDetails){
            this.changePasswordForm.controls.email.setValue(this.userDetails.email);
            this.changePasswordForm.controls.email.disable();
        }


    }



    comparePassword() {
        if (this.changePasswordForm.value.confirmPassword && this.changePasswordForm.value.password != this.changePasswordForm.value.confirmPassword) {
            this.mismatchMsg = "Password does not match";
        } else {
            this.mismatchMsg = '';
        }
    }

    changePassword(){
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.successMsg = '';
        this.errorMsg = '';
        const formData = new FormData();

        formData.append('password', this.changePasswordForm.value.password);

        this.userProfileService.changePassword(formData, data).subscribe(res => {
            this.loader = false;
            this.successMsg = 'Password has been changed successfully.'
            this.changePasswordForm.controls.password.setValue(null);
            this.changePasswordForm.controls.confirmPassword.setValue(null);
            this.changePasswordForm.controls.password.setErrors(null);
            this.changePasswordForm.controls.confirmPassword.setErrors(null);
            this.formValueChanged = false;
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }else 
            if (error.status === 400) {
                this.errorMsg = error.error.error;
            }
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            this.firebaseAuthService.signout();

        });

    }

   

    passwordValidation(){
       
        const passwordVal = this.changePasswordForm.controls.password.value;

        if(passwordVal){
            const lowercasePatt = new RegExp("(?=.*[a-z])");
            const checkLowercase = lowercasePatt.test(passwordVal);
            if(checkLowercase === false){
                this.passLowerCasePatt = false;
                $('#lowercase').addClass('invalidPass');
            }else{
                this.passLowerCasePatt = true;
                $('#lowercase').removeClass('invalidPass');
            }

            const uppercasePatt = new RegExp("(?=.*[A-Z])");
            const checkUppercase = uppercasePatt.test(passwordVal);
            if(checkUppercase === false){
                this.passUpeerCasePatt = false;
                $('#uppercase').addClass('invalidPass');
            }else{
                this.passUpeerCasePatt = true;
                $('#uppercase').removeClass('invalidPass');
            }

            const numberPatt = new RegExp("(?=.*[0-9])");
            const checkNumber = numberPatt.test(passwordVal);
            if(checkNumber === false){
                this.passNumberPatt = false;
                $('#digit').addClass('invalidPass');
            }else{
                this.passNumberPatt = true;
                $('#digit').removeClass('invalidPass');
            }


            const specialCharacterPatt = new RegExp("(?=.*[@$!%*?&])");
            const checkSpecialCharacter = specialCharacterPatt.test(passwordVal);
            if(checkSpecialCharacter === false){
                this.passSpecialCharacterPatt = false;
                $('#specialCharacter').addClass('invalidPass');
            }else{
                this.passSpecialCharacterPatt = true;
                $('#specialCharacter').removeClass('invalidPass');
            }

            const passwordLength = passwordVal.length;
            if(passwordLength < 8 || passwordLength > 15){
                this.passLength = false;
               
                $('#length').addClass('invalidPass');
            }else{
                this.passLength = true;
                $('#length').removeClass('invalidPass');
            }
    
        }

        
    }


}
