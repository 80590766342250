import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig,MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrgManagementService } from '../org-management.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { SubscrioptionLevelService } from '../../subscription-level/subscrioption-level.service';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-add-edit-org-modal',
    templateUrl: './add-edit-org-modal.component.html',
    styleUrls: ['./add-edit-org-modal.component.css']
})
export class AddEditOrgModalComponent implements OnInit {
    loader = false;
    authtoken: string;
    orgDetailsForm: FormGroup;
    org_id: any;
    errorMsg: string;
    org_domain_name: any;
    updateData: boolean = false;
    subscriptions = [];
    planDetailsForm: FormGroup;
    pipe = new DatePipe('en-US');
    sub_level: any;
    lastloginatTimeDifference: any;
    constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private matDialog: MatDialog,public dialogRef: MatDialogRef<AddEditOrgModalComponent>,
                private orgService: OrgManagementService, private fbAuth: StratizantFirebaseAuthService,
                private subscriptionService: SubscrioptionLevelService,
                private cookieServiceVariable: CookieService) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.dialogRef.updateSize('60%');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');

        this.orgDetailsForm = this.fb.group({
            org_id: [''],
            org_domain_name: ['', Validators.required],
            org_display_name: ['', Validators.required],
            subscription_level: ['', Validators.required]
        });
        
        this.getAllSubcriptionLevels();
        
        if (this.data.action === 'View/Edit') {
            this.updateData = true;
            this.planDetailsForm = this.fb.group({
                dashboard_user_count: [{value:'',disabled : true}],
                config_admin_user_count: [{value:'',disabled : true}],
                add_dashboard_user_count: [{value:'',disabled : true}],
                plan_purchase_date: [{value:'',disabled : true}],
                plan_renewal_date: [{value:'',disabled : true}],
                plan_end_date: [{value:'',disabled : true}],
                amount_paid: [{value:'',disabled : true}]
            });
            this.setData();
            this.getSubscriptionsList();
        }else{
            this.updateData = false;
        }
    }

  

    setData() {
        
        this.orgDetailsForm.patchValue({
            org_id: this.data.formData.org_id,
            org_domain_name: this.data.formData.domain_name,
            org_display_name: this.data.formData.display_name,
            // subscription_level: this.data.formData.subscription_level_id

        });
        this.planDetailsForm.controls.add_dashboard_user_count.setValue(this.data.formData.allowed_additional_user_count);
        this.planDetailsForm.controls.dashboard_user_count.setValue(this.data.formData.dashboard_count);
        this.planDetailsForm.controls.config_admin_user_count.setValue(this.data.formData.admin_count);
        this.sub_level = this.orgDetailsForm.controls.subscription_level.value;
        this.orgDetailsForm.controls.subscription_level.disable();
        //this.org_id = this.orgDetailsForm.value.org_id;
        //this.orgDetailsForm.controls.org_id.disable();
        this.org_domain_name = this.data.formData.domain_name;
        this.orgDetailsForm.controls.org_domain_name.disable();
    }

    saveOrg() {
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        this.errorMsg = '';

        const formData = new FormData();

        
        formData.append('org_display_name', this.orgDetailsForm.value.org_display_name);
        
        let org_id = '';
        if (this.data.action === 'View/Edit') {
          
            org_id = this.data.formData.org_id;
            formData.append('org_domain_name',  this.org_domain_name);
            formData.append('org_id', this.data.formData.org_id);
            formData.append('subscription_level_id', this.sub_level);
        }else{
           
            formData.append('org_domain_name',  this.orgDetailsForm.value.org_domain_name);
            formData.append('subscription_level_id', this.orgDetailsForm.value.subscription_level);
        }

        this.orgService.createorEditOrgDetails(obj, formData, org_id).subscribe(() => {
            this.loader = false;
            this.dialogRef.close(false);

        }, (error) => {
            if (error.status === 403 && error.error) {
                this.errorMsg = error.error;
            }
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // else if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            this.loader = false;
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }

    close() {
        this.dialogRef.close(true);
    }

    getAllSubcriptionLevels(){
        const obj = {
            "Authorization": this.authtoken
        }
        this.loader = true;
        this.subscriptionService.getSubscriptionLevelDetails(obj).subscribe((res) => {
            this.loader = false;
            const subscribtionLevels = Object.entries(res).map(([type, value]) => ({ type, value }));
            for(const sub in subscribtionLevels){
                if(this.data.action == 'Add' && (subscribtionLevels[sub]['value']['subscription_level'] == 'Trial Period' || subscribtionLevels[sub]['value']['subscription_level'] == 'Stratizant')){
                    const data = {
                        'sub_id':subscribtionLevels[sub]['type'],
                        'subscription_level': subscribtionLevels[sub]['value']['subscription_level']
                    }
                    this.subscriptions.push(data);
                }else if(this.data.action == 'View/Edit'){
                    const data = {
                        'sub_id': subscribtionLevels[sub]['type'],
                        'subscription_level': subscribtionLevels[sub]['value']['subscription_level']
                    }
                    this.subscriptions.push(data);
                }
                    
                if(this.data.action == 'View/Edit' &&  subscribtionLevels[sub]['value']['subscription_level'] == this.data.formData.subscription_level){
                    this.planDetailsForm.patchValue({
                        dashboard_user_count: subscribtionLevels[sub]['value']['dashboard_user_count'],
                        config_admin_user_count: subscribtionLevels[sub]['value']['config_admin_user_count'],
                        // add_dashboard_user_count: res[sub]['additional_dashboard_user_count']
                    })
                }
            }
           
        }, (error) => {
            this.loader = false;
            if (error.status === 403 && error.error) {
                this.errorMsg = error.error;
                // error && error.error == 'Unauthorized Access'
            }
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // else if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

   

    getSubscriptionsList(){
        const obj = {
            "Authorization": this.authtoken
        }
        this.orgDetailsForm.controls.subscription_level.setValue(this.data.formData.subscription_level_id);
        this.sub_level = this.data.formData.subscription_level_id;
        this.loader = true;
        this.orgService.getSubscriptionsList(obj).subscribe((res) => {
            this.loader = false;
            const data = JSON.parse(res);
            const subscriptions = data.data;
            for(const sub in subscriptions){
                if(subscriptions[sub]['status'] == 'active' && subscriptions[sub]['metadata'] && subscriptions[sub]['metadata']['organisation_id'] == this.data.formData.org_id){
                    const plan_purchase_date = new Date(subscriptions[sub]['created'] * 1000);
                    const plan_renewal_date = !subscriptions[sub]['cancel_at_period_end']? new Date(subscriptions[sub]['current_period_end'] * 1000):'';
                    const plan_end_date = subscriptions[sub]['cancel_at_period_end']? new Date(subscriptions[sub]['current_period_end'] * 1000): '';
                    this.planDetailsForm.patchValue({
                        plan_purchase_date: this.pipe.transform(plan_purchase_date, 'MM/d/y'),
                        plan_renewal_date: this.pipe.transform(plan_renewal_date, 'MM/d/y'),
                        plan_end_date: this.pipe.transform(plan_end_date, 'MM/d/y'),
                        amount_paid: subscriptions[sub]['plan']['amount'] / 100
                    })
                    for(const subIndex in this.subscriptions){
                        if(this.subscriptions[subIndex]['subscription_level'] == 'Enterprise' && subscriptions[sub]['metadata']['plan_name'] == 'Enterprise'){
                            this.orgDetailsForm.controls.subscription_level.setValue(this.subscriptions[subIndex]['sub_id']);
                            this.sub_level= this.subscriptions[subIndex]['sub_id'];
                            break;
                        }else if(this.subscriptions[subIndex]['subscription_level'] == 'Take-Off' && subscriptions[sub]['metadata']['plan_name'] == 'Take-Off'){
                            this.orgDetailsForm.controls.subscription_level.setValue(this.subscriptions[subIndex]['sub_id']);
                            this.sub_level = this.subscriptions[subIndex]['sub_id'];
                            break;
                        }else if(this.subscriptions[subIndex]['subscription_level'] == 'Initiator' && subscriptions[sub]['metadata']['plan_name'] == 'Initiator'){
                            this.orgDetailsForm.controls.subscription_level.setValue(this.subscriptions[subIndex]['sub_id']);
                            this.sub_level= this.subscriptions[subIndex]['sub_id'];
                            break;
                        }
                        // else if(this.subscriptions[subIndex]['subscription_level'] == 'Stratizant' && subscriptions[sub]['metadata']['plan_name'] == 'Stratizant'){
                        //     this.orgDetailsForm.controls.subscription_level.setValue(this.subscriptions[subIndex]['sub_id']);
                        //     break;
                        // }else if(this.subscriptions[subIndex]['subscription_level'] == 'Trial Period'){
                        //     this.orgDetailsForm.controls.subscription_level.setValue(this.subscriptions[subIndex]['sub_id']);
                        // }
                    }
                    
                     
                }
                
            }
        }, (error) => {
            this.loader = false;
           
            if (error.status === 403 && error.error) {
                this.errorMsg = error.error;
            }
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // else if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
              
            // }
        });
    }


}
