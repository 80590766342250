import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SignUpService {

    basepath = environment.basePath;
    cloud_function_path: any = environment.user_management_cloud_function;

    constructor(private http: HttpClient, public afAuth: AngularFireAuth) { }

    signUp(userData: any, data): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer'+' '+ data.Authorization,
      })
    }

        return this.http.post<any>(`${this.basepath + 'api/profile/user'}` , userData, httpOptions).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));

    }

    getOrganizationList(data): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        }

        return this.http.get<any>(`${this.basepath + 'api/list_organisations'}`, httpOptions);

    }

    signIn(username, password) {

        const promise = new Promise((resolve, reject) => {
            this.afAuth.signInWithEmailAndPassword(username,password)
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
        return promise;
    }


}
