import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import { UserProfileService } from '../../user-profile.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { DeleteSuccessModalComponent } from './delete-success-modal/delete-success-modal.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-deletesubscription-modal',
    templateUrl: './deletesubscription-modal.component.html',
    styleUrls: ['./deletesubscription-modal.component.css']
})
export class DeletesubscriptionModalComponent implements OnInit {

    subscriptionId: any;
    loader: any;
    authtoken: any;
    lastloginatTimeDifference: any;
    currentPeriodEndDate: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userProfileService: UserProfileService,
                public dialogRef: MatDialogRef<DeletesubscriptionModalComponent>,
                private fbAuth: StratizantFirebaseAuthService,
                private matDialog: MatDialog,
                private cookieServiceVariable: CookieService
    ) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        this.subscriptionId = this.data;
        this.currentPeriodEndDate = new Date(this.data.currentPeriodEndDate * 1000).toDateString();
 
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken =  this.cookieServiceVariable.get('auth_token_1');
      

       
    }



    close() {
        this.dialogRef.close('false');
    }


    cancelSubscription() {

        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };

        const formData = new FormData();
        formData.append('subscription_id', this.subscriptionId.id);
        formData.append('organisation_id', localStorage.getItem('organisation_id'));
        this.userProfileService.cancelSubscription(formData,data ).subscribe(res => {
            this.loader = false;
            const data = {
                flag: res
            }
            this.dialogRef.close(data);
            this.getStripeSubscriptionDetails();

            const dialogConfig = new MatDialogConfig();
            dialogConfig.data  = {
                message: res.message,
            }
            this.matDialog.open(DeleteSuccessModalComponent, dialogConfig);
            


        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error === 'No Session Cookie' || error.error === 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
                
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }

    getStripeSubscriptionDetails() {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.userProfileService.getStripeSubscriptionDetails(data).subscribe(res => {
            this.loader = false;
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error === 'No Session Cookie' || error.error === 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


 
}

