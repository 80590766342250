import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {environment} from '../../environments/environment'

@Injectable({
    providedIn: 'root'
})
export class StratizantFirebaseService {
    static config = {
        apiKey: environment.firebase.apiKey,
        authDomain:  environment.firebase.authDomain,
        databaseURL: environment.firebase.databaseURL,
        projectId: environment.firebase.projectId,
        storageBucket: environment.firebase.storageBucket,
        messagingSenderId: environment.firebase.measurementId,
        appId: environment.firebase.appId,
        measurementId: environment.firebase.measurementId
    };
    // fb = firebase.initializeApp(this.config);
    // db = this.fb.firestore();

    constructor() {
    }
}
