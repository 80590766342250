import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StratizantFireStoreService } from '../../../services/stratizant-fire-store.service';
import * as c3 from 'c3';
import { TableLevel1Service } from '../table-level1.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';

@Component({
    selector: 'app-level1-analysis',
    templateUrl: './level1-analysis.component.html',
    styleUrls: ['./level1-analysis.component.css']
})
export class Level1AnalysisComponent implements OnInit {
    durationDate: any;
    loader = false;
    noRecordsMsg: any;
    suppliers: any = [];
    trustScores: any = [];
    impactedTrustScores: any = [];
    @Input('sortedList') sortedList: any = [];
    @Output() selectRow = new EventEmitter<string>();

    constructor(private route: ActivatedRoute, private fireStoreService: StratizantFireStoreService,
                private router: Router, private level1Service: TableLevel1Service,private matDialog: MatDialog,
                private firebaseAuthService: StratizantFirebaseAuthService) {
    }

    ngOnInit() {
        // this.showTrustscoreAnalysis();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sortedList.currentValue.length != 0) {
            this.formatData(changes.sortedList.currentValue);
        } else{
            this.trustScores = [];
            this.impactedTrustScores = [];
            this.suppliers = [];
            this.suppliers[0] = 'x';
            this.trustScores[0] = 'Trust_Score';
            this.impactedTrustScores[0] = 'Impacted_Trust_Score';
            this.durationDate = '';
            this.generateBarChart();
    
        }
    }

    showTrustscoreAnalysis() {

        this.loader = true;
        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };
        this.level1Service.gettrustScoreCardSummary(data,'').subscribe(response => {

            this.formatData(response);

        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });

    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }


    formatData(response) {

        const resData = response;
      
        const timestamp = resData[0]['value']['timestamp'];
        const dateString = new Date(timestamp).toUTCString();
        this.durationDate = dateString.split(' ').slice(0, 4).join(' ');

        const newData = [];
        this.suppliers = [];
        this.trustScores = [];
        this.impactedTrustScores = [];
        this.suppliers[0] = 'x';
        this.trustScores[0] = 'Trust_Score';
        this.impactedTrustScores[0] = 'Impacted_Trust_Score';
        const imp_cnt = 0;
        for (const key in resData) {
            if (newData.length != 0) {
                let cnt = 0;
                for (const dt in newData) {

                    if (newData[dt]['value']['Supplier'] == resData[key]['value']['Supplier']) {
                        newData[dt]['total_count'] = newData[dt]['total_count'] + 1;
                        newData[dt]['value']['Trust_Score'] = newData[dt]['value']['Trust Score'] + resData[key]['value']['Trust_Score'];
                        if (newData[dt]['value']['Impacted_Delivery_date'] || newData[dt]['value']['Impacted_Trust_Score']) {
                            newData[dt]['impact_total_count'] = newData[dt]['impact_total_count'] + 1;
                            newData[dt]['value']['Impacted_Trust_Score'] = newData[dt]['value']['Impacted_Trust_Score'] + resData[key]['value']['Impacted_Trust_Score'];
                        }
                        break;
                    } else {
                        cnt++;
                    }
                    if (cnt == newData.length) {
                        this.suppliers.push(resData[key]['value']['Supplier']);
                        newData.push(resData[key]);
                        newData[newData.length - 1]['total_count'] = 1;
                        newData[newData.length - 1]['impact_total_count'] = 1;
                    }
                }
            } else {
                this.suppliers.push(resData[key]['value']['Supplier']);
                newData.push(resData[key]);
                newData[0]['total_count'] = 1;
                if (resData[key]['value']['Impacted_Delivery_Date'] || resData[key]['value']['Impacted_Trust_Score']) {
                    newData[0]['impact_total_count'] = 1;
                }
            }
        }

        for (const j in newData) {
            newData[j]['value']['Trust_Score'] = newData[j]['value']['Trust_Score'] / newData[j]['total_count'];
            newData[j]['value']['Trust_Score'] = JSON.stringify(Math.round(newData[j]['value']['Trust_Score']));
            this.trustScores.push(newData[j]['value']['Trust_Score']);

            newData[j]['value']['Impacted_Trust_Score'] = newData[j]['value']['Impacted_Trust_Score'] / newData[j]['impact_total_count'];
            newData[j]['value']['Impacted_Trust_Score'] = JSON.stringify(Math.round(newData[j]['value']['Impacted_Trust_Score']));
            this.impactedTrustScores.push(newData[j]['value']['Impacted_Trust_Score']);
            //newData[j]['Trust Score'] = newData[j]['Trust Score'] +'%'
        }

        this.generateBarChart();
        this.loader = false;
    }


    generateBarChart() {


        const chart = c3.generate({
            bindto: '#barchart',
            size: {
                height: 450
            },
            data: {
                x: 'x',
                columns: [
                    this.suppliers
                ],
                type: 'bar',
                colors: {
                    'Trust_Score': '#21627F',
                    'Impacted_Trust_Score': '#71B9DA'
                },

                onclick: (d, element) => {
                    this.selectedBar(d);
                }
            },
            transition: {
                duration: 2000
            },

            tooltip: {
                show: true
            },
            axis: {
                rotated: false,
                x: {
                    type: 'category',
                    tick: {
                        outer: false,
                        rotate: 90,
                        multiline: false
                    },
                    height: 130,
                    label: {
                        text: 'Supplier',
                        position: 'outer-center'
                    }
                },
                y: {
                    max: 100,
                    tick: {
                        values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                    },
                    show: true,
                    padding: { top: 0.3, bottom: 0 },
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle'
                    }
                },


            },

            bar: {
                width: {
                    ratio: 0.4
                }

            },
            legend: {
                hide: true,
                show: false,
            },
        });

        const sup = this.suppliers;
        const trustScore = this.trustScores;
        const imp_trust_score = this.impactedTrustScores;

        setTimeout(function () {
            chart.load({
                columns: [
                    sup,
                    trustScore,
                    imp_trust_score
                ]
            });
        }, 500);

        setTimeout(() => {
            chart.regions(
                [{ axis: 'y', start: 0, end: 20, class: 'region1' },
                    { axis: 'y', start: 20, end: 40, class: 'region2' },
                    { axis: 'y', start: 40, end: 80, class: 'region3' },
                    { axis: 'y', start: 80, class: 'region4' }]);
        }, 500);

        // if (imp_trust_score[1] != 'null') {
        //   setTimeout(function() {
        //     chart.load({
        //       columns: [
        //         sup,
        //         trustScore,
        //         imp_trust_score
        //       ]
        //     });
        //   }, 500);
        // } else {
        //   setTimeout(function() {
        //     chart.load({
        //       columns: [
        //         sup,
        //         trustScore
        //       ]
        //     });
        //   }, 500);
        // }


    }


    selectedBar(data) {
        this.selectRow.emit(data.index);
    }


}
