import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserProfileService {
    basepath = environment.basePath;

    constructor(private http: HttpClient) {
    }

    getUserDetails(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };

        return this.http.get<any>(`${this.basepath + 'api/profile/user_details'}`,httpOptions);

        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response'
        // };
        // return this.http.get<any>(`${this.basepath + 'api/profile/user_details'}`,httpOptions);
    }

    updateUserDetails(userData: any, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };

        // const httpOptions = {
        //     withCredentials: true,
        //     observe: 'response' as 'response'
        // };

        return this.http.put<any>(`${this.basepath + 'api/profile/user_details'}`, userData, httpOptions);

    }


    changePassword(userData: any, data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        // const httpOptions = {
        //     withCredentials: true,
        //     observe: 'response' as 'response'
        // };

        return this.http.put<any>(`${this.basepath + 'api/profile/change_password'}`, userData, httpOptions);

    }


    getStripeSubscriptionDetails(data){
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };

        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response'
        // };
    
        return this.http.get<any>(`${this.basepath + 'api/profile/stripe_subscriptions'}`, httpOptions);
    
    }

    cancelSubscription(subId, data): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' +data.Authorization,
            }),
            body: subId
        }
        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response',
        //     body: subId
        // };
            

        return this.http.delete<any>(`${this.basepath + 'api/profile/stripe_subscriptions'}`, httpOptions);

    }

    // changeSubscription(data: any, userData: any) {
    //     const httpOptions = {
    //         headers: new HttpHeaders({
    //             'Authorization': 'Bearer' + ' ' + data.Authorization,
    //         })
    //     };

    //     return this.http.put<any>(`${this.basepath + 'api/profile/checkout'}`, userData, httpOptions);

    // }


}
