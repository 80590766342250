import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AdminSettingsService } from '../../admin-settings.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
@Component({
    selector: 'app-add-new-impact-type',
    templateUrl: './add-new-impact-type.component.html',
    styleUrls: ['./add-new-impact-type.component.css']
})
export class AddNewImpactTypeComponent implements OnInit {
    newImpactTypeForm: FormGroup;
    authtoken: any;
    loader = false;
    impactTypeDropDawnList: any;
    imptypeAvailable: boolean;
    addedImpactTypeMsg: any
    errorMsg: any;
    closepopup: boolean;
    constructor(private fb: FormBuilder, private adminService: AdminSettingsService,
                private cookieServiceVariable: CookieService,
                private matDialog: MatDialog, private firebaseAuthService: StratizantFirebaseAuthService,
                @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddNewImpactTypeComponent>, ) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.createAddNewImpactForm();
        this.getImpactTypeList();

    }
    createAddNewImpactForm() {
        this.newImpactTypeForm = this.fb.group({
            impact_type: ['', Validators.required],
        });
    }

    close() {
        this.dialogRef.close();
    }


    saveImpactType() {
        const token = {
            'Authorization': this.authtoken
        };
        this.loader = true;

        const formData = new FormData();
        formData.append('mode', 'API');
        formData.append('switch', 'OFF');
        formData.append('impact_type', this.newImpactTypeForm.value.impact_type);

        this.adminService.changeMode(formData, token).subscribe(() => {
            this.loader = false;
            this.addedImpactTypeMsg = "Successfully added new impact type.";
            if (this.addedImpactTypeMsg) {
                setTimeout(function () {
                    document.getElementById("addedImpactTypeMsg").innerHTML = '';
                    // this.closepopup = true;

                },8000);
            }


            this.dialogRef.close();


        }, (error) => {
            console.log(error);
            this.loader = false;
        });
    }


    getImpactTypeList() {
        const obj = {
            'Authorization': this.authtoken
        };
        this.errorMsg = '';
        this.adminService.getModeStatus(obj).subscribe(res => {


            this.impactTypeDropDawnList = Object.keys(res);
            for (const imp_type in this.impactTypeDropDawnList) {
                if (this.newImpactTypeForm.value.impact_type == this.impactTypeDropDawnList[imp_type]) {
                    this.imptypeAvailable = true;
                    break
                } else {
                    this.imptypeAvailable = false;
                }
            }
        }, (error) => {
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
        });

    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }

    chcekImpactTypeExist() {

        for (const imp_type in this.impactTypeDropDawnList) {
            if (this.newImpactTypeForm.value.impact_type.toLowerCase() == this.impactTypeDropDawnList[imp_type].toLowerCase()) {
                this.imptypeAvailable = true;
                break
            } else {
                this.imptypeAvailable = false;
            }
        }


    }

}
