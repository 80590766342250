/* eslint-disable no-unused-vars */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource } from '@angular/material';
import { UploadDialogComponent } from '../upload-dialog/upload-dialog.component';
import { AdminSettingsService } from '../admin-settings.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from '../../../services/stratizant-firebase-auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ImpactTypeComponent } from './impact-type/impact-type.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AddManualImpactDataModalComponent } from './add-manual-impact-data-modal/add-manual-impact-data-modal.component';
import { TableLevel1Service } from '../../table-level1/table-level1.service';
import { AddNewImpactTypeComponent } from './add-new-impact-type/add-new-impact-type.component';
import { CookieService } from 'ngx-cookie-service';
import { AddManualImpactDataModalService } from './add-manual-impact-data-modal/add-manual-impact-data-modal.service';
declare let $: any;
// import { WebStorageService } from "ngx-web-storage";
@Component({
    selector: 'app-impact-data-load',
    templateUrl: './impact-data-load.component.html',
    styleUrls: ['./impact-data-load.component.css']
})
export class ImpactDataLoadComponent implements OnInit {

    public displayedColumns = ['file_name', 'file_type', 'impact_type', 'uploaded_date', 'uploaded_by'];
    public displayedColumnsManualImpactData = ['supplier', 'country', 'Min_Delay', 'Max_Delay', 'Impact_Start_Date', 'Impact_End_Date', 'action'];
    public dataSource = new MatTableDataSource();
    public dataSourceManualImpactData = new MatTableDataSource();
    manualImpactData: any = [];
    imageToShow: any;
    iconAvailable: boolean;
    disabletabs: boolean;
    impactTypeDropDawnList: any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('manualImpactPaginator', { static: false }) manualImpactPaginator: MatPaginator;

    loader = false;
    authtoken: any;
    typeofDataToUpload: any;
    errorMsg: any;
    toggleErrorMsg: any;
    noRecordsMsg: any;
    hideUploadFileBtn = true;
    manualOff: boolean;
    iconNotFound: boolean;
    disablemanualIPOption: boolean = true;
    dataAvailable: boolean;
    manualImpact: boolean;

    columnDirections = {
        'file_name': '',
        'file_type': '',
        'impact_type': '',
        'uploaded_date': '',
        'uploaded_by': ''
    };

    columnDirectionsManual = {
        'uploaded_date': '',
    };
    sortedData = [];
    sortedDataManual = [];
    toggleForm: FormGroup;
    modeStatus: any;
    impactTypeForm: FormGroup;
    actionForm: FormGroup;
    apiToggle: boolean;
    csvToggle: boolean;
    disableAddImpactBtn: boolean;
    noManualImpactDataFound: boolean;
    newSelectedImpactType: any = 'COVID-19';
    impt: any;
    lastloginatTimeDifference: any;
    // private storage: WebStorageService,
    constructor(private matDialog: MatDialog, private addManualImpactDataService: AddManualImpactDataModalService, private adminService: AdminSettingsService, private fbAuth: StratizantFirebaseAuthService,
        private fb: FormBuilder,
        private cookieServiceVariable: CookieService,
        private afStorage: AngularFireStorage, private level1Service: TableLevel1Service) {
    }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');
        this.authtoken = this.cookieServiceVariable.get('auth_token_1')
        localStorage.setItem('impact_type', 'COVID-19');
        this.getLogs();
        this.toggleForm = this.fb.group({
            api_call: [false],
            csv_upload: [false],
            manual_input: [false]
        });
        this.impactTypeForm = this.fb.group({
            impact_type: ['COVID-19', Validators.required]
        });


        this.getModeStatus();
        // this.getManualImpactData();
        this.createactionform();
        // this.getIcon(localStorage.getItem('impact_type'));
        // this.getManualImpactDataList();

        // this.getManualImpactDataList();

        this.impt = sessionStorage.getItem('impact_typeSession');

        // this.getLogs();

        if (this.impt) {
            this.getIcon(this.impt);

            this.impactTypeForm.controls.impact_type.setValue(this.impt);
        } else {
            this.getIcon('COVID-19');
        }





    }


    // ngAfterViewInit() {
    //   $('#iconDiv').addClass('col-md-offset-2');
    //   $('#toggleTextCsv').addClass('toggleTextCSS');
    // }




    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSourceManualImpactData.paginator = this.manualImpactPaginator;
    }

    actionToPerform(value, element) {

        if (value == 'viewedit') {
            this.openAddManualImpactModal('Update', element);
        } else if (value == 'delete') {
            this.openAddManualImpactModal('Delete', element);
        }

    }

    createactionform() {
        this.actionForm = this.fb.group({
            action: ['0'],
        });
    }
    getManualImpactData() {
        this.manualImpactData = [
            {
                'supplier': "ABC",
                'country': "India",
                'min_delay': "1",
                'max_delay': "5",
                'start_date': "1/2/2020",
                'end_date': "10/6/2020"
            }
        ]
        this.dataSourceManualImpactData = new MatTableDataSource(this.manualImpactData);
        setTimeout(() =>
            this.dataSourceManualImpactData.paginator = this.manualImpactPaginator
        );
    }

    openAddManualImpactModal(action, element) {
        const dialogConfig = new MatDialogConfig();
        if (action === 'Update') {
            dialogConfig.data = {
                'formData': element,
                'action': action,
                // 'uploaded_by':,
                'impact_type': this.newSelectedImpactType

            };
        } else if (action == 'Add') {
            dialogConfig.data = {
                'formData': element,
                'action': action,
                'impact_type': this.newSelectedImpactType
                // // 'uploaded_by':,
                // 'impact_type': this.newSelectedImpactType
            };
        } else if (action == 'Delete') {
            dialogConfig.data = {
                'formData': element,
                'action': action,
                'impact_type': this.newSelectedImpactType
            };

        }
        const dialogRef = this.matDialog.open(AddManualImpactDataModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.actionForm.patchValue({
                action: ['0']
            })
            this.getManualImpactDataList();
        });

    }
    selectedDialog(val) {

        let data = {};
        if (val == 'Data') {
            data = {
                title: 'Upload Impact Data',
                upload_type: 'Data',
                impactType: this.impactTypeForm.value.impact_type,

            };
        } else if (val == 'Icon') {
            data = {
                title: 'Upload Impact Icon',
                upload_type: 'Icon',
                impactType: this.impactTypeForm.value.impact_type,

            };
        }
        // else if (val === true) {
        //     data = {
        //         fileTypeToUpload: fileType,
        //         fileUploaded: true
        //     };
        // }
        this.openDialog(data);
    }

    openDialog(data) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = data;
        const dialogRef = this.matDialog.open(UploadDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            if (value) {
                this.uploadFileIcon(value);
                // this.getIcon(this.newSelectedImpactType);
                // this.getLogs();
            }

        });

    }

    uploadFileIcon(data) {
        this.loader = true;
        const formData = new FormData();
        formData.append('file', data.fileToUpload);
        formData.append('impact_type', data.impact_type);
        formData.append('file_type', data.file_type);
        formData.append('uploaded_by', localStorage.getItem('user'));


        const obj = {
            'Authorization': this.authtoken
        };
        this.errorMsg = '';
        this.adminService.uploadFileIcon(formData, obj, data.file_type).subscribe(() => {
            // this.loader = false;
            this.getLogs();
            this.errorMsg = '';
            this.getIcon(this.newSelectedImpactType);


        }, error => {

            this.loader = false;
            if (error && error.status == 406) {
                this.errorMsg = error.error;
            }
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            //  else if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


    getLogs() {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };

        this.newSelectedImpactType = sessionStorage.getItem('impact_typeSession');
        if (this.newSelectedImpactType === null) {
            this.newSelectedImpactType = 'COVID-19';
        }

        this.adminService.getLogs(data, this.newSelectedImpactType).subscribe(res => {
            this.loader = false;
            if (Object.keys(res).length === 0) {
                this.noRecordsMsg = "No Records Found.";
                return;
            }
            const new_array = Object.entries(res).map(([type, value]) => ({ type, value }));
            const array1 = [];
            for (const imp_type in new_array) {

                if (new_array[imp_type]['value']['impact_type'] === this.newSelectedImpactType) {
                    this.dataAvailable = true;
                    array1.push(new_array[imp_type]);
                }

            }

            const sortState = {
                'active': 'uploaded_date',
                'direction': 'desc'
            };
            this.columnDirections['uploaded_date'] = '';
            this.customSort(sortState, array1)


        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            console.log(error);
        });
    }


    customSort(val, dataArray) {

        if (val) {
            const data = dataArray.slice();
            this.sortedData = data.sort((a, b) => {
                let isAsc;
                if (val.direction == '') {
                    isAsc = true;
                    val.direction = 'asc';
                } else if (val.direction == this.columnDirections[val.active] && val.direction == 'asc') {
                    isAsc = false;
                    val.direction = 'desc';
                } else if (val.direction == this.columnDirections[val.active] && val.direction == 'desc') {
                    isAsc = true;
                    val.direction = 'asc';
                } else {
                    isAsc = val.direction === 'asc';
                }


                switch (val.active) {
                    case 'file_name':
                        return this.compare(a['value']['file_name'], b['value']['file_name'], isAsc);
                    case 'file_type':
                        return this.compare(a['value']['file_type'], b['value']['file_type'], isAsc);
                    case 'impact_type':
                        return this.compare(a['value']['impact_type'], b['value']['impact_type'], isAsc);
                    case 'uploaded_by':
                        return this.compare(a['value']['uploaded_by'], b['value']['uploaded_by'], isAsc);
                    case 'uploaded_date':
                        return this.compare(new Date(a['value']['uploaded_date']), new Date(b['value']['uploaded_date']), isAsc);

                    default:
                        return 0;
                }
            });

            this.dataSource = new MatTableDataSource(this.sortedData);
            setTimeout(() =>
                this.dataSource.paginator = this.paginator
            );

            this.columnDirections[val.active] = val.direction;

        }

    }


    customSortManual(val, dataArray) {

        if (val) {
            const data = dataArray.slice();
            this.sortedDataManual = data.sort((a, b) => {
                let isAsc;
                if (val.direction == '') {
                    isAsc = true;
                    val.direction = 'asc';
                } else if (val.direction == this.columnDirectionsManual[val.active] && val.direction == 'asc') {
                    isAsc = false;
                    val.direction = 'desc';
                } else if (val.direction == this.columnDirectionsManual[val.active] && val.direction == 'desc') {
                    isAsc = true;
                    val.direction = 'asc';
                } else {
                    isAsc = val.direction === 'asc';
                }


                switch (val.active) {
                    case 'uploaded_date':
                        return this.compare(new Date(a['value']['uploaded_date']), new Date(b['value']['uploaded_date']), isAsc);

                    default:
                        return 0;
                }
            });

            this.dataSourceManualImpactData = new MatTableDataSource(this.sortedDataManual);
            setTimeout(() =>
                this.dataSourceManualImpactData.paginator = this.manualImpactPaginator
            );

            this.columnDirectionsManual[val.active] = val.direction;

        }

    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    //To show that user has been logged out if token expires
    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }



    //To capture mat slider changed value
    slideChanged(event, val) {
        if (this.modeStatus) {
            if (event.checked === true && val === 'api_call') {
                $('#toggleTextApi').removeClass('toggleTextCSS');
                const data = {
                    'mode': 'API',
                    'switch': 'ON',
                    'modeStatus': this.modeStatus
                }
                this.changeMode(data);
            } else if (event.checked === false && val === 'api_call') {
                $('#toggleTextApi').addClass('toggleTextCSS');
                const data = {
                    'mode': 'API',
                    'switch': 'OFF',
                    'modeStatus': this.modeStatus
                }
                this.changeMode(data);
            } else if (event.checked === true && val === 'csv_upload') {
                this.hideUploadFileBtn = false;
                $('#toggleTextCsv').removeClass('toggleTextCSS');
                const data = {
                    'mode': 'CSV',
                    'switch': 'ON',
                    'modeStatus': this.modeStatus
                }
                this.changeMode(data);
            } else if (event.checked === false && val === 'csv_upload') {
                this.hideUploadFileBtn = true;
                // $('#iconDiv').addClass('col-md-offset-2');
                $('#toggleTextCsv').addClass('toggleTextCSS');
                const data = {
                    'mode': 'CSV',
                    'switch': 'OFF',
                    'modeStatus': this.modeStatus
                }
                this.changeMode(data);
            }
            else if (event.checked === true && val === 'manual_input') {
                $('#toggleTextManualIp').removeClass('toggleTextCSS');
                const data = {
                    'mode': 'Manual',
                    'switch': 'ON',
                    'modeStatus': this.modeStatus
                }
                this.changeMode(data);
            } else if (event.checked === false && val === 'manual_input') {
                $('#toggleTextManualIp').addClass('toggleTextCSS');
                const data = {
                    'mode': 'Manual',
                    'switch': 'OFF',
                    'modeStatus': this.modeStatus
                }
                this.changeMode(data);
            }
        }
    }

    changeMode(data) {
        this.toggleErrorMsg = '';
        if (Object.keys(data.modeStatus).length != 0) {
            for (const impact_type in data.modeStatus) {
                if (data.mode === 'API' && data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && data.modeStatus[impact_type]['CSV'] && data.modeStatus[impact_type]['CSV'] === true) {
                    this.toggleErrorMsg = 'CSV upload is On for the selected Impact Type. Please make it Off and try again.'
                    $('#toggleTextApi').addClass('toggleTextCSS');
                    this.getModeStatus();
                    return;
                } else if (data.mode === 'API' && data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && data.modeStatus[impact_type]['Manual'] && data.modeStatus[impact_type]['Manual'] === true) {
                    this.toggleErrorMsg = 'Manual input impact is On for the selected Impact Type. Please make it Off and try again.'
                    $('#toggleTextApi').addClass('toggleTextCSS');
                    this.getModeStatus();
                    return;
                }
                else if (data.mode === 'CSV' && data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && data.modeStatus[impact_type]['API'] && data.modeStatus[impact_type]['API'] === true) {
                    this.toggleErrorMsg = 'API call is On for the selected Impact Type. Please make it Off and try again.'
                    this.hideUploadFileBtn = true;
                    this.getModeStatus();
                    // $('#iconDiv').addClass('col-md-offset-2');
                    $('#toggleTextCsv').addClass('toggleTextCSS');
                    return;
                } else if (data.mode === 'CSV' && data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && data.modeStatus[impact_type]['Manual'] && data.modeStatus[impact_type]['Manual'] === true) {
                    this.toggleErrorMsg = 'Manual input impact is On for the selected Impact Type. Please make it Off and try again.'
                    this.hideUploadFileBtn = true;
                    this.getModeStatus();
                    // $('#iconDiv').addClass('col-md-offset-2');
                    $('#toggleTextCsv').addClass('toggleTextCSS');
                    return;
                }
                else if (data.mode === 'Manual' && data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && data.modeStatus[impact_type]['CSV'] && data.modeStatus[impact_type]['CSV'] === true) {
                    this.toggleErrorMsg = 'CSV upload is On for the selected Impact Type. Please make it Off and try again.'
                    $('#toggleTextManualIp').addClass('toggleTextCSS');
                    this.getModeStatus();
                    return;
                } else if (data.mode === 'Manual' && data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && data.modeStatus[impact_type]['API'] && data.modeStatus[impact_type]['API'] === true) {
                    this.toggleErrorMsg = 'API call is On for the selected Impact Type. Please make it Off and try again.'
                    $('#toggleTextManualIp').addClass('toggleTextCSS');
                    this.getModeStatus();
                    return;
                }
            }
        }

        const token = {
            'Authorization': this.authtoken
        };
        this.loader = true;
        const formData = new FormData();
        formData.append('mode', data.mode);
        formData.append('switch', data.switch);
        formData.append('impact_type', this.impactTypeForm.value.impact_type);
        this.adminService.changeMode(formData, token).subscribe(() => {
            this.loader = false;
            this.getModeStatus();
        }, (error) => {
            console.log(error);
            this.loader = false;
        });
    }


    openImpactTypeDialog(toggle_data) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            'toggle_data': toggle_data,
            'modeStatus': this.modeStatus
        };

        const dialogRef = this.matDialog.open(ImpactTypeComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {

            this.getModeStatus();
        });

    }
    openAddNewImpactTypeModal() {
        let data;
        if (this.toggleForm.value.api_call == true) {
            data = {
                'mode': 'API',
                'switch': 'ON',
                'impact_type': this.impactTypeForm.value.impact_type
            }

        } else if (this.toggleForm.value.csv_upload == true) {
            data = {
                'mode': 'CSV',
                'switch': 'ON',
                'impact_type': this.impactTypeForm.value.impact_type
            }
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            'newImpactTypeData': data
        }
        const dialogRef = this.matDialog.open(AddNewImpactTypeComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.getModeStatus();
        });
    }
    getModeStatus() {
        const obj = {
            'Authorization': this.authtoken
        };
        this.errorMsg = '';
        this.adminService.getModeStatus(obj).subscribe(res => {
            this.modeStatus = res;


            this.impactTypeDropDawnList = Object.keys(res);
            if (res && Object.keys(res).length != 0) {
                for (const imp_type in res) {

                    if (imp_type === 'COVID-19' && res['COVID-19']['API'] === false && res['COVID-19']['CSV'] === false) {
                        this.disableAddImpactBtn = true;
                    } else if (imp_type === 'COVID-19' && res['COVID-19']['API'] === true || res['COVID-19']['CSV'] === true) {
                        this.disableAddImpactBtn = false;
                    }
                    if (imp_type === this.impactTypeForm.value.impact_type) {
                        const csvVal = res[imp_type]['CSV'];
                        const apiVal = res[imp_type]['API'];
                        const manualIpVal = res[imp_type]['Manual'];
                        this.toggleForm.controls.csv_upload.setValue(csvVal);
                        this.toggleForm.controls.api_call.setValue(apiVal);
                        this.toggleForm.controls.manual_input.setValue(manualIpVal);

                        if (res[imp_type]['Manual'] === true) {
                            this.manualImpact = true;
                        } else {
                            this.manualImpact = false;
                        }
                        if (res[imp_type]['API'] === true) {
                            $('#toggleTextApi').removeClass('toggleTextCSS');
                        } else {
                            $('#toggleTextApi').addClass('toggleTextCSS');
                        }
                        if (res[imp_type]['CSV'] === true) {
                            $('#toggleTextCsv').removeClass('toggleTextCSS');
                            this.hideUploadFileBtn = false;
                            // $('#iconDiv').removeClass('col-md-offset-2');
                        }
                        else {
                            $('#toggleTextCsv').addClass('toggleTextCSS');
                            this.hideUploadFileBtn = true;
                            // $('#iconDiv').addClass('col-md-offset-2');
                        }
                        if (res[imp_type]['Manual'] === true) {
                            $('#toggleTextManualIp').removeClass('toggleTextCSS');
                        } else {
                            $('#toggleTextManualIp').addClass('toggleTextCSS');
                        }
                        break;
                    }
                }
                // $('#toggleTextManualIp').addClass('toggleTextCSS');

            } else {
                $('#toggleTextApi').addClass('toggleTextCSS');
                $('#toggleTextCsv').addClass('toggleTextCSS');
                $('#toggleTextManualIp').addClass('toggleTextCSS');
                this.hideUploadFileBtn = true;

                this.toggleForm.controls.csv_upload.setValue(false);
                this.toggleForm.controls.api_call.setValue(false);
                this.toggleForm.controls.manual_input.setValue(false);
                // $('#iconDiv').addClass('col-md-offset-2');
            }
        }, error => {
            console.log(error);
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });

    }


    getIcon(impact_type) {
        const data = {
            'Authorization': this.authtoken
        };

        this.level1Service.getIconURL(data, impact_type).subscribe((res) => {
            if (res) {
                if (res.Path) {
                    this.iconAvailable = true;
                    this.iconNotFound = false;

                    this.disabletabs = false;
                    // this.getManualImpactData();
                    this.getLogs();
                    this.getManualImpactDataList();


                } else {
                    this.iconAvailable = false;
                    // this.disabletabs = true;

                }

                // if(this.iconAvailable === false) {
                //     this.disabletabs = true;
                // }else {
                //     this.disabletabs = false;
                //     this.getManualImpactData();
                //     this.getLogs();
                // }
            }
            if (res) {
                const storageRef = this.afStorage.storage.ref().child(res.Path);
                storageRef.getDownloadURL().then(url => {

                    this.imageToShow = url;
                });
            }

        }, error => {
            console.log(error);
            if (error.status === 404 && error.error === 'Icon not found') {
                this.iconNotFound = true;
                this.disabletabs = true;
            } 
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // else if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
            else {
                this.iconNotFound = false;
            }
            this.loader = false;
        });




    }


    changeImpactType(val) {
        // localStorage.setItem('impact_type', val);

        this.newSelectedImpactType = val;
        localStorage.setItem('impact_type', this.newSelectedImpactType);

        sessionStorage.setItem('impact_typeSession', this.newSelectedImpactType);
        // this.storage.session.set("impkey", val);
        this.dataAvailable = false;
        this.getModeStatus();
        this.getIcon(val);

    }


    // getManualImpactDataList() {
    //     this.loader = true;
    //     const data = {
    //         'Authorization': this.authtoken
    //     };

    //     this.adminService.getManualImpactDataList(data).subscribe(res => {
    //         this.loader = false;

    //         // const new_array = Object.entries(res).map(([type, value]) => ({ type, value }));
    //         // // const array;

    //         // const sortState = {
    //         //     'active': 'uploaded_date',
    //         //     'direction': 'desc'
    //         // };
    //         // this.columnDirections['uploaded_date'] = '';
    //         // this.customSort(sortState, array1)
    //         // if(this.newSelectedImpactType){
    //         //     this.customSort(sortState, array1);
    //         // }else {
    //         //     this.customSort(sortState, new_array);
    //         // }

    //     }, error => {
    //         this.loader = false;
    //         if (error && error.error == 'Unauthorized Access') {
    //             this.openAuthDialog();
    //         }
    //     });
    // }



    getManualImpactDataList() {
        this.loader = true;
        const obj = {
            'Authorization': this.authtoken
        };
        // this.errorMsg = '';


        this.newSelectedImpactType = sessionStorage.getItem('impact_typeSession');
        if (this.newSelectedImpactType === null) {
            this.newSelectedImpactType = 'COVID-19';
        }

        this.addManualImpactDataService.getManualImpactDataList(obj, this.newSelectedImpactType).subscribe((res) => {
            this.loader = false;
            if (Object.keys(res).length === 0) {
                this.noManualImpactDataFound = true;
            } else {
                this.noManualImpactDataFound = false;
            }

            const new_array = Object.entries(res).map(([type, value]) => ({ type, value }));

            this.dataSourceManualImpactData = new MatTableDataSource(new_array);

            const sortState = {
                'active': 'uploaded_date',
                'direction': 'desc'
            };
            this.columnDirectionsManual['uploaded_date'] = '';
            this.customSortManual(sortState, new_array)
            setTimeout(() =>
                this.dataSourceManualImpactData.paginator = this.manualImpactPaginator
            );
        }, (error) => {
            console.log(error);
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // this.loader = false;
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            // if (error && error.status == 406) {
            //     this.errorMsg = error.error;
            // } else if (error && error.error == 'Unauthorized Access') {
            //     this.openAuthDialog();
            // }
        });
    }

}
