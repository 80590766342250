import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { UploadSupplierDeliveryFileService } from '../../upload-supplier-delivry-data/upload-supplier-delivery-file/upload-supplier-delivery-file.service'
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { UploadSuccessComponent } from '../../impact-data-load/upload-success/upload-success.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-complete-mapping-dialog',
    templateUrl: './complete-mapping-dialog.component.html',
    styleUrls: ['./complete-mapping-dialog.component.css']
})
export class CompleteMappingDialogComponent implements OnInit {
    loader: boolean;
    cancelMappingFlag: boolean = false;
    authtoken: any;
    invaidFileMessage: any;
    mappingErrorMessage: any;
    lastloginatTimeDifference: any;
    constructor(public dialogRef: MatDialogRef<CompleteMappingDialogComponent>, private fbAuth: StratizantFirebaseAuthService,
                private matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
                private cookieServiceVariable: CookieService,
                private uploadSupplierDeliveryFileService:UploadSupplierDeliveryFileService) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        // this.authtoken = localStorage.getItem('auth_token');

         this. authtoken = this.cookieServiceVariable.get('auth_token_1');
        
    }

   

    close(){
        
        this.dialogRef.close(
            
        );
    }

    uploadPredictionDatasetFile() {
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        const formData = new FormData();
        formData.append('file', this.data.uploadedxlsxfile);
        formData.append('uploaded_by', localStorage.getItem('user'));
        this.uploadSupplierDeliveryFileService.uploadPredictionDatasetFile(obj, formData).subscribe((res) => {
            this.loader = false;
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
               
            // }else 
            if (error && error.error == "Exception Occurred"){
                this.mappingErrorMessage = error.error;
            }
        });
    }

 

    completeMapping() {
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        const formData = new FormData();

        let mappedColumns: any;

        formData.append('file', this.data.uploadedxlsxfile);
        formData.append('mapped_columns',JSON.stringify(this.data.mapping_columns));
        formData.append('uploaded_by', localStorage.getItem('user'));
        this.uploadSupplierDeliveryFileService.completeMapping(obj, formData).subscribe((res: any) => {
            this.loader = false;
            this.uploadPredictionDatasetFile();
            this.openUploadSuccessComponent()
          
            this.dialogRef.close(
                this.cancelMappingFlag
            );
               
           
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // Invalid date value in file
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }else
            if (error && error.error == "Exception Occurred"){
                this.mappingErrorMessage = error.error;
            }else if (error && error.error == "Column missing from file." && error.status == "400"){
                this.mappingErrorMessage = error.error;
            }else if (error && error.status == "400"){
                this.mappingErrorMessage = error.error;
            }else if (error && error.status == "401"){
                this.mappingErrorMessage = error.error;
            }
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();
        });

    }


    openUploadSuccessComponent() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = 'File Processed';
        const dialogRef = this.matDialog.open(UploadSuccessComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
        });
    }
}
