/* eslint-disable no-var */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { StratizantFireStoreService } from '../../services/stratizant-fire-store.service';
import { StratizantFirebaseAuthService } from '../../services/stratizant-firebase-auth.service';
import { TableLevel1Service } from '../table-level1/table-level1.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseService } from 'src/app/services/stratizant-firebase.service';

import { AngularFireStorage } from '@angular/fire/storage';
import { AdminSettingsService } from '../admin-settings/admin-settings.service';
import { CookieService } from 'ngx-cookie-service';
// import { count } from 'console';


declare let $: any;
@Component({
    selector: 'app-po-prediction-details',
    templateUrl: './po-prediction-details.component.html',
    styleUrls: ['./po-prediction-details.component.css'],
    host: {
        "(window:resize)":"onWindowResize($event)"
    }
})
export class PoPredictionDetailsComponent implements OnInit {
    pageOfItems: any;
    oldestToNewestLabel: any = "Oldest to Newest";
    soonestToLatestLabel: any = "Newest to Oldest";
    higestToLowestLabel: any = "Highest to Lowest";
    durationDate: any;
    static predictionData = [];
    tabledata = [];
    public finalData = [];
    noRecordsMsg: any;
    trustScoreSortState = {
        'active': 'Trust_Score',
        'direction': 'desc'
    };

    deliverydateSortState = {
        'active': 'Predicted_Posting_Date',
        'direction': 'asc'
    }

    commitmentdateSortState = {
        'active': 'Commitment_Date',
        'direction': 'desc'
    }

    public displayedColumns = ['Supplier', 'Material', 'Purchasing_Document', 'Item', 'Schedule_line',
        'Scheduled_quantity', 'ECD_Date', 'Predicted_Posting_Date', 'Trust_Score'];
    public dataSource = new MatTableDataSource();
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('trustSort', { static: false }) sort: MatSort;
    loader = false;
    columnDirections = {
        'Supplier': '',
        'Material': '',
        'Purchasing_Document': '',
        'Item': '',
        'Schedule_line': '',
        'Scheduled_quantity': '',
        'ECD_Date': '',
        'Predicted_Posting_Date': '',
        'Trust_Score': '',
        'Impacted_Delivery_Date': '',
        'Impacted_Trust_Score': '',
        'Commitment_Date': ''
    };
    imageToShow: any;
    authtoken: any;
    showColumns = true;
    manualImpactType: boolean = false
    impact_type: any = 'COVID-19';
    errorMsg: any;
    showImpactDate: any;
    showImpactScore: any;
    selectedImpactType: any;
    showImpactMsg: boolean;
    temp: any;
    cardData: any = [];
    greenRangeData: any[];
    redRangeData: any[];
    orangeRangeData: any[];
    yellowRangeData: any[];
    listView = true;
    gridView: boolean = false;
    sortedData: any;
    is_default: any;
    getSeletedPeriodData: any;
    lastloginatTimeDifference: any;

    isMobile: boolean = false;
    width:number = window.innerWidth;
    height:number = window.innerHeight;
    mobileWidth:number  = 760;

    constructor(private route: ActivatedRoute, private fireStoreService: StratizantFireStoreService,
                private firebaseAuthService: StratizantFirebaseAuthService, private router: Router, private level1Service: TableLevel1Service,
                private matDialog: MatDialog, private firebaseServices: StratizantFirebaseService,
                private afStorage: AngularFireStorage,
                private cookieServiceVariable: CookieService,
                private adminService: AdminSettingsService) {
    }

    ngOnInit() {
        // this.loader = true;
        this.selectedImpactType = localStorage.getItem('impact_type');
        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
       
        this.getModeStatus();
        this.getAllSuppliersData('');
        
        

    }


    onWindowResize(event) {
        this.width = event.target.innerWidth;
        this.height = event.target.innerHeight;
        this.isMobile = this.width < this.mobileWidth;
        console.log("width", this.width);

        if(this.width >=768 && this.width <=992){
            this.gridView = true;
            this.listView = false;
            $('#gridView').addClass('selected');
            $('#listView').removeClass('selected');
        }else if(this.width >=280 && this.width <=768){
            this.gridView = true;
            this.listView = false;
            $('#gridView').addClass('selected');
            $('#listView').removeClass('selected');
        }else{
            this.gridView = false;
            this.listView = true;
            $('#gridView').removeClass('selected');
            $('#listView').addClass('selected');
          
        }


    }

    ngAfterViewInit() {
        $('#listView').addClass('selected');
    }

    getAllSuppliersData(selectedPeriod) {
        this.finalData = [];

        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };

        this.getSeletedPeriodData = selectedPeriod;

        this.level1Service.gettrustScoreCardSummary(data, selectedPeriod).subscribe(response => {
            this.loader = false;
            if (Object.keys(response['prediction_summary']).length === 0) {
                this.noRecordsMsg = "No Records Found.";
                this.loader = false;
            } else {
                this.noRecordsMsg = '';
            }
            this.is_default = response['is_default'];
            const data = Object.entries(response['prediction_summary']).map(([type, value]) => ({ type, value }));
            this.tabledata = data.slice();
            // this.tabledata.sort((a, b) => (a['value']['Trust_Score'] < b['value']['Trust_Score']) ? 1 : ((b['value']['Trust_Score'] < a['value']['Trust_Score']) ? -1 : 0));

            setTimeout(() => {
                this.dataSource = new MatTableDataSource(this.tabledata);
                this.dataSource.paginator = this.paginator;

            })

            const new_array = Object.entries(response['prediction_summary']).map(([type, value]) => ({ type, value }));
            if (new_array.length != 0) {
                const timestamp = new_array[0]['value']['timestamp'];
                const dateString = new Date(timestamp).toUTCString();
                this.durationDate = dateString.split(' ').slice(0, 4).join(' ');
            } else {
                this.durationDate = '';
            }
            //let cnt = 0;
            const prediction_summary = response['prediction_summary'];
            for (const key in prediction_summary) {
                if (key) {
                    if (prediction_summary[key]['Predicted_Posting_Date'] > prediction_summary[key]['ECD_Date']) {
                        prediction_summary[key]['down'] = true;
                        //this.finalData.push(response[key]);
                    } else {
                        prediction_summary[key]['down'] = false;

                    }

                    if (prediction_summary[key]['Impacted_Delivery_Date']) {
                        // this.showColumns = true;
                        this.showImpactDate = true;
                    }


                    if (!prediction_summary[key]['Impacted_Delivery_Date']) {
                        //this.showImpactDate = false;
                    } else {
                        //cnt++;
                        this.showImpactDate = true;

                        // this.impact_type = response[key]['Impact Type'];

                        if (prediction_summary[key]['Impacted_Delivery_Date'] > prediction_summary[key]['ECD_Date']) {
                            prediction_summary[key]['imp_down'] = true;
                        } else {
                            prediction_summary[key]['imp_down'] = false;
                        }

                    }
                    this.finalData.push(prediction_summary[key]);
                }


                // if (cnt == 0) {
                //   this.showColumns = false;
                //   this.displayedColumns = ['Vendor/supplying plant', 'Material', 'Purchasing Document', 'Item', 'Schedule line',
                //     'Scheduled quantity', 'ECD Date', 'Predicted Posting Date', 'Trust Score'];
                // } else {
                //   this.showColumns = true;
                //   this.displayedColumns.push('Impacted Delivery Date');
                //   this.displayedColumns.push('Impacted Trust Score');
                // }
            }
            if (this.showColumns) {
                // this.showColumns && this.manualImpactType
                // this.showImpactDate = true;

                this.displayedColumns.push('Impacted_Delivery_Date');
                this.displayedColumns.push('Impacted_Trust_Score');
            }

            const sortState = {
                'active': 'Trust_Score',
                'direction': 'desc'
            };


            // this.customSort(sortState, this.tabledata);
            this.customSort(sortState, this.tabledata, '', '');
            //this.customSort(sortState, this.finalData,'');
            this.loader = false;
            const sortState1 = {
                'active': 'Supplier',
                'direction': 'asc'
            };


            if (this.finalData.length == 0) {
                this.greenRangeData = [];
                this.yellowRangeData = [];
                this.orangeRangeData = [];
                this.redRangeData = [];
            } else {
                this.customSort(sortState1, this.finalData, 'cardView', '');
            }



        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } else 
            if (error.status === 404) {
                this.errorMsg = error.error;
            }
        });




    }


    customSort(val, dataArray, view, appliedon) {

        // if (this.showImpactDate === true) {
        //     // $('#commitmentdate').addClass('impactDate');
        //     // $('#soonestToLatest').addClass('impactDate');
        //     // $('#higestToLowest').addClass('impactDate');
            
        // }else{
        //     $('#commitmentdate').addClass('notImpactDate');
        //     $('#soonestToLatest').addClass('notImpactDate');
        //     $('#higestToLowest').addClass('notImpactDate');
        // }

        if (view === 'cardView') {
            const data = dataArray.slice();

            const sortedData = data.sort((a, b) => {
                switch (val.active) {

                    case 'Supplier':
                        // eslint-disable-next-line no-var
                        var val1 = a['Supplier'].split(' ');
                        // eslint-disable-next-line no-var
                        var val2 = b['Supplier'].split(' ');
                        return this.compare(parseInt(val1[1]), parseInt(val2[1]), true);
                    default: return 0;
                }
            });


            this.greenRangeData = [];
            this.greenRangeData = sortedData.filter(data => data['Trust_Score'] >= 81);
            this.redRangeData = [];
            this.redRangeData = sortedData.filter(data => data['Trust_Score'] > 0 && data['Trust_Score'] <= 20);
            this.orangeRangeData = [];
            this.orangeRangeData = sortedData.filter(data => data['Trust_Score'] >= 21 && data['Trust_Score'] <= 40);
            this.yellowRangeData = [];
            this.yellowRangeData = sortedData.filter(data => data['Trust_Score'] >= 41 && data['Trust_Score'] <= 80);
        } else {
            const data = dataArray.slice();
            if (val) {
                this.sortedData = data.sort((a, b) => {
                    let isAsc;
                    if (val.direction == '') {
                        isAsc = true;
                        val.direction = 'asc';
                    } else if (val.direction == this.columnDirections[val.active] && val.direction == 'asc') {
                        isAsc = false;
                        val.direction = 'desc';
                    } else if (val.direction == this.columnDirections[val.active] && val.direction == 'desc') {
                        isAsc = true;
                        val.direction = 'asc';
                    } else {
                        isAsc = val.direction === 'asc';
                    }



                    // if(appliedon === 'trustScore' && isAsc === true){
                    //     this.higestToLowestLabel = "lowest to highest";
                    // }else{
                    //     this.higestToLowestLabel = "highest to lowest";
                    // }

                    // if(appliedon === 'commitmentdate' && isAsc === true){
                    //     this.oldestToNewestLabel = "oldest to newest";
                    // }else{
                    //     this.oldestToNewestLabel = "newest to oldest";
                    // }

                    // if(appliedon === 'deliverydate' && isAsc === true){
                    //     this.soonestToLatestLabel = "soonest to latest";
                    // }else{
                    //     this.soonestToLatestLabel = "latest to soonest";
                    // }


                    if (appliedon === 'trustScore' && isAsc === true) {
                        this.higestToLowestLabel = "Lowest to Highest";
                    } else if (appliedon === 'trustScore' && isAsc === false) {
                        this.higestToLowestLabel = "Highest to Lowest";
                    } else if (appliedon === 'commitmentdate' && isAsc === true) {
                        this.oldestToNewestLabel = "Oldest to Newest";
                    } else if (appliedon === 'commitmentdate' && isAsc === false) {
                        this.oldestToNewestLabel = "Newest to Oldest";
                    } else if (appliedon === 'deliverydate' && isAsc === true) {
                        this.soonestToLatestLabel = "Newest to Oldest";
                    } else if (appliedon === 'deliverydate' && isAsc === false) {
                        this.soonestToLatestLabel = "Oldest to Newest";
                    }


                    switch (val.active) {
                        case 'Trust_Score':
                            return this.compare(a['value']['Trust_Score'], b['value']['Trust_Score'], isAsc);
                        case 'Material':
                            return this.compare(a['value']['Material'], b['value']['Material'], isAsc);
                        case 'Supplier':
                            var val1 = a['value']['Supplier'].split(' ');
                            var val2 = b['value']['Supplier'].split(' ');

                            return this.compare(parseInt(val1[1]), parseInt(val2[1]), isAsc);
                        //return this.compare(a['Vendor/supplying plant'], b['Vendor/supplying plant'], isAsc);
                        case 'Purchasing_Document':
                            return this.compare(a['value']['Purchasing_Document'], b['value']['Purchasing_Document'], isAsc);
                        case 'Item':
                            return this.compare(a['value']['Item'], b['value']['Item'], isAsc);
                        case 'Schedule_line':
                            return this.compare(a['value']['Schedule_line'], b['value']['Schedule_line'], isAsc);
                        case 'Scheduled_quantity':
                            return this.compare(a['value']['Scheduled_quantity'], b['value']['Scheduled_quantity'], isAsc);
                        case 'Commitment_Date':
                            return this.compare(new Date(a['value']['ECD_Date'] || a['value']['Commitment_Date']), new Date(b['value']['ECD_Date'] || b['value']['Commitment_Date']), isAsc);
                        case 'Predicted_Posting_Date':
                            return this.compare(new Date(a['value']['Predicted_Posting_Date']), new Date(b['value']['Predicted_Posting_Date']), isAsc);
                        case 'Impacted_Delivery_Date':
                            return this.compare(new Date(a['value']['Impacted_Delivery_Date']), new Date(b['value']['Impacted_Delivery_Date']), isAsc);
                        case 'Impacted_Trust_Score':
                            return this.compare(a['value']['Impacted_Trust_Score'], b['value']['Impacted_Trust_Score'], isAsc);
                        default:
                            return 0;
                    }

                });

                this.tabledata = this.sortedData;

                // this.dataSource = new MatTableDataSource(sortedData);
                // this.dataSource.paginator = this.paginator;

                setTimeout(() => {
                    this.dataSource = new MatTableDataSource(this.sortedData);
                    this.dataSource.paginator = this.paginator;

                })
                // this.dataSource.sort = this.sort;
                // const sortState: Sort = {active: val.active, direction: val.direction};
                // this.sort.active = sortState.active;
                // this.sort.direction = sortState.direction;
                // this.sort.sortChange.emit();
                this.columnDirections[val.active] = val.direction;
            }



        }
    }

    // compared(tabledata, appliedon) {
    //     this.loader = true;

    //     this.tabledata = tabledata.slice();
    //     if (appliedon == 'trust_score') {
    //         this.tabledata.sort((a, b) => (a['value']['Trust_Score'] < b['value']['Trust_Score']) ? 1 : ((b['value']['Trust_Score'] < a['value']['Trust_Score']) ? -1 : 0));
    //     }else if(appliedon == 'commitment_date'){
    //         this.tabledata.sort((a, b) => (new Date(a['value']['Commitment_Date'] || a['value']['ECD_Date']) < new Date(b['value']['Commitment_Date'] || a['value']['ECD_Date'])) ? 1 : ((new Date(b['value']['Commitment_Date'] || a['value']['ECD_Date']) < new Date(a['value']['Commitment_Date'] || a['value']['ECD_Date'])) ? -1 : 0));
    //     }else if(appliedon == 'delievery_date'){
    //         this.tabledata.sort((a, b) => (new Date(a['value']['Predicted_Posting_Date']) < new Date(b['value']['Predicted_Posting_Date'])) ? 1 : ((new Date(b['value']['Predicted_Posting_Date']) < new Date(a['value']['Predicted_Posting_Date'])) ? -1 : 0));

    //     }

    //     setTimeout(() => {
    //         this.dataSource = new MatTableDataSource(this.tabledata);
    //         this.dataSource.paginator = this.paginator;
    //         this.loader = false;
    //     })
    // }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getIcon(impact_type) {
        const data = {
            'Authorization': this.authtoken
        };

        this.level1Service.getIconURL(data, impact_type).subscribe((res) => {
            if (res) {
                const storageRef = this.afStorage.storage.ref().child(res.Path);
                storageRef.getDownloadURL().then(url => {

                    this.imageToShow = url;

                });
            }
            //this.createImageFromBlob(res.body);

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


    createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.imageToShow = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }


    getModeStatus() {

        const obj = {
            'Authorization': this.authtoken
        };
        // this.getIcon(localStorage.getItem('impact_type'));
        this.adminService.getModeStatus(obj).subscribe(res => {
            const impacttypeArr = Object.entries(res).map(([type, value]) => ({ type, value }));
            for (const imp_type in impacttypeArr) {

                if (impacttypeArr[imp_type]['type'] === 'COVID-19') {
                    if (impacttypeArr[imp_type]['value']['API'] === true || impacttypeArr[imp_type]['value']['CSV'] === true || impacttypeArr[imp_type]['value']['Manual'] === true) {
                        this.showImpactMsg = true;
                        this.getIcon(impacttypeArr[imp_type]['type']);
                    } else {
                        this.showImpactMsg = false;
                    }
                }

            }


            if (res && Object.keys(res).length != 0) {

                for (const imp_type in res) {
                    if (this.selectedImpactType == imp_type) {
                        if (res[imp_type]['API'] === true || res[imp_type]['CSV'] === true) {
                            // this.getIcon(imp_type);
                            // this.impact_type = imp_type;

                            // break;
                        }
                        if (res[imp_type]['API'] === true && res[imp_type]['CSV'] === false) {
                            // this.showColumns = true;
                            this.showImpactDate = false;
                            this.manualImpactType = false;
                            this.displayedColumns.push('Impacted_Trust_Score');
                        } else if (res[imp_type]['API'] === false && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === false) {
                            this.showColumns = false;
                            this.showImpactDate = false;
                            this.manualImpactType = false;
                        } else if (res[imp_type]['API'] === false && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === true) {
                            this.manualImpactType = true;
                        }
                        else {
                            // this.showColumns = true;
                            // this.showImpactDate = true;
                            this.manualImpactType = false;
                            this.displayedColumns.push('Impacted_Delivery_Date');
                            this.displayedColumns.push('Impacted_Trust_Score');
                        }
                    }
                }

                // this.displayedColumns.push('Impacted Delivery Date');
                // this.displayedColumns.push('Impacted Trust Score');
            } else {
                this.showColumns = false;
                this.showImpactDate = false;
            }

        }, (error) => {
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
        });

    }



    showPeriodicChart(periodId) {

        // if(this.finalData){
        //     this.paginator.pageIndex = 0;
        // }
        if (this.paginator != undefined) {
            this.paginator.pageIndex = 0;
        }
        this.pageOfItems = 1;

        if (periodId === 'oneDay') {
            $('#oneDay').addClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.getAllSuppliersData('');
        } else if (periodId === 'oneMonth') {
            $('#oneMonth').addClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.getAllSuppliersData('oneMonth');
        } else if (periodId === 'threeMonths') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').addClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.getAllSuppliersData('threeMonths');
        } else if (periodId === 'sixMonths') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').addClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.getAllSuppliersData('sixMonths');
        } else if (periodId === 'nineMonths') {
            $('#oneMonth').removeClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').addClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.getAllSuppliersData('nineMonths');
        } else if (periodId === 'oneYear') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').addClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.getAllSuppliersData('oneYear');
        } else if (periodId === 'twoYear') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').addClass('active');
            sessionStorage.setItem('duration', periodId);
            this.getAllSuppliersData('twoYear');
        }
    }

    onChangePage(event) {
        this.pageOfItems = event;
    }



    viewToggle(val) {
        if (val === 'listView') {
            this.listView = true;
            this.gridView = false;
            $('#listView').addClass('selected');
            $('#gridView').removeClass('selected');
            setTimeout(() => {
                this.dataSource = new MatTableDataSource(this.sortedData);
                this.dataSource.paginator = this.paginator;
            });
        } else {
            this.listView = false;
            this.gridView = true;
            $('#listView').removeClass('selected');
            $('#gridView').addClass('selected');
        }
    }

    goToTrendChart(supplierName) {
        this.router.navigate(['/home/trendChart/', supplierName]);
    }

    goToDetailPage(supplierName) {
        localStorage.setItem('navigated_from', 'roster');
        this.router.navigate(['/home/supplierDetail/', supplierName]);
    }

    // TODO change the name of this function now that I know it works 
    blakeSort(val, direction, dataArray, view) {
        if (view === 'cardView') {
            const data = dataArray.slice();

            const sortedData = data.sort((a, b) => {
                switch (val.active) {

                    case 'Supplier':
                        // eslint-disable-next-line no-var
                        var val1 = a['Supplier'].split(' ');
                        // eslint-disable-next-line no-var
                        var val2 = b['Supplier'].split(' ');
                        return this.compare(parseInt(val1[1]), parseInt(val2[1]), true);
                    default: return 0;
                }
            });

            this.greenRangeData = [];
            this.greenRangeData = sortedData.filter(data => data['Trust_Score'] >= 81);
            this.redRangeData = [];
            this.redRangeData = sortedData.filter(data => data['Trust_Score'] > 0 && data['Trust_Score'] <= 20);
            this.orangeRangeData = [];
            this.orangeRangeData = sortedData.filter(data => data['Trust_Score'] >= 21 && data['Trust_Score'] <= 40);
            this.yellowRangeData = [];
            this.yellowRangeData = sortedData.filter(data => data['Trust_Score'] >= 41 && data['Trust_Score'] <= 80);
        } else {
            const data = dataArray.slice();            
            if (val) {
                this.sortedData = data.sort((a, b) => {
                    let isAsc;
                    if (direction === 'asc') {
                        isAsc = true;
                        val.direction = 'asc'
                    }
                    else {
                        isAsc = false;
                        val.direction = 'desc'
                    }

                    switch (val.active) {
                        case 'Trust_Score':
                            return this.compare(a['value']['Trust_Score'], b['value']['Trust_Score'], isAsc);
                        case 'Material':
                            return this.compare(a['value']['Material'], b['value']['Material'], isAsc);
                        case 'Supplier':
                            var val1 = a['value']['Supplier'].split(' ');
                            var val2 = b['value']['Supplier'].split(' ');

                            return this.compare(parseInt(val1[1]), parseInt(val2[1]), isAsc);
                        //return this.compare(a['Vendor/supplying plant'], b['Vendor/supplying plant'], isAsc);
                        case 'Purchasing_Document':
                            return this.compare(a['value']['Purchasing_Document'], b['value']['Purchasing_Document'], isAsc);
                        case 'Item':
                            return this.compare(a['value']['Item'], b['value']['Item'], isAsc);
                        case 'Schedule_line':
                            return this.compare(a['value']['Schedule_line'], b['value']['Schedule_line'], isAsc);
                        case 'Scheduled_quantity':
                            return this.compare(a['value']['Scheduled_quantity'], b['value']['Scheduled_quantity'], isAsc);
                        case 'Commitment_Date':
                            return this.compare(new Date(a['value']['ECD_Date'] || a['value']['Commitment_Date']), new Date(b['value']['ECD_Date'] || b['value']['Commitment_Date']), isAsc);
                        case 'Predicted_Posting_Date':
                            return this.compare(new Date(a['value']['Predicted_Posting_Date']), new Date(b['value']['Predicted_Posting_Date']), isAsc);
                        case 'Impacted_Delivery_Date':
                            return this.compare(new Date(a['value']['Impacted_Delivery_Date']), new Date(b['value']['Impacted_Delivery_Date']), isAsc);
                        case 'Impacted_Trust_Score':
                            return this.compare(a['value']['Impacted_Trust_Score'], b['value']['Impacted_Trust_Score'], isAsc);
                        default:
                            return 0;
                    }

                });

                this.tabledata = this.sortedData;

                // this.dataSource = new MatTableDataSource(sortedData);
                // this.dataSource.paginator = this.paginator;

                setTimeout(() => {
                    this.dataSource = new MatTableDataSource(this.sortedData);
                    this.dataSource.paginator = this.paginator;

                })
                // this.dataSource.sort = this.sort;
                // const sortState: Sort = {active: val.active, direction: val.direction};
                // this.sort.active = sortState.active;
                // this.sort.direction = sortState.direction;
                // this.sort.sortChange.emit();
                this.columnDirections[val.active] = val.direction;
            }
        }
    }

}
