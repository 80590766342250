import { Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SignUpService } from './sign-up.service';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialogConfig, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestDialogComponent } from '../request-dialog/request-dialog.component';
import { NgSelectConfig } from '@ng-select/ng-select';
import { NextPageComponent } from './next-page/next-page.component';
import { environment } from './../../../environments/environment';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
declare const $: any;

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SignUpComponent implements OnInit {

    signUpForm: FormGroup;
    loader = false;
    organizations: any;
    successMsg: any;
    errorMsg: any;
    filteredOptions: Observable<string[]>;
    mismatchMsg: any;
    emailId: any;
    phoneNumberInvalid: string;
    captchaError: string;

    passLowerCasePatt: any;
    passUpeerCasePatt: any;
    passNumberPatt: any;
    passSpecialCharacterPatt: any;
    passLength: any;

    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<SignUpComponent>,
                private fbAuth: StratizantFirebaseAuthService,
                private fb: FormBuilder, private signUpService: SignUpService, public afAuth: AngularFireAuth,
                private matDialog: MatDialog,private config: NgSelectConfig) {
        // this.config.notFoundText = 'Organization not found';
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        this.signUpForm = this.fb.group({
            organisation_name: [null, Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            // job_title: ['', Validators.required],
            // phone_number: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            myRecaptcha: [false, Validators.required]
        });

    }
    
 
    onScriptError() {
        this.captchaError = 'Something went wrong when loading the reCAPTCHA. Check your internet connection.';
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.organizations.filter(option => option.toLowerCase().includes(filterValue));
    }

    getOrganizationsList() {
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }

        this.signUpService.getOrganizationList(obj).subscribe(res => {
            this.organizations = [];
            for(const org in res.names){
                this.organizations.push({'name': res.names[org]});
            }

        });
    }


    signUp() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars        
        //const dialogRef = this.matDialog.open(NextPageComponent);
        // this.dialogRef.close();
        // return;
        this.loader = true;
        this.errorMsg = '';
        this.successMsg = '';
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        const formData = new FormData();
        

        formData.append('first_name', this.signUpForm.value.first_name);
        formData.append('last_name', this.signUpForm.value.last_name);
        formData.append('email', this.signUpForm.value.email);
        formData.append('organisation_name', this.signUpForm.value.organisation_name);
        //formData.append('title', this.signUpForm.value.job_title);
        //formData.append('phone_number', this.signUpForm.value.phone_number);
        formData.append('password', this.signUpForm.value.password);
        formData.append('status', 'Pending Activation');
        formData.append('role_type', 'Trial Period User');

        const vm = this;
        this.signUpService.signUp(formData, obj).subscribe((res) => {
            //this.loader = false;
            this.emailId = this.signUpForm.value.email;
            this.signUpService.signIn(this.signUpForm.value.email, this.signUpForm.value.password).then((result) => {
                //this.loader = false;

                // const typeOf = typeof('result');
                // this.errorMsg = 'Your Organisations account limit is reached please upgrade plan to register!!!'

                if (result) {
                    const actionCodeSettings = {
                        // url: 'https://fullconfidence.stratizant.com/login',
                        url : environment.continueUrl,
                    };

                    result['user'].sendEmailVerification(actionCodeSettings).then(function () {
                        vm.afAuth.signOut();
                        
                        // vm.getOrganizationsList();
                        vm.loader = false;
                        vm.dialogRef.close();
                        result['user'].getIdTokenResult().then(function (idTokenResult) {
                            const dialogConfig = new MatDialogConfig();
        
                            dialogConfig.data ={
                                'uid' : res['Created'],
                                'first_name': vm.signUpForm.controls.first_name.value,
                                'last_name': vm.signUpForm.controls.last_name.value,
                                'auth_toke': idTokenResult.token
                            }
                            // vm.signUpForm.reset();
                            // Object.keys(vm.signUpForm.controls).forEach(key => {
                            //     vm.signUpForm.controls[key].setErrors(null)
                            // });
                            const dialogRef = vm.matDialog.open(NextPageComponent, dialogConfig);
    
                            dialogRef.afterClosed().subscribe(()=> {
                
                            }); 
                        });
                       
                    });
                }

            }).catch(() => {
                this.loader = false;
            });

        }, error => {
            this.loader = false;
            this.successMsg = '';
            const typeOf = typeof('error');
            if(typeOf == 'string'){
                this.errorMsg = 'Your Organisations account limit is reached please upgrade plan to register!!!'
            }else {
                this.errorMsg = '';
            }
           
            if (error.error.Error) {
                this.errorMsg = error.error.Error;
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }

            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }

        });
    }

    get f() { return this.signUpForm.controls; }


    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }


    comparePassword() {
        if (this.signUpForm.value.confirmPassword && this.signUpForm.value.password != this.signUpForm.value.confirmPassword) {
            this.mismatchMsg = "Password does not match";
        } else {
            this.mismatchMsg = '';
        }
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();

        const message = 'Verification mail has been sent to';
        const title = '';
        const emailId = this.emailId;
        const resetPassword = false;

        dialogConfig.data = {
            message,
            title,
            emailId,
            resetPassword
        };
        const dialogRef = this.matDialog.open(RequestDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe();

    }


    validatePhoneNumber(p_no, action){
        const numbers = /^[0-9]+$/;
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.signUpForm.controls.phone_number.setValue(p_no);
        }
        if((p_no.length != 10 || !p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = 'Enter 10 digit contact number';
        }else if((p_no.length == 10 && p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = '';
        }else if(p_no.length == 10 && p_no.match(numbers) && action === 'addCode'){
            this.phoneNumberInvalid = '';
            const stringEnc = encodeURI('+');
            const phn_number = stringEnc + 1 + this.signUpForm.value.phone_number;
            this.signUpForm.controls.phone_number.setValue(phn_number);
        }
    }

    removeCode(p_no){
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.signUpForm.controls.phone_number.setValue(p_no);
        }
    }

    close(){
        this.dialogRef.close();
    }


    passwordValidation(){
       
        const passwordVal = this.signUpForm.controls.password.value;

        if(passwordVal){
            const lowercasePatt = new RegExp("(?=.*[a-z])");
            const checkLowercase = lowercasePatt.test(passwordVal);
            if(checkLowercase === false){
                this.passLowerCasePatt = false;
                $('#lowercase').addClass('invalidPass');
            }else{
                this.passLowerCasePatt = true;
                $('#lowercase').removeClass('invalidPass');
            }

            const uppercasePatt = new RegExp("(?=.*[A-Z])");
            const checkUppercase = uppercasePatt.test(passwordVal);
            if(checkUppercase === false){
                this.passUpeerCasePatt = false;
                $('#uppercase').addClass('invalidPass');
            }else{
                this.passUpeerCasePatt = true;
                $('#uppercase').removeClass('invalidPass');
            }

            const numberPatt = new RegExp("(?=.*[0-9])");
            const checkNumber = numberPatt.test(passwordVal);
            if(checkNumber === false){
                this.passNumberPatt = false;
                $('#digit').addClass('invalidPass');
            }else{
                this.passNumberPatt = true;
                $('#digit').removeClass('invalidPass');
            }


            const specialCharacterPatt = new RegExp("(?=.*[@$!%*?&])");
            const checkSpecialCharacter = specialCharacterPatt.test(passwordVal);
            if(checkSpecialCharacter === false){
                this.passSpecialCharacterPatt = false;
                $('#specialCharacter').addClass('invalidPass');
            }else{
                this.passSpecialCharacterPatt = true;
                $('#specialCharacter').removeClass('invalidPass');
            }

            const passwordLength = passwordVal.length;
            if(passwordLength < 8 || passwordLength > 15){
                this.passLength = false;
                $('#length').addClass('invalidPass');
            }else{
                this.passLength = true;
                $('#length').removeClass('invalidPass');
            }
    
        }

        
    }

}

