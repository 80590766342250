import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StratizantFireStoreService } from 'src/app/services/stratizant-fire-store.service';
import { TableLevel1Service } from '../table-level1/table-level1.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { StratizantFirebaseService } from 'src/app/services/stratizant-firebase.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AdminSettingsService } from '../admin-settings/admin-settings.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
import { RefreshTokenService } from 'src/app/services/refresh-token.service';

declare let $: any;

@Component({
    selector: 'app-chart-view',
    templateUrl: './chart-view.component.html',
    styleUrls: ['./chart-view.component.css']
})
export class ChartViewComponent implements OnInit {


    durationDate: any;
    loader = false;
    suppliers: any = [];
    trustScores: any = [];
    borderList: any = [];
    impactedTrustScores: any = [];
    sortedData = [];
    impact_type: any = 'COVID-19';
    imageToShow: any;
    errorMsg: any;
    authtoken: string;
    showImpact = false;
    showImpactMsg: boolean;
    noRecordsMsg: any;
    timeout: string;
    impactToggleForm: FormGroup;
    SlideOptions = {
        items: 1, dots: true, nav: true,
        onChange: () => {

        }
    };
    CarouselOptions = { items: 3, dots: true, nav: true };
    chartList = [];
    chartData = [];
    itemList = {};
    impactToggle: any;
    is_default: any;
    showTrustscoreAnalysisVal: any;
    showTrustscoreAnalysisFlag: any;
    lastloginatTimeDifference: any;
    refreshToken: string;

    constructor(private route: ActivatedRoute, private fireStoreService: StratizantFireStoreService,
        private router: Router, private level1Service: TableLevel1Service, private matDialog: MatDialog,
        private firebaseAuthService: StratizantFirebaseAuthService, private firebaseServices: StratizantFirebaseService,
        private afStorage: AngularFireStorage,
        private cookieServiceVariable: CookieService,
        private userIdle: UserIdleService,
        private refreshTokenService: RefreshTokenService,
        private adminService: AdminSettingsService, private fb: FormBuilder) {
    }

    ngOnInit() {
        this.impact_type = localStorage.getItem('impact_type');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        //$('#oneMonth').addClass('active');
        this.showTrustscoreAnalysis('', 'from_init', '');
        const duration = sessionStorage.getItem("duration");
        if (duration) {
            sessionStorage.removeItem("duration");
        }

        this.impactToggleForm = this.fb.group({
            impact: [true],
        });

        this.getModeStatus();

        console.log('auth token from cookie',this.cookieServiceVariable.get('auth_token_1'));
        if (this.authtoken) {
            // Start watching for user inactivity.

            this.userIdle.startWatching();
            // Start watching when user idle is starting and reset if user action i  s there.

            this.userIdle.onTimerStart().subscribe(count => {
                document.addEventListener("keypress", () => this.userIdle.resetTimer());
                document.addEventListener("click", () => this.userIdle.resetTimer());
                document.addEventListener("mouseover", () => this.userIdle.resetTimer());
                document.addEventListener("keydown", () => this.userIdle.resetTimer());
                document.addEventListener("DOMMouseScroll", () => this.userIdle.resetTimer());
                document.addEventListener("mousewheel", () => this.userIdle.resetTimer());
                document.addEventListener("mousedown", () => this.userIdle.resetTimer());
                document.addEventListener("touchstart", () => this.userIdle.resetTimer());
                document.addEventListener("touchmove", () => this.userIdle.resetTimer());
                document.addEventListener("scroll", () => this.userIdle.resetTimer());
                document.addEventListener("keyup", () => this.userIdle.resetTimer());



                console.log("count", count);
                if (count == 1) {
                    this.refreshToken = 'true';
                    console.log("refreshtoken", this.refreshToken);
                    localStorage.setItem("isrefreshtoken", this.refreshToken);
                }
            });
            // Start watch when time is up.
            this.userIdle.onTimeout().subscribe(() => {
                this.timeout = 'true';
                localStorage.setItem("timeout", this.timeout);
                // alert('Your session has expired click on OK to resume the application.');
                // this.openAuthDialog();
                this.userIdle.stopWatching();

            })
        }



    }


    // ngOnChanges(){
    //     if (this.authtoken) {
    //         // Start watching for user inactivity.

    //         this.userIdle.startWatching();
    //         // Start watching when user idle is starting and reset if user action i  s there.

    //         this.userIdle.onTimerStart().subscribe(count => {
    //             document.addEventListener("keypress", () => this.userIdle.resetTimer());
    //             document.addEventListener("click", () => this.userIdle.resetTimer());
    //             document.addEventListener("mouseover", () => this.userIdle.resetTimer());
    //             document.addEventListener("keydown", () => this.userIdle.resetTimer());
    //             document.addEventListener("DOMMouseScroll", () => this.userIdle.resetTimer());
    //             document.addEventListener("mousewheel", () => this.userIdle.resetTimer());
    //             document.addEventListener("mousedown", () => this.userIdle.resetTimer());
    //             document.addEventListener("touchstart", () => this.userIdle.resetTimer());
    //             document.addEventListener("touchmove", () => this.userIdle.resetTimer());
    //             document.addEventListener("scroll", () => this.userIdle.resetTimer());
    //             document.addEventListener("keyup", () => this.userIdle.resetTimer());



    //             console.log("count", count);
    //             if (count == 1) {
    //                 this.refreshToken = 'true';
    //                 console.log("refreshtoken", this.refreshToken);
    //                 localStorage.setItem("isrefreshtoken", this.refreshToken);
    //             }
    //         });
    //         // Start watch when time is up.
    //         this.userIdle.onTimeout().subscribe(() => {
    //             this.timeout = 'true';
    //             localStorage.setItem("timeout", this.timeout);
    //             // alert('Your session has expired click on OK to resume the application.');
    //             // this.openAuthDialog();
    //             this.userIdle.stopWatching();

    //         })
    //     }
    // }
    
    fireEvent() {
        console.log('click triggered');
        document.addEventListener('click', () => this.userIdle.resetTimer());
    }

    // refreshTokenMethod('showTrustscoreAnalysis', data?, flag?){
    //     const authtoken = localStorage.getItem('refreshtoken');
    //     this.refreshTokenService.refreshToken(authtoken).subscribe((res) => {
    //         this.authtoken = res.id_token;
    //         localStorage.setItem('auth_token', this.authtoken);
    //     }, () => {
    //         this.loader = false;
    //     });
    // }


    refreshTokenMethod(calledFuncName, data?, flag?) {
        this.loader = true;
        const authtoken = localStorage.getItem('refreshtoken');
        this.refreshTokenService.refreshToken(authtoken).subscribe((res) => {
            this.loader = false;
            console.log("in trsut score analysis");
            this.authtoken = res.id_token;
            if (calledFuncName == 'showTrustscoreAnalysis') {
                this.showTrustscoreAnalysis(data, flag, 'refreshtoken');
            }

        }, () => {
            this.loader = false;
        });
    }



    showView() {
        this.router.navigate(['home/supplierSummary/level1/tableView']);
    }


    showTrustscoreAnalysis(val, flag, calledIn) {

        this.loader = true;
        let data: any= '';
        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }
        if (calledIn == 'refreshtoken') {
            data = {
                Authorization:  this.authtoken
            };
        } else {
            data = {
                Authorization: localStorage.getItem('auth_token')
            };
        }


        this.showTrustscoreAnalysisVal = val;
        this.showTrustscoreAnalysisFlag = flag;
        this.level1Service.gettrustScoreCardSummary(data, val).subscribe(response => {
            this.loader = false;
            this.is_default = response['is_default'];

            this.formatData(response, flag);

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }else 
            if (error && (error.status === 401) && this.timeout) {
                this.openAuthDialog();
            } else if (error && (error.status === 401) && this.refreshToken) {
                this.authtoken = this.cookieServiceVariable.get('auth_token_1');
                this.refreshTokenMethod('showTrustscoreAnalysis', this.showTrustscoreAnalysisVal, this.showTrustscoreAnalysisFlag);
            }
            if (error.status === 404) {
                this.errorMsg = error.error;
            }
        });

    }


    formatData(res, flag) {
        const response = Object.entries(res['prediction_summary']).map(([type, value]) => ({ type, value }));
        if (Object.keys(response).length != 0) {
            const timestamp = response[0]['value']['timestamp'];
            const dateString = new Date(timestamp).toUTCString();
            this.durationDate = dateString.split(' ').slice(0, 4).join(' ');
            this.noRecordsMsg = '';

        } else {
            this.durationDate = '';
            this.noRecordsMsg = "No Records Found.";
        }


        const resData = response;
        const newData = [];

        for (const key in resData) {
            if (newData.length != 0) {
                let cnt = 0;
                for (const dt in newData) {

                    if (newData[dt]['value']['Supplier'] == resData[key]['value']['Supplier']) {
                        newData[dt]['total_count'] = newData[dt]['total_count'] + 1;
                        newData[dt]['value']['Trust_Score'] = newData[dt]['value']['Trust_Score'] + resData[key]['value']['Trust_Score'];
                        if (newData[dt]['value']['Impacted_Delivery_date'] || newData[dt]['value']['Impacted_Trust_Score']) {
                            //imp_cnt++;
                            //this.showImpact = true;
                            // this.impact_type = resData[key]['Impact Type'];
                            newData[dt].impact_total_count = newData[dt]['impact_total_count'] + 1;
                            newData[dt]['value']['Impacted_Trust_Score'] = newData[dt]['value']['Impacted_Trust_Score'] + resData[key]['value']['Impacted_Trust_Score'];
                        }
                        break;
                    } else {
                        cnt++;
                    }
                    if (cnt == newData.length) {
                        //  this.suppliers.push(resData[key]['Supplier']);
                        newData.push(resData[key]);
                        newData[newData.length - 1]['total_count'] = 1;
                        newData[newData.length - 1]['impact_total_count'] = 1;
                    }
                }
            } else {
                //this.suppliers.push(resData[key]['Supplier']);
                newData.push(resData[key]);
                newData[0]['total_count'] = 1;
                if (resData[key]['value']['Impacted_Delivery_date'] || resData[key]['value']['Impacted_Trust_Score']) {
                    //imp_cnt++;
                    //this.showImpact = true;
                    newData[0]['impact_total_count'] = 1;
                }
            }
        }



        const sortState = {
            'active': 'Supplier',
            'direction': 'asc'
        };
        //this.loader = false;
        this.customSort(sortState, newData, flag);
        // this.generateBarChart();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stopLoader(val) {
        this.loader = false;
    }

    getIcon(impact_type) {
        //this.loader = true;
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };

        this.level1Service.getIconURL(data, impact_type).subscribe((res) => {
            if (res) {
                const storageRef = this.afStorage.storage.ref(res.Path);
                storageRef.getDownloadURL().then(url => {
                    console.log("url", url);
                    this.imageToShow = url;
                });
            }
            //this.createImageFromBlob(res.body);

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }



    slideChanged() {
        this.itemList = {
            chartList: this.chartList,
            sortedList: this.sortedData,
            impactScore: this.impactToggleForm.controls.impact.value
        }
        // this.generateBarChart();
    }





    customSort(val, dataArray, flag) {

        const data = dataArray.slice();
        if (val) {

            this.sortedData = data.sort((a, b) => {


                const isAsc = val.direction === 'asc';


                switch (val.active) {
                    case 'Trust_Score':
                        return this.compare(a['value']['Trust_Score'], b['value']['Trust_Score'], isAsc);
                    case 'Supplier':
                        // eslint-disable-next-line no-var
                        var val1 = a['value']['Supplier'].split(' ');
                        // eslint-disable-next-line no-var
                        var val2 = b['value']['Supplier'].split(' ');

                        //return val1.localeCompare(val2);
                        return this.compare(parseInt(val1[1]), parseInt(val2[1]), isAsc);
                    //return val1 - val2;
                    //return a['Supplier'] - b['Supplier'];
                    // return this.compare(a['Supplier'], b['Supplier'], isAsc);

                    default:
                        return 0;
                }


            });

            if (flag === 'from_init') {
                this.chartList = [];
                const div = Math.ceil(this.sortedData.length / 20);
                for (let i = 1; i <= div; i++) {
                    this.chartList.push("barchart" + i);
                }
                this.itemList = {
                    chartList: this.chartList,
                    sortedList: this.sortedData,
                    impactScore: this.impactToggleForm.controls.impact.value,
                    loader: 'false'
                }
                return;
            }
            //this.chartData = this.sortedData;
            this.chartList = [];
            const div = Math.ceil(this.sortedData.length / 20);
            for (let i = 1; i <= div; i++) {
                this.chartList.push("barchart" + i);
            }
            this.itemList = {
                chartList: this.chartList,
                sortedList: this.sortedData,
                impactScore: this.impactToggleForm.controls.impact.value
            }
            //this.impactToggle = this.impactToggleForm.controls.impact.value;

            // this.loader = false;




        }


    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }


    goToSupplierView(index) {
        this.router.navigate(['home/supplierSummary/level2', this.suppliers[index]]);
    }


    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }

    getModeStatus() {

        const obj = {
            'Authorization': this.authtoken
        };
        this.errorMsg = '';
        // this.getIcon(localStorage.getItem('impact_type'));
        this.adminService.getModeStatus(obj).subscribe(res => {

            const impacttypeArr = Object.entries(res).map(([type, value]) => ({ type, value }));

            for (const imp_type in impacttypeArr) {

                if (impacttypeArr[imp_type]['type'] === 'COVID-19') {
                    if (impacttypeArr[imp_type]['value']['API'] === true || impacttypeArr[imp_type]['value']['CSV'] === true || impacttypeArr[imp_type]['value']['Manual'] === true) {
                        this.showImpactMsg = true;
                        this.getIcon(impacttypeArr[imp_type]['type']);
                    } else {
                        this.showImpactMsg = false;
                    }
                }

            }

            if (res && Object.keys(res).length != 0) {

                for (const imp_type in res) {
                    if (res[imp_type]['API'] === true || res[imp_type]['CSV'] === true) {
                        // this.getIcon(imp_type);
                        // this.impact_type = imp_type;

                        // break;
                    }
                    if (res[imp_type]['API'] === true && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === false) {
                        this.showImpact = true;
                    } else if (res[imp_type]['API'] === false && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === true) {
                        this.showImpact = true;
                    } else if (res[imp_type]['API'] === false && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === false) {
                        this.showImpact = false;
                    } else {
                        this.showImpact = true;
                    }
                }
            } else {
                this.showImpact = false;
            }

        }, (error) => {
            if (error && (error.status === 401)) {
                this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
        });

    }





    showPeriodicChart(periodId) {
        //this.chartList = [];
        //this.chartList.push(1);
        if (periodId === 'oneDay') {
            $('#oneDay').addClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('', '', '');
        } else if (periodId === 'oneMonth') {
            $('#oneMonth').addClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('oneMonth', '', '');
        } else if (periodId === 'threeMonths') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').addClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('threeMonths', '', '');
        } else if (periodId === 'sixMonths') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').addClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('sixMonths', '', '');
        } else if (periodId === 'nineMonths') {
            $('#oneMonth').removeClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').addClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('nineMonths', '', '');
        } else if (periodId === 'oneYear') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').addClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('oneYear', '', '');
        } else if (periodId === 'twoYear') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').addClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('twoYear', '', '');
        }


    }


}
