import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    basepath = environment.basePath;
    cloud_function_path: any = environment.user_management_cloud_function;

    constructor(private http: HttpClient, private afAuth: AngularFireAuth) { }

    getAllUsersList(data: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer'+' '+ data.Authorization,
            })
        }
        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response'
        // };
        
        return this.http.get<any>(`${this.basepath + 'api/profile/user'}`, httpOptions);
    }

    getOrganizationList(data): Observable<any> {
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Authorization': 'Bearer'+' '+ data.Authorization,
        //     })
        // }
        // return this.http.get<any>(`${this.basepath + 'api/profile/organisations'}`, httpOptions);


        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + ' ' +data.Authorization,
        })
         return this.http.get<any>(`${this.basepath + 'api/profile/organisations'}`, {headers, responseType: 'text' as 'json'});
        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response'
        // };
        // return this.http.get<any>(`${this.basepath + 'api/profile/organisations'}`, {responseType: 'text' as 'json'});
        
        // return this.http.get<any>(`${this.basepath + 'api/profile/organisations'}`, httpOptions);

    }


    createorEditUserDetails(data: any, userData: any, uid?: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer'+' '+ data.Authorization,
            })
        }

        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response'
        // };
        if(!uid){
            return this.http.post<any>(`${this.basepath + 'api/profile/user'}` , userData, httpOptions).pipe(map((data: HttpResponse<any>) => {
                return data;
            }));
        }else if(uid){
            return this.http.put<any>(`${this.basepath + 'api/profile/user'}` , userData, httpOptions).pipe(map((data: HttpResponse<any>) => {
                return data;
            }));
        }

    }


    deleteUser(data: any, uid): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' +data.Authorization,
            })
        }
        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response'
        // };

        return this.http.delete<any>(`${this.basepath + 'api/profile/user?uid=' + uid}`, httpOptions);

    }

    signIn(username, password) {

        const promise = new Promise((resolve, reject) => {
            this.afAuth.signInWithEmailAndPassword(username,password)
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
        return promise;
    }

}
