import { Component, OnInit, ViewChild, Input, SimpleChanges, EventEmitter, ElementRef } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog, MatDialogConfig } from '@angular/material';
import { UploadSupplierDeliveryFileComponent } from './upload-supplier-delivery-file/upload-supplier-delivery-file.component';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { UploadSupplierDeliveryFileService } from './upload-supplier-delivery-file/upload-supplier-delivery-file.service';
declare let $: any;
import { FileUploader } from 'ng2-file-upload';
import { UploadSuccessComponent } from '../impact-data-load/upload-success/upload-success.component';
import { UploadSupplierDeliveryDataService } from './upload-supplier-delivery-data.service';
import { FileCountReachDialogComponent } from './file-count-reach-dialog/file-count-reach-dialog.component';
import { CookieService } from 'ngx-cookie-service';


@Component({
    selector: 'app-upload-supplier-delivry-data',
    templateUrl: './upload-supplier-delivry-data.component.html',
    styleUrls: ['./upload-supplier-delivry-data.component.css']
})
export class UploadSupplierDelivryDataComponent implements OnInit {
    public displayedColumns = ['file_name', 'file_type', 'uploaded_by', 'organisation_name', 'uploaded_date'];
    public dataSource = new MatTableDataSource();
    uploaded_file: any;
    noRecordsMsg: any;
    loader = false;
    xlsxFileData: any;
    uploadedxlsxfile: any;
    mapfileflag: boolean;
    cancelMapping: boolean = false;
    discardMapFileFlag: boolean = true;
    authtoken: any;
    predictionDataList = [];
    file_path: any;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @Input('supplierDeliveryClicked') supplierDeliveryClicked: any = [];
    supplierDelieveryData = [];
    invalidPredictionFile: boolean = false;
    invalidTrainingFile: boolean = false;
    invalidFileMessage = '';
    noFileUploaded = true;
    disabledTrainingDataSet = true;
    noteMsg = false;
    organisation_name: any;
    @ViewChild('predictionFileUpload', { static: false }) predictionFileUpload: ElementRef;
    @ViewChild('trainingFileUpload', { static: false }) trainingInput: ElementRef;
    lastloginatTimeDifference: any;

    constructor(private matDialog: MatDialog, private fbAuth: StratizantFirebaseAuthService,
                private cookieServiceVariable: CookieService,
                private uploadSupplierDeliveryFileService: UploadSupplierDeliveryFileService, private afStorage: AngularFireStorage,
                private uploadFileService: UploadSupplierDeliveryDataService) { }

    ngOnInit() {

        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.organisation_name = localStorage.getItem('organisation_name');


    }


    public uploader: FileUploader = new FileUploader({
        disableMultipart: true
    });
    public hasBaseDropZoneOver: boolean = false;
    public hasAnotherDropZoneOver: boolean = false;


    cancelMappingFlag(cancelMapping: any) {
        this.cancelMapping = cancelMapping;
        this.getPredictionDataList();
        if (cancelMapping === "false") {
            this.uploaded_file = '';
            this.mapfileflag = false;
            this.getPredictionDataList();
        } else {
            this.mapfileflag = false;
            this.getPredictionDataList();
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.supplierDeliveryClicked.currentValue != null && changes.supplierDeliveryClicked.currentValue != undefined) {
            this.getPredictionDataList();

        }

    }

    getPredictionDataList() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }

        this.uploadSupplierDeliveryFileService.getPredictionDataList(obj).subscribe((res) => {
            this.loader = false;
            let responseData = [];
            responseData = res;
            if (Object.keys(res).length === 0) {
                this.noRecordsMsg = "No Records Found.";
            } else {
                this.noRecordsMsg = '';
            }
            this.predictionDataList = [];
            const predictiondata = Object.entries(res).map(([type, value]) => ({ type, value }));

            for (const pd in predictiondata) {

                if (predictiondata[pd]['value']['organisation_name'] == this.organisation_name) {
                    this.predictionDataList.push(predictiondata[pd]);
                    this.dataSource = new MatTableDataSource(this.predictionDataList);
                    this.dataSource.paginator = this.paginator;
                }
                // else if(predictiondata[pd]['value']['organisation_name'] == 'Stratizant'){
                //     this.predictionDataList.push(predictiondata[pd]);
                //     this.dataSource = new MatTableDataSource(this.predictionDataList);
                //     this.dataSource.paginator = this.paginator;
                // }
            }
            const sortState = {
                'active': "uploaded_date",
                'direction': "desc"
            }


            if (responseData.length == 1) {
                if (this.predictionDataList[0]['value']['organisation_name'] === 'Stratizant') {
                    this.noFileUploaded = true;
                    this.noteMsg = true;
                } else {
                    this.noFileUploaded = false;
                    this.noteMsg = false;
                }
            } else if (responseData.length == 0) {
                this.noFileUploaded = true;
                this.noteMsg = false;
            } else {
                this.noFileUploaded = false;
                this.noteMsg = false;
            }

            this.customSort(sortState, this.predictionDataList);
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });



    }



    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator
    }

    dawnloadFile(path) {
        this.loader = true;
        const storageRef = this.afStorage.storage.ref().child(path);
        storageRef.getDownloadURL().then(url => {

            this.file_path = url;
            window.location.href = url;
            this.loader = false;
            // if(this.file_path){
            //     this.loader = false;
            // }
            // $("a").attr("href", this.file_path);


        });
    }

    customSort(val, dataArray) {
        const data = dataArray.slice();
        const sortedData = data.sort((a, b) => {
            const isAsc = val.direction === 'asc';
            switch (val.active) {
                case 'uploaded_date': return this.compare(new Date(a['value']['uploaded_date']), new Date(b['value']['uploaded_date']), isAsc);

                default: return 0;
            }
        });
        this.dataSource = new MatTableDataSource(sortedData);
        this.dataSource.paginator = this.paginator;
        setTimeout(() => {

            this.dataSource.paginator = this.paginator
        });

    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();
        });

    }



    openDialog() {
        const dialogConfig = new MatDialogConfig();

        const dialogRef = this.matDialog.open(UploadSupplierDeliveryFileComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            this.uploaded_file = value.fileToUpload.name;
            this.uploadedxlsxfile = value.fileToUpload;
            this.discardMapFileFlag = false;
            this.xlsxFileData = value.filedata;
        });
    }

    mapfile() {
        this.mapfileflag = true;
    }

    discardMapFile() {
        this.discardMapFileFlag = true;
        if (this.predictionDataList.length == 1) {
            if (this.predictionDataList[0]['value']['organisation_name'] === 'Stratizant') {
                this.noFileUploaded = true;
                this.noteMsg = true;
            } else {
                this.noFileUploaded = false;
                this.noteMsg = false;
            }
        } else if (this.predictionDataList.length == 0) {
            this.noFileUploaded = true;
            this.noteMsg = false;
        } else {
            this.noFileUploaded = false;
            this.noteMsg = false;
        }
        this.predictionFileUpload.nativeElement.value = '';
        this.trainingInput.nativeElement.value = '';
    }

    public onFileSelected(event: EventEmitter<File[]>, fileType) {
        this.invalidPredictionFile = false;
        this.invalidTrainingFile = false;
        this.invalidFileMessage = '';
        if (!event[0]) {
            return;
        }
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        const formData = new FormData();


        const file: File = event[0];
        let invalidCsvFile;
        if (!file.name.includes('.csv')) {
            invalidCsvFile = true;
        } else {
            invalidCsvFile = false;
        }

        if (fileType === 'prediction') {
            if (invalidCsvFile == true) {
                this.invalidPredictionFile = true;
                this.loader = false;
                return;
            } else {
                this.invalidPredictionFile = false;
            }
        } else if (fileType === 'training') {
            if (invalidCsvFile == true) {
                this.invalidTrainingFile = true;
                this.loader = false;
                return;
            } else {
                this.invalidTrainingFile = false;
            }
        }
        formData.append('file', file);
        
        this.uploadFileService.mapnames(obj, formData).subscribe((res) => {
            this.loader = false;
            this.noFileUploaded = false;
            this.uploaded_file = file.name;
            this.uploadedxlsxfile = file;
            this.discardMapFileFlag = false;
            this.xlsxFileData = res;

            this.openUploadSuccessComponent();
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            // && error.error == 'Invalid file, please download template for reference.'
            if (error && error.status == 400) {
                this.invalidFileMessage = error.error;
            }

            if (error && error.status == 403) {
                this.showFileCountReachComponent('You have reached the max file count limit for uploading files. Please contact info@stratizant.com if you have questions.');
            }

            if (error && error.status == 413 && error.error == 'You have reached the max file size limit for uploading files. Please reduce your file size before uploading or contact info@stratizant.com if you have questions.') {
                this.showFileCountReachComponent(error.error);
            }
        });

    }
    showFileCountReachComponent(error: any) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = error;
        const dialogRef = this.matDialog.open(FileCountReachDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            this.predictionFileUpload.nativeElement.value = '';
        });
    }


    openUploadSuccessComponent() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = 'File';
        const dialogRef = this.matDialog.open(UploadSuccessComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            //this.noFileUploaded = true;
            //this.discardMapFileFlag = true;
            this.getPredictionDataList();
        });
    }


}
