import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-upload-success',
    templateUrl: './upload-success.component.html',
    styleUrls: ['./upload-success.component.css']
})
export class UploadSuccessComponent implements OnInit {
    dataToUpload:any;
    typeofdata: any
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<UploadSuccessComponent>) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        this.dataToUpload = this.data;
        this.typeofdata = typeof(this.dataToUpload);
    }

    close() {
 
        this.dialogRef.close('false');
    

    }

}
