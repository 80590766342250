import { AfterViewInit, Component, OnInit, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { StratizantFirebaseAuthService } from '../services/stratizant-firebase-auth.service';
import { HttpClient } from '@angular/common/http';
import { OrgManagementService } from './admin-settings/org-management/org-management.service';
import { UserProfileService } from './user-profile/user-profile.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from '../shared/auth-dialog/auth-dialog/auth-dialog.component';
import { SessionManagementServiceService } from '../services/session-management-service.service';
import { CookieService } from 'ngx-cookie-service';


declare let $: any;

interface QuestionResponse {
    max_score_text: string;
    min_score_text: string;
    survey_question: string;
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, AfterViewChecked {
    hidesidebar: any = false;
    loader = false;
    caretShow = false;
    focusedValue = -1;
    questionString = '';
    minRatingString = '';
    maxRatingString = '';
    isSurveyPopupVisible = false;
    submitButtonDisabled = true;
    private httpHeaders = { 'Client-Id': 'Full Confidence', 'Client-Key': 'ceddab4c3f9043fa9b11719c413af13e' };
    private httpParams = {
        kpi_name: 'App_Experience_Score',
        organization_name: 'Stratizant',
        bu_name: 'All',
        program_name: 'All',
        application_name: 'FullConfidence'
    };
    loggedInUserName: any;
    organisation_id: any;
    organisation_name: any;
    roleType: any;
    userDetails: any;
    organisationId: any;
    authtoken: any;

    showUpgradeNow: boolean;
    showCurrentperiodEnd: boolean;
    currentPeriodEndDate: any;
    lastloginatTimeDifference: any;

    constructor(private router: Router, private firebaseAuthService: StratizantFirebaseAuthService, private httpClient: HttpClient,
                private cdRef: ChangeDetectorRef, private orgService: OrgManagementService, 
                private fbAuth: StratizantFirebaseAuthService, private matDialog: MatDialog,
                private userProfileService: UserProfileService, 
                private cookieServiceVariable: CookieService,
                private sessionManagementServiceService:SessionManagementServiceService) {

        // window.addEventListener('click', function (event) {
        //     event.stopPropagation();
        // }, true);
    }

    ngOnInit() {
        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }
      
        this.organisationId = localStorage.getItem('organisation_id');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1')
       

        // if (this.router.url.includes('upgradeSubscription')) {
        //     this.hidesidebar = true
        //     $('#sidebar').addClass('hideNav');
        //     $('#sidebar1').addClass('hideNav');
        //     $('#content').addClass('hideNav');
        //     this.router.navigate(['/home/upgradeSubscription'])
        // }
        this.loggedInUserName = localStorage.getItem('loggedInUserName');
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.roleType = localStorage.getItem('roleType');
        // TODO find a way to grab the date created attribute for the current user to display the trial period
        console.log(this.userDetails);
        this.userDetails.email = (this.userDetails.email).split("@")[0];
        $('#small-img').toggleClass('d-none');
        $('#list-itm2').toggleClass('d-none');
        document.getElementById('ques_modal').style.display = 'block';
        this.getOrganizationsList();
        this.httpClient.get<QuestionResponse>('https://surveyapp-dot-kpi-dashboard-272713.appspot.com/api/app_experience_score/get_question?',
                                              {
                                                  params: this.httpParams, responseType: 'json', headers: this.httpHeaders
                                              }).subscribe(response => {

            this.questionString = response.survey_question;
            this.minRatingString = response.min_score_text;
            this.maxRatingString = response.max_score_text;
            document.getElementById('question').textContent = this.questionString;
            document.getElementById('minText').textContent = this.minRatingString;
            document.getElementById('maxText').textContent = this.maxRatingString;
            document.getElementById('popup-title').textContent = 'FullConfidence App Experience';
            this.isSurveyPopupVisible = true;
        },
                                                           () => {

                                                           });

        if (this.router.url.includes('scores')) {
            this.selectItem('scoreCard', 'sidebar');
        } else if (this.router.url.includes('trendChart')) {
            this.selectItem('trendChart', 'sidebar');
        } else if (this.router.url.includes('adminSettings')) {
            this.selectItem('adminSettings', 'sidebar');
        } else if (this.router.url.includes('roster')) {
            this.selectItem('roster', 'sidebar');
        } else if (this.router.url.includes('myProfile')) {
            this.selectItem('myProfile', 'sidebar');
        }
        this.getStripeSubscriptionDetails();
       
    }

  

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    gotoUpgradeSubscription() {
        // this.hidesidebar = true;
        // $('#sidebar').addClass('hideNav');
        // $('#sidebar1').addClass('hideNav');
        // $('#content').addClass('hideNav');

        this.router.navigate(['/home/upgradeSubscription'])
        $('#scorecard').removeClass('active');
        $('#scores1').removeClass('active');
        $('#adminSettings').removeClass('active');
        $('#admin1').removeClass('active');
        $('#roster1').removeClass('active');
        $('#sup_roster').removeClass('active');
        $('#trendChart').removeClass('active');
    }

    ngAfterViewInit() {
        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }

        if (this.router.url.includes('upgradeSubscription')) {
            $('#scorecard').removeClass('active');
            $('#scores1').removeClass('active');
            $('#adminSettings').removeClass('active');
            $('#admin1').removeClass('active');
            $('#roster1').removeClass('active');
            $('#sup_roster').removeClass('active');
            $('#trendChart').removeClass('active');
        }


        if (this.router.url.includes('scores')) {
            this.caretShow = true;
            this.highlightItem('scoreCard');
        } else if (this.router.url.includes('roster')) {
            this.caretShow = true;
            this.highlightItem('roster');
        } else if (this.router.url.includes('adminSettings')) {
            this.caretShow = false;
            this.highlightItem('adminSettings');
        } else if (this.router.url.includes('trendChart')) {
            this.caretShow = true;
            this.highlightItem('trendChart');
        } else if (this.router.url.includes('myProfile')) {
            this.removeActiveClass();
        }
    }

    highlightItem(val) {
       
        if (val === 'scoreCard') {
            this.caretShow = true;
            $('#scorecard').addClass('active');
            $('#scores1').addClass('active');
            $('#adminSettings').removeClass('active');
            $('#admin1').removeClass('active');
            $('#roster1').removeClass('active');
            $('#sup_roster').removeClass('active');
            $('#trendChart').removeClass('active');
        } else if (val === 'roster') {
            this.caretShow = false;
            $('#roster1').addClass('active');
            $('#sup_roster').addClass('active');
            $('#scorecard').removeClass('active');
            $('#scores1').removeClass('active');
            $('#adminSettings').removeClass('active');
            $('#admin1').removeClass('active');
            $('#trendChart').removeClass('active');
        } else if (val === 'adminSettings') {
            this.caretShow = false;
            $('#sup_roster').removeClass('active');
            $('#adminSettings').addClass('active');
            $('#admin1').addClass('active');
            $('#scores1').removeClass('active');
            $('#roster1').removeClass('active');
            $('#scorecard').removeClass('active');
            $('#trendChart').removeClass('active');
        } else if (val === 'trendChart') {
            this.caretShow = true;
            $('#trendChart').addClass('active');
            $('#adminSettings').removeClass('active');
            $('#admin1').removeClass('active');
            $('#roster1').removeClass('active');
            $('#scores1').addClass('active');
            $('#scorecard').removeClass('active');
            $('#sup_roster').removeClass('active');
        }
    }

    selectItem(val, barname) {
        if (barname === 'sidebar1') {
            $('#sidebar1').toggleClass('sidebar1-hide');
            $('#sidebar1').toggleClass('slide');
            $('#sidebar1').toggleClass('active');
            $('#overlay-id').toggleClass('overlay');
        }
        localStorage.setItem('backToTrend', 'false');

        if (val === 'adminSettings') {
            this.highlightItem(val);
            this.router.navigate(['/home/adminSettings']);
        } else if (val === 'roster') {
            this.router.navigate(['/home/roster']);
            this.highlightItem(val);
        } else if (val === 'trendChart') {
            this.router.navigate(['/home/trendChart']);
            this.highlightItem(val);
        } else if (val == 'scoreCard') {
            this.router.navigate(['/home/scores']);
            this.highlightItem(val);
        } else if (val == 'myProfile') {
            this.router.navigate(['/home/myProfile']);
            this.removeActiveClass();
        }
    }

    toggleClick() {
        // $('#sidebar').toggleClass('active');
        $('#sidebar1').toggleClass('slide');

        $('#sidebar1').toggleClass('active');
        $('#sidebar1').toggleClass('sidebar1-hide');
        // $('#collapse-id').toggleClass('active');
        $('#overlay-id').toggleClass('overlay');
        // $('#toggle-btn').toggleClass('slide-toggle');
    }

    overlay_clicked() {
        $('#sidebar1').toggleClass('sidebar1-hide');
        $('#sidebar1').toggleClass('slide');
        $('#sidebar1').toggleClass('active');
        $('#overlay-id').toggleClass('overlay');
    }

    onActivate(component) {
        if (component.router && component.router.routerState.snapshot.url.includes('scores')) {

            this.highlightItem('scoreCard');
        } else if (component.route && component.route.url.value[0].path.includes('trendChart')) {
            this.highlightItem('trendChart');
        } else if (component.router && component.router.routerState.snapshot.url.includes('supplierDetail')) {
            this.removeActiveClass();
            //this.highlightItem('roster');
        }else if (component.router && component.router.routerState.snapshot.url.includes('roster')) {
            this.highlightItem('roster');
        }else if (this.router.url.includes("/home/myProfile")) {
            this.getStripeSubscriptionDetails();
        }
    }
    logout() {
        this.firebaseAuthService.signout();
        sessionStorage.removeItem("impact_typeSession");
        sessionStorage.removeItem("duration");
        localStorage.removeItem("xlsxFileColumns");
        localStorage.removeItem("Planname");
        this.cookieServiceVariable.delete('auth_token_1');
        this.endSession();
    }

    endSession() {
        this.sessionManagementServiceService.endSession().subscribe((res) => {
            console.log(res);
        }, (error) => {
            console.log(error);
        });
    }

    
    buttonFocusOut() {
        if (this.focusedValue >= 0) {
            this.submitButtonDisabled = false;
        }
    }
    closePopup() {
        document.getElementById('ques_modal').style.display = 'none';
        const formData = new FormData();
        formData.append('kpi_name', 'App_Experience_Score');
        formData.append('organization_name', 'Stratizant');
        formData.append('bu_name', 'All');
        formData.append('program_name', 'All');
        formData.append('application_name', 'FullConfidence');
        formData.append('survey_score', this.focusedValue.toString());
        if (this.focusedValue >= 0) {
            this.httpClient.put('https://surveyapp-dot-kpi-dashboard-272713.appspot.com/api/app_experience_score/update_score',
                                formData, {
                                    responseType: 'text',
                                    headers: this.httpHeaders
                                }).subscribe(() =>

                error => {
                    console.log(error);
                });
        }

    }

    setFocusedValue(value) {
        this.focusedValue = value;
        this.submitButtonDisabled = false;
    }

    removeActiveClass() {
        this.caretShow = false;
        $('#roster1').removeClass('active');
        $('#sup_roster').removeClass('active');
        $('#scorecard').removeClass('active');
        $('#scores1').removeClass('active');
        $('#adminSettings').removeClass('active');
        $('#admin1').removeClass('active');
        $('#trendChart').removeClass('active');
    }

    expandCollapse() {
        this.caretShow = !this.caretShow;
        if (this.caretShow == true && this.router.url.includes('scoreCard')) {
            this.highlightItem('scoreCard');
        } else if (this.caretShow == true && this.router.url.includes('trendChart')) {
            this.highlightItem('trendChart');
        }
    }

    getStripeSubscriptionDetails() {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.userProfileService.getStripeSubscriptionDetails(data).subscribe(res => {
            this.loader = false;
            for (const row in res.data) {
                if (res.data) {
            
                        
                       


                    if (res.data[row]['metadata']['organisation_id'] == this.organisationId && res.data[row]['status'] == 'active') {
                        this.showUpgradeNow = false;
                        break;
                    }
                    else{
                        this.showUpgradeNow = true;
                    }
                }

            }


        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

  

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }
    getOrganizationsList() {
        this.loader = true;
        let organisations;
        this.organisation_id = localStorage.getItem('organisation_id')

        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.orgService.getOrganizationList(obj).subscribe(res => {
            res = res.replace(/'/g, '"');
            organisations = JSON.parse(res);
            // console.log("organisations", organisations);
         
            for(const org in organisations){
                if(organisations[org]['org_id'] ===  this.organisation_id){
                    this.organisation_name  = organisations[org]['display_name'];
                    localStorage.setItem('organisation_name',this.organisation_name);

                    if (organisations[org]['cancel_at_period_end']) {
                        this.showCurrentperiodEnd = true;
                        this.currentPeriodEndDate = new Date(organisations[org]['cancel_at_period_end']);
                    }else{
                        this.showCurrentperiodEnd = false;
                    }
                }

               
            }

        },(error)=>{
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie' )) {
            //     this.openAuthDialog();
               
            // }
            this.loader = false;
        });
    }

}
