import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestDialogService } from '../request-dialog/request-dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { RequestDialogComponent } from '../request-dialog/request-dialog.component';
import { environment } from '../../../environments/environment';
import * as firebase from 'firebase';
declare const $: any;

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
    oobCode: any;
    apiKey: any;
    confirmPasswordForm: FormGroup;
    mismatchMsg: any;
    loader = false;
    showSetPasswword = false;
    showEmailVerification = false;
    authApp: firebase.auth.Auth;
    mode: any;
    continueUrl: any;


    passLowerCasePatt: any;
    passUpeerCasePatt: any;
    passNumberPatt: any;
    passSpecialCharacterPatt: any;
    passLength: any;


    constructor(private route: ActivatedRoute, private requestService: RequestDialogService, private fb: FormBuilder,
                private matDialog: MatDialog, private router: Router, private _routeParams: ActivatedRoute) { }

    ngOnInit() {
        this.oobCode = this.route.snapshot.queryParams['oobCode'];
        this.apiKey = this.route.snapshot.queryParams['apiKey'];
        this.loader = true;

        this._routeParams.queryParams
            .subscribe(params => {

                this.oobCode = params.oobCode;
                this.apiKey = params.apiKey;
                this.mode = params.mode;
                // if (params.mode == 'verifyEmail') {
                //   this.showSetPasswword = false;
                // } else
                if (params.mode == 'resetPassword') {
                    this.showSetPasswword = true;
                }else{
                    this.continueUrl = decodeURIComponent(params.continueUrl);
                }
                this.checkMode();
            });


    }

    createConfirmPasswordForm() {
        this.confirmPasswordForm = this.fb.group({
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required]
        });
    }


    checkMode() {

        const actionCode = this.oobCode;
        const config = {
            apiKey: environment.firebase.apiKey,
            authDomain: environment.firebase.authDomain,
            databaseURL: environment.firebase.databaseURL,
            projectId: environment.firebase.projectId,
            storageBucket: environment.firebase.storageBucket,
            messagingSenderId: environment.firebase.measurementId,
            appId: environment.firebase.appId,
            measurementId: environment.firebase.measurementId
        };
        const app = firebase.initializeApp(config);
        const auth = app.auth();
        this.authApp = auth;
        switch (this.mode) {
            case 'resetPassword':
                this.showSetPasswword = true;
                this.createConfirmPasswordForm();
                this.loader = false;
                break;
            case 'verifyEmail':
                this.handleVerifyEmail(auth, actionCode, '', '');
                break;
            default:
        }

    }

    handleVerifyEmail(auth, actionCode, continueUrl, lang) {
        const vm = this;
        auth.applyActionCode(actionCode).then(function (resp) {
            vm.showEmailVerification = true;
            vm.loader = false;
        }).catch(function (error) {
            vm.loader = false;
        });
    }

    confirmPassword() {
        this.loader = true;
        this.requestService.confirmPassword(this.oobCode, this.confirmPasswordForm.value.newPassword).then((result) => {
            this.loader = false;
            this.openDialog('passwordChanged');
        }).catch((error) => {
            this.loader = false;
            this.openDialog('passwordError');
        });

    }

    comparePassword() {
        if (this.confirmPasswordForm.value.confirmPassword && this.confirmPasswordForm.value.newPassword != this.confirmPasswordForm.value.confirmPassword) {
            this.mismatchMsg = "Password does not match";
        } else {
            this.mismatchMsg = '';
        }
    }

    gotoLogin(){
        this.router.navigate(['/login']).then(() => { });
    }


    openDialog(action) {
        const dialogConfig = new MatDialogConfig();
        let message, title, resetPassword, errMessage;
        if (action === 'passwordError') {
            errMessage = 'Password change error. Please try again later.';
            title = '';
            resetPassword = false;
        } else {
            message = 'Your password has been successfully changed.';
            title = '';
            resetPassword = false;
            errMessage = '';
        }
        dialogConfig.data = {
            'message': message,
            'title': title,
            'resetPassword': resetPassword,
            'errMessage': errMessage,
            'passwordChange': true
        };
        const dialogRef = this.matDialog.open(RequestDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            // if(value === 'loginpage'){
            //   this.router.navigate(['/login']).then(() => {
            //   });
            // }

        });

    }



    passwordValidation(){
       
        const passwordVal = this.confirmPasswordForm.controls.newPassword.value;

        if(passwordVal){
            const lowercasePatt = new RegExp("(?=.*[a-z])");
            const checkLowercase = lowercasePatt.test(passwordVal);
            if(checkLowercase === false){
                this.passLowerCasePatt = false;
                $('#lowercase').addClass('invalidPass');
            }else{
                this.passLowerCasePatt = true;
                $('#lowercase').removeClass('invalidPass');
            }

            const uppercasePatt = new RegExp("(?=.*[A-Z])");
            const checkUppercase = uppercasePatt.test(passwordVal);
            if(checkUppercase === false){
                this.passUpeerCasePatt = false;
                $('#uppercase').addClass('invalidPass');
            }else{
                this.passUpeerCasePatt = true;
                $('#uppercase').removeClass('invalidPass');
            }

            const numberPatt = new RegExp("(?=.*[0-9])");
            const checkNumber = numberPatt.test(passwordVal);
            if(checkNumber === false){
                this.passNumberPatt = false;
                $('#digit').addClass('invalidPass');
            }else{
                this.passNumberPatt = true;
                $('#digit').removeClass('invalidPass');
            }


            const specialCharacterPatt = new RegExp("(?=.*[@$!%*?&])");
            const checkSpecialCharacter = specialCharacterPatt.test(passwordVal);
            if(checkSpecialCharacter === false){
                this.passSpecialCharacterPatt = false;
                $('#specialCharacter').addClass('invalidPass');
            }else{
                this.passSpecialCharacterPatt = true;
                $('#specialCharacter').removeClass('invalidPass');
            }

            const passwordLength = passwordVal.length;
            if(passwordLength < 8 || passwordLength > 15){
                this.passLength = false;
                $('#length').addClass('invalidPass');
            }else{
                this.passLength = true;
                $('#length').removeClass('invalidPass');
            }
    
        }

        
    }
}
