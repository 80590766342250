import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-file-count-reach-dialog',
    templateUrl: './file-count-reach-dialog.component.html',
    styleUrls: ['./file-count-reach-dialog.component.css']
})
export class FileCountReachDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FileCountReachDialogComponent>) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
    }

    close() {
        this.dialogRef.close();
    }

}
