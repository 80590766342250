import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminSettingsService } from '../../admin-settings.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-impact-type',
    templateUrl: './impact-type.component.html',
    styleUrls: ['./impact-type.component.css']
})
export class ImpactTypeComponent implements OnInit {

    impactTypeForm: FormGroup;
    authtoken: string;
    loader = false;
    errorMsg: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ImpactTypeComponent>,
                private fb: FormBuilder, private adminService: AdminSettingsService,
                private cookieServiceVariable: CookieService) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.dialogRef.updateSize('50%');
        this.impactTypeForm = this.fb.group({
            impact_type: ['', Validators.required]
        });
    }


    changeMode() {
        this.errorMsg = '';
        if(Object.keys(this.data.modeStatus).length != 0){
            for (const impact_type in this.data.modeStatus) {
                if (this.data.toggle_data.mode === 'API' && this.data.toggle_data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && this.data.modeStatus[impact_type]['CSV'] && this.data.modeStatus[impact_type]['CSV'] === true) {
                    this.errorMsg = 'CSV upload is On for the selected Impact Type. Please make it Off and try again.'
                    return;
                } else if (this.data.toggle_data.mode === 'CSV' && this.data.toggle_data.switch === 'ON' && this.impactTypeForm.value.impact_type === impact_type && this.data.modeStatus[impact_type]['API'] && this.data.modeStatus[impact_type]['API'] === true) {
                    this.errorMsg = 'API call is On for the selected Impact Type. Please make it Off and try again.'
                    return;
                }
            }
        }

        const data = {
            'Authorization': this.authtoken
        };
        this.loader = true;

        const formData = new FormData();
        formData.append('mode', this.data.toggle_data.mode);
        formData.append('switch', this.data.toggle_data.switch);
        formData.append('impact_type', this.impactTypeForm.value.impact_type);

        this.adminService.changeMode(formData, data).subscribe(() => {
            this.loader = false;
            this.dialogRef.close(false);
        });
    }


    close() {
        this.dialogRef.close(true);
    }


}
