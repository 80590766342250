import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdminSettingsService {

    basepath = environment.basePath;
    manualImpactBasePath = environment.basePath;
    // environment.manualImpactBasePath;

    constructor(private http: HttpClient) {
    }

    uploadFileIcon(file_data, data, file_type): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        let url = '';
        if (file_type == 'Data') {
            url = 'api/app/upload_file';
        } else if (file_type == 'Icon') {
            url = 'api/app/upload_icon';
        }
        return this.http.post<any>(`${this.basepath + url}`, file_data, httpOptions).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));

    }


    getLogs(data: any, impactType: any ): Observable<any> {
       
        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + ' ' + data.Authorization,
        })
        return this.http.get<any>(`${this.manualImpactBasePath + 'api/app/uploaded_catalogs'}`,
                                  {
                                      params: {impact_type:impactType},headers, responseType: 'json'
                                  })

    }

    getManualImpactDataList(data: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        //const organisation_name = localStorage.getItem('organisation_name');
        return this.http.get<any>(`${this.manualImpactBasePath + 'api/app/manual_impact_catalog'}`, httpOptions);

    }

    getAllSuppliersList(data: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        //const organisation_name = localStorage.getItem('organisation_name');
        return this.http.get<any>(`${this.manualImpactBasePath + 'api/app/suppliers'}`, httpOptions);

    }

    changeMode(toggle_data, data): Observable<any> {
        // let httpOptions = {
        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + ' ' + data.Authorization,
        })
        //};

        return this.http.put<any>(`${this.basepath + 'api/app/impact_ingest_mode'}`, toggle_data, {headers,responseType: 'text' as 'json' }).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));

    }


    getModeStatus(data): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };

        return this.http.get<any>(`${this.basepath + 'api/app/impact_ingest_mode'}`, httpOptions).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));

    }



    deleteManualImpactRecord(data: any, impact_id): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' +data.Authorization,
            })
        }
        return this.http.delete<any>(`${this.manualImpactBasePath + 'api/app/manual_impact_catalog' + impact_id}`, httpOptions);
    }

}
