/* eslint-disable no-var */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { StratizantFireStoreService } from '../../services/stratizant-fire-store.service';
import { StratizantFirebaseAuthService } from '../../services/stratizant-firebase-auth.service';
import { TableLevel1Service } from './table-level1.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseService } from 'src/app/services/stratizant-firebase.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AdminSettingsService } from '../admin-settings/admin-settings.service';
import { CookieService } from 'ngx-cookie-service';

declare let $: any;
@Component({
    selector: 'app-table-level1',
    templateUrl: './table-level1.component.html',
    styleUrls: ['./table-level1.component.css']
})
export class TableLevel1Component implements OnInit {
    static predictionData = [];
    noRecordsMsg: any;
    sortedData: any;
    showAllSuppliers = false;
    public finalData = [];
    public displayedColumns = ['Supplier', 'Trust_Score'];
    public dataSource = new MatTableDataSource();
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('trustSort', { static: false }) sort: MatSort;
    loader = false;
    sortedList = [];
    dir: any = 'desc';
    firstTime: any = true;
    columnDirections = {
        'Supplier': '',
        'Trust_Score': '',
        'Impacted_Trust_Score': ''
    };
    pageNumber: any;
    authtoken: any;
    imageToShow: any;
    showColumns = true;
    impact_type: any = 'COVID-19';
    errorMsg: any;
    showImpactDate: boolean;
    selectedImpactType: any;
    manualImpactType: boolean = false
    showImpactMsg: boolean;
    getselectedPeriodDuration: any;
    lastloginatTimeDifference: any;

    constructor(private route: ActivatedRoute, private fireStoreService: StratizantFireStoreService,
                private firebaseAuthService: StratizantFirebaseAuthService, private router: Router,
                private level1Service: TableLevel1Service, private matDialog: MatDialog,
                private cookieServiceVariable: CookieService,
                private firebaseServices: StratizantFirebaseService,
                private afStorage: AngularFireStorage, private adminService: AdminSettingsService) {
    }

    ngOnInit() {
        this.firstTime = true;
        this.impact_type = localStorage.getItem('impact_type');
        TableLevel1Component.predictionData = [];
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.selectedImpactType = localStorage.getItem('impact_type');
        // this.loader = true;
        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }
        this.getModeStatus();
        const periodId = sessionStorage.getItem('duration');
        // this.changecolour(periodId);
        if(periodId == 'oneDay'){
            this.getAllSuppliersData('');
            // this.changecolour(periodId);
        }else{
            this.getAllSuppliersData(periodId);
            // this.changecolour(periodId);
        }

        // this.fireStoreService.getTrustScore().then((response) => {

        //   for (const key in response) {
        //     if (key) {
        //       const dateObj1 = new Date(response[key]['ECD Date'].seconds * 1000);
        //       response[key]['ECD Date'] = dateObj1.toDateString();
        //       const dateObj2 = new Date(response[key]['Predicted Posting Date'].seconds * 1000);
        //       response[key]['Predicted Posting Date'] = dateObj2.toDateString();
        //     }
        //   }
        //   TableLevel1Component.predictionData = response;
        //   var resData = response;
        //   for (var key in resData) {
        //         if(this.finalData.length != 0){
        //           var cnt =0;
        //           for (var dt in this.finalData) {

        //             if(this.finalData[dt]['Vendor/supplying plant'] == resData[key]['Vendor/supplying plant']){
        //               this.finalData[dt]['total_count'] = this.finalData[dt]['total_count'] + 1;
        //               this.finalData[dt]['Trust Score'] = parseInt(this.finalData[dt]['Trust Score']) + parseInt(resData[key]['Trust Score']);

        //               break;
        //             }else{
        //               cnt++;
        //             }
        //             if(cnt == this.finalData.length){
        //               this.finalData.push(resData[key]);
        //               this.finalData[this.finalData.length-1]['total_count'] = 1;
        //             }
        //           }
        //         } else{
        //           this.finalData.push(resData[key]);
        //           this.finalData[0]['total_count'] = 1;
        //         }
        //   }

        //   for(var j in this.finalData){
        //     this.finalData[j]['Trust Score'] = parseInt(this.finalData[j]['Trust Score']) / this.finalData[j]['total_count'];
        //     this.finalData[j]['Trust Score'] = JSON.stringify(Math.floor(this.finalData[j]['Trust Score']));
        //     this.finalData[j]['Trust Score'] = this.finalData[j]['Trust Score'] +'%'
        //   }


        //   let sortState = {
        //     'active': "Trust Score",
        //     'direction': "asc"
        //   }
        //   this.pageNumber = 0;
        //   this.customSort(sortState, this.finalData);
        //   this.loader = false;
        // });
    }


    getAllSuppliersData(selectedPeriod) {
        this.finalData = [];
        this.loader = true;
    
        const data = {
            'Authorization': this.authtoken
        };
        this.getselectedPeriodDuration = selectedPeriod;


        this.level1Service.gettrustScoreCardSummary(data, selectedPeriod).subscribe(response => {
            //this.loader = false;
         
            if (Object.keys(response).length === 0) {
                this.noRecordsMsg = "No Records Found.";
                this.loader = false;
            } else {
                this.noRecordsMsg = '';
            }
            const new_array = Object.entries(response).map(([type, value]) => ({ type, value }));

            // if( this.manualImpactType){
            //     this.manualImpactType = true; 
            //     this.displayedColumns.push('Impacted_Trust_Score');
               
            // }

            

            TableLevel1Component.predictionData = new_array;
            const resData = new_array;
            for (const key in resData) {
                if (this.finalData.length != 0) {
                    let cnt = 0;
                    for (const dt in this.finalData) {

                        if (this.finalData[dt]['value']['Supplier'] == resData[key]['value']['Supplier']) {
                            this.finalData[dt]['total_count'] = this.finalData[dt]['total_count'] + 1;
                            this.finalData[dt]['value']['Trust_Score'] = this.finalData[dt]['value']['Trust_Score'] + resData[key]['value']['Trust_Score'];
                            if (this.finalData[dt]['value']['Impacted_Delivery_date'] || this.finalData[dt]['value']['Impacted_Trust_Score']) {

                                this.finalData[dt]['impact_total_count'] = this.finalData[dt]['impact_total_count'] + 1;
                                this.finalData[dt]['value']['Impacted_Trust_Score'] = this.finalData[dt]['value']['Impacted_Trust_Score'] + resData[key]['value']['Impacted_Trust_Score'];
                                // this.impact_type = this.finalData[dt]['Impact Type'];
                                this.showColumns = true;
                            }

                            break;
                        } else {
                            cnt++;
                        }

                        if (cnt == this.finalData.length) {
                            this.finalData.push(resData[key]);
                            this.finalData[this.finalData.length - 1]['total_count'] = 1;
                            this.finalData[this.finalData.length - 1]['impact_total_count'] = 1;
                        }

                    }
                } else {
                    this.finalData.push(resData[key]);
                    this.finalData[0]['total_count'] = 1;
                    if (new_array[key]['value']['Impacted_Delivery_date'] || new_array[key]['value']['Impacted_Trust_Score'] ) {
                        this.finalData[0]['impact_total_count'] = 1;
                    }
                }

                // if (imp_cnt === 0) {
                //   this.showColumns = false;
                //   this.displayedColumns = ['Supplier', 'Trust_Score'];
                // }
            }

         

            for (const j in this.finalData) {
                this.finalData[j]['value']['Trust_Score'] = this.finalData[j]['value']['Trust_Score'] / this.finalData[j]['total_count'];
                this.finalData[j]['value']['Trust_Score'] = JSON.stringify(Math.round(this.finalData[j]['value']['Trust_Score']));
                // eslint-disable-next-line no-self-assign
                this.finalData[j]['value']['Trust_Score']= this.finalData[j]['value']['Trust_Score'];

                if (this.finalData[j]['value']['Impacted_Trust_Score'] ) {
                    this.finalData[j]['value']['Impacted_Trust_Score']  = this.finalData[j]['value']['Impacted_Trust_Score']  / this.finalData[j]['impact_total_count'];
                    this.finalData[j]['value']['Impacted_Trust_Score']  = JSON.stringify(Math.round(this.finalData[j]['value']['Impacted_Trust_Score'] ));
                    // this.finalData[j]['Impacted Trust Score'] = this.finalData[j]['Impacted Trust Score'];
                }
            }


            const sortState = {
                'active': 'Trust_Score',
                'direction': 'asc'
            };
            this.pageNumber = 0;
            this.customSort(sortState, this.finalData);
            this.loader = false;
            

        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }else
             if (error.status === 404) {
                this.errorMsg = error.error;
            }

        });
    }



    customSort(val, dataArray) {
        const data = dataArray.slice();
        if (val) {

            this.sortedData = data.sort((a, b) => {
                let isAsc;
                if (val.direction == '') {
                    isAsc = true;
                    val.direction = 'asc';
                } else if (val.direction == this.columnDirections[val.active] && val.direction == 'asc') {
                    isAsc = false;
                    val.direction = 'desc';
                } else if (val.direction == this.columnDirections[val.active] && val.direction == 'desc') {
                    isAsc = true;
                    val.direction = 'asc';
                } else {
                    isAsc = val.direction === 'asc';
                }


                switch (val.active) {
                    case 'Trust_Score':
                        return this.compare(a['value']['Trust_Score'], b['value']['Trust_Score'], isAsc);
                    case 'Supplier':
                        var val1 = a['value']['Supplier'].split(' ');
                        var val2 = b['value']['Supplier'].split(' ');
                        return this.compare(parseInt(val1[1]), parseInt(val2[1]), isAsc);
                    case 'Impacted_Trust_Score':
                        return this.compare(a['value']['Impacted_Trust_Score'], b['value']['Impacted_Trust_Score'], isAsc);
                    default:
                        return 0;
                }


            });

            // this.dataSource.sort = this.sort;
            const list = [];
            const pageStart = this.pageNumber * 10;
            const pageEnd = Math.abs(pageStart + 9);
            for (let i = pageStart; i <= pageEnd; i++) {
                if (this.sortedData[i]) {
                    list.push(this.sortedData[i]);
                }
            }
           
            this.sortedList = list;
            for (const j in this.sortedData) {
                if (this.sortedData[j]['selectRow']) {
                    this.sortedData[j]['selectRow'] = false;
                }
            }
            this.dataSource = new MatTableDataSource(this.sortedData);
            // this.dataSource.paginator = this.paginator;
            
            setTimeout(() =>{
                // this.paginator.pageIndex = 0;
                this.dataSource = new MatTableDataSource(this.sortedData);
                this.dataSource.paginator = this.paginator;
                // this.dataSource.sort = this.sort
            } );

            // this.firstTime = false;
            // const sortState: Sort = {active: val.active, direction: val.direction};
            // this.sort.active = sortState.active;
            // this.sort.direction = sortState.direction;
            // this.sort.sortChange.emit();
            this.columnDirections[val.active] = val.direction;

        }


    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    getPaginatorData(event) {
        const list = [];
        this.pageNumber = event.pageIndex;
        const pageStart = event.pageIndex * event.pageSize;
        const pageEnd = Math.abs(pageStart + 9);
        for (let i = pageStart; i <= pageEnd; i++) {
            if (this.sortedData[i]) {
                list.push(this.sortedData[i]);
            }
        }
        for (const j in this.sortedData) {
            if (this.sortedData[j]['selectRow']) {
                this.sortedData[j]['selectRow'] = false;
            }
        }
        this.dataSource = new MatTableDataSource(this.sortedData);
        this.dataSource.paginator = this.paginator;
        this.sortedList = list;
    }

    selectRow(event) {
        let cnt = 0;
        let found = false;
        const pageStart = this.pageNumber * 10;
        const pageEnd = Math.abs(pageStart + 9);
        for (let i = pageStart; i <= pageEnd; i++) {
            if (cnt == event && found == false) {
                found = true;
                if (this.sortedData[i]) {
                    this.sortedData[i]['selectRow'] = true;
                }
            } else {
                cnt++;
                if (this.sortedData[i]) {
                    this.sortedData[i]['selectRow'] = false;
                }

            }
        }
        this.dataSource = new MatTableDataSource(this.sortedData);
        this.dataSource.paginator = this.paginator;
    }


    getIcon(impact_type) {
        //this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };

        this.level1Service.getIconURL(data, impact_type).subscribe((res) => {
            if (res) {
                const path = res.Path;
                const storageRef = this.afStorage.storage.ref(path);
                storageRef.getDownloadURL().then(url => {

                    this.imageToShow = url;
                });
            }

            //this.createImageFromBlob(res.body);

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.imageToShow = reader.result;
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }


    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }


    showView() {
        this.router.navigate(['home/scoreCard/chartView']);
    }

    getModeStatus() {

        const obj = {
            'Authorization': this.authtoken
        };
        this.errorMsg = '';
        // this.getIcon(localStorage.getItem('impact_type'));
        this.adminService.getModeStatus(obj).subscribe(res => {
            const impacttypeArr = Object.entries(res).map(([type, value]) => ({ type, value }));
            for (const imp_type in impacttypeArr) {

                if (impacttypeArr[imp_type]['type'] === 'COVID-19') {
                    if(impacttypeArr[imp_type]['value']['API'] === true || impacttypeArr[imp_type]['value']['CSV'] === true || impacttypeArr[imp_type]['value']['Manual'] === true){
                        this.showImpactMsg = true;
                        this.getIcon(impacttypeArr[imp_type]['type']);
                    }else{
                        this.showImpactMsg = false;
                    }
                }

            }

            if (res && Object.keys(res).length != 0) {

                for (const imp_type in res) {
                    if (this.selectedImpactType == imp_type) {
                        if (res[imp_type]['API'] === true || res[imp_type]['CSV'] === true) {
                            // this.getIcon(imp_type);
                            // this.impact_type = imp_type;

                            // break;
                        }
                        if (res[imp_type]['API'] === true && res[imp_type]['CSV'] === false) {
                            this.showColumns = true;
                            this.manualImpactType = false;
                            //this.showImpactDate= false;
                            this.displayedColumns.push('Impacted_Trust_Score');
                        } else if(res[imp_type]['API'] === false && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === true){
                            this.manualImpactType = true;
                            this.displayedColumns.push('Impacted_Trust_Score');
                        }else if (res[imp_type]['API'] === false && res[imp_type]['CSV'] === false) {
                            this.showColumns = false;
                            // this.showImpactDate= false;
                            this.manualImpactType = false;
                        } else {
                            this.showColumns = true;
                            // this.showImpactDate = true;
                            this.displayedColumns.push('Impacted_Trust_Score');
                        }
                    }

                }
                // this.displayedColumns.push('Impacted_Trust_Score');
            } else {
                this.showColumns = false;
                this.showImpactDate = false;
            }

        },(error) => {
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
        });

    }


    

    showPeriodicChart(periodId){
        // this.paginator.pageIndex = 0;

        if (this.paginator != undefined) {
            this.paginator.pageIndex = 0;
        }
        if(periodId === 'oneDay'){
            $('#oneDay').addClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration',periodId);
            this.getAllSuppliersData('');
        }else if(periodId === 'oneMonth'){
            $('#oneMonth').addClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration',periodId);       
            this.getAllSuppliersData('oneMonth');
        }else if(periodId === 'threeMonths'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').addClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration',periodId); 
            this.getAllSuppliersData('threeMonths');
        }else if(periodId === 'sixMonths'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').addClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration',periodId); 
            this.getAllSuppliersData('sixMonths');
        }else if(periodId === 'nineMonths'){
            $('#oneMonth').removeClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').addClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration',periodId); 
            this.getAllSuppliersData('nineMonths');
        }else if(periodId === 'oneYear'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').addClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration',periodId); 
            this.getAllSuppliersData('oneYear');
        }else if(periodId === 'twoYear'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').addClass('active');
            sessionStorage.setItem('duration',periodId);
            this.getAllSuppliersData('twoYear');
        }
              
        
    }


    ngAfterViewInit(){
        const periodId = sessionStorage.getItem('duration');
        if(periodId === 'oneDay'){
            $('#oneDay').addClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
        }else if(periodId === 'oneMonth'){
            $('#oneMonth').addClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
        }else if(periodId === 'threeMonths'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').addClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
        }else if(periodId === 'sixMonths'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').addClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
        }else if(periodId === 'nineMonths'){
            $('#oneMonth').removeClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').addClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
        }else if(periodId === 'oneYear'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').addClass('active');
            $('#twoYear').removeClass('active');
        }else if(periodId === 'twoYear'){
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').addClass('active');
        }
              
        
    }
 


}
