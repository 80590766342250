import { Component, OnInit } from '@angular/core';
import { SubscrioptionLevelService } from '../admin-settings/subscription-level/subscrioption-level.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import {  MatDialogConfig,MatDialog } from '@angular/material';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OrgManagementService } from '../admin-settings/org-management/org-management.service';
import { UserManagementService } from '../admin-settings/user-management/user-management.service';



@Component({
    selector: 'app-upgrade-subscription',
    templateUrl: './upgrade-subscription.component.html',
    styleUrls: ['./upgrade-subscription.component.css']
})
export class UpgradeSubscriptionComponent implements OnInit {
    loader = false;
    take_off_subscription_level: any;
    initiator_subscription_level: any;
    enterprise_subscription_level: any;

    initiatorUserCnt: any;
    takeoffUserCnt: any;
    enterPriseUserCnt: any;

    initiator_prediction_file_count_limit: any;
    takeoff_prediction_file_count_limit:any;
    enterprise_prediction_file_count_limit:any;

    initiator_total_file_storage_space: any;
    takeoff_total_file_storage_space: any;
    enterprise_total_file_storage_space: any;

    initiator_configAdmin_Cost: any;
    takeoff_configAdmin_Cost: any;
    enterprise_configAdmin_Cost: any;


    initiator_dashboard_Cost: any;
    takeoff_dashboard_Cost: any;
    enterprise_dashboard_Cost: any;


    initiator_configAdmin_User_Cnt: any;
    initiator_dashboard_User_Cnt: any;

    takeoff_configAdmin_User_Cnt: any;
    takeoff_dashboard_User_Cnt: any;

    enterprise_configAdmin_User_Cnt: any;
    enterprise_dashboard_User_Cnt: any;

    disableInitiatorSelectButton: boolean = true;
    disableTakeoffSelectButton: boolean = true;
   
    organisationId: any;

    sameOrganisationUsersList: any = [];
    organizations: any;

    sameOrganisationUserCnt: any;
  


    planname: any;
    preivouslySelectedPlan: any;
    additionalEnterPriseUser: any;
    disableSelectButton: any;
    authtoken: any;
    lastloginatTimeDifference: any;
   


    constructor(private subscriptionLevelService: SubscrioptionLevelService,
                private fbAuth: StratizantFirebaseAuthService,
                private matDialog: MatDialog,
                private router: Router,
                private orgService: OrgManagementService,
                private route: ActivatedRoute,
                private userService: UserManagementService
                
    ) { }

    ngOnInit() {
        this.getSubscriptionLevelDetails();
        this.getSameOrganisationList();
       
        this.organisationId = localStorage.getItem('organisation_id');
        this.planname = localStorage.getItem('Planname');
        if(this.router.url.includes('changeSubscription')){
            this.route.params.subscribe(params => {
                this.preivouslySelectedPlan = params['subscriptionToChange'];
                this.additionalEnterPriseUser = localStorage.getItem('additionalEnterPriseUser');

            });
        }


        // if(this.router.url.includes('changeSubscription')){
        //     this.preivouslySelectedPlan = localStorage.getItem('previouslySelectedPlan');
        // }
    }

  


    getSubscriptionLevelDetails() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.subscriptionLevelService.getSubscriptionLevelDetails(obj).subscribe(res => {
            this.loader = false;
            const subscribtiondetails = Object.entries(res).map(([type, value]) => ({ type, value }));

           
            for (const subindex in subscribtiondetails) {
              
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Initiator") {
                    this.initiator_subscription_level = subscribtiondetails[subindex];
                    this.setInitiatorSubscriptionLevelValue();

                }
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Take-Off") {
                    this.take_off_subscription_level = subscribtiondetails[subindex];
                    this.setTakeOffSubscriptionLevelValue();
                }
             
               
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Enterprise") {
                    this.enterprise_subscription_level = subscribtiondetails[subindex];
                    this.setEnterpriseSubscriptionLevelValue();
                }

            }

            if(this.preivouslySelectedPlan == 'take-off' && this.takeoffUserCnt > this.initiatorUserCnt && this.router.url.includes('changeSubscription')){
                this.disableInitiatorSelectButton = true;
            }else{
                this.disableInitiatorSelectButton = false;
            }

            if(this.preivouslySelectedPlan == 'initiator' && this.initiatorUserCnt > this.takeoffUserCnt && this.router.url.includes('changeSubscription')){
                this.disableTakeoffSelectButton = true;
            }else{
                this.disableTakeoffSelectButton = false;
            }

            this.getAllUsersList();
        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

  

    setInitiatorSubscriptionLevelValue(){

        this.initiator_configAdmin_User_Cnt = parseInt(this.initiator_subscription_level.value.config_admin_user_count);
        this.initiator_dashboard_User_Cnt = parseInt(this.initiator_subscription_level.value.dashboard_user_count);
        
        this.initiatorUserCnt =  parseInt(this.initiator_subscription_level.value.dashboard_user_count) + parseInt(this.initiator_subscription_level.value.config_admin_user_count);

        this.initiator_prediction_file_count_limit = parseInt(this.initiator_subscription_level.value.prediction_file_count_limit);
        this.initiator_total_file_storage_space = parseInt(this.initiator_subscription_level.value.total_file_storage_space) / 1000;

        this.initiator_configAdmin_Cost = parseInt(this.initiator_subscription_level.value.config_admin_user_cost);
        this.initiator_dashboard_Cost = parseInt(this.initiator_subscription_level.value.dashboard_user_cost);

        if(this.preivouslySelectedPlan == 'enterprise' && this.additionalEnterPriseUser >= this.initiatorUserCnt){
            this.disableSelectButton = true;
        }else {
            this.disableSelectButton = false;
        }
    }

    setTakeOffSubscriptionLevelValue(){
        this.takeoff_configAdmin_User_Cnt = parseInt(this.take_off_subscription_level.value.config_admin_user_count);
        this.takeoff_dashboard_User_Cnt = parseInt(this.take_off_subscription_level.value.dashboard_user_count);

        this.takeoffUserCnt =  parseInt(this.take_off_subscription_level.value.dashboard_user_count) + parseInt(this.take_off_subscription_level.value.config_admin_user_count);

        this.takeoff_prediction_file_count_limit = parseInt(this.take_off_subscription_level.value.prediction_file_count_limit);
        this.takeoff_total_file_storage_space = parseInt(this.take_off_subscription_level.value.total_file_storage_space) / 1000;

        this.takeoff_configAdmin_Cost = parseInt(this.take_off_subscription_level.value.config_admin_user_cost);
        this.takeoff_dashboard_Cost = parseInt(this.take_off_subscription_level.value.dashboard_user_cost);

        if(this.preivouslySelectedPlan == 'enterprise' && this.additionalEnterPriseUser >= this.takeoffUserCnt){
            this.disableSelectButton = true;
        }else {
            this.disableSelectButton = false;
        }

    }

    setEnterpriseSubscriptionLevelValue(){

        this.enterprise_configAdmin_User_Cnt = parseInt(this.enterprise_subscription_level.value.config_admin_user_count);
        this.enterprise_dashboard_User_Cnt = parseInt(this.enterprise_subscription_level.value.dashboard_user_count);

        this.enterPriseUserCnt =  parseInt(this.enterprise_subscription_level.value.dashboard_user_count) + parseInt(this.enterprise_subscription_level.value.config_admin_user_count);

        this.enterprise_prediction_file_count_limit = parseInt(this.enterprise_subscription_level.value.prediction_file_count_limit);
        this.enterprise_total_file_storage_space = parseInt(this.enterprise_subscription_level.value.total_file_storage_space) / 1000;

        this.enterprise_configAdmin_Cost = parseInt(this.enterprise_subscription_level.value.config_admin_user_cost);
        this.enterprise_dashboard_Cost = parseInt(this.enterprise_subscription_level.value.dashboard_user_cost);

    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }

    goToSelectedSubscriptionPlan(plan:any){
        if (this.router.url.includes('changeSubscription')){
            this.router.navigate(['home/changeSelectedSubscriptionPlan/', plan]);
        }else{
            this.router.navigate(['home/selectedSubscriptionPlan/', plan]);

        }
        localStorage.setItem('previouslySelectedPlan', plan);
    }


    getSameOrganisationList() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.orgService.getOrganizationList(obj).subscribe(res => {
            const filteredList = [];
            if(res){
                res = res.replace(/'/g, '"');
                this.organizations = JSON.parse(res);

                for(const userIndex in  this.organizations){               
                    const user = this.organizations[userIndex];
                    if(user['org_id'] == this.organisationId){
                        filteredList.push(user);
                    }
                }
               

            }else{
                this.loader = false;
            }
        },(error)=>{
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
                
        });
    }


    getAllUsersList() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        const filteredList = [];
        this.userService.getAllUsersList(obj).subscribe(res => {
            if(Object.keys(res).length > 0){
                const arr = Object.entries(res).map(([type, value]) => ({type,value}));
                

                for (const row in arr) {
                    if (arr[row]['value']['organisation_id'] == this.organisationId) {
                        filteredList.push(arr);
                    } 
                }
            }else{
                this.loader = false;
            }
            this.loader = false;
            this.sameOrganisationUserCnt = filteredList.length;
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        })
    }
}
