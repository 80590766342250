import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
    selector: 'app-subscription-success-dialog',
    templateUrl: './subscription-success-dialog.component.html',
    styleUrls: ['./subscription-success-dialog.component.css']
})
export class SubscriptionSuccessDialogComponent implements OnInit {
    message: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<SubscriptionSuccessDialogComponent>) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        this.message= this.data;
        console.log("data",this.data);
        
    }
    close() {
 
        this.dialogRef.close();

    }
}
