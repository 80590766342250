import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RequestDialogService } from './request-dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-request-dialog',
    templateUrl: './request-dialog.component.html',
    styleUrls: ['./request-dialog.component.css']
})
export class RequestDialogComponent implements OnInit {

    mailSent = false;
    loader: boolean = false;
    passwordForm: FormGroup;
    errMsg: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<RequestDialogComponent>,
                private requestService : RequestDialogService, private fb: FormBuilder,private router:Router) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        if(this.data.resetPassword === true){
            this.passwordForm = this.fb.group({
                email: ['', Validators.required]
            });
        }
    }

    close() {
        if(this.data.passwordChange){
            this.router.navigate(['/login']).then(() => {
            });
            this.dialogRef.close();
        }else{
            this.dialogRef.close();
        }

    }

    resetPassword() {
        this.loader = true;
        this.errMsg = '';
        this.requestService.resetPassword(this.passwordForm.value.email).then(() => {
            this.loader = false;
            this.mailSent = true;

        }).catch((error) => {
            if(error.code === 'auth/user-not-found'){
                this.errMsg = 'There is no user associated with this email ID.';
            }else if(error.code === 'auth/invalid-email'){
                this.errMsg = error.message;
            }
            this.loader = false;
        });
    }


}
