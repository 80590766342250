import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SupplierTrendChartService {
    basepath = environment.basePath;

    constructor(private http: HttpClient) { }

    getSupplierTrends(data, duration, supplier_name, country) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };

        return this.http.get<any>(`${this.basepath + 'api/app/supplier_trends?duration='+duration+'&supplier='+supplier_name+'&country='+country}`, httpOptions);
    }

    getAllSuppliers(data: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        
        return this.http.get<any>(`${this.basepath + 'api/app/suppliers'}`, httpOptions);

    }

}
