import { Component, OnInit } from '@angular/core';
import { StratizantFirebaseAuthService } from '../services/stratizant-firebase-auth.service';
import { Router } from '@angular/router';
import { StratizantFirebaseService } from '../services/stratizant-firebase.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { RequestDialogComponent } from './request-dialog/request-dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AngularFireAuth } from '@angular/fire/auth';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { SessionManagementServiceService } from '../services/session-management-service.service';
import { InitSessionErrorDialogueComponent } from '../shared/init-session-error-dialogue/init-session-error-dialogue.component';
import { environment } from './../../environments/environment';
import * as moment from 'moment'
import { UserIdleService } from 'angular-user-idle';
import { CookieService } from 'ngx-cookie-service';
import { NextPageComponent } from './sign-up/next-page/next-page.component';
@Component({
    selector: 'app-dash-board-login',
    templateUrl: './dash-board-login.component.html',
    styleUrls: ['./dash-board-login.component.css']
})
export class DashBoardLoginComponent implements OnInit {
    fieldTextType: boolean;
    showResendlink: boolean = false;
    hours: any;
    user: any;
    idCookieVariable:any;
    constructor(private router: Router, private sessionManagementServiceService: SessionManagementServiceService,
                private firebaseAuthService: StratizantFirebaseAuthService,
                private fbAuth: StratizantFirebaseAuthService,
                private cookieServiceVariable: CookieService,
                private userIdle: UserIdleService,
                private firebaseServices: StratizantFirebaseService, private analytics: AngularFireAnalytics, private matDialog: MatDialog,
                private fb: FormBuilder, private afAuth: AngularFireAuth, private googleAnalyticsService: GoogleAnalyticsService) {
        // const eventList = ['click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel',
        //     'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'];
        // for (const event of eventList) {
        //     window.addEventListener(event, () => console.log("event", event));
        // }
      
    }
    loginForm: FormGroup;
    username: string;
    password: string;
    showSpinner = false;
    errorMsg;
    loader = false;
    authtoken: any;

   


    ngOnInit() {

        // this.userIdle.stopWatching();
        if (localStorage.getItem('user')) {
            this.router.navigate(['/home/scores'])
        }
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

    }






    signIn() {
        this.loader = true;
        this.errorMsg = '';
        let createdAt;
        const vm = this;
        this.firebaseAuthService.signIn(this.loginForm.value.username, this.loginForm.value.password).then((result) => {
            this.loader = false;
            createdAt = result['user']['createdAt']
        
          

            if (result) {
                

                this.authtoken = result['user']['xa'];
                if (result['user']['emailVerified'] === true) {
                    localStorage.setItem('user', this.loginForm.value.username);
                    localStorage.setItem('refreshtoken', result['user']['refreshToken']);

                    const fullName = result['user']['displayName'];
                    const splittedName = fullName.split(' ');
                    const fn = splittedName[0];
                    const ln = splittedName[1].charAt(0);
                    const loggedInUserName = fn + ' ' + ln + '.';
                    localStorage.setItem("loggedInUserName", loggedInUserName);
                    this.router.navigate(['/home/scores']).then(() => { });
                    this.googleAnalyticsService.eventEmitter('login', 'engagement', 'login', 'FullConfidence Login');

                    result['user'].getIdTokenResult().then(function (idTokenResult) {
                        // this.authtoken = idTokenResult.token;
                        localStorage.setItem('organisation_id', idTokenResult.claims.organisation_id);
                        localStorage.setItem('auth_token', idTokenResult.token);
                       
                        console.log("role", idTokenResult.claims.role_type);
                        localStorage.setItem('roleType', idTokenResult.claims.role_type);
                        vm.idCookieVariable = idTokenResult.token;
                        vm.cookieServiceVariable.set('auth_token_1',vm.idCookieVariable);

                        const userDetails = {
                            'email': idTokenResult.claims.email,
                            'organisation_name': idTokenResult.claims.organisation_name,
                            'display_name': idTokenResult.claims.name,
                            'phone_number': idTokenResult.claims.phone_number
                        }
                        localStorage.setItem('userDetails', JSON.stringify(userDetails));
                        // this.cookieServiceVariable.set('auth_token_1',idTokenResult.token);
                        // console.log('auth_token_cookie',this.cookieServiceVariable.get('auth_token_1'))
                    });
                    this.startSession();


                } else {
                    this.errorMsg = "Email not verified.";

                    // const ts = parseInt(createdAt);creationTime
                    // const ts_ms = ts * 1000;
                    // const date_ob = new Date(ts_ms);
                    // const year = date_ob.getFullYear();
                    // const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
                    // const date = ("0" + date_ob.getDate()).slice(-2);
                    // const hours = ("0" + date_ob.getHours()).slice(-2);
                    // const minutes = ("0" + date_ob.getMinutes()).slice(-2);
                    // const seconds = ("0" + date_ob.getSeconds()).slice(-2);
                    // // console.log("Date as YYYY-MM-DD Format: " + year + "-" + month + "-" + date);
                    const d = new Date()
                    console.log("nowdate", d);
                    const utcnow: any = d.toUTCString()
                    console.log("nowlocalUTc",utcnow);

                    console.log("ctfrombackend", result['user']['metadata']['creationTime']);
                    console.log("ctafterUTC", new Date(result['user']['metadata']['creationTime']).toUTCString());

                    const ct: any = new Date(result['user']['metadata']['creationTime']).toUTCString()
                    this.hours = moment.duration((moment(utcnow).diff(moment(ct)))).asHours();
                    console.log("hrs", this.hours);
                    this.showResendlink = true
                    this.user = result['user']
                    this.afAuth.signOut();
                }


            }

        }).catch((error) => {
            this.loader = false;
            if (error.message) {
                this.errorMsg = error.message;
                localStorage.removeItem('user');
            }
        });
    }


    openResendEmailSuccesspopup() {

        const actionCodeSettings = {
            // url: 'https://fullconfidence.stratizant.com/login',
            url: environment.continueUrl,
        };
        this.user.sendEmailVerification(actionCodeSettings).then(function () {

        });
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            'submitted': true
        }
        const dialogRef = this.matDialog.open(NextPageComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            this.showResendlink = false;
            this.errorMsg = ''
        });
    }
    startSession() {
        console.log("in start session func");
        const formData = new FormData();
        // const  token = localStorage.getItem('auth_token');
        formData.append('idToken', this.authtoken);
        this.sessionManagementServiceService.startSession(formData).subscribe(() => {
            this.loader = false;
            // this.getUserDetails();
        }, (error) => {
            if (error && (error.error == 'Recent sign in required' || error.error == 'Invalid ID Token' || error.error == 'Failed to create a session cookie')) {
                this.openInitSessionErrorDialog(error.error);
            }
            this.loader = false;
        });
    }

    openInitSessionErrorDialog(error: any) {

        const dialogConfig = new MatDialogConfig();
        if (error == 'Recent sign in required') {
            dialogConfig.data = {
                'error': error
            }
        } else if (error == 'Invalid ID Token' || error == 'Failed to create a session cookie') {
            dialogConfig.data = {
                'error': 'Network error, please login again'
            }
            const dialogRef = this.matDialog.open(InitSessionErrorDialogueComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(() => {
                this.fbAuth.signout();

            });
        }

    }
    getUserDetails() {

        this.sessionManagementServiceService.getUserDetails().subscribe(res => {

            console.log(res);

        }, error => {
            console.log(error);
        });
    }

    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    openDialog(action) {
        const dialogConfig = new MatDialogConfig();
        let message;
        let title;
        let resetPassword;
        if (action === 'forgotPassword') {
            message = '';
            title = 'Reset Your Password';
            resetPassword = true;
        }
        dialogConfig.data = {
            message,
            title,
            resetPassword
        };
        const dialogRef = this.matDialog.open(RequestDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();
            this
                .googleAnalyticsService
                .eventEmitter('logout', 'engagement', 'logout', 'FullConfidence Logout');
        });

    }

    openRegistrationModal() {
        const dialogConfig = new MatDialogConfig();

        //dialogConfig.data 
        const dialogRef = this.matDialog.open(SignUpComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {

        });
    }

    openRequestDemoModal() {
        const dialogConfig = new MatDialogConfig();

        //dialogConfig.data 
        const dialogRef = this.matDialog.open(RequestDemoComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {

        });
    }

}
