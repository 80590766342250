import {Component, OnInit} from '@angular/core';
import {StratizantFireStoreService} from '../../services/stratizant-fire-store.service';
import {StratizantFirebaseAuthService} from '../../services/stratizant-firebase-auth.service';
import {Router} from '@angular/router';


@Component({
    selector: 'app-tabular-lob',
    templateUrl: './tabular-lob.component.html',
    styleUrls: ['./tabular-lob.component.css']
})
export class TabularLobComponent implements OnInit {
    rows = [];
    datesData = [];
    //users: AngularFireList<any>;
    finalData = [];
    dates = [];
    loader = false;

    constructor(private fireStoreService: StratizantFireStoreService, private firebaseAuthService: StratizantFirebaseAuthService, private router: Router) {
        // this.users = db2.list('/parts');

        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }
    }

    ngOnInit() {
        this.loader = true;
        // this.fireStoreService.getPartsData().then(r => {

        //   for (const key of Object.keys(r)) {
        //     const dateObj1 = new Date(r[key].UncoveredLoadDate.seconds * 1000);
        //     const UncoveredLoadDate = dateObj1.toLocaleDateString();
        //     const dateObj2 = new Date(r[key].UCLWithRawMtl.seconds * 1000);
        //     const UCLWithRawMtl = dateObj2.toLocaleDateString();
        //     const dateObj3 = new Date(r[key].UCLWithWIP.seconds * 1000);
        //     const UCLWithWIP = dateObj3.toLocaleDateString();

        //     this.fireStoreService.getDates(r[key].id).then(datesData => {
        //       for (const date in datesData) {
        //         if (this.dates.indexOf(datesData[date].id) === -1) {
        //           this.dates.push(datesData[date].id);
        //           this.dates.sort((val1, val2) => {
        //             return (new Date(val1) as any) - (new Date(val2) as any);
        //           });
        //         }
        //       }

        //       this.datesData = datesData;
        //       this.finalData.push({
        //         PartID: r[key].id,
        //         InvMax: r[key].InvMax,
        //         InvMin: r[key].InvMin,
        //         CurrentInv: r[key].CurrentInv,
        //         RMQty: r[key].RMQty,
        //         UCLWithRawMtl,
        //         UncoveredLoadDate,
        //         UCLWithWIP,
        //         WIPQty: r[key].WIPQty,
        //         ASNs: r[key].ASNs,
        //         ninetydayDemand: r[key]['90DayDemand'],
        //         'Spirit Requirements': [],
        //         'Cumulative Inv': [],
        //         'Delivery Commitments': [],
        //         'Plus WIP': [],
        //         'Plus Raw Material': [],
        //         'Min/Max Penetration': []
        //       });

        //       for (const j in this.datesData) {
        //         this.finalData[key]['Spirit Requirements'].push(this.datesData[j].SpiritRequirements);
        //         this.finalData[key]['Cumulative Inv'].push(this.datesData[j].CumInv);
        //         this.finalData[key]['Delivery Commitments'].push(this.datesData[j].DeliveryCommitment);
        //         this.finalData[key]['Plus WIP'].push(this.datesData[j].PlusWIP);
        //         this.finalData[key]['Plus Raw Material'].push(this.datesData[j].PlusRawMaterial);
        //         this.finalData[key]['Min/Max Penetration'].push(this.datesData[j].MinMaxPenPercent);

        //       }
        //     });

        //   }


        //   this.loader = false;
        // });


    }


}
