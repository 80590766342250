import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OrgManagementService {

    basepath = environment.basePath;

    constructor(private http: HttpClient) { }

    getOrganizationList(data): Observable<any> {
        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + ' ' +data.Authorization,
        })
         return this.http.get<any>(`${this.basepath + 'api/profile/organisations'}`, {headers, responseType: 'text' as 'json'});

        //     let httpOptions = {
        //         headers: new HttpHeaders({
        //             'Authorization': 'Bearer' + ' ' + data.Authorization,
        //         })
        //     }
      
        // return this.http.get<any>(`${this.basepath + 'api/profile/organisations'}`,httpOptions);
       
    }


    createorEditOrgDetails(data: any, userData: any, org_id: any): Observable<any> {
        
        const headers = new HttpHeaders({
            'Authorization': 'Bearer'+' '+ data.Authorization,
        })
  
         if(!org_id){
            // return this.http.post<any>(`${this.basepath+'api/profile/organisations'}` , userData, httpOptions);
            return this.http.post<any>(`${this.basepath+'api/profile/organisations'}` , userData, {headers, responseType: 'text' as 'json'}).pipe(map((data: HttpResponse<any>) => {
                return data;
            }));
        }else if(org_id){
            // return this.http.put<any>(`${this.basepath+'api/profile/organisations'}` , userData, httpOptions);
            return this.http.put<any>(`${this.basepath+'api/profile/organisations'}` , userData, {headers, responseType: 'text' as 'json'}).pipe(map((data: HttpResponse<any>) => {
                return data;
            }));
        }


        return this.http.post<any>(`${this.basepath+'api/profile/organisations'}` , userData, {headers, responseType: 'text' as 'json'}).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));
        // const httpOptions = {
        //     headers: new HttpHeaders({
        //         'Authorization': 'Bearer' + ' ' + data.Authorization,
        //     })
        // };

        // if(!org_id){
        //     return this.http.post<any>(`${this.basepath+'api/profile/organisations'}` , userData, httpOptions);
        // }else if(org_id){
        //     return this.http.put<any>(`${this.basepath+'api/profile/organisations'}` , userData, httpOptions);
        // }

    }

    getSubscriptionsList(data): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
     
        
        // return this.http.get<any>(`${this.basepath + 'api/profile/stripe_subscriptions'}`, {responseType: 'text' as 'json'});
   
        return this.http.get<any>(`${this.basepath + 'api/profile/stripe_subscriptions'}`, httpOptions);
        
    }


}
