import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class UploadSupplierDeliveryFileService {
    basepath = environment.basePath + "api/app/map_prediction_set";
    completeMappingBasePath = environment.basePath + "api/app/generate_ai_prediction";
    predictionDataSetListPath = environment.basePath + "api/app/prediction_dataset_log"


    constructor(private http: HttpClient) { }


    mapnames(data: any, userData: any): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        return this.http.post<any>(`${this.basepath}`, userData, httpOptions).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));
    }


    completeMapping(data: any, userData: any): Observable<any> {

        // let httpOptions = {
            const headers = new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        // }
        return this.http.post<any>(`${this.completeMappingBasePath}`,userData,{headers,responseType: 'text' as 'json' }).pipe(
          map((res: HttpResponse<any>) =>{
          return res;
          })
          //catchError(this.handleError<any>('create login'))
        );

      


        // // let httpOptions = {
        // const headers = new HttpHeaders({
        //     'Authorization': 'Bearer' + ' ' + data.Authorization,
        // })
        // //};

        // return this.http.post<any>(`${this.completeMappingBasePath}`, userData, {responseType: 'text' as 'json' }).pipe(map((data: HttpResponse<any>) => {
        //     return data;
        // }));


    }

    getPredictionDataList(data: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        //const organisation_name = localStorage.getItem('organisation_name');
        return this.http.get<any>(`${this.predictionDataSetListPath}`, httpOptions);

    }

    uploadPredictionDatasetFile(data: any, userData: any) {
        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + ' ' + data.Authorization,
        })
        return this.http.post<any>(`${this.predictionDataSetListPath}`, userData, {headers,responseType: 'text' as 'json' }).pipe(map((res: HttpResponse<any>) => {
            return res;
        }));
    }
}
