import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubscrioptionLevelService } from './subscrioption-level.service';
import { UpdateSubscriptionLevelUserIpComponent } from './update-subscription-level-user-ip/update-subscription-level-user-ip.component';
import {  MatDialogConfig,MatDialog } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionSuccessDialogComponent } from './subscription-success-dialog/subscription-success-dialog.component';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
declare let $: any;
@Component({
    selector: 'app-subscription-level',
    templateUrl: './subscription-level.component.html',
    styleUrls: ['./subscription-level.component.css']
})
export class SubscriptionLevelComponent implements OnInit {
    authtoken: any;
    disableUpdate:boolean;
    loader = false;
    subscription_level_id: any;
    selectedSubscriptionLevel: any;
    trial_Period_subscription_level: any;
    take_off_subscription_level: any;
    stratizant_subscription_level: any;
    initiator_subscription_level: any;
    enterprise_subscription_level: any;

    trial_period_cost_additional_dashboard_user: any;
    initiator_cost_additional_dashboard_user: any;
    takeoff_cost_additional_dashboard_user: any;
    stratizant_cost_additional_dashboard_user: any;
    lastloginatTimeDifference: any;

    @Input('subscriptionManagementtabClicked') subscriptionManagementtabClicked: any = [];
    subscritionLevelForm: FormGroup;

    constructor(private fbAuth: StratizantFirebaseAuthService,
                private cookieServiceVariable: CookieService,
                private matDialog: MatDialog,private fb: FormBuilder, private subscriptionLevelService: SubscrioptionLevelService) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        // this.getSubscriptionLevelDetails();
        this.createSubscriptionLevelForm();
    }



    createSubscriptionLevelForm() {
        this.subscritionLevelForm = this.fb.group({
            trial_period_TrialP_user_cnt: ['', Validators.required],
            trial_period_dashboard_user_cnt: ['', Validators.required],
            trial_period_configAdmin_user_cnt: ['', Validators.required],
            trial_period_superAdmin_user_cnt: ['', Validators.required],
            trial_period_cost_trialP_user: ['', Validators.required],
            trial_period_cost_dashboard_user: ['', Validators.required],
            trial_period_cost_configAdmin_user: ['', Validators.required],
            trial_period_cost_superAdmin_user: ['', Validators.required],
            trial_period_cost_additional_dashboard_user: ['', Validators.required],
            trial_period_file_upload_limit_cnt: ['', Validators.required],
            trial_period_file_upload_limit_size: ['', Validators.required],
            trial_period_file_upload_frequency: ['', Validators.required],
            trial_period_training_file_upload_limit: ['', Validators.required],
            trial_period_training_file_upload_limit_size: ['', Validators.required],
            trial_period_training_file_upload_frequency: ['', Validators.required],
            trial_period_total_file_storage_space: ['', Validators.required],
            trial_period_user_upload: ['', Validators.required],
            trial_period_file_drop: ['', Validators.required],
            trial_period_api_integration: ['', Validators.required],
            trial_period_env_impact: ['', Validators.required],
            trial_period_export_to_pdf: ['', Validators.required],
            trial_period_export_to_csv: ['', Validators.required],
            trial_period_email_phone_support: ['', Validators.required],
            trial_period_live_chat_support: ['', Validators.required],
            trial_period_company_logo: ['', Validators.required],
           


            initiator_TrialP_user_cnt: ['', Validators.required],
            initiator_dashboard_user_cnt: ['', Validators.required],
            initiator_configAdmin_user_cnt: ['', Validators.required],
            initiator_superAdmin_user_cnt: ['', Validators.required],
            initiator_cost_trialP_user: ['', Validators.required],
            initiator_cost_dashboard_user: ['', Validators.required],
            initiator_cost_configAdmin_user: ['', Validators.required],
            initiator_cost_superAdmin_user: ['', Validators.required],
            initiator_cost_additional_dashboard_user: ['', Validators.required],
            initiator_file_upload_limit_cnt: ['', Validators.required],
            initiator_file_upload_limit_size: ['', Validators.required],
            initiator_file_upload_frequency: ['', Validators.required],
            initiator_training_file_upload_limit: ['', Validators.required],
            initiator_training_file_upload_limit_size: ['', Validators.required],
            initiator_training_file_upload_frequency: ['', Validators.required],
            initiator_user_upload: ['', Validators.required],
            initiator_file_drop: ['', Validators.required],
            initiator_api_integration: ['', Validators.required],
            initiator_env_impact: ['', Validators.required],
            initiator_export_to_pdf: ['', Validators.required],
            initiator_export_to_csv: ['', Validators.required],
            initiator_email_phone_support: ['', Validators.required],
            initiator_live_chat_support: ['', Validators.required],
            initiator_company_logo: ['', Validators.required],
            initiator_total_file_storage_space: ['', Validators.required],



            takeoff_TrialP_user_cnt: ['', Validators.required],
            takeoff_dashboard_user_cnt: ['', Validators.required],
            takeoff_configAdmin_user_cnt: ['', Validators.required],
            takeoff_superAdmin_user_cnt: ['', Validators.required],
            takeoff_cost_trialP_user: ['', Validators.required],
            takeoff_cost_dashboard_user: ['', Validators.required],
            takeoff_cost_configAdmin_user: ['', Validators.required],
            takeoff_cost_superAdmin_user: ['', Validators.required],
            takeoff_cost_additional_dashboard_user: ['', Validators.required],
            takeoff_file_upload_limit_cnt: ['', Validators.required],
            takeoff_file_upload_limit_size: ['', Validators.required],
            takeoff_file_upload_frequency: ['', Validators.required],
            takeoff_training_file_upload_limit: ['', Validators.required],
            takeoff_training_file_upload_limit_size: ['', Validators.required],
            takeoff_training_file_upload_frequency: ['', Validators.required],
            takeoff_user_upload: ['', Validators.required],
            takeoff_file_drop: ['', Validators.required],
            takeoff_api_integration: ['', Validators.required],
            takeoff_env_impact: ['', Validators.required],
            takeoff_export_to_pdf: ['', Validators.required],
            takeoff_export_to_csv: ['', Validators.required],
            takeoff_email_phone_support: ['', Validators.required],
            takeoff_live_chat_support: ['', Validators.required],
            takeoff_company_logo: ['', Validators.required],
            takeoff_total_file_storage_space: ['', Validators.required],




            enterprise_TrialP_user_cnt: ['', Validators.required],
            enterprise_dashboard_user_cnt: ['', Validators.required],
            enterprise_configAdmin_user_cnt: ['', Validators.required],
            enterprise_superAdmin_user_cnt: ['', Validators.required],
            enterprise_cost_trialP_user: ['', Validators.required],
            enterprise_cost_dashboard_user: ['', Validators.required],
            enterprise_cost_configAdmin_user: ['', Validators.required],
            enterprise_cost_superAdmin_user: ['', Validators.required],
            enterprise_cost_additional_dashboard_user: ['', Validators.required],
            enterprise_file_upload_limit_cnt: ['', Validators.required],
            enterprise_file_upload_limit_size: ['', Validators.required],
            enterprise_file_upload_frequency: ['', Validators.required],
            enterprise_training_file_upload_limit: ['', Validators.required],
            enterprise_training_file_upload_limit_size: ['', Validators.required],
            enterprise_training_file_upload_frequency: ['', Validators.required],
            enterprise_user_upload: ['', Validators.required],
            enterprise_file_drop: ['', Validators.required],
            enterprise_api_integration: ['', Validators.required],
            enterprise_env_impact: ['', Validators.required],
            enterprise_export_to_pdf: ['', Validators.required],
            enterprise_export_to_csv: ['', Validators.required],
            enterprise_email_phone_support: ['', Validators.required],
            enterprise_live_chat_support: ['', Validators.required],
            enterprise_company_logo: ['', Validators.required],
            enterprise_total_file_storage_space: ['', Validators.required],



            stratizant_TrialP_user_cnt: ['', Validators.required],
            stratizant_dashboard_user_cnt: ['', Validators.required],
            stratizant_configAdmin_user_cnt: ['', Validators.required],
            stratizant_superAdmin_user_cnt: ['', Validators.required],
            stratizant_cost_trialP_user: ['', Validators.required],
            stratizant_cost_dashboard_user: ['', Validators.required],
            stratizant_cost_configAdmin_user: ['', Validators.required],
            stratizant_cost_superAdmin_user: ['', Validators.required],
            stratizant_cost_additional_dashboard_user: ['', Validators.required],
            stratizant_file_upload_limit_cnt: ['', Validators.required],
            stratizant_file_upload_limit_size: ['', Validators.required],
            stratizant_file_upload_frequency: ['', Validators.required],
            stratizant_training_file_upload_limit: ['', Validators.required],
            stratizant_training_file_upload_limit_size: ['', Validators.required],
            stratizant_training_file_upload_frequency: ['', Validators.required],
            stratizant_user_upload: ['', Validators.required],
            stratizant_file_drop: ['', Validators.required],
            stratizant_api_integration: ['', Validators.required],
            stratizant_env_impact: ['', Validators.required],
            stratizant_export_to_pdf: ['', Validators.required],
            stratizant_export_to_csv: ['', Validators.required],
            stratizant_email_phone_support: ['', Validators.required],
            stratizant_live_chat_support: ['', Validators.required],
            stratizant_company_logo: ['', Validators.required],
            stratizant_total_file_storage_space: ['', Validators.required]


        });
    }

    getSubscriptionLevelDetails() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.subscriptionLevelService.getSubscriptionLevelDetails(obj).subscribe(res => {
            this.loader = false;
            const subscribtiondetails = Object.entries(res).map(([type, value]) => ({ type, value }));

            for (const subindex in subscribtiondetails) {
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Trial Period") {
                    this.trial_Period_subscription_level = subscribtiondetails[subindex];
                    this.setTrialPeriodSubscriptionLevelValue();
                }
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Take-Off") {
                    this.take_off_subscription_level = subscribtiondetails[subindex];
                    this.setTakeOffSubscriptionLevelValue();
                }
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Stratizant") {
                    this.stratizant_subscription_level = subscribtiondetails[subindex];
                    this.setStratizantSubscriptionLevelValue();
                }
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Initiator") {
                    this.initiator_subscription_level = subscribtiondetails[subindex];
                    this.setInitiatorSubscriptionLevelValue();
                    
                }
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Enterprise") {
                    this.enterprise_subscription_level = subscribtiondetails[subindex];
                    this.setEnterpriseSubscriptionLevelValue();
                }

            }

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            //    if(error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    setTrialPeriodSubscriptionLevelValue(){
        if(this.trial_Period_subscription_level){
            this.subscritionLevelForm.patchValue({
                trial_period_TrialP_user_cnt: this.trial_Period_subscription_level['value']['trial_period_user_count'],
                trial_period_dashboard_user_cnt: this.trial_Period_subscription_level['value']['dashboard_user_count'],
                trial_period_configAdmin_user_cnt: this.trial_Period_subscription_level['value']['config_admin_user_count'],
                trial_period_superAdmin_user_cnt: this.trial_Period_subscription_level['value']['super_admin_user_count'],
                trial_period_cost_trialP_user:this.trial_Period_subscription_level['value']['trial_period_user_cost'],
                trial_period_cost_dashboard_user: this.trial_Period_subscription_level['value']['dashboard_user_cost'],
                trial_period_cost_configAdmin_user: this.trial_Period_subscription_level['value']['config_admin_user_cost'],
                trial_period_cost_superAdmin_user:this.trial_Period_subscription_level['value']['super_admin_user_cost'],
                trial_period_cost_additional_dashboard_user:this.trial_Period_subscription_level['value']['additional_dashboard_user_cost'],
                trial_period_file_upload_limit_cnt: this.trial_Period_subscription_level['value']['prediction_file_count_limit'],
                trial_period_file_upload_limit_size: this.trial_Period_subscription_level['value']['prediction_file_size_limit'],
                trial_period_file_upload_frequency: this.trial_Period_subscription_level['value']['prediction_file_upload_frequency'],
                trial_period_training_file_upload_limit: this.trial_Period_subscription_level['value']['training_file_count_limit'],
                trial_period_training_file_upload_limit_size: this.trial_Period_subscription_level['value']['training_file_size_limit'],
                trial_period_training_file_upload_frequency: this.trial_Period_subscription_level['value']['training_file_upload_frequency'],
                trial_period_total_file_storage_space: this.trial_Period_subscription_level['value']['total_file_storage_space'],
                trial_period_user_upload: this.trial_Period_subscription_level['value']['user_upload'],
                trial_period_file_drop: this.trial_Period_subscription_level['value']['file_drop'],
                trial_period_api_integration: this.trial_Period_subscription_level['value']['api_integration_with_client'],
                trial_period_env_impact: this.trial_Period_subscription_level['value']['env_impact_calculation_no'],
                trial_period_export_to_pdf: this.trial_Period_subscription_level['value']['export_to_pdf'],
                trial_period_export_to_csv: this.trial_Period_subscription_level['value']['export_to_csv'],
                trial_period_email_phone_support: this.trial_Period_subscription_level['value']['email_phone_support'],
                trial_period_live_chat_support: this.trial_Period_subscription_level['value']['live_chat_support'],
                trial_period_company_logo: this.trial_Period_subscription_level['value']['customized_company_logo'],
           
            });

            this.subscritionLevelForm.controls.trial_period_TrialP_user_cnt.disable();
            this.subscritionLevelForm.controls.trial_period_dashboard_user_cnt.disable();
            this.subscritionLevelForm.controls. trial_period_configAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls. trial_period_superAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls. trial_period_cost_trialP_user.disable();
            this.subscritionLevelForm.controls. trial_period_cost_dashboard_user.disable();
            this.subscritionLevelForm.controls. trial_period_cost_configAdmin_user.disable();
            this.subscritionLevelForm.controls. trial_period_cost_superAdmin_user.disable();
            this.subscritionLevelForm.controls. trial_period_cost_additional_dashboard_user.disable();
            this.subscritionLevelForm.controls. trial_period_file_upload_limit_cnt.disable();
            this.subscritionLevelForm.controls. trial_period_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls. trial_period_file_upload_frequency.disable();
            this.subscritionLevelForm.controls. trial_period_training_file_upload_limit.disable();
            this.subscritionLevelForm.controls. trial_period_training_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls. trial_period_training_file_upload_frequency.disable();
            this.subscritionLevelForm.controls. trial_period_total_file_storage_space.disable();
            this.subscritionLevelForm.controls. trial_period_user_upload.disable();
            this.subscritionLevelForm.controls. trial_period_file_drop.disable();
            this.subscritionLevelForm.controls. trial_period_api_integration.disable();
            this.subscritionLevelForm.controls. trial_period_env_impact.disable();
            this.subscritionLevelForm.controls. trial_period_export_to_pdf.disable();
            this.subscritionLevelForm.controls. trial_period_export_to_csv.disable();
            this.subscritionLevelForm.controls. trial_period_email_phone_support.disable();
            this.subscritionLevelForm.controls. trial_period_live_chat_support.disable();
            this.subscritionLevelForm.controls. trial_period_company_logo.disable();

        }
     
    }

    setInitiatorSubscriptionLevelValue(){

        if(this.initiator_subscription_level){
            this.subscritionLevelForm.patchValue({
                initiator_TrialP_user_cnt: this.initiator_subscription_level['value']['trial_period_user_count'],
                initiator_dashboard_user_cnt: this.initiator_subscription_level['value']['dashboard_user_count'],
                initiator_configAdmin_user_cnt: this.initiator_subscription_level['value']['config_admin_user_count'],
                initiator_superAdmin_user_cnt: this.initiator_subscription_level['value']['super_admin_user_count'],
                initiator_cost_trialP_user:this.initiator_subscription_level['value']['trial_period_user_cost'],
                initiator_cost_dashboard_user: this.initiator_subscription_level['value']['dashboard_user_cost'],
                initiator_cost_configAdmin_user: this.initiator_subscription_level['value']['config_admin_user_cost'],
                initiator_cost_superAdmin_user:this.initiator_subscription_level['value']['super_admin_user_cost'],
                initiator_cost_additional_dashboard_user:this.initiator_subscription_level['value']['additional_dashboard_user_cost'],
                initiator_file_upload_limit_cnt: this.initiator_subscription_level['value']['prediction_file_count_limit'],
                initiator_file_upload_limit_size: this.initiator_subscription_level['value']['prediction_file_size_limit'],
                initiator_file_upload_frequency: this.initiator_subscription_level['value']['prediction_file_upload_frequency'],
                initiator_training_file_upload_limit: this.initiator_subscription_level['value']['training_file_count_limit'],
                initiator_training_file_upload_limit_size: this.initiator_subscription_level['value']['training_file_size_limit'],
                initiator_training_file_upload_frequency: this.initiator_subscription_level['value']['training_file_upload_frequency'],
                initiator_total_file_storage_space: this.initiator_subscription_level['value']['total_file_storage_space'],
                initiator_user_upload: this.initiator_subscription_level['value']['user_upload'],
                initiator_file_drop: this.initiator_subscription_level['value']['file_drop'],
                initiator_api_integration: this.initiator_subscription_level['value']['api_integration_with_client'],
                initiator_env_impact: this.initiator_subscription_level['value']['env_impact_calculation_no'],
                initiator_export_to_pdf: this.initiator_subscription_level['value']['export_to_pdf'],
                initiator_export_to_csv: this.initiator_subscription_level['value']['export_to_csv'],
                initiator_email_phone_support: this.initiator_subscription_level['value']['email_phone_support'],
                initiator_live_chat_support: this.initiator_subscription_level['value']['live_chat_support'],
                initiator_company_logo: this.initiator_subscription_level['value']['customized_company_logo'],
            });
            this.subscritionLevelForm.controls.initiator_TrialP_user_cnt.disable();
            this.subscritionLevelForm.controls.initiator_dashboard_user_cnt.disable();
            this.subscritionLevelForm.controls.initiator_configAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls.initiator_superAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls.initiator_cost_trialP_user.disable();
            this.subscritionLevelForm.controls.initiator_cost_dashboard_user.disable();
            this.subscritionLevelForm.controls.initiator_cost_configAdmin_user.disable();
            this.subscritionLevelForm.controls.initiator_cost_superAdmin_user.disable();
            this.subscritionLevelForm.controls.initiator_cost_additional_dashboard_user.disable();
            this.subscritionLevelForm.controls.initiator_file_upload_limit_cnt.disable();
            this.subscritionLevelForm.controls.initiator_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls.initiator_file_upload_frequency.disable();
            this.subscritionLevelForm.controls.initiator_training_file_upload_limit.disable();
            this.subscritionLevelForm.controls.initiator_training_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls.initiator_training_file_upload_frequency.disable();
            this.subscritionLevelForm.controls.initiator_total_file_storage_space.disable();
            this.subscritionLevelForm.controls.initiator_user_upload.disable();
            this.subscritionLevelForm.controls.initiator_file_drop.disable();
            this.subscritionLevelForm.controls.initiator_api_integration.disable();
            this.subscritionLevelForm.controls.initiator_env_impact.disable();
            this.subscritionLevelForm.controls.initiator_export_to_pdf.disable();
            this.subscritionLevelForm.controls.initiator_export_to_csv.disable();
            this.subscritionLevelForm.controls.initiator_email_phone_support.disable();
            this.subscritionLevelForm.controls.initiator_live_chat_support.disable();
            this.subscritionLevelForm.controls.initiator_company_logo.disable();
        }
    }

    setTakeOffSubscriptionLevelValue(){
        if(this.take_off_subscription_level){
            this.subscritionLevelForm.patchValue({
                takeoff_TrialP_user_cnt: this.take_off_subscription_level['value']['trial_period_user_count'],
                takeoff_dashboard_user_cnt: this.take_off_subscription_level['value']['dashboard_user_count'],
                takeoff_configAdmin_user_cnt: this.take_off_subscription_level['value']['config_admin_user_count'],
                takeoff_superAdmin_user_cnt: this.take_off_subscription_level['value']['super_admin_user_count'],
                takeoff_cost_trialP_user:this.take_off_subscription_level['value']['trial_period_user_cost'],
                takeoff_cost_dashboard_user: this.take_off_subscription_level['value']['dashboard_user_cost'],
                takeoff_cost_configAdmin_user: this.take_off_subscription_level['value']['config_admin_user_cost'],
                takeoff_cost_superAdmin_user:this.take_off_subscription_level['value']['super_admin_user_cost'],
                takeoff_cost_additional_dashboard_user:this.take_off_subscription_level['value']['additional_dashboard_user_cost'],
                takeoff_file_upload_limit_cnt: this.take_off_subscription_level['value']['prediction_file_count_limit'],
                takeoff_file_upload_limit_size: this.take_off_subscription_level['value']['prediction_file_size_limit'],
                takeoff_file_upload_frequency: this.take_off_subscription_level['value']['prediction_file_upload_frequency'],
                takeoff_training_file_upload_limit: this.take_off_subscription_level['value']['training_file_count_limit'],
                takeoff_training_file_upload_limit_size: this.take_off_subscription_level['value']['training_file_size_limit'],
                takeoff_training_file_upload_frequency: this.take_off_subscription_level['value']['training_file_upload_frequency'],
                takeoff_total_file_storage_space: this.take_off_subscription_level['value']['total_file_storage_space'],
                takeoff_user_upload: this.take_off_subscription_level['value']['user_upload'],
                takeoff_file_drop: this.take_off_subscription_level['value']['file_drop'],
                takeoff_api_integration: this.take_off_subscription_level['value']['api_integration_with_client'],
                takeoff_env_impact: this.take_off_subscription_level['value']['env_impact_calculation_no'],
                takeoff_export_to_pdf: this.take_off_subscription_level['value']['export_to_pdf'],
                takeoff_export_to_csv: this.take_off_subscription_level['value']['export_to_csv'],
                takeoff_email_phone_support: this.take_off_subscription_level['value']['email_phone_support'],
                takeoff_live_chat_support: this.take_off_subscription_level['value']['live_chat_support'],
                takeoff_company_logo: this.take_off_subscription_level['value']['customized_company_logo'],

            });

            this.subscritionLevelForm.controls.takeoff_TrialP_user_cnt.disable();
            this.subscritionLevelForm.controls.takeoff_dashboard_user_cnt.disable();
            this.subscritionLevelForm.controls. takeoff_configAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls. takeoff_superAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls. takeoff_cost_trialP_user.disable();
            this.subscritionLevelForm.controls. takeoff_cost_dashboard_user.disable();
            this.subscritionLevelForm.controls. takeoff_cost_configAdmin_user.disable();
            this.subscritionLevelForm.controls. takeoff_cost_superAdmin_user.disable();
            this.subscritionLevelForm.controls. takeoff_cost_additional_dashboard_user.disable();
            this.subscritionLevelForm.controls. takeoff_file_upload_limit_cnt.disable();
            this.subscritionLevelForm.controls. takeoff_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls. takeoff_file_upload_frequency.disable();
            this.subscritionLevelForm.controls. takeoff_training_file_upload_limit.disable();
            this.subscritionLevelForm.controls. takeoff_training_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls. takeoff_training_file_upload_frequency.disable();
            this.subscritionLevelForm.controls. takeoff_total_file_storage_space.disable();
            this.subscritionLevelForm.controls. takeoff_user_upload.disable();
            this.subscritionLevelForm.controls. takeoff_file_drop.disable();
            this.subscritionLevelForm.controls. takeoff_api_integration.disable();
            this.subscritionLevelForm.controls. takeoff_env_impact.disable();
            this.subscritionLevelForm.controls. takeoff_export_to_pdf.disable();
            this.subscritionLevelForm.controls. takeoff_export_to_csv.disable();
            this.subscritionLevelForm.controls. takeoff_email_phone_support.disable();
            this.subscritionLevelForm.controls. takeoff_live_chat_support.disable();
            this.subscritionLevelForm.controls. takeoff_company_logo.disable();
        }
     
    }

    setEnterpriseSubscriptionLevelValue(){
        if(this.enterprise_subscription_level){
            this.subscritionLevelForm.patchValue({
                enterprise_TrialP_user_cnt: this.enterprise_subscription_level['value']['trial_period_user_count'],
                enterprise_dashboard_user_cnt: this.enterprise_subscription_level['value']['dashboard_user_count'],
                enterprise_configAdmin_user_cnt: this.enterprise_subscription_level['value']['config_admin_user_count'],
                enterprise_superAdmin_user_cnt: this.enterprise_subscription_level['value']['super_admin_user_count'],
                enterprise_cost_trialP_user:this.enterprise_subscription_level['value']['trial_period_user_cost'],
                enterprise_cost_dashboard_user: this.enterprise_subscription_level['value']['dashboard_user_cost'],
                enterprise_cost_configAdmin_user: this.enterprise_subscription_level['value']['config_admin_user_cost'],
                enterprise_cost_superAdmin_user:this.enterprise_subscription_level['value']['super_admin_user_cost'],
                enterprise_cost_additional_dashboard_user:this.enterprise_subscription_level['value']['additional_dashboard_user_cost'],
                enterprise_file_upload_limit_cnt: this.enterprise_subscription_level['value']['prediction_file_count_limit'],
                enterprise_file_upload_limit_size: this.enterprise_subscription_level['value']['prediction_file_size_limit'],
                enterprise_file_upload_frequency: this.enterprise_subscription_level['value']['prediction_file_upload_frequency'],
                enterprise_training_file_upload_limit: this.enterprise_subscription_level['value']['training_file_count_limit'],
                enterprise_training_file_upload_limit_size: this.enterprise_subscription_level['value']['training_file_size_limit'],
                enterprise_training_file_upload_frequency: this.enterprise_subscription_level['value']['training_file_upload_frequency'],
                enterprise_total_file_storage_space: this.enterprise_subscription_level['value']['total_file_storage_space'],
                enterprise_user_upload: this.enterprise_subscription_level['value']['user_upload'],
                enterprise_file_drop: this.enterprise_subscription_level['value']['file_drop'],
                enterprise_api_integration: this.enterprise_subscription_level['value']['api_integration_with_client'],
                enterprise_env_impact: this.enterprise_subscription_level['value']['env_impact_calculation_no'],
                enterprise_export_to_pdf: this.enterprise_subscription_level['value']['export_to_pdf'],
                enterprise_export_to_csv: this.enterprise_subscription_level['value']['export_to_csv'],
                enterprise_email_phone_support: this.enterprise_subscription_level['value']['email_phone_support'],
                enterprise_live_chat_support: this.enterprise_subscription_level['value']['live_chat_support'],
                enterprise_company_logo: this.enterprise_subscription_level['value']['customized_company_logo'],
            });
            this.subscritionLevelForm.controls.enterprise_TrialP_user_cnt.disable();
            this.subscritionLevelForm.controls.enterprise_dashboard_user_cnt.disable();
            this.subscritionLevelForm.controls.enterprise_configAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls.enterprise_superAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls.enterprise_cost_trialP_user.disable();
            this.subscritionLevelForm.controls.enterprise_cost_dashboard_user.disable();
            this.subscritionLevelForm.controls.enterprise_cost_configAdmin_user.disable();
            this.subscritionLevelForm.controls.enterprise_cost_superAdmin_user.disable();
            this.subscritionLevelForm.controls.enterprise_cost_additional_dashboard_user.disable();
            this.subscritionLevelForm.controls.enterprise_file_upload_limit_cnt.disable();
            this.subscritionLevelForm.controls.enterprise_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls.enterprise_file_upload_frequency.disable();
            this.subscritionLevelForm.controls.enterprise_training_file_upload_limit.disable();
            this.subscritionLevelForm.controls.enterprise_training_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls.enterprise_training_file_upload_frequency.disable();
            this.subscritionLevelForm.controls.enterprise_total_file_storage_space.disable();
            this.subscritionLevelForm.controls.enterprise_user_upload.disable();
            this.subscritionLevelForm.controls.enterprise_file_drop.disable();
            this.subscritionLevelForm.controls.enterprise_api_integration.disable();
            this.subscritionLevelForm.controls.enterprise_env_impact.disable();
            this.subscritionLevelForm.controls.enterprise_export_to_pdf.disable();
            this.subscritionLevelForm.controls.enterprise_export_to_csv.disable();
            this.subscritionLevelForm.controls.enterprise_email_phone_support.disable();
            this.subscritionLevelForm.controls.enterprise_live_chat_support.disable();
            this.subscritionLevelForm.controls.enterprise_company_logo.disable();
        }

    }
    setStratizantSubscriptionLevelValue(){
        if(this.stratizant_subscription_level){
            this.subscritionLevelForm.patchValue({
                stratizant_TrialP_user_cnt: this.stratizant_subscription_level['value']['trial_period_user_count'],
                stratizant_dashboard_user_cnt: this.stratizant_subscription_level['value']['dashboard_user_count'],
                stratizant_configAdmin_user_cnt: this.stratizant_subscription_level['value']['config_admin_user_count'],
                stratizant_superAdmin_user_cnt: this.stratizant_subscription_level['value']['super_admin_user_count'],
                stratizant_cost_trialP_user:this.stratizant_subscription_level['value']['trial_period_user_cost'],
                stratizant_cost_dashboard_user: this.stratizant_subscription_level['value']['dashboard_user_cost'],
                stratizant_cost_configAdmin_user: this.stratizant_subscription_level['value']['config_admin_user_cost'],
                stratizant_cost_superAdmin_user:this.stratizant_subscription_level['value']['super_admin_user_cost'],
                stratizant_cost_additional_dashboard_user:this.stratizant_subscription_level['value']['additional_dashboard_user_cost'],
                stratizant_file_upload_limit_cnt: this.stratizant_subscription_level['value']['prediction_file_count_limit'],
                stratizant_file_upload_limit_size: this.stratizant_subscription_level['value']['prediction_file_size_limit'],
                stratizant_file_upload_frequency: this.stratizant_subscription_level['value']['prediction_file_upload_frequency'],
                stratizant_training_file_upload_limit: this.stratizant_subscription_level['value']['training_file_count_limit'],
                stratizant_training_file_upload_limit_size: this.stratizant_subscription_level['value']['training_file_size_limit'],
                stratizant_training_file_upload_frequency: this.stratizant_subscription_level['value']['training_file_upload_frequency'],
                stratizant_total_file_storage_space: this.stratizant_subscription_level['value']['total_file_storage_space'],
                stratizant_user_upload: this.stratizant_subscription_level['value']['user_upload'],
                stratizant_file_drop: this.stratizant_subscription_level['value']['file_drop'],
                stratizant_api_integration: this.stratizant_subscription_level['value']['api_integration_with_client'],
                stratizant_env_impact: this.stratizant_subscription_level['value']['env_impact_calculation_no'],
                stratizant_export_to_pdf: this.stratizant_subscription_level['value']['export_to_pdf'],
                stratizant_export_to_csv: this.stratizant_subscription_level['value']['export_to_csv'],
                stratizant_email_phone_support: this.stratizant_subscription_level['value']['email_phone_support'],
                stratizant_live_chat_support: this.stratizant_subscription_level['value']['live_chat_support'],
                stratizant_company_logo: this.stratizant_subscription_level['value']['customized_company_logo'],
            });
            this.subscritionLevelForm.controls.stratizant_TrialP_user_cnt.disable();
            this.subscritionLevelForm.controls.stratizant_dashboard_user_cnt.disable();
            this.subscritionLevelForm.controls.stratizant_configAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls.stratizant_superAdmin_user_cnt.disable();
            this.subscritionLevelForm.controls.stratizant_cost_trialP_user.disable();
            this.subscritionLevelForm.controls.stratizant_cost_dashboard_user.disable();
            this.subscritionLevelForm.controls.stratizant_cost_configAdmin_user.disable();
            this.subscritionLevelForm.controls.stratizant_cost_superAdmin_user.disable();
            this.subscritionLevelForm.controls.stratizant_cost_additional_dashboard_user.disable();
            this.subscritionLevelForm.controls.stratizant_file_upload_limit_cnt.disable();
            this.subscritionLevelForm.controls.stratizant_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls.stratizant_file_upload_frequency.disable();
            this.subscritionLevelForm.controls.stratizant_training_file_upload_limit.disable();
            this.subscritionLevelForm.controls.stratizant_training_file_upload_limit_size.disable();
            this.subscritionLevelForm.controls.stratizant_training_file_upload_frequency.disable();
            this.subscritionLevelForm.controls.stratizant_total_file_storage_space.disable();
            this.subscritionLevelForm.controls.stratizant_user_upload.disable();
            this.subscritionLevelForm.controls.stratizant_file_drop.disable();
            this.subscritionLevelForm.controls.stratizant_api_integration.disable();
            this.subscritionLevelForm.controls.stratizant_env_impact.disable();
            this.subscritionLevelForm.controls.stratizant_export_to_pdf.disable();
            this.subscritionLevelForm.controls.stratizant_export_to_csv.disable();
            this.subscritionLevelForm.controls.stratizant_email_phone_support.disable();
            this.subscritionLevelForm.controls.stratizant_live_chat_support.disable();
            this.subscritionLevelForm.controls.stratizant_company_logo.disable();
        }

    }


    ngOnChanges(changes: SimpleChanges) {

        if (changes.subscriptionManagementtabClicked.currentValue != null && changes.subscriptionManagementtabClicked.currentValue != undefined) {
            this.getSubscriptionLevelDetails();
        }
    }

    getSubscriptionLeveltoUpdate(){
        const dialogConfig = new MatDialogConfig();
       
        const dialogRef = this.matDialog.open(UpdateSubscriptionLevelUserIpComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value =>{
            this.selectedSubscriptionLevel = value;

            if(this.selectedSubscriptionLevel === 'cancel'){
                this.disableUpdate = false;
            }else{
                this.disableUpdate = true;
                this.allowSubscriptionToEdit();
            }
           
           
        });
    }


    allowSubscriptionToEdit(){
        if(this.selectedSubscriptionLevel == 'trial_period'){
            this.trial_period_cost_additional_dashboard_user = this.subscritionLevelForm.value.trial_period_cost_additional_dashboard_user;
            this.subscription_level_id = this.trial_Period_subscription_level['type'];
            this.subscritionLevelForm.controls.trial_period_TrialP_user_cnt.enable();
            this.subscritionLevelForm.controls.trial_period_dashboard_user_cnt.enable();
            this.subscritionLevelForm.controls. trial_period_configAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls. trial_period_superAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls. trial_period_cost_trialP_user.enable();
            this.subscritionLevelForm.controls. trial_period_cost_dashboard_user.enable();
            this.subscritionLevelForm.controls. trial_period_cost_configAdmin_user.enable();
            this.subscritionLevelForm.controls. trial_period_cost_superAdmin_user.enable();
            // this.subscritionLevelForm.controls. trial_period_cost_additional_dashboard_user.enable();
            this.subscritionLevelForm.controls. trial_period_file_upload_limit_cnt.enable();
            this.subscritionLevelForm.controls. trial_period_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls. trial_period_file_upload_frequency.enable();
            this.subscritionLevelForm.controls. trial_period_training_file_upload_limit.enable();
            this.subscritionLevelForm.controls. trial_period_training_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls. trial_period_training_file_upload_frequency.enable();
            this.subscritionLevelForm.controls. trial_period_total_file_storage_space.enable();
            this.subscritionLevelForm.controls. trial_period_user_upload.enable();
            this.subscritionLevelForm.controls. trial_period_file_drop.enable();
            this.subscritionLevelForm.controls. trial_period_api_integration.enable();
            this.subscritionLevelForm.controls. trial_period_env_impact.enable();
            this.subscritionLevelForm.controls. trial_period_export_to_pdf.enable();
            this.subscritionLevelForm.controls. trial_period_export_to_csv.enable();
            this.subscritionLevelForm.controls. trial_period_email_phone_support.enable();
            this.subscritionLevelForm.controls. trial_period_live_chat_support.enable();
            this.subscritionLevelForm.controls. trial_period_company_logo.enable();

            $('#trial_period_TrialP_user_cnt').addClass('activeTd');
            $('#trial_period_dashboard_user_cnt').addClass('activeTd');
            $('#trial_period_configAdmin_user_cnt').addClass('activeTd');
            $('#trial_period_superAdmin_user_cnt').addClass('activeTd');
            $('#trial_period_cost_trialP_user').addClass('activeTd');
            $('#trial_period_cost_dashboard_user').addClass('activeTd');
            $('#trial_period_cost_configAdmin_user').addClass('activeTd');
            $('#trial_period_cost_superAdmin_user').addClass('activeTd');
            $('#trial_period_cost_additional_dashboard_user').addClass('activeTd');
            $('#trial_period_file_upload_limit_cnt').addClass('activeTd');
            $('#trial_period_file_upload_limit_size').addClass('activeTd');
            $('#trial_period_file_upload_frequency').addClass('activeTd');
            $('#trial_period_training_file_upload_limit').addClass('activeTd');
            $('#trial_period_training_file_upload_limit_size').addClass('activeTd');
            $('#trial_period_training_file_upload_frequency').addClass('activeTd');
            $('#trial_period_total_file_storage_space').addClass('activeTd');
            $('#trial_period_user_upload').addClass('activeTd');
            $('#trial_period_file_drop').addClass('activeTd');
            $('#trial_period_api_integration').addClass('activeTd');
            $('#trial_period_env_impact').addClass('activeTd');
            $('#trial_period_export_to_pdf').addClass('activeTd');
            $('#trial_period_export_to_csv').addClass('activeTd');
            $('#trial_period_email_phone_support').addClass('activeTd');
            $('#trial_period_live_chat_support').addClass('activeTd');
            $('#trial_period_company_logo').addClass('activeTd');
            

        }else if(this.selectedSubscriptionLevel == 'initiator'){
            this.initiator_cost_additional_dashboard_user = this.subscritionLevelForm.value.initiator_cost_additional_dashboard_user;

            this.subscription_level_id = this.initiator_subscription_level['type'];
            this.subscritionLevelForm.controls.initiator_TrialP_user_cnt.enable();
            this.subscritionLevelForm.controls.initiator_dashboard_user_cnt.enable();
            this.subscritionLevelForm.controls.initiator_configAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.initiator_superAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.initiator_cost_trialP_user.enable();
            this.subscritionLevelForm.controls.initiator_cost_dashboard_user.enable();
            this.subscritionLevelForm.controls.initiator_cost_configAdmin_user.enable();
            this.subscritionLevelForm.controls.initiator_cost_superAdmin_user.enable();
            // this.subscritionLevelForm.controls.initiator_cost_additional_dashboard_user.enable();
            this.subscritionLevelForm.controls.initiator_file_upload_limit_cnt.enable();
            this.subscritionLevelForm.controls.initiator_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.initiator_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.initiator_training_file_upload_limit.enable();
            this.subscritionLevelForm.controls.initiator_training_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.initiator_training_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.initiator_total_file_storage_space.enable();
            this.subscritionLevelForm.controls.initiator_user_upload.enable();
            this.subscritionLevelForm.controls.initiator_file_drop.enable();
            this.subscritionLevelForm.controls.initiator_api_integration.enable();
            this.subscritionLevelForm.controls.initiator_env_impact.enable();
            this.subscritionLevelForm.controls.initiator_export_to_pdf.enable();
            this.subscritionLevelForm.controls.initiator_export_to_csv.enable();
            this.subscritionLevelForm.controls.initiator_email_phone_support.enable();
            this.subscritionLevelForm.controls.initiator_live_chat_support.enable();
            this.subscritionLevelForm.controls.initiator_company_logo.enable();


            $('#initiator_TrialP_user_cnt').addClass('activeTd');
            $('#initiator_dashboard_user_cnt').addClass('activeTd');
            $('#initiator_configAdmin_user_cnt').addClass('activeTd');
            $('#initiator_superAdmin_user_cnt').addClass('activeTd');
            $('#initiator_cost_trialP_user').addClass('activeTd');
            $('#initiator_cost_dashboard_user').addClass('activeTd');
            $('#initiator_cost_configAdmin_user').addClass('activeTd');
            $('#initiator_cost_superAdmin_user').addClass('activeTd');
            $('#initiator_cost_additional_dashboard_user').addClass('activeTd');
            $('#initiator_file_upload_limit_cnt').addClass('activeTd');
            $('#initiator_file_upload_limit_size').addClass('activeTd');
            $('#initiator_file_upload_frequency').addClass('activeTd');
            $('#initiator_training_file_upload_limit').addClass('activeTd');
            $('#initiator_training_file_upload_limit_size').addClass('activeTd');
            $('#initiator_training_file_upload_frequency').addClass('activeTd');
            $('#initiator_total_file_storage_space').addClass('activeTd');
            $('#initiator_user_upload').addClass('activeTd');
            $('#initiator_file_drop').addClass('activeTd');
            $('#initiator_api_integration').addClass('activeTd');
            $('#initiator_env_impact').addClass('activeTd');
            $('#initiator_export_to_pdf').addClass('activeTd');
            $('#initiator_export_to_csv').addClass('activeTd');
            $('#initiator_email_phone_support').addClass('activeTd');
            $('#initiator_live_chat_support').addClass('activeTd');
            $('#initiator_company_logo').addClass('activeTd');

        }else if(this.selectedSubscriptionLevel == 'take_off'){
            this.takeoff_cost_additional_dashboard_user = this.subscritionLevelForm.value.takeoff_cost_additional_dashboard_user;
            this.subscription_level_id = this.take_off_subscription_level['type'];
            this.subscritionLevelForm.controls.takeoff_TrialP_user_cnt.enable();
            this.subscritionLevelForm.controls.takeoff_dashboard_user_cnt.enable();
            this.subscritionLevelForm.controls.takeoff_configAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.takeoff_superAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.takeoff_cost_trialP_user.enable();
            this.subscritionLevelForm.controls.takeoff_cost_dashboard_user.enable();
            this.subscritionLevelForm.controls.takeoff_cost_configAdmin_user.enable();
            this.subscritionLevelForm.controls.takeoff_cost_superAdmin_user.enable();
            // this.subscritionLevelForm.controls.takeoff_cost_additional_dashboard_user.enable();
            this.subscritionLevelForm.controls.takeoff_file_upload_limit_cnt.enable();
            this.subscritionLevelForm.controls.takeoff_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.takeoff_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.takeoff_training_file_upload_limit.enable();
            this.subscritionLevelForm.controls.takeoff_training_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.takeoff_training_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.takeoff_total_file_storage_space.enable();
            this.subscritionLevelForm.controls.takeoff_user_upload.enable();
            this.subscritionLevelForm.controls.takeoff_file_drop.enable();
            this.subscritionLevelForm.controls.takeoff_api_integration.enable();
            this.subscritionLevelForm.controls.takeoff_env_impact.enable();
            this.subscritionLevelForm.controls.takeoff_export_to_pdf.enable();
            this.subscritionLevelForm.controls.takeoff_export_to_csv.enable();
            this.subscritionLevelForm.controls.takeoff_email_phone_support.enable();
            this.subscritionLevelForm.controls.takeoff_live_chat_support.enable();
            this.subscritionLevelForm.controls.takeoff_company_logo.enable();

            $('#takeoff_TrialP_user_cnt').addClass('activeTd');
            $('#takeoff_dashboard_user_cnt').addClass('activeTd');
            $('#takeoff_configAdmin_user_cnt').addClass('activeTd');
            $('#takeoff_superAdmin_user_cnt').addClass('activeTd');
            $('#takeoff_cost_trialP_user').addClass('activeTd');
            $('#takeoff_cost_dashboard_user').addClass('activeTd');
            $('#takeoff_cost_configAdmin_user').addClass('activeTd');
            $('#takeoff_cost_superAdmin_user').addClass('activeTd');
            $('#takeoff_cost_additional_dashboard_user').addClass('activeTd');
            $('#takeoff_file_upload_limit_cnt').addClass('activeTd');
            $('#takeoff_file_upload_limit_size').addClass('activeTd');
            $('#takeoff_file_upload_frequency').addClass('activeTd');
            $('#takeoff_training_file_upload_limit').addClass('activeTd');
            $('#takeoff_training_file_upload_limit_size').addClass('activeTd');
            $('#takeoff_training_file_upload_frequency').addClass('activeTd');
            $('#takeoff_total_file_storage_space').addClass('activeTd');
            $('#takeoff_user_upload').addClass('activeTd');
            $('#takeoff_file_drop').addClass('activeTd');
            $('#takeoff_api_integration').addClass('activeTd');
            $('#takeoff_env_impact').addClass('activeTd');
            $('#takeoff_export_to_pdf').addClass('activeTd');
            $('#takeoff_export_to_csv').addClass('activeTd');
            $('#takeoff_email_phone_support').addClass('activeTd');
            $('#takeoff_live_chat_support').addClass('activeTd');
            $('#takeoff_company_logo').addClass('activeTd');


        }else if(this.selectedSubscriptionLevel == 'enterprise'){
            this.subscription_level_id = this.enterprise_subscription_level['type'];
            this.subscritionLevelForm.controls.enterprise_TrialP_user_cnt.enable();
            this.subscritionLevelForm.controls.enterprise_dashboard_user_cnt.enable();
            this.subscritionLevelForm.controls.enterprise_configAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.enterprise_superAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.enterprise_cost_trialP_user.enable();
            this.subscritionLevelForm.controls.enterprise_cost_dashboard_user.enable();
            this.subscritionLevelForm.controls.enterprise_cost_configAdmin_user.enable();
            this.subscritionLevelForm.controls.enterprise_cost_superAdmin_user.enable();
            this.subscritionLevelForm.controls.enterprise_cost_additional_dashboard_user.enable();
            this.subscritionLevelForm.controls.enterprise_file_upload_limit_cnt.enable();
            this.subscritionLevelForm.controls.enterprise_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.enterprise_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.enterprise_training_file_upload_limit.enable();
            this.subscritionLevelForm.controls.enterprise_training_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.enterprise_training_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.enterprise_total_file_storage_space.enable();
            this.subscritionLevelForm.controls.enterprise_user_upload.enable();
            this.subscritionLevelForm.controls.enterprise_file_drop.enable();
            this.subscritionLevelForm.controls.enterprise_api_integration.enable();
            this.subscritionLevelForm.controls.enterprise_env_impact.enable();
            this.subscritionLevelForm.controls.enterprise_export_to_pdf.enable();
            this.subscritionLevelForm.controls.enterprise_export_to_csv.enable();
            this.subscritionLevelForm.controls.enterprise_email_phone_support.enable();
            this.subscritionLevelForm.controls.enterprise_live_chat_support.enable();
            this.subscritionLevelForm.controls.enterprise_company_logo.enable();

            $('#enterprise_TrialP_user_cnt').addClass('activeTd');
            $('#enterprise_dashboard_user_cnt').addClass('activeTd');
            $('#enterprise_configAdmin_user_cnt').addClass('activeTd');
            $('#enterprise_superAdmin_user_cnt').addClass('activeTd');
            $('#enterprise_cost_trialP_user').addClass('activeTd');
            $('#enterprise_cost_dashboard_user').addClass('activeTd');
            $('#enterprise_cost_configAdmin_user').addClass('activeTd');
            $('#enterprise_cost_superAdmin_user').addClass('activeTd');
            $('#enterprise_cost_additional_dashboard_user').addClass('activeTd');
            $('#enterprise_file_upload_limit_cnt').addClass('activeTd');
            $('#enterprise_file_upload_limit_size').addClass('activeTd');
            $('#enterprise_file_upload_frequency').addClass('activeTd');
            $('#enterprise_training_file_upload_limit').addClass('activeTd');
            $('#enterprise_training_file_upload_limit_size').addClass('activeTd');
            $('#enterprise_training_file_upload_frequency').addClass('activeTd');
            $('#enterprise_total_file_storage_space').addClass('activeTd');
            $('#enterprise_user_upload').addClass('activeTd');
            $('#enterprise_file_drop').addClass('activeTd');
            $('#enterprise_api_integration').addClass('activeTd');
            $('#enterprise_env_impact').addClass('activeTd');
            $('#enterprise_export_to_pdf').addClass('activeTd');
            $('#enterprise_export_to_csv').addClass('activeTd');
            $('#enterprise_email_phone_support').addClass('activeTd');
            $('#enterprise_live_chat_support').addClass('activeTd');
            $('#enterprise_company_logo').addClass('activeTd');


        }else if(this.selectedSubscriptionLevel == 'stratizant'){
            this.stratizant_cost_additional_dashboard_user = this.subscritionLevelForm.value.stratizant_cost_additional_dashboard_user;
            this.subscription_level_id = this.stratizant_subscription_level['type'];
            this.subscritionLevelForm.controls.stratizant_TrialP_user_cnt.enable();
            this.subscritionLevelForm.controls.stratizant_dashboard_user_cnt.enable();
            this.subscritionLevelForm.controls.stratizant_configAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.stratizant_superAdmin_user_cnt.enable();
            this.subscritionLevelForm.controls.stratizant_cost_trialP_user.enable();
            this.subscritionLevelForm.controls.stratizant_cost_dashboard_user.enable();
            this.subscritionLevelForm.controls.stratizant_cost_configAdmin_user.enable();
            this.subscritionLevelForm.controls.stratizant_cost_superAdmin_user.enable();
            // this.subscritionLevelForm.controls.stratizant_cost_additional_dashboard_user.enable();
            this.subscritionLevelForm.controls.stratizant_file_upload_limit_cnt.enable();
            this.subscritionLevelForm.controls.stratizant_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.stratizant_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.stratizant_training_file_upload_limit.enable();
            this.subscritionLevelForm.controls.stratizant_training_file_upload_limit_size.enable();
            this.subscritionLevelForm.controls.stratizant_training_file_upload_frequency.enable();
            this.subscritionLevelForm.controls.stratizant_total_file_storage_space.enable();
            this.subscritionLevelForm.controls.stratizant_user_upload.enable();
            this.subscritionLevelForm.controls.stratizant_file_drop.enable();
            this.subscritionLevelForm.controls.stratizant_api_integration.enable();
            this.subscritionLevelForm.controls.stratizant_env_impact.enable();
            this.subscritionLevelForm.controls.stratizant_export_to_pdf.enable();
            this.subscritionLevelForm.controls.stratizant_export_to_csv.enable();
            this.subscritionLevelForm.controls.stratizant_email_phone_support.enable();
            this.subscritionLevelForm.controls.stratizant_live_chat_support.enable();
            this.subscritionLevelForm.controls.stratizant_company_logo.enable();

            $('#stratizant_TrialP_user_cnt').addClass('activeTd');
            $('#stratizant_dashboard_user_cnt').addClass('activeTd');
            $('#stratizant_configAdmin_user_cnt').addClass('activeTd');
            $('#stratizant_superAdmin_user_cnt').addClass('activeTd');
            $('#stratizant_cost_trialP_user').addClass('activeTd');
            $('#stratizant_cost_dashboard_user').addClass('activeTd');
            $('#stratizant_cost_configAdmin_user').addClass('activeTd');
            $('#stratizant_cost_superAdmin_user').addClass('activeTd');
            $('#stratizant_cost_additional_dashboard_user').addClass('activeTd');
            $('#stratizant_file_upload_limit_cnt').addClass('activeTd');
            $('#stratizant_file_upload_limit_size').addClass('activeTd');
            $('#stratizant_file_upload_frequency').addClass('activeTd');
            $('#stratizant_training_file_upload_limit').addClass('activeTd');
            $('#stratizant_training_file_upload_limit_size').addClass('activeTd');
            $('#stratizant_training_file_upload_frequency').addClass('activeTd');
            $('#stratizant_total_file_storage_space').addClass('activeTd');
            $('#stratizant_user_upload').addClass('activeTd');
            $('#stratizant_file_drop').addClass('activeTd');
            $('#stratizant_api_integration').addClass('activeTd');
            $('#stratizant_env_impact').addClass('activeTd');
            $('#stratizant_export_to_pdf').addClass('activeTd');
            $('#stratizant_export_to_csv').addClass('activeTd');
            $('#stratizant_email_phone_support').addClass('activeTd');
            $('#stratizant_live_chat_support').addClass('activeTd');
            $('#stratizant_company_logo').addClass('activeTd');
        }
    }


    saveSubscriptionLevelDetails() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }

        const formData = new FormData();
        if(this.selectedSubscriptionLevel == 'trial_period'){
            formData.append('subscription_level_id', this.subscription_level_id);
            formData.append('trial_period_user_count', this.subscritionLevelForm.value.trial_period_TrialP_user_cnt);
            formData.append('dashboard_user_count', this.subscritionLevelForm.value.trial_period_dashboard_user_cnt);
            formData.append('config_admin_user_count', this.subscritionLevelForm.value.trial_period_configAdmin_user_cnt);
            formData.append('super_admin_user_count', this.subscritionLevelForm.value.trial_period_superAdmin_user_cnt);
            formData.append('trial_period_user_cost', this.subscritionLevelForm.value.trial_period_cost_trialP_user);
            formData.append('dashboard_user_cost', this.subscritionLevelForm.value.trial_period_cost_dashboard_user);
            formData.append('config_admin_user_cost', this.subscritionLevelForm.value.trial_period_cost_configAdmin_user);
            formData.append('super_admin_user_cost', this.subscritionLevelForm.value.trial_period_cost_superAdmin_user);

            formData.append('additional_dashboard_user_cost', this.trial_period_cost_additional_dashboard_user);
            formData.append('prediction_file_count_limit',this.subscritionLevelForm.value.trial_period_file_upload_limit_cnt);
            formData.append('prediction_file_size_limit', this.subscritionLevelForm.value.trial_period_file_upload_limit_size);
            formData.append('prediction_file_upload_frequency', this.subscritionLevelForm.value.trial_period_file_upload_frequency);
            formData.append('training_file_count_limit', this.subscritionLevelForm.value.trial_period_training_file_upload_limit);
            formData.append('training_file_size_limit',this.subscritionLevelForm.value.trial_period_training_file_upload_limit_size);
            formData.append('training_file_upload_frequency', this.subscritionLevelForm.value.trial_period_training_file_upload_frequency);
            formData.append('total_file_storage_space',this.subscritionLevelForm.value.trial_period_total_file_storage_space);
            formData.append('user_upload', this.subscritionLevelForm.value.trial_period_user_upload);
            formData.append('file_drop', this.subscritionLevelForm.value.trial_period_file_drop);
            formData.append('api_integration_with_client', this.subscritionLevelForm.value.trial_period_api_integration);
            formData.append('env_impact_calculation_no',this.subscritionLevelForm.value.trial_period_env_impact);
            formData.append('export_to_pdf', this.subscritionLevelForm.value.trial_period_export_to_pdf);
            formData.append('export_to_csv',this.subscritionLevelForm.value.trial_period_export_to_csv);
            formData.append('email_phone_support', this.subscritionLevelForm.value.trial_period_email_phone_support);
            formData.append('live_chat_support', this.subscritionLevelForm.value.trial_period_live_chat_support);
            formData.append('customized_company_logo', this.subscritionLevelForm.value.trial_period_company_logo);
        }else if(this.selectedSubscriptionLevel == 'initiator'){
            formData.append('subscription_level_id', this.subscription_level_id);
            formData.append('trial_period_user_count', this.subscritionLevelForm.value.initiator_TrialP_user_cnt);
            formData.append('dashboard_user_count', this.subscritionLevelForm.value.initiator_dashboard_user_cnt);
            formData.append('config_admin_user_count', this.subscritionLevelForm.value.initiator_configAdmin_user_cnt);
            formData.append('super_admin_user_count', this.subscritionLevelForm.value.initiator_superAdmin_user_cnt);
            formData.append('trial_period_user_cost', this.subscritionLevelForm.value.initiator_cost_trialP_user);
            formData.append('dashboard_user_cost', this.subscritionLevelForm.value.initiator_cost_dashboard_user);
            formData.append('config_admin_user_cost', this.subscritionLevelForm.value.initiator_cost_configAdmin_user);
            formData.append('super_admin_user_cost', this.subscritionLevelForm.value.initiator_cost_superAdmin_user);
            formData.append('additional_dashboard_user_cost', this.initiator_cost_additional_dashboard_user);

            formData.append('prediction_file_count_limit',this.subscritionLevelForm.value.initiator_file_upload_limit_cnt);
            formData.append('prediction_file_size_limit', this.subscritionLevelForm.value.initiator_file_upload_limit_size);
            formData.append('prediction_file_upload_frequency', this.subscritionLevelForm.value.initiator_file_upload_frequency);
            formData.append('training_file_count_limit', this.subscritionLevelForm.value.initiator_training_file_upload_limit);
            formData.append('training_file_size_limit',this.subscritionLevelForm.value.initiator_training_file_upload_limit_size);
            formData.append('training_file_upload_frequency', this.subscritionLevelForm.value.initiator_training_file_upload_frequency);
            formData.append('total_file_storage_space',this.subscritionLevelForm.value.initiator_total_file_storage_space);
            formData.append('user_upload', this.subscritionLevelForm.value.initiator_user_upload);
            formData.append('file_drop', this.subscritionLevelForm.value.initiator_file_drop);
            formData.append('api_integration_with_client', this.subscritionLevelForm.value.initiator_api_integration);
            formData.append('env_impact_calculation_no',this.subscritionLevelForm.value.initiator_env_impact);
            formData.append('export_to_pdf', this.subscritionLevelForm.value.initiator_export_to_pdf);
            formData.append('export_to_csv',this.subscritionLevelForm.value.initiator_export_to_csv);
            formData.append('email_phone_support', this.subscritionLevelForm.value.initiator_email_phone_support);
            formData.append('live_chat_support', this.subscritionLevelForm.value.initiator_live_chat_support);
            formData.append('customized_company_logo', this.subscritionLevelForm.value.initiator_company_logo);

        }else if(this.selectedSubscriptionLevel == 'take_off'){

            formData.append('subscription_level_id', this.subscription_level_id);
            formData.append('trial_period_user_count', this.subscritionLevelForm.value.takeoff_TrialP_user_cnt);
            formData.append('dashboard_user_count', this.subscritionLevelForm.value.takeoff_dashboard_user_cnt);
            formData.append('config_admin_user_count', this.subscritionLevelForm.value.takeoff_configAdmin_user_cnt);
            formData.append('super_admin_user_count', this.subscritionLevelForm.value.takeoff_superAdmin_user_cnt);
            formData.append('trial_period_user_cost', this.subscritionLevelForm.value.takeoff_cost_trialP_user);
            formData.append('dashboard_user_cost', this.subscritionLevelForm.value.takeoff_cost_dashboard_user);
            formData.append('config_admin_user_cost', this.subscritionLevelForm.value.takeoff_cost_configAdmin_user);
            formData.append('super_admin_user_cost', this.subscritionLevelForm.value.takeoff_cost_superAdmin_user);

            formData.append('additional_dashboard_user_cost', this.takeoff_cost_additional_dashboard_user);

            formData.append('prediction_file_count_limit',this.subscritionLevelForm.value.takeoff_file_upload_limit_cnt);
            formData.append('prediction_file_size_limit', this.subscritionLevelForm.value.takeoff_file_upload_limit_size);
            formData.append('prediction_file_upload_frequency', this.subscritionLevelForm.value.takeoff_file_upload_frequency);
            formData.append('training_file_count_limit', this.subscritionLevelForm.value.takeoff_training_file_upload_limit);
            formData.append('training_file_size_limit',this.subscritionLevelForm.value.takeoff_training_file_upload_limit_size);
            formData.append('training_file_upload_frequency', this.subscritionLevelForm.value.takeoff_training_file_upload_frequency);
            formData.append('total_file_storage_space',this.subscritionLevelForm.value.takeoff_total_file_storage_space);
            formData.append('user_upload', this.subscritionLevelForm.value.takeoff_user_upload);
            formData.append('file_drop', this.subscritionLevelForm.value.takeoff_file_drop);
            formData.append('api_integration_with_client', this.subscritionLevelForm.value.takeoff_api_integration);
            formData.append('env_impact_calculation_no',this.subscritionLevelForm.value.takeoff_env_impact);
            formData.append('export_to_pdf', this.subscritionLevelForm.value.takeoff_export_to_pdf);
            formData.append('export_to_csv',this.subscritionLevelForm.value.takeoff_export_to_csv);
            formData.append('email_phone_support', this.subscritionLevelForm.value.takeoff_email_phone_support);
            formData.append('live_chat_support', this.subscritionLevelForm.value.takeoff_live_chat_support);
            formData.append('customized_company_logo', this.subscritionLevelForm.value.takeoff_company_logo);

          
        }else if(this.selectedSubscriptionLevel == 'enterprise'){

            formData.append('subscription_level_id', this.subscription_level_id);
            formData.append('trial_period_user_count', this.subscritionLevelForm.value.enterprise_TrialP_user_cnt);
            formData.append('dashboard_user_count', this.subscritionLevelForm.value.enterprise_dashboard_user_cnt);
            formData.append('config_admin_user_count', this.subscritionLevelForm.value.enterprise_configAdmin_user_cnt);
            formData.append('super_admin_user_count', this.subscritionLevelForm.value.enterprise_superAdmin_user_cnt);
            formData.append('trial_period_user_cost', this.subscritionLevelForm.value.enterprise_cost_trialP_user);
            formData.append('dashboard_user_cost', this.subscritionLevelForm.value.enterprise_cost_dashboard_user);
            formData.append('config_admin_user_cost', this.subscritionLevelForm.value.enterprise_cost_configAdmin_user);
            formData.append('super_admin_user_cost', this.subscritionLevelForm.value.enterprise_cost_superAdmin_user);

            formData.append('additional_dashboard_user_cost', this.subscritionLevelForm.value.enterprise_cost_additional_dashboard_user);

            formData.append('prediction_file_count_limit',this.subscritionLevelForm.value.enterprise_file_upload_limit_cnt);
            formData.append('prediction_file_size_limit', this.subscritionLevelForm.value.enterprise_file_upload_limit_size);
            formData.append('prediction_file_upload_frequency', this.subscritionLevelForm.value.enterprise_file_upload_frequency);
            formData.append('training_file_count_limit', this.subscritionLevelForm.value.enterprise_training_file_upload_limit);
            formData.append('training_file_size_limit',this.subscritionLevelForm.value.enterprise_training_file_upload_limit_size);
            formData.append('training_file_upload_frequency', this.subscritionLevelForm.value.enterprise_training_file_upload_frequency);
            formData.append('total_file_storage_space',this.subscritionLevelForm.value.enterprise_total_file_storage_space);
            formData.append('user_upload', this.subscritionLevelForm.value.enterprise_user_upload);
            formData.append('file_drop', this.subscritionLevelForm.value.enterprise_file_drop);
            formData.append('api_integration_with_client', this.subscritionLevelForm.value.enterprise_api_integration);
            formData.append('env_impact_calculation_no',this.subscritionLevelForm.value.enterprise_env_impact);
            formData.append('export_to_pdf', this.subscritionLevelForm.value.enterprise_export_to_pdf);
            formData.append('export_to_csv',this.subscritionLevelForm.value.enterprise_export_to_csv);
            formData.append('email_phone_support', this.subscritionLevelForm.value.enterprise_email_phone_support);
            formData.append('live_chat_support', this.subscritionLevelForm.value.enterprise_live_chat_support);
            formData.append('customized_company_logo', this.subscritionLevelForm.value.enterprise_company_logo);

        }else if(this.selectedSubscriptionLevel == 'stratizant'){

            formData.append('subscription_level_id', this.subscription_level_id);
            formData.append('trial_period_user_count', this.subscritionLevelForm.value.stratizant_TrialP_user_cnt);
            formData.append('dashboard_user_count', this.subscritionLevelForm.value.stratizant_dashboard_user_cnt);
            formData.append('config_admin_user_count', this.subscritionLevelForm.value.stratizant_configAdmin_user_cnt);
            formData.append('super_admin_user_count', this.subscritionLevelForm.value.stratizant_superAdmin_user_cnt);
            formData.append('trial_period_user_cost', this.subscritionLevelForm.value.stratizant_cost_trialP_user);
            formData.append('dashboard_user_cost', this.subscritionLevelForm.value.stratizant_cost_dashboard_user);
            formData.append('config_admin_user_cost', this.subscritionLevelForm.value.stratizant_cost_configAdmin_user);
            formData.append('super_admin_user_cost', this.subscritionLevelForm.value.stratizant_cost_superAdmin_user);
            formData.append('additional_dashboard_user_cost', this.stratizant_cost_additional_dashboard_user);
            formData.append('prediction_file_count_limit',this.subscritionLevelForm.value.stratizant_file_upload_limit_cnt);
            formData.append('prediction_file_size_limit', this.subscritionLevelForm.value.stratizant_file_upload_limit_size);
            formData.append('prediction_file_upload_frequency', this.subscritionLevelForm.value.stratizant_file_upload_frequency);
            formData.append('training_file_count_limit', this.subscritionLevelForm.value.stratizant_training_file_upload_limit);
            formData.append('training_file_size_limit',this.subscritionLevelForm.value.stratizant_training_file_upload_limit_size);
            formData.append('training_file_upload_frequency', this.subscritionLevelForm.value.stratizant_training_file_upload_frequency);
            formData.append('total_file_storage_space',this.subscritionLevelForm.value.stratizant_total_file_storage_space);
            formData.append('user_upload', this.subscritionLevelForm.value.stratizant_user_upload);
            formData.append('file_drop', this.subscritionLevelForm.value.stratizant_file_drop);
            formData.append('api_integration_with_client', this.subscritionLevelForm.value.stratizant_api_integration);
            formData.append('env_impact_calculation_no',this.subscritionLevelForm.value.stratizant_env_impact);
            formData.append('export_to_pdf', this.subscritionLevelForm.value.stratizant_export_to_pdf);
            formData.append('export_to_csv',this.subscritionLevelForm.value.stratizant_export_to_csv);
            formData.append('email_phone_support', this.subscritionLevelForm.value.stratizant_email_phone_support);
            formData.append('live_chat_support', this.subscritionLevelForm.value.stratizant_live_chat_support);
            formData.append('customized_company_logo', this.subscritionLevelForm.value.stratizant_company_logo);

        }
       

        this.subscriptionLevelService.saveSubscriptionLevelDetails(obj, formData).subscribe(res => {
            this.loader= false
            const dialogConfig = new MatDialogConfig();
            const obj = {
                error: false,
                message: res,
                selectedSubscriptionLevel: this.selectedSubscriptionLevel

            }
            dialogConfig.data = obj;
            const dialogRef = this.matDialog.open(SubscriptionSuccessDialogComponent, dialogConfig);
            this.getSubscriptionLevelDetails();
            this.disableUpdate = false;
            this.removeActiveClass()
    
            dialogRef.afterClosed().subscribe(value =>{
               
            });

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            const dialogConfig = new MatDialogConfig();
            const obj = {
                error: true,
                message: error.error,
                selectedSubscriptionLevel: this.selectedSubscriptionLevel

            }
            dialogConfig.data = obj;
            const dialogRef = this.matDialog.open(SubscriptionSuccessDialogComponent, dialogConfig);
    
            dialogRef.afterClosed().subscribe(value =>{
               
            });
        });
    }

   
    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }
    get f() { return this.subscritionLevelForm.controls; }

    cancelSubscriptionUpdate(){
        this.disableUpdate = false;
        this.getSubscriptionLevelDetails();
        this.removeActiveClass();
    }


    removeActiveClass(){
        $('#trial_period_TrialP_user_cnt').removeClass('activeTd');
        $('#trial_period_dashboard_user_cnt').removeClass('activeTd');
        $('#trial_period_configAdmin_user_cnt').removeClass('activeTd');
        $('#trial_period_superAdmin_user_cnt').removeClass('activeTd');
        $('#trial_period_cost_trialP_user').removeClass('activeTd');
        $('#trial_period_cost_dashboard_user').removeClass('activeTd');
        $('#trial_period_cost_configAdmin_user').removeClass('activeTd');
        $('#trial_period_cost_superAdmin_user').removeClass('activeTd');
        $('#trial_period_cost_additional_dashboard_user').removeClass('activeTd');
        $('#trial_period_file_upload_limit_cnt').removeClass('activeTd');
        $('#trial_period_file_upload_limit_size').removeClass('activeTd');
        $('#trial_period_file_upload_frequency').removeClass('activeTd');
        $('#trial_period_training_file_upload_limit').removeClass('activeTd');
        $('#trial_period_training_file_upload_limit_size').removeClass('activeTd');
        $('#trial_period_training_file_upload_frequency').removeClass('activeTd');
        $('#trial_period_total_file_storage_space').removeClass('activeTd');
        $('#trial_period_user_upload').removeClass('activeTd');
        $('#trial_period_file_drop').removeClass('activeTd');
        $('#trial_period_api_integration').removeClass('activeTd');
        $('#trial_period_env_impact').removeClass('activeTd');
        $('#trial_period_export_to_pdf').removeClass('activeTd');
        $('#trial_period_export_to_csv').removeClass('activeTd');
        $('#trial_period_email_phone_support').removeClass('activeTd');
        $('#trial_period_live_chat_support').removeClass('activeTd');
        $('#trial_period_company_logo').removeClass('activeTd');



        $('#initiator_TrialP_user_cnt').removeClass('activeTd');
        $('#initiator_dashboard_user_cnt').removeClass('activeTd');
        $('#initiator_configAdmin_user_cnt').removeClass('activeTd');
        $('#initiator_superAdmin_user_cnt').removeClass('activeTd');
        $('#initiator_cost_trialP_user').removeClass('activeTd');
        $('#initiator_cost_dashboard_user').removeClass('activeTd');
        $('#initiator_cost_configAdmin_user').removeClass('activeTd');
        $('#initiator_cost_superAdmin_user').removeClass('activeTd');
        $('#initiator_cost_additional_dashboard_user').removeClass('activeTd');
        $('#initiator_file_upload_limit_cnt').removeClass('activeTd');
        $('#initiator_file_upload_limit_size').removeClass('activeTd');
        $('#initiator_file_upload_frequency').removeClass('activeTd');
        $('#initiator_training_file_upload_limit').removeClass('activeTd');
        $('#initiator_training_file_upload_limit_size').removeClass('activeTd');
        $('#initiator_training_file_upload_frequency').removeClass('activeTd');
        $('#initiator_total_file_storage_space').removeClass('activeTd');
        $('#initiator_user_upload').removeClass('activeTd');
        $('#initiator_file_drop').removeClass('activeTd');
        $('#initiator_api_integration').removeClass('activeTd');
        $('#initiator_env_impact').removeClass('activeTd');
        $('#initiator_export_to_pdf').removeClass('activeTd');
        $('#initiator_export_to_csv').removeClass('activeTd');
        $('#initiator_email_phone_support').removeClass('activeTd');
        $('#initiator_live_chat_support').removeClass('activeTd');
        $('#initiator_company_logo').removeClass('activeTd');


        $('#takeoff_TrialP_user_cnt').removeClass('activeTd');
        $('#takeoff_dashboard_user_cnt').removeClass('activeTd');
        $('#takeoff_configAdmin_user_cnt').removeClass('activeTd');
        $('#takeoff_superAdmin_user_cnt').removeClass('activeTd');
        $('#takeoff_cost_trialP_user').removeClass('activeTd');
        $('#takeoff_cost_dashboard_user').removeClass('activeTd');
        $('#takeoff_cost_configAdmin_user').removeClass('activeTd');
        $('#takeoff_cost_superAdmin_user').removeClass('activeTd');
        $('#takeoff_cost_additional_dashboard_user').removeClass('activeTd');
        $('#takeoff_file_upload_limit_cnt').removeClass('activeTd');
        $('#takeoff_file_upload_limit_size').removeClass('activeTd');
        $('#takeoff_file_upload_frequency').removeClass('activeTd');
        $('#takeoff_training_file_upload_limit').removeClass('activeTd');
        $('#takeoff_training_file_upload_limit_size').removeClass('activeTd');
        $('#takeoff_training_file_upload_frequency').removeClass('activeTd');
        $('#takeoff_total_file_storage_space').removeClass('activeTd');
        $('#takeoff_user_upload').removeClass('activeTd');
        $('#takeoff_file_drop').removeClass('activeTd');
        $('#takeoff_api_integration').removeClass('activeTd');
        $('#takeoff_env_impact').removeClass('activeTd');
        $('#takeoff_export_to_pdf').removeClass('activeTd');
        $('#takeoff_export_to_csv').removeClass('activeTd');
        $('#takeoff_email_phone_support').removeClass('activeTd');
        $('#takeoff_live_chat_support').removeClass('activeTd');
        $('#takeoff_company_logo').removeClass('activeTd');

        $('#enterprise_TrialP_user_cnt').removeClass('activeTd');
        $('#enterprise_dashboard_user_cnt').removeClass('activeTd');
        $('#enterprise_configAdmin_user_cnt').removeClass('activeTd');
        $('#enterprise_superAdmin_user_cnt').removeClass('activeTd');
        $('#enterprise_cost_trialP_user').removeClass('activeTd');
        $('#enterprise_cost_dashboard_user').removeClass('activeTd');
        $('#enterprise_cost_configAdmin_user').removeClass('activeTd');
        $('#enterprise_cost_superAdmin_user').removeClass('activeTd');
        $('#enterprise_cost_additional_dashboard_user').removeClass('activeTd');
        $('#enterprise_file_upload_limit_cnt').removeClass('activeTd');
        $('#enterprise_file_upload_limit_size').removeClass('activeTd');
        $('#enterprise_file_upload_frequency').removeClass('activeTd');
        $('#enterprise_training_file_upload_limit').removeClass('activeTd');
        $('#enterprise_training_file_upload_limit_size').removeClass('activeTd');
        $('#enterprise_training_file_upload_frequency').removeClass('activeTd');
        $('#enterprise_total_file_storage_space').removeClass('activeTd');
        $('#enterprise_user_upload').removeClass('activeTd');
        $('#enterprise_file_drop').removeClass('activeTd');
        $('#enterprise_api_integration').removeClass('activeTd');
        $('#enterprise_env_impact').removeClass('activeTd');
        $('#enterprise_export_to_pdf').removeClass('activeTd');
        $('#enterprise_export_to_csv').removeClass('activeTd');
        $('#enterprise_email_phone_support').removeClass('activeTd');
        $('#enterprise_live_chat_support').removeClass('activeTd');
        $('#enterprise_company_logo').removeClass('activeTd');

        $('#stratizant_TrialP_user_cnt').removeClass('activeTd');
        $('#stratizant_dashboard_user_cnt').removeClass('activeTd');
        $('#stratizant_configAdmin_user_cnt').removeClass('activeTd');
        $('#stratizant_superAdmin_user_cnt').removeClass('activeTd');
        $('#stratizant_cost_trialP_user').removeClass('activeTd');
        $('#stratizant_cost_dashboard_user').removeClass('activeTd');
        $('#stratizant_cost_configAdmin_user').removeClass('activeTd');
        $('#stratizant_cost_superAdmin_user').removeClass('activeTd');
        $('#stratizant_cost_additional_dashboard_user').removeClass('activeTd');
        $('#stratizant_file_upload_limit_cnt').removeClass('activeTd');
        $('#stratizant_file_upload_limit_size').removeClass('activeTd');
        $('#stratizant_file_upload_frequency').removeClass('activeTd');
        $('#stratizant_training_file_upload_limit').removeClass('activeTd');
        $('#stratizant_training_file_upload_limit_size').removeClass('activeTd');
        $('#stratizant_training_file_upload_frequency').removeClass('activeTd');
        $('#stratizant_total_file_storage_space').removeClass('activeTd');
        $('#stratizant_user_upload').removeClass('activeTd');
        $('#stratizant_file_drop').removeClass('activeTd');
        $('#stratizant_api_integration').removeClass('activeTd');
        $('#stratizant_env_impact').removeClass('activeTd');
        $('#stratizant_export_to_pdf').removeClass('activeTd');
        $('#stratizant_export_to_csv').removeClass('activeTd');
        $('#stratizant_email_phone_support').removeClass('activeTd');
        $('#stratizant_live_chat_support').removeClass('activeTd');
        $('#stratizant_company_logo').removeClass('activeTd');

    }
}
