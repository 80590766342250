import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-delete-success-modal',
    templateUrl: './delete-success-modal.component.html',
    styleUrls: ['./delete-success-modal.component.css']
})
export class DeleteSuccessModalComponent implements OnInit {
    message: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,private router:Router, public dialogRef: MatDialogRef<DeleteSuccessModalComponent>) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        this.message = this.data.message;
    }


    close() {
        this.dialogRef.close();
        location.reload();
    }
}
