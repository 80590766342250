import { Component, OnInit } from '@angular/core';
import { SupplierTrendChartService } from './supplier-trend-chart.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { CookieService } from 'ngx-cookie-service';
declare let $: any;

@Component({
    selector: 'app-supplier-trend-chart',
    templateUrl: './supplier-trend-chart.component.html',
    styleUrls: ['./supplier-trend-chart.component.css']
})
export class SupplierTrendChartComponent implements OnInit {
    selectedDuration: any;
    authtoken: string;
    countriesList: any = [];
    trendForm: FormGroup;
    infoMsg: string;
    showChart = false;
    chartData: any;
    periods: any = [];
    trustScores: any = [];
    impactedTrustScores: any = [];
    supplierTrendsData: any;
    loader = false;
    currentDuration: any;
    suppliersList:any = [];
    removedDupCountries: any;
    suppliersWithCountries: any = [];
    countryOfSelectedSupplier: any;
    errorMsg: boolean;
    noRecordsFoundMsg: boolean = false;
    fitValue: boolean;
    selectedSupplier: any;
    selectedSupplierCountry: any;
    lastloginatTimeDifference: any;

    constructor(private supplierTrendService: SupplierTrendChartService, private fb: FormBuilder, 
                private matDialog: MatDialog, private fbAuth: StratizantFirebaseAuthService, 
                private cookieServiceVariable: CookieService) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        $('#1M').addClass('active');
        this.selectedDuration = '1M';
        this.trendForm = this.fb.group({
            supplier: [''],
            country: [''],
            segment:'Supply Chain'
        });
        this.getAllSuppliers();
       
    }

   

    getAllSuppliers() {
        
        const data = {
            'Authorization': this.authtoken
        };


        this.supplierTrendService.getAllSuppliers(data).subscribe((res) => {
            this.suppliersWithCountries = res;
            this.suppliersList = Object.keys(res);
            this.countriesList = Object.values(res);
            this.removedDupCountries = this.countriesList.filter((value, index) => this.countriesList.indexOf(value) === index);
            this.countriesList = this.removedDupCountries;
            if(localStorage.getItem('backToTrend') === 'true'){
                this.trendForm.controls.supplier.setValue(localStorage.getItem('selected_supplier'));
                this.setCountry(localStorage.getItem('selected_supplier'));
            }else{
                this.trendForm.controls.supplier.setValue(this.suppliersList[0]);
                this.setCountry(this.suppliersList[0]);
            }
            
        }, (error) => {
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            //this.loader = false;
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
        });

    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }



    selectedCountry(country){
        this.suppliersList = [];
        for (const data in this.suppliersWithCountries) {
            
            if (country === this.suppliersWithCountries[data]) {
                this.suppliersList.push(data);
            }
        }

    }

    setCountry(supplier) {
        //this.showChart = true;
        this.infoMsg = '';
        //this.trendForm.controls.country.setValue('USA');
        for (const data in this.suppliersWithCountries) {
            
            if (supplier === data) {
                this.countryOfSelectedSupplier = this.suppliersWithCountries[data];
            }
        }
        this.trendForm.controls['country'].setValue(this.countryOfSelectedSupplier);
        this.selectedSupplier = this.trendForm.controls.supplier.value;
        this.selectedSupplierCountry = this.trendForm.controls.country.value;
        
        // this.getSupplierTrends(this.currentDuration);
    }

}

