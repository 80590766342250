import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserManagementService } from '../user-management.service';

@Component({
    selector: 'app-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.css']
})
export class GenericModalComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GenericModalComponent>,
                private userService: UserManagementService) {
        dialogRef.disableClose = true
    }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
    }



    close(val) {
        if (val === 'Delete') {
            const data = {
                'action': 'Delete',
                'uid': this.data.uid
            }
            this.dialogRef.close(data);
        } else {
            const data = {
                'action': 'Cancel'
            }
            this.dialogRef.close(data);
        }

    }

}
