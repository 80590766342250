import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { UploadSuccessComponent } from '../impact-data-load/upload-success/upload-success.component';


@Component({
    selector: 'app-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.css']
})
export class UploadDialogComponent implements OnInit {
    dataToUpload: any;
    fileToUpload: any;
    invalidCSVFile = false;
    invalidImage = false;
    fileUploadForm: FormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private matDialog: MatDialog,public dialogRef: MatDialogRef<UploadDialogComponent>,
                private fb: FormBuilder) {
        dialogRef.disableClose = true;
    }

    get fuf() {
        return this.fileUploadForm.controls;
    }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        this.dataToUpload = this.data;
        this.fileUploadForm = this.fb.group({
            impact_type: ['', Validators.required],
            uploaded_file: ['', Validators.required],
        });
    }

    close(val) {
        if (val == false) {
            const data = {
                'fileToUpload': this.fileToUpload,
                'impact_type': this.data.impactType,
                'file_type': this.data.upload_type
            };
            this.dialogRef.close(data);
            this.openUploadSuccessComponent(this.data.upload_type);

        } else {
            this.dialogRef.close();
        }

    }

    getFileName(files: FileList) {
        if (files != null) {
            this.fileToUpload = files[0];
        } else {
            this.fileToUpload = '';
        }
        if (this.data.upload_type == 'Data' && !this.fileToUpload.name.includes('.csv') && !this.fileToUpload.name.includes('.CSV')) {
            this.fileUploadForm.controls.uploaded_file.setValue(undefined);
            this.invalidCSVFile = true;
        } else {
            this.invalidCSVFile = false;
        }

        if (this.data.upload_type == 'Icon' && ((!this.fileToUpload.name.includes('.png') && !this.fileToUpload.name.includes('.PNG')) && (!this.fileToUpload.name.includes('.jpeg') && !this.fileToUpload.name.includes('.JPEG')))) {
            this.fileUploadForm.controls.uploaded_file.setValue(undefined);
            this.invalidImage = true;
        } else {
            this.invalidImage = false;
        }

    }


    openUploadSuccessComponent(data) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = data;
        const dialogRef = this.matDialog.open(UploadSuccessComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {


        });

    }
}
