/* eslint-disable no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfileService } from '../user-profile.service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { CookieService } from 'ngx-cookie-service';


@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.css']
})
export class ContactDetailsComponent implements OnInit {

    contactDetailsForm: FormGroup;
    userDetails: any;
    authtoken: any;
    errorMsg: any;
    loader = false;
    phoneNumberInvalid: string;
    successMsg: string;
    formValueChanged = false;
    lastloginatTimeDifference: any;

    constructor(private fb: FormBuilder, private userProfileService: UserProfileService,
        private cookieServiceVariable: CookieService,
         private firebaseAuthService: StratizantFirebaseAuthService,
                private matDialog: MatDialog) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.contactDetailsForm = this.fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            title: [''],
            phone_number: ['', Validators.required]
        });

        // this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        // if (this.userDetails) {
        //   let splittedName = this.userDetails.display_name.split(' ');
        //   let fn = splittedName[0];
        //   let ln = splittedName[1];

        //   this.contactDetailsForm.controls.first_name.setValue(fn);
        //   this.contactDetailsForm.controls.last_name.setValue(ln);
        //   this.contactDetailsForm.controls.phone_number.setValue(this.userDetails.phone_number);

        // }

     

        this.getUserDetails();

    }



    getUserDetails() {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.userProfileService.getUserDetails(data).subscribe(res => {
            this.loader = false;
            const splittedName = res.name.split(' ');
            const fn = splittedName[0];
            const ln = splittedName[1];
            this.contactDetailsForm.controls.first_name.setValue(fn);
            this.contactDetailsForm.controls.last_name.setValue(ln);
            this.contactDetailsForm.controls.phone_number.setValue(res.phone_number);
            this.contactDetailsForm.controls.title.setValue(res.title);

        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } else
             if (error.status === 404) {
                this.errorMsg = error.error;
            }
        });
    }

   

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            this.firebaseAuthService.signout();

        });

    }

    validatePhoneNumber(p_no, action){
        const numbers = /^[0-9]+$/;
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.contactDetailsForm.controls.phone_number.setValue(p_no);
        }
        if((p_no.length != 10 || !p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = 'Enter 10 digit phone number';
        }else if((p_no.length == 10 && p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = '';
        }else if(p_no.length == 10 && p_no.match(numbers) && action === 'addCode'){
            this.phoneNumberInvalid = '';
            const stringEnc = encodeURI('+');
            const phn_number = stringEnc + 1 + this.contactDetailsForm.value.phone_number;
            this.contactDetailsForm.controls.phone_number.setValue(phn_number);
        }
    }

    removeCode(p_no){
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.contactDetailsForm.controls.phone_number.setValue(p_no);
        }
    }

    updateUserDetails(){
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.successMsg = '';
        this.errorMsg = '';
        const formData = new FormData();

        formData.append('first_name', this.contactDetailsForm.value.first_name);
        formData.append('last_name', this.contactDetailsForm.value.last_name);
        formData.append('phone_number', this.contactDetailsForm.value.phone_number);
        formData.append('title', this.contactDetailsForm.value.title);


        this.userProfileService.updateUserDetails(formData, data).subscribe(res => {
            this.loader = false;
            this.successMsg = 'Contact details has been updated successfully.';
            this.formValueChanged = false;
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } else 
            if (error.status === 400) {
                this.errorMsg = error.error.error;
            }
        });
    }

    get f() { return this.contactDetailsForm.controls; }


}
