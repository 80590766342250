import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
    selector: 'app-incorrect-route-component',
    templateUrl: './incorrect-route.component.html',
    styleUrls: ['./incorrect-route.component.css']
})
export class IncorrectRouteComponent implements OnInit, AfterViewInit {

    constructor(private router: Router) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.router.navigate(['/login']).then(() => {
            });
        }, 5000);
    }
}
