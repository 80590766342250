import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog, MatRadioChange, MatRadioButton } from '@angular/material';
@Component({
    selector: 'app-update-subscription-level-user-ip',
    templateUrl: './update-subscription-level-user-ip.component.html',
    styleUrls: ['./update-subscription-level-user-ip.component.css']
})
export class UpdateSubscriptionLevelUserIpComponent implements OnInit {
    selectedValue: any;
    constructor(public dialogRef: MatDialogRef<UpdateSubscriptionLevelUserIpComponent>, @Inject(MAT_DIALOG_DATA) public data: any, ) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
    }


    submit() {
        this.dialogRef.close(this.selectedValue);
    }

    cancel(){
        this.dialogRef.close('cancel');
    }

    onSelectionChange(mrChange: MatRadioChange) {
        this.selectedValue = mrChange.value;
        // const mrButton: MatRadioButton = mrChange.source;
       
       
    }
}
