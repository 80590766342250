import {Injectable} from '@angular/core';

import {StratizantFirebaseService} from './stratizant-firebase.service';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class StratizantFirebaseAuthService {

    constructor(private router: Router, private firebaseServices: StratizantFirebaseService, public afAuth: AngularFireAuth) {
    }

    signIn(username, password) {

        return new Promise((resolve, reject) => {
            this.afAuth.signInWithEmailAndPassword(username, password)
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
    }

    signout() {
        this.afAuth.signOut().then(() => {
            localStorage.removeItem('user');
            localStorage.removeItem('auth_token');
            localStorage.removeItem('roleType');
            localStorage.removeItem('loggedInUserName');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('organisation_name');
            localStorage.removeItem('additionalEnterPriseUser');
            localStorage.removeItem('lastloginatTimeDifference');
            this.router.navigate(['/login']).then(() => {
            });
        });
    }

   
}
