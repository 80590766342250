import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SupplierDataService {
    basepath = environment.basePath;

    constructor(private http: HttpClient) {
    }

    getIconURL(data: any,impact_type) {

        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        //const organisation_name = localStorage.getItem('organisation_name');
        return this.http.get<any>(`${this.basepath + 'api/app/get_icon?impact_type='+impact_type}`, httpOptions);

    }



    getSupplierDataByDuration(data, duration) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };
        // if(duration == 'oneMonth'){
        //     duration = '1M';
        // }else if(duration == 'threeMonths'){
        //     duration = '3M';
        // }else if(duration == 'sixMonths'){
        //     duration = '6M';
        // }else if(duration == 'nineMonths'){
        //     duration = '9M';
        // }else if(duration == 'oneYear'){
        //     duration = '1Y';
        // }else if(duration == 'twoYear'){
        //     duration = '2Y';
        // }

        //const organisation_name = localStorage.getItem('organisation_name');
        if(duration && duration != '1D'){
            return this.http.get<any>(`${this.basepath + 'api/app/trust_scorecard_summary?duration='+duration}`,httpOptions);
        }else{
            return this.http.get<any>(`${this.basepath + 'api/app/prediction_summary'}`, httpOptions);
        }
    
    }

}
