import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-subscription-success',
    templateUrl: './subscription-success.component.html',
    styleUrls: ['./subscription-success.component.css']
})
export class SubscriptionSuccessComponent implements OnInit {

    constructor( private router:Router,@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<SubscriptionSuccessComponent>) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
    }


    navigateToMyAccount(){
        // location.reload();
        this.router.navigate(['/home/myProfile']);
        this.dialogRef.close();
       
       
    }
}
