import {Component, OnInit} from '@angular/core';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-admin-settings',
    templateUrl: './admin-settings.component.html',
    styleUrls: ['./admin-settings.component.css']
})
export class AdminSettingsComponent implements OnInit {
    roleType: any;
    orgTabClicked = false;
    userTabClicked = false;
    supplierDeliveryClicked = false;

    subscriptionManagementtabClicked = false;
    authtoken: any;
    constructor(private fbAuth: StratizantFirebaseAuthService,   private cookieServiceVariable: CookieService,private matDialog: MatDialog) {
    }

    ngOnInit() {
        this.roleType = localStorage.getItem('roleType');
        // this.authtoken = localStorage.getItem('auth_token');
        this.authtoken =  this.cookieServiceVariable.get('auth_token_1');
       
    }

   
    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }

    orgManagementClicked(){
        this.orgTabClicked = !this.orgTabClicked;
    }

    userManagementClicked(){
        this.userTabClicked = !this.userTabClicked;
    }

    supplierDeliveryManagementClicked(){
        this.supplierDeliveryClicked = !this.supplierDeliveryClicked;
    }

    subscriptionManagementClicked(){
        this.subscriptionManagementtabClicked = !this.subscriptionManagementtabClicked;

    }

}
