import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StratizantFireStoreService } from '../../../services/stratizant-fire-store.service';
import * as c3 from 'c3';
import * as d3 from 'd3';
import { TableLevel1Service } from 'src/app/home/table-level1/table-level1.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { StratizantFirebaseService } from 'src/app/services/stratizant-firebase.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AdminSettingsService } from '../../admin-settings/admin-settings.service';
import {  FormGroup, FormBuilder } from '@angular/forms';
import { RefreshTokenService } from 'src/app/services/refresh-token.service';
import { CookieService } from 'ngx-cookie-service';
declare let $: any;

@Component({
    selector: 'app-all-sup-analysis',
    templateUrl: './all-sup-analysis.component.html',
    styleUrls: ['./all-sup-analysis.component.css']
})
export class AllSupAnalysisComponent implements OnInit {
    durationDate: any;
    loader = false;
    suppliers: any = [];
    trustScores: any = [];
    borderList: any = [];
    impactedTrustScores: any = [];
    sortedData = [];
    impact_type: any = 'COVID-19';
    imageToShow: any;
    errorMsg: any;
    authtoken: string;
    showImpact = false;
    showImpactMsg: boolean;
    noRecordsMsg: any;
    timeout: any;
    impactToggleForm: FormGroup;
    SlideOptions = {
        items: 1, dots: false, nav: true,
        onChange: () => {

        }
    };
    CarouselOptions = { items: 3, dots: false, nav: true, autoplay: true, };
    chartList = [];
    //@Input('sortedList') sortedList: any = [];
    @Input('itemList') itemList: any = [];
    @Output() stopLoader = new EventEmitter<string>();
    newList = [];
    impactToggle: any;
    noRecordsChart = true;
    carouselCharts = true;
    showTrustscoreAnalysisData: any;
    lastloginatTimeDifference: any;

    constructor(private route: ActivatedRoute, private fireStoreService: StratizantFireStoreService,
                private refreshTokenService: RefreshTokenService,
                private cookieServiceVariable: CookieService,
                private router: Router, private level1Service: TableLevel1Service, private matDialog: MatDialog,
                private firebaseAuthService: StratizantFirebaseAuthService, private firebaseServices: StratizantFirebaseService,
                private afStorage: AngularFireStorage, private adminService: AdminSettingsService, private fb: FormBuilder) {
    }

    ngOnInit() {
        this.impact_type = localStorage.getItem('impact_type');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        //$('#oneMonth').addClass('active');
        this.chartList = [];
        this.impactToggle = true;
        // this.loader = true;
        // const div = Math.ceil(this.newList.length / 20)
        // for(let i =1; i <= div; i++){
        //     this.chartList.push("barchart"+i);
        // }   

        // for(let j =1; j < this.chartList.length; j++){
        //     this.generateBarChart(j);
        // }

      
        this.showTrustscoreAnalysis('');
        const duration = sessionStorage.getItem("duration");
        if (duration) {
            sessionStorage.removeItem("duration");
        }

        this.impactToggleForm = this.fb.group({
            impact: [true],
        });

        //this.getModeStatus();

    }


    ngOnChanges(changes: SimpleChanges) {
        // if(changes.itemList.currentValue){
        //     this.chartList = changes.itemList.currentValue;
        // }

        if (!changes.itemList.isFirstChange() && changes.itemList.currentValue.sortedList && changes.itemList.currentValue.sortedList.length != 0) {
            this.carouselCharts = false;
            this.noRecordsChart = true;
            this.chartList = [];
            //this.loader = true;
            this.chartList = changes.itemList.currentValue.chartList;
            this.impactToggle = changes.itemList.currentValue.impactScore;
            //const div = Math.ceil(changes.itemList.currentValue.sortedList.length / 20);
            // for(let i =1; i <= div; i++){
            //     this.chartList.push("barchart"+i);
            // }
            this.newList = changes.itemList.currentValue.sortedList;
            const loader_val = changes.itemList.currentValue.loader ? changes.itemList.currentValue.loader : "";
            this.formatDataForChart(changes.itemList.currentValue.sortedList, loader_val);
        } else if (!changes.itemList.isFirstChange()) {
            //const num = 1;
            this.carouselCharts = true;
            this.noRecordsChart = false;
            this.suppliers = [];
            this.trustScores = [];
            this.impactedTrustScores = [];
            this.suppliers[0] = 'x';
            this.trustScores[0] = 'Trust Score';
            this.impactedTrustScores[0] = 'Impacted Trust Score';
            this.generateNorecordsChart();
            this.stopLoader.emit('false');
        }
    }

    formatDataForChart(sortedData, loader_flag) {
        let cnt = 0;
        let num = 1;
        // this.chartList.push("barchart1");
        this.suppliers[num] = [];
        this.trustScores[num] = [];
        this.impactedTrustScores[num] = [];
        this.suppliers[num][0] = 'x';
        this.trustScores[num][0] = 'Trust_Score';
        this.impactedTrustScores[num][0] = 'Impacted_Trust_Score';
        // const div = Math.round(sortedData.length / 20);

        // for(let i =1; i <= div; i++){
        //     this.chartList.push("barchart"+i);
        // }
        // this.chartList = [];
        // const div = Math.ceil(sortedData.length / 20);
        // for(let i =1; i <= div; i++){
        //     this.chartList.push("barchart"+i);
        // }
        let flag = 0;
        let cnt1 = 0;
        for (const j in sortedData) {
            cnt++;
            cnt1++;
            sortedData[j]['value']['Trust_Score'] = sortedData[j]['value']['Trust_Score'] / sortedData[j]['total_count'];
            sortedData[j]['value']['Trust_Score'] = JSON.stringify(Math.round(sortedData[j]['value']['Trust_Score']));
            this.trustScores[num].push(sortedData[j]['value']['Trust_Score']);
            //this.borderList[num].push(this.sortedData[j]['value']['Trust_Score']);
            this.suppliers[num].push(sortedData[j]['value']['Supplier']);
            sortedData[j]['value']['Impacted_Trust_Score'] = sortedData[j]['value']['Impacted_Trust_Score'] / sortedData[j]['impact_total_count'];
            sortedData[j]['value']['Impacted_Trust_Score'] = JSON.stringify(Math.round(sortedData[j]['value']['Impacted_Trust_Score']));
            this.impactedTrustScores[num].push(sortedData[j]['value']['Impacted_Trust_Score']);
            if (sortedData.length < 20 && cnt == sortedData.length) {
                this.generateBarChart(num);
            } else if (cnt == 20) {
                //this.chartList.push(num);
                this.generateBarChart(num);
                const num1 = sortedData.length - 20;
                num++;
                this.suppliers[num] = [];
                this.trustScores[num] = [];
                this.impactedTrustScores[num] = [];
                this.suppliers[num][0] = 'x';
                this.trustScores[num][0] = 'Trust_Score';
                this.impactedTrustScores[num][0] = 'Impacted_Trust_Score';
                if (num1 > 20) {
                    flag = 1;
                    cnt = 0;
                } else {
                    flag = 2;
                    cnt = 0;
                }
            } else if (sortedData.length == cnt1 && flag == 2) {
                //this.chartList.push("barchart"+num);
                //this.chartList.push("barchart"+num);
                this.generateBarChart(num);

                //this.chartList.push(num);

                //num++;

            }
        }
        if (!loader_flag) {
            this.stopLoader.emit('false');
        }


    }

    ngAfterViewInit() {
        // if(this.newList.length > 0){
        if (this.newList.length < 20) {
            this.chartList.push("barchart1");
        } else {
            const div = Math.ceil(this.newList.length / 20)
            for (let i = 1; i <= div; i++) {
                this.chartList.push("barchart" + i);
            }
        }


        for (let j = 1; j < this.chartList.length; j++) {
            this.generateBarChart(j);
        }


        // }

    }

    // ngOnChanges(changes: SimpleChanges) {

    //   this.formatData(changes.sortedList.currentValue);

    // }

    showTrustscoreAnalysis(val) {
        this.carouselCharts = true;
        this.noRecordsChart = false;
        this.loader = true;
        if (!localStorage.getItem('user')) {
            this.router.navigate(['/login']).then(() => {
            });
        }
        this.showTrustscoreAnalysisData = val;
        const data = {
            Authorization: localStorage.getItem('auth_token')
        };

        this.level1Service.gettrustScoreCardSummary(data, val).subscribe(response => {


            this.formatData(response['prediction_summary']);
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }else 
            // const refreshToken = localStorage.getItem('isrefreshtoken');
            // const timeout = localStorage.getItem('timeout');

            // if (error && (error.status === 401) && timeout) {
            //     this.openAuthDialog();
            // } else if (error && (error.status === 401) && refreshToken === 'true') {
            //     // this.authtoken = localStorage.getItem('refreshtoken')
            //     this.refreshTokenMethod('showTrustscoreAnalysis', this.showTrustscoreAnalysisData);
            // }
            if(error.status === 404){
                this.errorMsg = error.error;
            }
        });

    }


    formatData(res) {
        const response = Object.entries(res).map(([type, value]) => ({ type, value }));
        if (Object.keys(response).length != 0) {
            const timestamp = response[0]['value']['timestamp'];
            const dateString = new Date(timestamp).toUTCString();
            this.durationDate = dateString.split(' ').slice(0, 4).join(' ');
            this.noRecordsMsg = '';

        } else {
            this.durationDate = '';
            this.noRecordsMsg = "No Records Found.";
        }


        const resData = response;
        const newData = [];

        for (const key in resData) {
            if (newData.length != 0) {
                let cnt = 0;
                for (const dt in newData) {

                    if (newData[dt]['value']['Supplier'] == resData[key]['value']['Supplier']) {
                        newData[dt]['total_count'] = newData[dt]['total_count'] + 1;
                        newData[dt]['value']['Trust_Score'] = newData[dt]['value']['Trust_Score'] + resData[key]['value']['Trust_Score'];
                        if (newData[dt]['value']['Impacted_Delivery_date'] || newData[dt]['value']['Impacted_Trust_Score']) {
                            //imp_cnt++;
                            //this.showImpact = true;
                            // this.impact_type = resData[key]['Impact Type'];
                            newData[dt].impact_total_count = newData[dt]['impact_total_count'] + 1;
                            newData[dt]['value']['Impacted_Trust_Score'] = newData[dt]['value']['Impacted_Trust_Score'] + resData[key]['value']['Impacted_Trust_Score'];
                        }
                        break;
                    } else {
                        cnt++;
                    }
                    if (cnt == newData.length) {
                        //  this.suppliers.push(resData[key]['Supplier']);
                        newData.push(resData[key]);
                        newData[newData.length - 1]['total_count'] = 1;
                        newData[newData.length - 1]['impact_total_count'] = 1;
                    }
                }
            } else {
                //this.suppliers.push(resData[key]['Supplier']);
                newData.push(resData[key]);
                newData[0]['total_count'] = 1;
                if (resData[key]['value']['Impacted_Delivery_date'] || resData[key]['value']['Impacted_Trust_Score']) {
                    //imp_cnt++;
                    //this.showImpact = true;
                    newData[0]['impact_total_count'] = 1;
                }
            }
        }

        const sortState = {
            'active': 'Supplier',
            'direction': 'asc'
        };
        //this.loader = false;
        this.customSort(sortState, newData);
        // this.generateBarChart();
    }


    getIcon(impact_type) {
        //this.loader = true;
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };

        this.level1Service.getIconURL(data, impact_type).subscribe((res) => {
            if (res) {
                const storageRef = this.afStorage.storage.ref(res.Path);
                storageRef.getDownloadURL().then(url => {

                    this.imageToShow = url;
                });
            }
            //this.createImageFromBlob(res.body);

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    // window.action = function() {
    //   clearTimeout(this.timeout);
    //   this.hideTooltip();
    // }

    hideTooltip = function () {
        d3.select('#barchart1 .c3-tooltip-container').style('display', 'none');
    }

    // store for timer

    slideChanged() {
        // this.generateBarChart();
    }


    generateNorecordsChart() {
        //const vm = this;

        const chart = c3.generate({
            bindto: '#barchart',
            size: {
                height: 500,
                width: 800
            },
            data: {
                x: 'x',
                columns: [
                    this.suppliers
                ],
                type: 'bar',

            },
            transition: {
                duration: 2000
            },
            // tooltip: {
            //     show: true
            // },
            axis: {
                rotated: false,
                x: {
                    type: 'category',
                    tick: {
                        outer: false,
                        rotate: 90,
                        multiline: false
                    },
                    height: 120,
                    label: {
                        text: 'Supplier',
                        position: 'outer-center',
                    }
                },
                y: {
                    max: 100,
                    tick: {
                        values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                    },
                    show: true,
                    padding: { top: 0.3, bottom: 0 },
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle'
                    }
                },


            },

            grid: {
                lines: {
                    front: false
                },
                y: {
                    lines: [
                        { value: 20 },
                        { value: 40 },
                        { value: 80 },
                    ]
                }
            },

            bar: {
                width: 10

            },
            legend: {
                hide: true,
                show: false
            },
        });

        const sup = this.suppliers;
        const trustScore = this.trustScores;
        const imp_trust_score = this.impactedTrustScores;
        //const borderData = this.borderList;

        if (this.impactToggle === true) {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore,
                        imp_trust_score
                    ]
                });
            }, 500);
        } else {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore
                    ]
                });
            }, 500);
        }

        setTimeout(() => {
            chart.regions(
                [{ axis: 'y', start: 0, end: 20, class: 'region1' },
                    { axis: 'y', start: 20, end: 40, class: 'region2' },
                    { axis: 'y', start: 40, end: 80, class: 'region3' },
                    { axis: 'y', start: 80, class: 'region4' }]);
        }, 500);


    }


    generateBarChart(num) {
        // this.chartList = [];
        // const div = Math.ceil(this.newList.length / 20)
        // for(let i =1; i <= div; i++){
        //     this.chartList.push("barchart"+i);
        // }   
        //this.stopLoader.emit('false');
        const vm = this;
        const chartid = '#barchart' + num;

        const chart = c3.generate({
            bindto: chartid,
            size: {
                height: 500,
                width: 1060
            },
            data: {
                x: 'x',
                columns: [
                    this.suppliers[num]
                ],
                type: 'bar',
                // labels: {
                //     format:  function (v, id, i, j){
                //         if (v == 10.001)
                //             return "N/S";
                //         else if (v == 10.002)
                //             return "N/A";
                //         else
                //             return v.toString();
                //     } 

                // },
                color: function (inColor, data) {
                    if (data['id'] !== undefined && data['id'] === 'Trust_Score') {
                        return data['value'] <= 20 ? '#DC143C' : data['value'] >= 21 && data['value'] <= 40 ? 'var(--secondary-orange)' : data['value'] >= 41 && data['value'] <= 80 ? 'var(--secondary-yellow)' : 'var(--primary-green)';

                    }
                    return inColor;
                },
                colors: {
                    //'Trust_Score': 'darkgrey',
                    'Impacted_Trust_Score': 'var(--secondary-grey)'
                },

                onclick: (d) => {
                    // this.selectedBar(d);

                    if (d.index !== undefined) {
                        this.goToDetailPage(d.index + 1, num);
                    }
                }
            },
            transition: {
                duration: 2000
            },
            tooltip: {
                contents: function (data) {
                    let boxClass;
                    const impactValue = vm.impactToggleForm.controls.impact.value;
                    if (data[0]['value'] <= 20) {
                        boxClass = '<div class="row"><div class="custom-container" style="padding:2px;border: 2px solid var(--secondary-red);background-color:white;">'
                            + '<div class="col-md-12 form-group" style="font-size:14px; margin-bottom:20px;"><div><div style="margin-top:5px;padding-right:5px;">This supplier is chronically</div><img src="assets/images/late.png" style="width:60px;position:absolute;top:0px;right:2px;"></div></div>' +
                            '<div class="col-md-12 form-group"><span><img src="assets/images/red_gauge.png" style="width:90px; padding-right:16px"> <div style="position:absolute;top:8px;left:95px;"> <span style="font-size: 14px;">Trust Score:</span></span>&nbsp;&nbsp;<span style="font-size:18px; font-weight: bold;">' + data[0]['value'] + '</span></div></div>';
                        if (impactValue && (data[1] && data[1]['value'])) {
                            boxClass = boxClass + '<div class="col-md-3 form-group"><div style="background-color:lightgray;text-align:center; line-height: normal;"><span style="font-size:12px;">Impact Score:</span><br><span style="font-weight:bold;font-size:14px;">' + data[1]['value'] + '</span></div> </div></div></div>';
                        } else {
                            boxClass = boxClass + '</div></div>';
                        }

                    } else if (data[0]['value'] >= 21 && data[0]['value'] <= 40) {
                        boxClass = '<div class="row"><div class="custom-container" style="padding:2px;border: 2px solid var(--secondary-orange);background-color:white;">'
                            + '<div class="col-md-12 form-group" style="font-size:14px; margin-bottom:20px;"><span><div style="margin-top:5px;">This supplier can be</div><img src="assets/images/fairly_delayed.png" style="width:92px;position:absolute;top:1px;right:5px;"></span></div>' +
                            '<div class="col-md-12 form-group"><span><img src="assets/images/orange_gauge.png" style="width:90px; padding-right:16px;"><div style="position:absolute;top:8px;left:95px;">  <span style="font-size: 14px;">Trust Score:</span></span>&nbsp;&nbsp;<span style="font-size:18px; font-weight: bold;">' + data[0]['value'] + '</span></div></div>';
                        if (impactValue && (data[1] && data[1]['value'])) {
                            boxClass = boxClass + '<div class="col-md-3 form-group"><div style="background-color:lightgray;text-align:center; line-height: normal;"><span style="font-size:12px;">Impact Score:</span><br><span style="font-weight:bold;font-size:14px;">' + data[1]['value'] + '</span></div> </div></div></div>';
                        } else {
                            boxClass = boxClass + '</div></div>';
                        }
                    } else if (data[0]['value'] >= 41 && data[0]['value'] <= 80) {
                        boxClass = '<div class="row"><div class="custom-container" style="padding:2px;border: 2px solid var(--secondary-yellow);background-color:white;">'
                            + '<div class="col-md-12 form-group" style="font-size: 14px;margin-bottom:20px;"><span><div style="margin-top:5px;">This supplier is</div><img src="assets/images/rarely_late.png" style="width:92px;position:absolute;top:0;right:40px;"></span></div>' +
                            '<div class="col-md-12 form-group"><span><img src="assets/images/yellow_gauge.png" style="width:90px; padding-right: 16px;"><div style="position:absolute;top:8px;left:95px;">  <span style="font-size: 14px;">Trust Score:</span></span>&nbsp;&nbsp;<span style="font-size:18px; font-weight: bold;">' + data[0]['value'] + '</span></div></div>';
                        if (impactValue && (data[1] && data[1]['value'])) {
                            boxClass = boxClass + '<div class="col-md-3 form-group"><div style="background-color:lightgray;text-align:center; line-height: normal;"><span style="font-size:12px;">Impact Score:</span><br><span style="font-weight:bold;font-size:14px;">' + data[1]['value'] + '</span></div> </div></div></div>';
                        } else {
                            boxClass = boxClass + '</div></div>';
                        }
                    } else {
                        boxClass = '<div class="row"><div class="custom-container" style="padding:2px;border: 2px solid var(--primary-green);background-color:white;">'
                            + '<div class="col-md-12 form-group" style="font-size: 14px;margin-bottom:20px;"><span><div style="margin-top:5px; padding-right:4px;">This supplier is always</div><img src="assets/images/ontime.png" style="width:88px;position:absolute;top:1px;right:2px;"></span></div>' +
                            '<div class="col-md-12 form-group"><span><img src="assets/images/green_gauge.png" style="width:90px; padding-right: 16px;"><div style="position:absolute;top:8px;left:95px;">  <span style="font-size: 14px;">Trust Score:</span></span>&nbsp;&nbsp;<span style="font-size:18px; font-weight: bold;">' + data[0]['value'] + '</span></div></div>';
                        if (impactValue && (data[1] && data[1]['value'])) {
                            boxClass = boxClass + '<div class="col-md-3 form-group"><div style="background-color:lightgray;text-align:center; line-height: normal;"><span style="font-size:12px;">Impact Score:</span><br><span style="font-weight:bold;font-size:14px;">' + data[1]['value'] + '</span></div> </div></div></div>';
                        } else {
                            boxClass = boxClass + '</div></div>';
                        }
                    }
                    return boxClass;

                }
            },
            // tooltip: {
            //     show: true
            // },
            axis: {
                rotated: false,
                x: {
                    type: 'category',
                    tick: {
                        outer: false,
                        rotate: 90,
                        multiline: true,
                        multilineMax: 2,
                        fit: true,
                        width: 80,
                    },
                    height: 110,
                    padding: {bottom: 5},
                    label: {
                        text: 'Supplier',
                        position: 'outer-center',
                    }
                },
                y: {
                    max: 100,
                    tick: {
                        values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                    },
                    show: true,
                    padding: { top: 0.3, bottom: 0 },
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle'
                    }
                },


            },

            grid: {
                lines: {
                    front: false
                },
                y: {
                    lines: [
                        { value: 20 },
                        { value: 40 },
                        { value: 80 },
                        // {value: 0.5, class: 'grid1'},
                        // {value: 19.5, class: 'grid1'},
                        // {value: 20, class: 'grid2'},
                        // {value: 39.5, class: 'grid2'},
                        // {value: 40, class: 'grid3'},
                        // {value: 79.5, class: 'grid3'},
                        // {value: 80, class: 'grid4'},
                        // {value: 100, class: 'grid4'}
                    ]
                }
            },
            //   regions: [

            //     {axis: 'y', start: 0,end: 20, class: 'region1'},
            //     {axis: 'y', start: 20, end: 40, class: 'region2'},
            //     {axis: 'y', start: 40, end: 80, class: 'region3'},
            //     {axis: 'y', start: 80, class: 'region4'}
            // ],
            bar: {
                width: 20
            },
            legend: {
                hide: true,
                show: false
            },
            padding: {
                bottom: 20
            },
            onrendered: function () {
                const thisChart = d3.select(this.config.bindto);
                // TODO when adjusting the position of bars, it should use the width of bars / 2
                thisChart.selectAll(".c3-shapes .c3-shape, .c3-bars .c3-bar").style("transform", "translate(+"+9+"px,0)");
            }
        });

        const sup = this.suppliers[num];
        const trustScore = this.trustScores[num];
        const imp_trust_score = this.impactedTrustScores[num];
        //const borderData = this.borderList;

        if (this.impactToggle === true) {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore,
                        imp_trust_score
                    ]
                });
            }, 500);
        } else {
            setTimeout(function () {
                chart.load({
                    columns: [
                        sup,
                        trustScore
                    ]
                });
            }, 500);
        }

        setTimeout(() => {
            chart.regions(
                [{ axis: 'y', start: 0, end: 20, class: 'region1' },
                    { axis: 'y', start: 20, end: 40, class: 'region2' },
                    { axis: 'y', start: 40, end: 80, class: 'region3' },
                    { axis: 'y', start: 80, class: 'region4' }]);
        }, 500);


        // const originalHideTooltip = chart.internal.hideTooltip
        // chart.internal.hideTooltip = function () {
        //     setTimeout(originalHideTooltip, 100)
        // };

    }


    customSort(val, dataArray) {

        const data = dataArray.slice();
        if (val) {

            this.sortedData = data.sort((a, b) => {


                const isAsc = val.direction === 'asc';


                switch (val.active) {
                    case 'Trust_Score':
                        return this.compare(a['value']['Trust_Score'], b['value']['Trust_Score'], isAsc);
                    case 'Supplier':
                        // eslint-disable-next-line no-var
                        var val1 = a['value']['Supplier'].split(' ');
                        // eslint-disable-next-line no-var
                        var val2 = b['value']['Supplier'].split(' ');

                        //return val1.localeCompare(val2);
                        return this.compare(parseInt(val1[1]), parseInt(val2[1]), isAsc);
                        //return val1 - val2;
                        //return a['Supplier'] - b['Supplier'];
                        // return this.compare(a['Supplier'], b['Supplier'], isAsc);

                    default:
                        return 0;
                }


            });
            let cnt = 0;
            let num = 1;
            this.chartList = [];
            //this.chartList.push("barchart1");
            this.suppliers[num] = [];
            this.trustScores[num] = [];
            this.impactedTrustScores[num] = [];
            this.suppliers[num][0] = 'x';
            this.trustScores[num][0] = 'Trust_Score';
            this.impactedTrustScores[num][0] = 'Impacted_Trust_Score';
            if (this.sortedData.length < 20) {
                this.chartList.push("barchart1");
            } else {
                const div = Math.round(this.sortedData.length / 20);
                for (let i = 1; i <= div; i++) {
                    this.chartList.push("barchart" + i);
                }
            }

            let flag = 0;
            let cnt1 = 0;
            for (const j in this.sortedData) {
                cnt++;
                cnt1++;
                this.sortedData[j]['value']['Trust_Score'] = this.sortedData[j]['value']['Trust_Score'] / this.sortedData[j]['total_count'];
                this.sortedData[j]['value']['Trust_Score'] = JSON.stringify(Math.round(this.sortedData[j]['value']['Trust_Score']));
                this.trustScores[num].push(this.sortedData[j]['value']['Trust_Score']);
                //this.borderList.push(this.sortedData[j]['value']['Trust_Score']);
                this.suppliers[num].push(this.sortedData[j]['value']['Supplier']);
                this.sortedData[j]['value']['Impacted_Trust_Score'] = this.sortedData[j]['value']['Impacted_Trust_Score'] / this.sortedData[j]['impact_total_count'];
                this.sortedData[j]['value']['Impacted_Trust_Score'] = JSON.stringify(Math.round(this.sortedData[j]['value']['Impacted_Trust_Score']));
                this.impactedTrustScores[num].push(this.sortedData[j]['value']['Impacted_Trust_Score']);
                if (this.sortedData.length < 20 && cnt == this.sortedData.length) {
                    this.generateBarChart(num);
                } else if (cnt == 20) {
                    //this.chartList.push(num);
                    this.generateBarChart(num);
                    const num1 = this.sortedData.length - 20;
                    num++;
                    this.suppliers[num] = [];
                    this.trustScores[num] = [];
                    this.impactedTrustScores[num] = [];
                    this.suppliers[num][0] = 'x';
                    this.trustScores[num][0] = 'Trust_Score';
                    this.impactedTrustScores[num][0] = 'Impacted_Trust_Score';
                    if (num1 > 20) {
                        flag = 1;
                        cnt = 0;
                    } else {
                        flag = 2;
                        cnt = 0;
                    }
                } else if (this.sortedData.length == cnt1 && flag == 2) {
                    //this.chartList.push("barchart"+num);                   
                    this.generateBarChart(num);

                    //num++;

                }
            }

            this.stopLoader.emit('false');
            this.loader = false;


        }


    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }


    goToSupplierView(index) {
        this.router.navigate(['home/supplierSummary/level2', this.suppliers[index]]);
    }


    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }

    getModeStatus() {

        const obj = {
            'Authorization': this.authtoken
        };
        this.errorMsg = '';
        // this.getIcon(localStorage.getItem('impact_type'));
        this.adminService.getModeStatus(obj).subscribe(res => {

            const impacttypeArr = Object.entries(res).map(([type, value]) => ({ type, value }));

            for (const imp_type in impacttypeArr) {

                if (impacttypeArr[imp_type]['type'] === 'COVID-19') {
                    if (impacttypeArr[imp_type]['value']['API'] === true || impacttypeArr[imp_type]['value']['CSV'] === true || impacttypeArr[imp_type]['value']['Manual'] === true) {
                        this.showImpactMsg = true;
                        this.getIcon(impacttypeArr[imp_type]['type']);
                    } else {
                        this.showImpactMsg = false;
                    }
                }

            }

            if (res && Object.keys(res).length != 0) {

                for (const imp_type in res) {
                    if (res[imp_type]['API'] === true || res[imp_type]['CSV'] === true) {
                        // this.getIcon(imp_type);
                        // this.impact_type = imp_type;

                        // break;
                    }
                    if (res[imp_type]['API'] === true && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === false) {
                        this.showImpact = true;
                    } else if (res[imp_type]['API'] === false && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === true) {
                        this.showImpact = true;
                    } else if (res[imp_type]['API'] === false && res[imp_type]['CSV'] === false && res[imp_type]['Manual'] === false) {
                        this.showImpact = false;
                    } else {
                        this.showImpact = true;
                    }
                }
            } else {
                this.showImpact = false;
            }

        }, (error) => {
            if (error && (error.status === 401)) {
                this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
        });

    }



    refreshTokenMethod(calledFuncName, data?) {
        this.loader = true;
        const authtoken = localStorage.getItem('refreshtoken');
        this.refreshTokenService.refreshToken(authtoken).subscribe((res) => {
            this.loader = false;
            console.log("in all supplier analysis");
            this.authtoken = res.id_token;
            if (calledFuncName == 'showTrustscoreAnalysis') {
                this.showTrustscoreAnalysis(data);
            }
        }, () => {
            this.loader = false;
        });
    }





    showPeriodicChart(periodId) {
        this.chartList = [];
        //this.chartList.push(1);
        if (periodId === 'oneDay') {
            $('#oneDay').addClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('');
        } else if (periodId === 'oneMonth') {
            $('#oneMonth').addClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('oneMonth');
        } else if (periodId === 'threeMonths') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').addClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('threeMonths');
        } else if (periodId === 'sixMonths') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').addClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('sixMonths');
        } else if (periodId === 'nineMonths') {
            $('#oneMonth').removeClass('active');
            $('#oneDay').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').addClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('nineMonths');
        } else if (periodId === 'oneYear') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').addClass('active');
            $('#twoYear').removeClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('oneYear');
        } else if (periodId === 'twoYear') {
            $('#oneDay').removeClass('active');
            $('#oneMonth').removeClass('active');
            $('#threeMonth').removeClass('active');
            $('#sixMonth').removeClass('active');
            $('#nineMonth').removeClass('active');
            $('#oneYear').removeClass('active');
            $('#twoYear').addClass('active');
            sessionStorage.setItem('duration', periodId);
            this.showTrustscoreAnalysis('twoYear');
        }


    }


    goToDetailPage(index, num) {
        localStorage.setItem('navigated_from', 'scores');
        this.router.navigate(['/home/supplierDetail/', this.suppliers[num][index]]);
    }

}
