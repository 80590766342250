import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';

@Component({
    selector: 'app-init-session-error-dialogue',
    templateUrl: './init-session-error-dialogue.component.html',
    styleUrls: ['./init-session-error-dialogue.component.css']
})
export class InitSessionErrorDialogueComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
                private afAuth: StratizantFirebaseAuthService,
                public dialogRef: MatDialogRef<InitSessionErrorDialogueComponent>) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
    }


    close(){
        this.afAuth.signout();
        this.dialogRef.close();
        this.router.navigate(['/login']).then(() => {
        });
    }
}
