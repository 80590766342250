import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

    planname: any;
    navigationSubscription: any;
    roleType: any;
    purchasedDate: any;
    authtoken: any;
    constructor(private fbAuth: StratizantFirebaseAuthService,  
        private cookieServiceVariable: CookieService,
        private matDialog: MatDialog) { 
       
    }

    ngOnInit() {
        //this.planname = localStorage.getItem('Planname');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.roleType = localStorage.getItem('roleType');
       
        //this.purchasedDate = localStorage.getItem('purchasedDate');
      
        
    }

 

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }
    receivePlanDetails($event) {
        this.planname = $event.plan_name;
        this.purchasedDate = $event.purchase_date;
    }

    changetab() {
      
    }
}
