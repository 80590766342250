import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserProfileService } from 'src/app/home/user-profile/user-profile.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
declare const $: any;

@Component({
    selector: 'app-next-page',
    templateUrl: './next-page.component.html',
    styleUrls: ['./next-page.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NextPageComponent implements OnInit {

    submitted = false;
    infoForm: FormGroup;
    phoneNumberInvalid: string;
    loader: boolean = false;
    fletter: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NextPageComponent>,
                private fb: FormBuilder, private userProfileService: UserProfileService,
                private fbAuth: StratizantFirebaseAuthService, private matDialog: MatDialog
    ) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
        this.infoForm = this.fb.group({
            job_title: ['', Validators.required],
            phone_number: ['', Validators.required]
        });
        if (this.data.submitted == true) {
            this.submitted = true
        } else {
            this.submitted = false
        }
        if (this.data.first_name) {
            this.fletter = this.data.first_name.charAt(0).toUpperCase() + this.data.first_name.slice(1);

        }

    }

    validatePhoneNumber(p_no, action) {
        const numbers = /^[0-9]+$/;
        if (p_no.includes('+1')) {
            p_no = p_no.replace('+1', '');
            this.infoForm.controls.phone_number.setValue(p_no);
        }
        if ((p_no.length != 10 || !p_no.match(numbers)) && action === 'validation') {
            this.phoneNumberInvalid = 'Enter 10 digit contact number';
        } else if ((p_no.length == 10 && p_no.match(numbers)) && action === 'validation') {
            this.phoneNumberInvalid = '';
        } else if (p_no.length == 10 && p_no.match(numbers) && action === 'addCode') {
            this.phoneNumberInvalid = '';
            const stringEnc = encodeURI('+');
            const phn_number = stringEnc + 1 + this.infoForm.value.phone_number;
            this.infoForm.controls.phone_number.setValue(phn_number);
        }
    }

    removeCode(p_no) {
        if (p_no.includes('+1')) {
            p_no = p_no.replace('+1', '');
            this.infoForm.controls.phone_number.setValue(p_no);
        }
    }

    submit() {
        this.loader = true;
        const formData = new FormData();
        const data = {
            'Authorization': this.data.auth_toke
        };

        formData.append('first_name', this.data.first_name);
        formData.append('last_name', this.data.last_name);
        formData.append('phone_number', this.infoForm.value.phone_number);
        formData.append('title', this.infoForm.value.job_title);
        this.userProfileService.updateUserDetails(formData, data).subscribe(() => {
            this.loader = false;
            this.submitted = true;
        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            // if (error && error.error == 'Unauthorized Access') {
            //     this.openAuthDialog();
            // } else if (error.status === 400) {
            //     this.errorMsg = error.error.error;
            // }
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }

    skip() {
        this.submitted = true;
    }

    close() {
        this.dialogRef.close();
    }

}
