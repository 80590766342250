// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebase: {
        apiKey: 'AIzaSyBXKsEQmMKoSTWW6Ed_Mlss6YfeATmE-1I',
        authDomain: 'supplierwatch-f055c.firebaseapp.com',
        databaseURL: 'https://supplierwatch-f055c.firebaseio.com',
        projectId: 'supplierwatch-f055c',
        storageBucket: 'supplierwatch-f055c.appspot.com',
        messagingSenderId: '912094786434',
        appId: '1:912094786434:web:0b5276ba405b569585d59b',
        measurementId: 'G-K5QWGPH5FJ'
    },
    basePath : "https://supplierwatch-f055c.appspot.com/",
    continueUrl: "https://fullconfidence.stratizant.com/login",
    user_management_cloud_function: "https://us-central1-supplierwatch-f055c.cloudfunctions.net/User_Management",
    requestDemoPage: "https://share.hsforms.com/1SDosI363S8OmHnNIs8Piww4iffa"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
