import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminSettingsService } from '../../admin-settings.service';
import {  MatDialogConfig , MatDialog } from '@angular/material';
import { AddManualImpactDataModalService } from './add-manual-impact-data-modal.service';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-add-manual-impact-data-modal',
    templateUrl: './add-manual-impact-data-modal.component.html',
    styleUrls: ['./add-manual-impact-data-modal.component.css']
})
export class AddManualImpactDataModalComponent implements OnInit {
    manualImpactDataForm: FormGroup;
    // suppliersList: any = [];
    // countriesList: any = [];
    minErrorMsg = '';
    maxErrorMsg = "";
    mindelayInputWrong: any;
    recordExistMsg: any;
    minDelayPatternWrong: boolean;
    maxDelayPatternWrong: boolean;
    minDelayCorrect: boolean;
    countryFirst: boolean;
    supplierFirst:boolean;
    maxDelayGreater: boolean;
    MindelayMsg = "Min delay should be numeric and <= 98";
    Maxerrormsg = "Max delay should be numeric and <= 99";
    minMaxEqual: boolean;
    minMaxErrorMsg: any;
    dateErrorMsg: any;
    minDelayGreater: boolean;
    validDate: boolean = false;
    currentDateGreater: boolean = false;
    validDateMsg: boolean;
    equalDates: boolean = false;
    deleteRecord: boolean;
    patternErrorMsg:boolean;
    loader = false;
    authtoken: any;
    suppliersList: any = [];
    countriesList: any = [];
    sameCountrySuppliersList: any = [];
    countryOfSelectedSupplier: any = [];
    allSuppliersandCountrieData: any;
    exDate: any;
    stDate: any;
    impact_id: any;
    removeAsterick: boolean = false;
    removedDupSup: any = [];
    lastloginatTimeDifference: any;
    constructor( private fbAuth: StratizantFirebaseAuthService,private matDialog: MatDialog,private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddManualImpactDataModalComponent>, private adminService: AdminSettingsService,
                 private addManualImpactDataService: AddManualImpactDataModalService,  private cookieServiceVariable: CookieService) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');
        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.dialogRef.updateSize('50%');
        this.createManualImpactDataForm();
        if (this.data.action === 'Update') {
            this.removeAsterick = true;
            this.deleteRecord = false;
            this.impact_id = this.data.formData.type;
            this.setData();
        } else if (this.data.action === 'Delete') {
            this.impact_id = this.data.formData.type;
            this.deleteRecord = true;
            this.removeAsterick = false;
        }
        else {
            this.deleteRecord = false;
            this.removeAsterick = false;
            this.getAllSuppliersList();
        }

    }


  
    createManualImpactDataForm() {
        this.manualImpactDataForm = this.fb.group({
            supplier: ['', Validators.required],
            country: ['', Validators.required],
            min_delay: ['', Validators.required],
            max_delay: ['', Validators.required],
            start_date: ['', Validators.required],
            end_date: [''],
        });
    }

    setData() {
        
        this.suppliersList.push(this.data.formData.value.supplier);
        this.countriesList.push(this.data.formData.value.country);
        this.manualImpactDataForm.patchValue({
            supplier: this.suppliersList,
            country: this.countriesList,
            min_delay: this.data.formData.value['Min_Delay'],
            max_delay: this.data.formData.value['Max_Delay'],
            start_date: new Date(this.data.formData.value['Impact_Start_Date']),
        });

        if(this.data.formData.value['Impact_End_Date']){
            this.manualImpactDataForm.patchValue({
                end_date: new Date(this.data.formData.value['Impact_End_Date']),
            });
        }else{
            this.manualImpactDataForm.patchValue({
                end_date: '',
            });
        }

        for (const data in this.countriesList) {
            this.manualImpactDataForm.controls['country'].setValue(this.countriesList[data]);
        }

        this.manualImpactDataForm.controls.supplier.disable();
        this.manualImpactDataForm.controls.country.disable();
        this.checkForm();
        this.dateCheck();

    }

    close() {
        this.dialogRef.close();
        // this.getAllSuppliersList();
    }

    cleardata() {
        if(this.data.action == 'Update'){
            this.manualImpactDataForm.controls.min_delay.reset();
            this.manualImpactDataForm.controls.max_delay.reset();
            this.manualImpactDataForm.controls.start_date.reset();
            this.manualImpactDataForm.controls.end_date.reset()
        }else{
            this.manualImpactDataForm.reset();
            this.manualImpactDataForm.controls.supplier.enable();
            this.manualImpactDataForm.controls.country.enable();
            this.recordExistMsg = ''
            this.getAllSuppliersList();
        }
        // this.minMaxErrorMsg = "",
        this.dateErrorMsg = ""

        this.minErrorMsg = '';
        this.maxErrorMsg = "";

        

    }

    

    getSelectedCountry(country) {
       
        if( this.manualImpactDataForm.controls.country.value && !this.manualImpactDataForm.controls.supplier.value){
            this.countryFirst = true;
        }else{
            this.countryFirst = false;
            this.suppliersList = false;
        }

        for (const data in this.allSuppliersandCountrieData) {
            if (this.allSuppliersandCountrieData[data] === country) {
               
                this.sameCountrySuppliersList.push(data);
            }
        }
        this.suppliersList = this.sameCountrySuppliersList;

        this.manualImpactDataForm.controls.supplier.disable();
        this.manualImpactDataForm.controls.country.disable();
       
    }

    getSelectedSupplier(supplier) {
        
        if(this.manualImpactDataForm.controls.supplier.value && !this.manualImpactDataForm.controls.country.value){
            this.supplierFirst = true;
        }else{
            this.supplierFirst = false;
            this.countryFirst = false;
        }

        this.suppliersList = []
        this.suppliersList.push(supplier);
        for (const data in this.allSuppliersandCountrieData) {
            
            if (supplier === data) {
                this.countryOfSelectedSupplier.push(this.allSuppliersandCountrieData[data]);
            }
        }
        this.countriesList = this.countryOfSelectedSupplier;
        for (const data in this.countriesList) {
            this.manualImpactDataForm.controls['country'].setValue(this.countriesList[data]);
        }

        this.manualImpactDataForm.controls.country.disable();
        this.manualImpactDataForm.controls.supplier.disable();
       
    }

    dateCheck() {

        const startdate = this.manualImpactDataForm.value.start_date;
        const enddate = this.manualImpactDataForm.value.end_date;
        const today = new Date();
        const tday = today.getDate();
        const tmonth = today.getMonth() + 1;
        const tyear = today.getFullYear();
        let today_day, today_month;
        if (Number(tday) <= 9) {
            today_day = "0" + Number(tday);
        } else {
            today_day = tday;
        }
        if (Number(today_month) <= 9) {
            today_month = "0" + Number(tmonth);
        } else {
            today_month = tmonth;
        }
        const date = tyear + "-" + today_month + "-" + today_day;
        const sd = new Date(this.manualImpactDataForm.value.start_date);
        const sday = sd.getDate();
        const smonth = sd.getMonth() + 1;
        const syear = sd.getFullYear();
        let day, month;
        if (Number(sday) <= 9) {
            day = "0" + Number(sday);
        } else {
            day = sday;
        }
        if (Number(smonth) <= 9) {
            month = "0" + Number(smonth);
        } else {
            month = smonth;
        }
        this.stDate = syear + "-" + month + "-" + day;
        if(this.manualImpactDataForm.value.end_date){
            const ed = new Date(this.manualImpactDataForm.value.end_date);
            const exday = ed.getDate();
            const exmonth = ed.getMonth() + 1;
            const exyear = ed.getFullYear();
            let edy, emth;
            if (Number(exday) <= 9) {
                edy = "0" + Number(exday);
            } else {
                edy = exday;
            }
            if (Number(exmonth) <= 9) {
                emth = "0" + Number(exmonth);
            } else {
                emth = exmonth;
            }
            this.exDate = exyear + "-" + emth + "-" + edy;
        }else{
            this.exDate = '';
        }
        

        if(enddate && date){
            if(  Date.parse(enddate) < Date.parse(date) || Date.parse(enddate) == Date.parse(date) ){
                this.currentDateGreater = true;
                this.dateErrorMsg = "End date should be greater than current date"
            }else{
                this.currentDateGreater = false;
                // this.dateErrorMsg = ""
            }
        }

        if (startdate && enddate) {
            if (Date.parse(startdate) > Date.parse(enddate)) {
                this.validDate = false;
                this.validDateMsg = true;
                this.equalDates = false;
                this.dateErrorMsg = "End date should be greater than current date"
            }
            else {
                this.validDate = true;
                this.validDateMsg = false;
                // this.dateErrorMsg = ""
            }

            if (Date.parse(startdate) == Date.parse(enddate)) {
                this.validDateMsg = false;
                this.equalDates = true;
                this.currentDateGreater = false;
                this.dateErrorMsg = "End date should be greater than current date"
            }
            else {
                this.equalDates = false;
            }
        }
    }



    getAllSuppliersList() {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
     
        this.adminService.getAllSuppliersList(data).subscribe(res => {
            this.loader = false;
            this.allSuppliersandCountrieData = res;

            this.suppliersList = Object.keys(res);
            this.countriesList = Object.values(res);


            this.removedDupSup = this.countriesList.filter((value, index) => this.countriesList.indexOf(value) === index);
            this.countriesList = this.removedDupSup;


        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            console.log(error);
        });
    }
    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }
    myFunction(suppliersList) {
        JSON.stringify(suppliersList)
        const arr: any = [];
        for (const i in suppliersList) {
            const sl =  JSON.stringify(suppliersList[i]);
            // "'" + suppliersList[i] + "'";
            arr.push(sl);
        }
        return arr;
    }

    addManualImpactData(){
        this.loader = true;
        const authtoken = {
            'Authorization': this.authtoken
        };
        const userDetails: any = JSON.parse(localStorage.getItem('userDetails'));
     
        const sl = this.myFunction(this.suppliersList);
        const supList = '[' + sl + ']';
        const email = userDetails['email'];
        const formData = new FormData();

        if (!this.impact_id) {
            formData.append('impact_type', this.data.impact_type);
            formData.append('suppliers', supList);
            formData.append('country', this.manualImpactDataForm.controls.country.value);
            formData.append('impact_start_date', this.stDate);
            formData.append('impact_end_date', this.exDate);
        } else {
            formData.append('impact_id', this.impact_id);
            formData.append('impact_start_date', this.stDate);
            formData.append('impact_end_date', this.exDate);
            // formData.append('impact_start_date', this.data.formData.value['Impact_Start_Date']);
            // formData.append('impact_end_date', this.data.formData.value['Impact_End_Date']);

        }
        formData.append('min_delay', this.manualImpactDataForm.controls.min_delay.value);
        formData.append('max_delay', this.manualImpactDataForm.controls.max_delay.value);
        formData.append('uploaded_by', email);



        this.addManualImpactDataService.createorEditManualdataDetails(authtoken, formData, this.impact_id).subscribe((res) => {
            this.loader = false;
            if (res === 'Manual Impact data saved' && !this.impact_id) {
                this.recordExistMsg = "Manual Impact data saved";
                this.dialogRef.close(false);
                this.loader = false;
            } else if (!this.impact_id) {
                if(this.supplierFirst){
                    this.recordExistMsg = "Record already exist";
                }else if(this.countryFirst){
                    this.recordExistMsg = "Some records already exist";
                }
            } else {
                this.dialogRef.close(false);
            }

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
           
        });
    }



    deleteManualImpactRecord(){
        this.loader = true;
        const authtoken = {
            'Authorization': this.authtoken
        };
        const formData = new FormData();
        formData.append('impact_id', this.impact_id);
        this.addManualImpactDataService.deleteManualImpactRecord(authtoken, formData).subscribe(() => {
            this.loader = false;
            this.dialogRef.close(false);
        }, (error) => {
            console.log("error", error);
            
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
               
            // }
        });
    }

 

    checkForm(){
        // const re = /^[0-9]$/;
        // this.minDelayCorrect = re.test(parseInt(this.manualImpactDataForm.controls.min_delay.value));
        // const minCharcode = this.manualImpactDataForm.controls.min_delay.value.charCode
        // if(minCharcode >= 65 || minCharcode <= 90 ){
        //     this.mindelayInputWrong = true;
        // }
       
        const minDelay = parseInt(this.manualImpactDataForm.controls.min_delay.value);
        const maxDelay = parseInt(this.manualImpactDataForm.controls.max_delay.value)


        if((minDelay && maxDelay) && minDelay > maxDelay){
           
            this.minErrorMsg = "Min delay should be less than Max delay";
        }else if((minDelay && maxDelay) && minDelay == maxDelay){
          
            this.minErrorMsg = "Min delay and Max delay should not be equal";
        }else if(minDelay && minDelay > 98 && (maxDelay && maxDelay > 99)){
            this.minErrorMsg = "Min delay should be numeric and <= 98";
            this.maxErrorMsg = "Max delay should be numeric and <= 99";
        }
        else if(minDelay && minDelay > 98){
          
            this.minErrorMsg = "Min delay should be numeric and <= 98";
        }else if(maxDelay && maxDelay > 99){
          
            this.maxErrorMsg = "Max delay should be numeric and <= 99";
        }else{
            this.minErrorMsg = '';
            this.maxErrorMsg = "";
        }

        // if(minDelay.toString().length > 2){
        //     this.mindelayInputWrong = 'greater';
        // } else{

        // if(minDelay && maxDelay){
        //     if(minDelay > maxDelay){
        //         this.minDelayGreater = true;
        //         this.minMaxErrorMsg = "Min delay should be numeric and <= 98"
        //     }else if(minDelay == maxDelay || (maxDelay == 99 && minDelay == 99) ){
        //         this.minDelayGreater = true;
        //         this.minMaxErrorMsg = "Min delay should be numeric and <= 98"
        //     }else if(minDelay >=99  || (maxDelay > 99) ){
        //         this.minDelayGreater = true;
        //         this.minMaxErrorMsg = "Min delay should be numeric and <= 98"
        //     }
        //     else{
        //         this.minDelayGreater = false;
        //         this.minMaxErrorMsg = ""
        //     }
        // }else if(minDelay){
        //     if(minDelay >= 99){
        //         this.minDelayGreater = true;
        //         this.minMaxErrorMsg = "Min delay should be numeric and <= 98"
        //     }
        // }else if(maxDelay){
        //     if(maxDelay > 99){
        //         this.maxDelayGreater = true;
        //         this.minMaxErrorMsg = "Max delay should be numeric and <= 99"
        //     }
        // }
        // else{
        //     this.minDelayGreater = false;
        //     this.minMaxErrorMsg = ""
        // }

        // // }
        
       
      
    }
}
