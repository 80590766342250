import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RequestDialogService {

    constructor(public afAuth: AngularFireAuth,private http: HttpClient) { }


    resetPassword(email) {
        const promise = new Promise((resolve, reject) => {
            this.afAuth.sendPasswordResetEmail(email)
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
        return promise;
    }

    confirmPassword(code,password){

        const promise = new Promise((resolve, reject) => {
            this.afAuth.confirmPasswordReset(code, password)
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    err => { // Error
                        reject(err);
                    }
                );
        });
        return promise;
    }


    submitNewPassword(apiKey, data) : Observable<any>{
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization':  'Bearer' + ' ' + data.authtoken,
                'Content-Type':  'application/json'
            })
        }
        return this.http.post(`${"https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword?key="+ apiKey}`,data, httpOptions);

    }



}
