import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class SessionManagementServiceService {
    basepath = environment.basePath;
    constructor(private http: HttpClient) { }

    startSession(userData: any): Observable<any> {
        console.log("in start session service");
        return this.http.post<any>(`${this.basepath + 'api/profile/initSession'}`, userData).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));
    }


    endSession(): Observable<any> {

        return this.http.get<any>(`${this.basepath + 'api/profile/terminateSession'}`);
    }
    getUserDetails(): Observable<any> {
        const httpOptions = {
            withCredentials: true,
            observe: 'response' as 'response'
        };
        return this.http.get<any>(`https://supplierwatch-f055c.appspot.com/api/profile/test_session_cookie`, httpOptions);
    }
}
