import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class RefreshTokenService {

    constructor(private http: HttpClient) { }

    refreshToken(authtoken: any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                // 'Authorization': 'Bearer' + ' ' + authtoken,
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'grant_type': 'refresh_token=' + ' ' + authtoken
                // 'grant_type': 'refresh_token&refresh_token=' + ' ' + authtoken
            })
        }
        const data: any = 'grant_type=refresh_token&refresh_token='+authtoken;
    
        return this.http.post<any>(`${'https://securetoken.googleapis.com/v1/token?key=AIzaSyBXKsEQmMKoSTWW6Ed_Mlss6YfeATmE-1I'}` , data, httpOptions).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));
    
    }
}
