import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class AddManualImpactDataModalService {
    manualImpactBasePath = environment.basePath;
    // environment.manualImpactBasePath;
    constructor(private http: HttpClient) { }

    createorEditManualdataDetails(data: any, userData: any, id: any): Observable<any> {
    // let httpOptions = {
        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + ' ' + data.Authorization,
        })
        // };
        if(!id){
            return this.http.post<any>(`${this.manualImpactBasePath + 'api/app/manual_impact_catalog'}`, userData,{headers, responseType: 'text' as 'json' }).pipe(map((data: HttpResponse<any>) => {
                return data;
            }));
        }else{
            return this.http.put<any>(`${this.manualImpactBasePath + 'api/app/manual_impact_catalog'}`, userData, {headers, responseType: 'text' as 'json' }).pipe(map((data: HttpResponse<any>) => {
                return data;
            }));
        }
       

    }

    getManualImpactDataList(data: any, impactType: any){
        const headers = new HttpHeaders({
            'Authorization': 'Bearer' + ' ' + data.Authorization,
        })
        return this.http.get<any>(`${this.manualImpactBasePath + 'api/app/manual_impact_catalog'}`,
                                  {
                                      params: {impact_type:impactType}, headers, responseType: 'json'
                                  })
    }


    deleteManualImpactRecord(data: any, impact_id): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' +data.Authorization,
            }),
            body: impact_id
        }
        return this.http.delete<any>(`${this.manualImpactBasePath + 'api/app/manual_impact_catalog'}`, httpOptions);
    }
}
