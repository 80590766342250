import {Injectable} from '@angular/core';
import 'firebase/database';

@Injectable({
    providedIn: 'root'
})

export class StratizantFireStoreService {


    constructor() {
    }

    // async getPartsData() {
    //   const snapshot = await this.firebaseServices.db.collection('/parts').get();
    //   return snapshot.docChanges().map(doc => {
    //     return {id: doc.doc.id, ...doc.doc.data()};
    //   });
    // }

    // getPartNames(partData) {
    //   const partNames = [];
    //   for (const index in Object.keys(partData)) {
    //     if (partData[index]) {
    //       partNames.push(partData[index].id);
    //     }
    //   }
    //   return partNames;
    // }

    // async getDates(partNumber) {

    //   const snapshot = await this.firebaseServices.db.collection('/parts/' + partNumber + '/dates').get();
    //   return snapshot.docChanges().map(doc => {
    //     return {id: doc.doc.id, ...doc.doc.data()};
    //   });
    // }

    // async getTrustScore() {
    //   const snapshot = await this.firebaseServices.db.collection('/supplier_prediction').get();
    //   return snapshot.docChanges().map(doc => {
    //     return {id: doc.doc.id, ...doc.doc.data()};
    //   });
    // }

}
