import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { SubscrioptionLevelService } from '../../admin-settings/subscription-level/subscrioption-level.service';
import { Router } from '@angular/router';
import { UserProfileService } from '../user-profile.service';
import { DeletesubscriptionModalComponent } from './deletesubscription-modal/deletesubscription-modal.component';
import { UserManagementService } from '../../admin-settings/user-management/user-management.service';
import { CookieService } from 'ngx-cookie-service';



@Component({
    selector: 'app-plan-details',
    templateUrl: './plan-details.component.html',
    styleUrls: ['./plan-details.component.css']
})
export class PlanDetailsComponent implements OnInit {
    currentPeriodEndDate: any;
    loader = false;
    take_off_subscription_level: any;
    initiator_subscription_level: any;
    enterprise_subscription_level: any;

    initiatorUserCnt: any;
    takeoffUserCnt: any;
    enterPriseUserCnt: any;

    initiator_prediction_file_count_limit: any;
    takeoff_prediction_file_count_limit: any;
    enterprise_prediction_file_count_limit: any;

    initiator_total_file_storage_space: any;
    takeoff_total_file_storage_space: any;
    enterprise_total_file_storage_space: any;

    initiator_configAdmin_Cost: any;
    takeoff_configAdmin_Cost: any;
    enterprise_configAdmin_Cost: any;


    initiator_dashboard_Cost: any;
    takeoff_dashboard_Cost: any;
    enterprise_dashboard_Cost: any;


    initiator_configAdmin_User_Cnt: any;
    initiator_dashboard_User_Cnt: any;

    takeoff_configAdmin_User_Cnt: any;
    takeoff_dashboard_User_Cnt: any;

    enterprise_configAdmin_User_Cnt: any;
    enterprise_dashboard_User_Cnt: any;
    enterprise_dashboard_user_count: any;

    initiatorTodaysTotal: any;
    takeoffTodaysTotal: any;
    enterPriseTodaysTotal: any

    todaysTotal: any;

    takeoff_dashboard_user_count: any;
    initiator_dashboard_user_count: any;

    roleType: any;
    planName: any;
    authtoken: any;
    organisationId: any;
    subscriptionId: any;

    purchasedDate: any;
    planRenewalDate: boolean;
    planCancelDate: boolean;
    email: any;

    pCancelDate: any;
    pRenewalDate: any;
    organizations: any;
    notsubscribedMsg: boolean;
    allowChangeSubscription: boolean;

    planPurchasedAmt: any;
    enrolledPeriod: any;


    @Output() sendPlanDetails = new EventEmitter<object>();
    lastloginatTimeDifference: any;


    constructor(private fbAuth: StratizantFirebaseAuthService, private matDialog: MatDialog,
                private subscriptionLevelService: SubscrioptionLevelService,
                private router: Router,
                private cookieServiceVariable: CookieService,
                private userProfileService: UserProfileService,
                private userService: UserManagementService

    ) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken =  this.cookieServiceVariable.get('auth_token_1');
        this.organisationId = localStorage.getItem('organisation_id');
        this.getSubscriptionLevelDetails();
        // this.roleType = localStorage.getItem('roleType');
        this.getStripeSubscriptionDetails();
        // location.reload();
       
        // this.getAllUsersList();
        
    }



    getSubscriptionLevelDetails() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.subscriptionLevelService.getSubscriptionLevelDetails(obj).subscribe(res => {
            this.loader = false;
            const subscribtiondetails = Object.entries(res).map(([type, value]) => ({ type, value }));
            for (const subindex in subscribtiondetails) {

                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Initiator") {
                    this.initiator_subscription_level = subscribtiondetails[subindex];
                    this.setInitiatorSubscriptionLevelValue();

                }
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Take-Off") {
                    this.take_off_subscription_level = subscribtiondetails[subindex];
                    this.setTakeOffSubscriptionLevelValue();
                }


                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Enterprise") {
                    this.enterprise_subscription_level = subscribtiondetails[subindex];
                    this.setEnterpriseSubscriptionLevelValue();
                }

            }

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


    setInitiatorSubscriptionLevelValue() {

        this.initiator_configAdmin_User_Cnt = parseInt(this.initiator_subscription_level.value.config_admin_user_count);
        this.initiator_dashboard_User_Cnt = parseInt(this.initiator_subscription_level.value.dashboard_user_count);


        this.initiator_dashboard_user_count = parseInt(this.initiator_subscription_level.value.dashboard_user_count);

        this.initiatorUserCnt = parseInt(this.initiator_subscription_level.value.dashboard_user_count) + parseInt(this.initiator_subscription_level.value.config_admin_user_count);

        this.initiator_prediction_file_count_limit = parseInt(this.initiator_subscription_level.value.prediction_file_count_limit);
        this.initiator_total_file_storage_space = parseInt(this.initiator_subscription_level.value.total_file_storage_space) / 1000;

        this.initiator_configAdmin_Cost = parseInt(this.initiator_subscription_level.value.config_admin_user_cost);
        this.initiator_dashboard_Cost = parseInt(this.initiator_subscription_level.value.dashboard_user_cost);

        this.initiatorTodaysTotal = (parseInt(this.initiator_subscription_level.value.dashboard_user_count) * parseInt(this.initiator_subscription_level.value.dashboard_user_cost)) +
            (parseInt(this.initiator_subscription_level.value.config_admin_user_count) * parseInt(this.initiator_subscription_level.value.config_admin_user_cost))
        this.todaysTotal = this.initiatorTodaysTotal;
    }

    setTakeOffSubscriptionLevelValue() {

        this.takeoff_configAdmin_User_Cnt = parseInt(this.take_off_subscription_level.value.config_admin_user_count);
        this.takeoff_dashboard_User_Cnt = parseInt(this.take_off_subscription_level.value.dashboard_user_count);

        this.takeoff_dashboard_user_count = parseInt(this.take_off_subscription_level.value.dashboard_user_count);

        this.takeoffUserCnt = parseInt(this.take_off_subscription_level.value.dashboard_user_count) + parseInt(this.take_off_subscription_level.value.config_admin_user_count);
        this.takeoff_prediction_file_count_limit = parseInt(this.take_off_subscription_level.value.prediction_file_count_limit);
        this.takeoff_total_file_storage_space = parseInt(this.take_off_subscription_level.value.total_file_storage_space) / 1000;

        this.takeoff_configAdmin_Cost = parseInt(this.take_off_subscription_level.value.config_admin_user_cost);
        this.takeoff_dashboard_Cost = parseInt(this.take_off_subscription_level.value.dashboard_user_cost);

        this.takeoffTodaysTotal = (parseInt(this.take_off_subscription_level.value.dashboard_user_count) * parseInt(this.take_off_subscription_level.value.dashboard_user_cost)) +
            (parseInt(this.take_off_subscription_level.value.config_admin_user_count) * parseInt(this.take_off_subscription_level.value.config_admin_user_cost))
        this.todaysTotal = this.takeoffTodaysTotal;
    }
    setEnterpriseSubscriptionLevelValue() {
        const additionalEnterPriseUser = localStorage.getItem('additionalEnterPriseUser');
        this.enterprise_configAdmin_User_Cnt = parseInt(this.enterprise_subscription_level.value.config_admin_user_count);
        this.enterprise_dashboard_User_Cnt = parseInt(this.enterprise_subscription_level.value.dashboard_user_count);

        this.enterprise_dashboard_user_count = parseInt(this.enterprise_subscription_level.value.dashboard_user_count);
        if(additionalEnterPriseUser){
            this.enterPriseUserCnt = parseInt(additionalEnterPriseUser) + parseInt(this.enterprise_subscription_level.value.dashboard_user_count) + parseInt(this.enterprise_subscription_level.value.config_admin_user_count);
           
        }else {
            this.enterPriseUserCnt = parseInt(this.enterprise_subscription_level.value.dashboard_user_count) + parseInt(this.enterprise_subscription_level.value.config_admin_user_count);
        }
        
        this.enterprise_prediction_file_count_limit = parseInt(this.enterprise_subscription_level.value.prediction_file_count_limit);
        this.enterprise_total_file_storage_space = parseInt(this.enterprise_subscription_level.value.total_file_storage_space) / 1000;

        this.enterprise_configAdmin_Cost = parseInt(this.enterprise_subscription_level.value.config_admin_user_cost);
        this.enterprise_dashboard_Cost = parseInt(this.enterprise_subscription_level.value.dashboard_user_cost);

        this.enterPriseTodaysTotal = (parseInt(this.enterprise_subscription_level.value.dashboard_user_count) * parseInt(this.enterprise_subscription_level.value.dashboard_user_cost)) +
            (parseInt(this.enterprise_subscription_level.value.config_admin_user_count) * parseInt(this.enterprise_subscription_level.value.config_admin_user_cost))
        this.todaysTotal = this.enterPriseTodaysTotal;
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }

  

    // goToChangeOrCancelSubscription() {
    //     this.router.navigate(['/home/changeSubscription']);
    // }

    goToChangeOrCancelSubscription(plan:any) {
        this.router.navigate(['home/changeSubscription/', plan]);
    }

    getStripeSubscriptionDetails() {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.planName = '';
        this.userProfileService.getStripeSubscriptionDetails(data).subscribe(res => {
            this.loader = false;
            const planDetails = [];
            console.log("this.orgid", this.organisationId);
            for (const row in res.data) {
                if (res.data) {
                    if (res.data[row]['metadata']['organisation_id'] == this.organisationId) {
                        planDetails.push(res.data[row]);
                        
                        console.log("planorgid", res.data[row]['metadata']['organisation_id']);
                        this.currentPeriodEndDate = res.data[row]['current_period_end'];
                        console.log("currentPeriodEndDate",this.currentPeriodEndDate);
                        this.currentPeriodEndDate = res.data[row]['current_period_end'];
                        this.planPurchasedAmt = planDetails[0]['metadata']['amount'];
                        if(planDetails[0]['metadata']['interval'] == 'day'){
                            this.enrolledPeriod = 'daily';
                        }else if(planDetails[0]['metadata']['interval'] == 'month'){
                            this.enrolledPeriod = 'monthly'
                        }
                    }
                }

            }

            if(planDetails.length == 0){
                this.notsubscribedMsg = true;
            }else{
                this.notsubscribedMsg = false;
            }

            for (const row in planDetails) {
                if (planDetails[row]['cancel_at_period_end'] === false) {
                    this.planRenewalDate = true;
                    this.planCancelDate = false
                    this.pRenewalDate = new Date(planDetails[row]['current_period_end'] * 1000).toDateString();
                } else {
                    this.planRenewalDate = false;
                    this.planCancelDate = true;
                    this.pCancelDate = new Date(planDetails[row]['current_period_end'] * 1000).toDateString();
                }

                this.purchasedDate = this.convertTimeStampIntoDate(planDetails[row]['created']);
                this.email =  planDetails[row]['metadata']['email'];
                this.planName = planDetails[row]['metadata']['plan_name'];
                this.subscriptionId = planDetails[row]['id'];
                const planData = {
                    'plan_name': this.planName,
                    'purchase_date': this.purchasedDate
                }
                this.sendPlanDetails.emit(planData);
                localStorage.setItem('Planname', this.planName);
                localStorage.setItem('payment_method_id',planDetails[row]['metadata']['payment_method_id']);
                // const ts_ms = ts * 1000;
                // const date_ob = new Date(ts_ms);
                // const year = date_ob.getFullYear();
                // const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
                // const date = ("0" + date_ob.getDate()).slice(-2);
                // this.purchasedDate =  month + "/" + date + "/" + year;
                localStorage.setItem('purchasedDate', this.purchasedDate);
                this.getAllUsersList();
               
            }

        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    convertTimeStampIntoDate(ts) {
        const ts_ms = ts * 1000;
        const date_ob = new Date(ts_ms);
        const year = date_ob.getFullYear();
        const month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
        const date = ("0" + date_ob.getDate()).slice(-2);
        const convertedDate = month + "/" + date + "/" + year;
        return convertedDate;
    }

    cancelSubscription() {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            'id': this.subscriptionId,
            'currentPeriodEndDate':this.currentPeriodEndDate
        }
        const dialogRef = this.matDialog.open(DeletesubscriptionModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((res) => {
            this.getStripeSubscriptionDetails();
        });

    }
    getAllUsersList() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.userService.getAllUsersList(obj).subscribe(res => {
            if(Object.keys(res).length > 0){
                const arr = Object.entries(res).map(([type, value]) => ({type,value}));
                const email = localStorage.getItem('user');

                for (const row in arr) {
                    if (arr[row]['value']['organisation_id'] == this.organisationId && arr[row]['value']['role_type'] == 'Config Admin User' && email == arr[row]['value']['email']) {
                        this.allowChangeSubscription = true;
                        break;
                    } else {
                        this.allowChangeSubscription = false;
                    }
                }
            }else{
                this.loader = false;
            }
            this.loader = false;
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        })
    }

   
}
