import { Component, OnInit } from '@angular/core';
import * as c3 from 'c3';
import { ActivatedRoute, Router } from '@angular/router';
import { SupplierDataService } from 'src/app/services/supplier-data.service';
import { SupplierTrendChartService } from '../../supplier-trend-chart/supplier-trend-chart.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
declare let $: any;
import { cloneDeep } from "lodash";

@Component({
    selector: 'app-supplier-detail',
    templateUrl: './supplier-detail.component.html',
    styleUrls: ['./supplier-detail.component.css']
})
export class SupplierDetailComponent implements OnInit {
    showChart: boolean = true;
    supplierName: any;
    suppliers: any;
    fitValue: boolean;
    noRecordsFoundMsg: boolean;
    supplierTrendsData: any;
    periods: any = [];
    trustScores: any = [];
    impactedTrustScores: any = [];
    pipe = new DatePipe('en-US');
    loader: boolean = false;
    parts: any = [];
    scoresByItemList: any = [];
    supDetailloader = false;
    supplierData: any;
    impactToggleForm: FormGroup;
    impact_type: any;
    imageToShow: any;
    sortedData = [];
    scoreTableloader: boolean = false;
    selectedPeriod: string;
    partsData =[];
    showBreakdown = [];
    itemList = [];
    pageOfItems: number;
    authtoken: any;
    durationDate: string;
    chartList = [];
    dataObj = {};
    chartData = [];
    noRecordsMsg: string;
    currentImpactedScore: any;
    currentTrustScore: any;
    is_default: any;
    getSupplierTrendsDuration: any;
    getAllSuppliersDataSelectedPeriod: any;
    getAllSuppliersDataFlag: any;
    lastloginatTimeDifference: any;
    constructor(private route: ActivatedRoute, private supplierService: SupplierDataService,
                private supplierTrendService: SupplierTrendChartService, private fb: FormBuilder,
                private cookieServiceVariable: CookieService,
                private router: Router, private afStorage: AngularFireStorage,private matDialog: MatDialog,
                private firebaseAuthService: StratizantFirebaseAuthService) { }

    ngOnInit() {
        $('#currentChart').addClass('selected');
        $('#trendsChart').removeClass('selected');
        $('#currentChartMd').addClass('selected');
        $('#trendsChartMd').removeClass('selected');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.route.params.subscribe(params => {
            this.supplierName = params['supplierName'];
            this.loader = true;
            this.supDetailloader =true;
            this.impactToggleForm = this.fb.group({
                impact: [true],
            });
            this.getAllSuppliers();
            this.getAllSuppliersData('1D', 'from_init');
            this.selectedPeriod = '1D';
        });
       
    }

  

    toggleCharts(val){
        this.supDetailloader = true;
        this.itemList = [];
        this.showBreakdown = [];
        if(val == 'bar'){
            this.showChart = true;
            $('#currentChart').addClass('selected');
            $('#trendsChart').removeClass('selected');
            $('#currentChartMd').addClass('selected');
            $('#trendsChartMd').removeClass('selected');
            $('#1D').addClass('active');
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
            this.getAllSuppliersData('1D', '');
            this.selectedPeriod = '1D';
        }else{
            this.showChart = false;
            $('#currentChart').removeClass('selected');
            $('#trendsChart').addClass('selected');

            $('#currentChartMd').removeClass('selected');
            $('#trendsChartMd').addClass('selected');

            $('#1D').removeClass('active');
            $('#1M').addClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
            this.getSupplierTrends('1M');
        }

    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stopLoader(val){
        this.supDetailloader = false;
    }

    getAllSuppliersData(selectedPeriod, flag) {
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };
        //this.loader = true;
        this.getAllSuppliersDataSelectedPeriod = selectedPeriod;
        this.getAllSuppliersDataFlag = flag;

        this.supplierService.getSupplierDataByDuration(data, selectedPeriod).subscribe(response => {
            //this.loader = false;
            this.supDetailloader = false;
            const newData = [];
            const finalData = [];
            this.parts = [];
            this.trustScores = [];
            this.impactedTrustScores = [];
            this.parts[0] = 'x';
            this.trustScores[0] = 'Trust Score';
            this.impactedTrustScores[0] = 'Impacted Trust Score';
            this.scoresByItemList = [];
            this.is_default = response['is_default'];
            const conv_array = Object.entries(response['prediction_summary']).map(([type, value]) => ({ type, value }));
            if(conv_array.length != 0) {
                const timestamp = conv_array[0]['value']['timestamp'];
                const dateString = new Date(timestamp).toUTCString();
                this.durationDate = dateString.split(' ').slice(0, 4).join(' ');
            } else {
                this.durationDate = '';
            }
            // const imp_cnt = 0;
            
            if(Object.keys(response['prediction_summary']).length != 0){
                this.noRecordsMsg = '';
                let new_array = Object.entries(response['prediction_summary']).map(([type, value]) => ({ type, value }));
                new_array = new_array.filter(res => res['value']['Supplier'] === this.supplierName);
                const clonedObject = cloneDeep(new_array);
                for (const sup in clonedObject) {
                    let cnt = 0;
                    //if (clonedObject[sup]['Supplier'] == this.supplierName) {
                    this.impact_type = clonedObject[sup]['value']['Impact_Type'][0];
                        
                    if (newData.length != 0) {
                        for (const key in newData) {
                            if (newData[key]['value']['Material'] == clonedObject[sup]['value']['Material']) {
                                newData[key]['total_count'] = newData[key]['total_count'] + 1;
                                newData[key]['value']['Trust_Score'] = newData[key]['value']['Trust_Score'] + clonedObject[sup]['value']['Trust_Score'];
                                if (newData[key]['value']['Impacted_Delivery_Date'] || newData[key]['value']['Impacted_Trust_Score']) {
                                    newData[key]['impact_total_count'] = newData[key]['impact_total_count'] + 1;
                                    newData[key]['value']['Impacted_Trust_Score'] = newData[key]['value']['Impacted_Trust_Score'] + clonedObject[sup]['value']['Impacted_Trust_Score'];
                                }
                            } else {
                                cnt++;
                            }
                            if (cnt == newData.length) {
                                newData.push(clonedObject[sup]);
                                newData[newData.length - 1]['total_count'] = 1;
                                this.parts.push(clonedObject[sup]['value']['Material']);
                                newData[newData.length - 1]['impact_total_count'] = 1;
                            }
                        }
                    } else {
                        newData.push(clonedObject[sup]);
                        newData[0]['total_count'] = 1;
                        this.parts.push(clonedObject[sup]['value']['Material']);
                        if (clonedObject[sup]['value']['Impacted_Delivery_Date'] || clonedObject[sup]['value']['Impacted_Trust_Score']) {
                            newData[0]['impact_total_count'] = 1;
                        }
                    }   
    
                    //}

                }
                const clonedObject1 = cloneDeep(response['prediction_summary']);
                for (const sup in clonedObject1) {

                    if (finalData.length != 0) {
                        //let cnt = 0;
                        for (const dt in finalData) {
    
                            if (finalData[dt]['Supplier'] == clonedObject1[sup]['Supplier']) {
                                finalData[dt]['total_count'] = finalData[dt]['total_count'] + 1;
                                finalData[dt]['Trust_Score'] = finalData[dt]['Trust_Score'] + clonedObject1[sup]['Trust_Score'];
                                if (finalData[dt]['Impacted_Delivery_date'] || finalData[dt]['Impacted_Trust_Score']) {
    
                                    finalData[dt]['impact_total_count'] = finalData[dt]['impact_total_count'] + 1;
                                    finalData[dt]['Impacted_Trust_Score'] = finalData[dt]['Impacted_Trust_Score'] + clonedObject1[sup]['Impacted_Trust_Score'];
                                    // this.impact_type = finalData[dt]['Impact Type'];
                                    //this.showColumns = true;
                                }
    
                                break;
                            } 
                            // else {
                            //     cnt++;
                            // }
    
                            // if (cnt == finalData.length) {
                            //     finalData.push(clonedObject1[sup]);
                            //     finalData[finalData.length - 1]['total_count'] = 1;
                            //     finalData[finalData.length - 1]['impact_total_count'] = 1;
                            // }
    
                        }
                    } else {
                        if(clonedObject1[sup]['Supplier'] == this.supplierName){
                            finalData.push(clonedObject1[sup]);
                            finalData[0]['total_count'] = 1;
                            if (clonedObject1[sup]['Impacted_Delivery_date'] || clonedObject1[sup]['Impacted_Trust_Score'] ) {
                                finalData[0]['impact_total_count'] = 1;
                            }
                        }
                        
                    }
                }

                const clonedObject2 = cloneDeep(response['prediction_summary']);
                for (const sup in clonedObject2) {
                    if (clonedObject2[sup]['Supplier'] == this.supplierName) {
                        this.scoresByItemList.push(clonedObject2[sup]);
                    }
                }

                for (const j in newData) {
                    // newData[j]['Trust_Score'] = newData[j]['Trust_Score'];
                    newData[j]['value']['Trust_Score'] = (parseInt(newData[j]['value']['Trust_Score'])) / (newData[j]['total_count']);
                    newData[j]['value']['Trust_Score'] = JSON.stringify(Math.round((newData[j]['value']['Trust_Score'])));
                    // newData[j]['Trust_Score'] = newData[j]['Trust_Score'] +'%'
                    this.trustScores.push(newData[j]['value']['Trust_Score']);
        
                    newData[j]['value']['Impacted_Trust_Score'] = parseInt(newData[j]['value']['Impacted_Trust_Score']) / newData[j]['impact_total_count'];
                    newData[j]['value']['Impacted_Trust_Score'] = JSON.stringify(Math.round((newData[j]['value']['Impacted_Trust_Score'])));
                    this.impactedTrustScores.push(newData[j]['value']['Impacted_Trust_Score']);
        
                }
    
                for (const j in finalData) {
                    finalData[j]['Trust_Score'] = finalData[j]['Trust_Score'] / finalData[j]['total_count'];
                    finalData[j]['Trust_Score'] = JSON.stringify(Math.round(finalData[j]['Trust_Score']));
                    // eslint-disable-next-line no-self-assign
                    finalData[j]['Trust_Score']= finalData[j]['Trust_Score'];
    
                    if (finalData[j]['Impacted_Trust_Score'] ) {
                        finalData[j]['Impacted_Trust_Score']  = finalData[j]['Impacted_Trust_Score']  / finalData[j]['impact_total_count'];
                        finalData[j]['Impacted_Trust_Score']  = JSON.stringify(Math.round(finalData[j]['Impacted_Trust_Score'] ));
                        // finalData[j]['Impacted Trust Score'] = finalData[j]['Impacted Trust Score'];
                    }
                }

                this.supplierData = finalData[0];

                
            }else{
                this.supplierData = '';
                this.loader = false;
                this.supDetailloader = false;
                this.noRecordsMsg = "No Records Found.";
            }
            if(this.impact_type){
                this.getIcon(this.impact_type);
            }
            
            this.loader = false;
            if(flag === 'from_init'){
                this.chartList = [];
                const div = Math.ceil(newData.length / 10);
                for(let i =1; i <= div; i++){
                    this.chartList.push("barchart"+i);
                }
                this.chartData = newData;
                this.dataObj = {
                    chartList: this.chartList,
                    sortedList: newData,
                    impactScore: this.impactToggleForm.controls.impact.value,
                    loader: 'false'
                }
                
            }else{
                this.chartList = [];
                const div = Math.ceil(newData.length / 10);
                for(let i =1; i <= div; i++){
                    this.chartList.push("barchart"+i);
                }
                this.chartData = newData;
                this.dataObj = {
                    chartList:this.chartList,
                    sortedList: newData,
                    impactScore: this.impactToggleForm.controls.impact.value
                }
            }
            //this.chartData = this.sortedData;
            
            
    
        },(error) =>{
            this.loader = false;
            this.supDetailloader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


    slideChanged() {
        this.dataObj = {
            chartList:this.chartList,
            sortedList: this.chartData,
            impactScore: this.impactToggleForm.controls.impact.value
        }
    }

    generateBarChart() {


        const chart = c3.generate({
            bindto: '#barchart',
            size: {
                height: 260
            },
            data: {
                x: 'x',
                columns: [
                    this.parts
                ],
                type: 'bar',
                color: function(inColor, data) {
                    if(data['id'] !== undefined && data['id'] === 'Trust Score') {
                        return data['value'] <= 20? 'var(--secondary-red)': data['value'] >= 21 && data['value'] <= 40? 'var(--secondary-orange)':  data['value'] >= 41 && data['value'] <= 80? 'var(--secondary-yellow)' : 'var(--primary-green)';
          
                    }
                    return inColor;
                },
                colors: {
                    //'Trust_Score': 'darkgrey',
                    'Impacted Trust Score': 'var(--secondary-grey)'
                },
                // onclick: (d) => {
                //     //this.selectedBar(d);
                // }
            },
            transition: {
                duration: 2000
            },

            tooltip: {
                show: true
            },
            axis: {
                rotated: false,
                x: {
                    type: 'category',
                    tick: {
                        outer: false,
                        rotate: 90,
                        multiline: false
                    },
                    height: 80,
                    label: {
                        text: 'Parts',
                        position: 'outer-center'
                    }
                },
                y: {
                    max: 100,
                    tick: {
                        values: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
                    },
                    show: true,
                    padding: {top: 0.3, bottom: 0},
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle'
                    }
                },


            },

            bar: {
                width: {
                    ratio: 0.4
                }

            },
            legend: {
                hide: true,
                show: false
            },
        });

        const sup = this.parts;
        const trustScore = this.trustScores;
        const imp_trust_score = this.impactedTrustScores;
        if (imp_trust_score[1] != 'null' && this.impactToggleForm.controls.impact.value === true) {
            setTimeout(function() {
                chart.load({
                    columns: [
                        sup,
                        trustScore,
                        imp_trust_score
                    ]
                });
            }, 500);
            setTimeout(() => {
                chart.regions(
                    [{axis: 'y', start: 0, end: 20, class: 'region1'},
                        {axis: 'y', start: 20, end: 40, class: 'region2'},
                        {axis: 'y', start: 40, end: 80, class: 'region3'},
                        {axis: 'y', start: 80, class: 'region4'}]);
            }, 500);
        } else {
            setTimeout(function() {
                chart.load({
                    columns: [
                        sup,
                        trustScore
                    ]
                });
            }, 500);

            setTimeout(() => {
                chart.regions(
                    [{axis: 'y', start: 0, end: 20, class: 'region1'},
                        {axis: 'y', start: 20, end: 40, class: 'region2'},
                        {axis: 'y', start: 40, end: 80, class: 'region3'},
                        {axis: 'y', start: 80, class: 'region4'}]);
            }, 500);
        }

        this.loader = false;
    }

    showPartBreakdown(data){
        //const dataString = JSON.parse(data); 
        this.selectedBar(data['selectedPart'],data['index'], data['parts']);   
    }

    selectedBar(val,num, parts){
        const selectedPart = parts[val.index+1];
        this.itemList = [];
        const poList = [];

        for (const sup in this.scoresByItemList) {
            let cnt = 0;
            if (this.scoresByItemList[sup]['Supplier'] === this.supplierName && this.scoresByItemList[sup]['Material'] === selectedPart) {
                //this.impact_type = this.scoresByItemList[sup]['Impact_Type'][0];
               
                if (this.itemList.length != 0) {
                    for (const key in this.itemList) {
                        if (this.itemList[key]['Material'] == this.scoresByItemList[sup]['Material'] && this.itemList[key]['Purchasing_Document'] == this.scoresByItemList[sup]['Purchasing_Document'] && 
                        this.itemList[key]['Item'] == this.scoresByItemList[sup]['Item']) {
                            this.itemList[key]['total_count'] = this.itemList[key]['total_count'] + 1;
                            this.itemList[key]['Trust_Score'] = parseInt(this.itemList[key]['Trust_Score']) + parseInt(this.scoresByItemList[sup]['Trust_Score']);
                            if (this.itemList[key]['Impacted_Delivery_Date'] || this.itemList[key]['Impacted_Trust_Score']) {
                                this.itemList[key]['impact_total_count'] = this.itemList[key]['impact_total_count'] + 1;
                                this.itemList[key]['Impacted_Trust_Score'] = parseInt(this.itemList[key]['Impacted_Trust_Score']) + parseInt(this.scoresByItemList[sup]['Impacted_Trust_Score']);
                            }
                        } else {
                            cnt++;
                        }
                        if (cnt == this.itemList.length) {
                            this.itemList.push(this.scoresByItemList[sup]);
                            this.itemList[this.itemList.length - 1]['total_count'] = 1;
                            //this.parts.push(response[sup]['Material']);
                            this.itemList[this.itemList.length - 1]['impact_total_count'] = 1;
                        }
                    }
                } else {
                    this.itemList.push(this.scoresByItemList[sup]);
                    this.itemList[0]['total_count'] = 1;
                    if (this.scoresByItemList[sup]['Impacted_Delivery_Date'] || this.scoresByItemList[sup]['Impacted_Trust_Score']) {
                        this.itemList[0]['impact_total_count'] = 1;
                    }
                }

                if (poList.length != 0) {
                    let cnt = 0;
                    for (const dt in poList) {

                        if (poList[dt]['Material'] == this.scoresByItemList[sup]['Material'] && poList[dt]['Purchasing_Document'] == this.scoresByItemList[sup]['Purchasing_Document']) {
                            poList[dt]['total_count'] = poList[dt]['total_count'] + 1;
                            poList[dt]['Trust_Score'] = parseInt(poList[dt]['Trust_Score']) + parseInt(this.scoresByItemList[sup]['Trust_Score']);
                            if (poList[dt]['Impacted_Delivery_date'] || poList[dt]['Impacted_Trust_Score']) {

                                poList[dt]['impact_total_count'] = poList[dt]['impact_total_count'] + 1;
                                poList[dt]['Impacted_Trust_Score'] = parseInt(poList[dt]['Impacted_Trust_Score']) + parseInt(this.scoresByItemList[sup]['Impacted_Trust_Score']);
                                // this.impact_type = finalData[dt]['Impact Type'];
                                //this.showColumns = true;
                            }

                            break;
                        } else {
                            cnt++;
                        }

                        if (cnt == poList.length) {
                            poList.push(this.scoresByItemList[sup]);
                            poList[poList.length - 1]['total_count'] = 1;
                            poList[poList.length - 1]['impact_total_count'] = 1;
                        }

                    }
                } else {
                    poList.push(this.scoresByItemList[sup]);
                    poList[0]['total_count'] = 1;
                    if (this.scoresByItemList[sup]['Impacted_Delivery_date'] || this.scoresByItemList[sup]['Impacted_Trust_Score'] ) {
                        poList[0]['impact_total_count'] = 1;
                    }
                }



            }
        }

        for (const k in poList) {
            poList[k]['Trust_Score'] = (parseInt(poList[k]['Trust_Score'])) / (poList[k]['total_count']);
            poList[k]['Trust_Score'] = JSON.stringify(Math.round(parseInt(poList[k]['Trust_Score'])));
            
            poList[k]['Impacted_Trust_Score'] = parseInt(poList[k]['Impacted_Trust_Score']) / poList[k]['impact_total_count'];
            poList[k]['Impacted_Trust_Score'] = JSON.stringify(Math.round(parseInt(poList[k]['Impacted_Trust_Score'])));
            
        }

        for (const j in this.itemList) {
            this.itemList[j]['Trust_Score'] = (parseInt(this.itemList[j]['Trust_Score'])) / (this.itemList[j]['total_count']);
            this.itemList[j]['Trust_Score'] = JSON.stringify(Math.round(parseInt(this.itemList[j]['Trust_Score'])));
            
            this.itemList[j]['Impacted_Trust_Score'] = parseInt(this.itemList[j]['Impacted_Trust_Score']) / this.itemList[j]['impact_total_count'];
            this.itemList[j]['Impacted_Trust_Score'] = JSON.stringify(Math.round(parseInt(this.itemList[j]['Impacted_Trust_Score'])));
            this.itemList[j]['PartScores'] = {};
            this.itemList[j]['PartScores'] = {
                'Trust_Score': this.trustScores[val.index+1],
                'Impacted_Trust_Score': this.impactedTrustScores[val.index+1]
            };
            this.showBreakdown.push(false);
            for (const l in poList) {
                if(this.itemList[j]['Material'] === poList[l]['Material'] && this.itemList[j]['Purchasing_Document'] === poList[l]['Purchasing_Document']){
                    this.itemList[j]['POScores'] = {};
                    
                    this.itemList[j]['POScores'] = {
                        'Trust_Score': poList[l]['Trust_Score'],
                        'Impacted_Trust_Score': poList[l]['Impacted_Trust_Score']
                    };
                    
                    break;
                }
                
            }
        }


    }

    sortScoreTable(flag){
        let active, direction;
        if(flag === 'oldest_to_newest'){
            active = 'Commitment_Date';
            direction = 'asc';
        }else if(flag === 'soonest_to_latest'){
            active = 'Predicted_Posting_Date';
            direction = 'asc';
        }else if(flag === 'highest_to_lowest'){
            active = 'Trust_Score';
            direction = 'desc';
        }
        const sortState = {
            'active': active,
            'direction': direction
        };
        //this.loader = false;
        this.scoreTableloader = true;
        this.customSort(sortState, this.scoresByItemList);
    }


    customSort(val, dataArray) {

        const data = dataArray.slice();
        if (val) {

            this.sortedData = data.sort((a, b) => {
                

                const isAsc = val.direction === 'asc';


                switch (val.active) {
                    case 'Trust_Score':
                        return this.compare(a['Trust_Score'], b['Trust_Score'], isAsc);
                    case 'Commitment_Date':
                        return this.compare(new Date(a['Commitment_Date']), new Date(b['Commitment_Date']), isAsc);
                    case 'Predicted_Posting_Date':
                        return this.compare(new Date(a['Predicted_Posting_Date']), new Date(b['Predicted_Posting_Date']), isAsc);
                    default:
                        return 0;
                }


            });

            this.scoresByItemList = this.sortedData;
            this.scoreTableloader = false;
        }


    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }


    getAllSuppliers(){
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };

        this.supplierTrendService.getAllSuppliers(data).subscribe((res) => {
            this.suppliers = res;
            //this.getSupplierTrends('1M');
        },(error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        }
        );
    }

    getSupplierTrends(duration) {
        //this.loader = true;
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };

        this.getSupplierTrendsDuration = duration;

        this.supplierTrendService.getSupplierTrends(data, duration, this.supplierName, this.suppliers[this.supplierName]).subscribe((res) => {
            this.supDetailloader = false;
            if(Object.keys(res['legacy_trustscores']).length == 0){
                this.fitValue = true;
                this.noRecordsFoundMsg = true;                
            }else{
                this.fitValue = false;
                this.noRecordsFoundMsg = false;                
            }
            this.supplierTrendsData = res['legacy_trustscores'];

            this.generateLineChart();
          
        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();

            // }
        });

    }


    generateLineChart() {
        //this.showChart = true;
        if (this.supplierTrendsData) {
            this.periods = [];
            this.trustScores = [];
            this.impactedTrustScores = [];
            this.periods[0] = 'x';
            this.trustScores[0] = 'Trust Score';
            this.impactedTrustScores[0] = 'Impacted Trust Score';
            // for (const trendsIndex in this.supplierTrendsData) {
            //     const trendsData = this.supplierTrendsData[trendsIndex];
            //     this.trustScores.push(trendsData['Trust_Score']);
            //     if (trendsData['Impacted_Trust_Score']) {
            //         this.impactedTrustScores.push(trendsData['Impacted_Trust_Score']);
            //     }else{
            //         this.impactedTrustScores.push(0);
            //     }
            //     const convDate = new Date(trendsData['timestamp']);
            //     let date = JSON.stringify(convDate)
            //     date = date.slice(1,11);
            //     this.periods.push(date);
            // }


            for (const trendsIndex in this.supplierTrendsData) {
                const trendsData = this.supplierTrendsData[trendsIndex];
                this.trustScores.push(trendsData['trust_score']);
                if (trendsData['impacted_trust_score']) {
                    this.impactedTrustScores.push(trendsData['impacted_trust_score']);
                } else {
                    this.impactedTrustScores.push(0);
                }
                const convDate = new Date(trendsIndex);
                let date = JSON.stringify(convDate)
                date = date.slice(1, 11);
                this.periods.push(date);

                const today = new Date();
                let nowDate = JSON.stringify(today);
                nowDate = nowDate.slice(1, 11);
                if (JSON.stringify(nowDate) === JSON.stringify(date)) {
                    this.currentTrustScore = trendsData['trust_score'];
                    this.currentImpactedScore = trendsData['impacted_trust_score'] ? trendsData['impacted_trust_score'] : 0;
                    if(this.currentImpactedScore && this.currentImpactedScore != 0){
                        this.getIcon('COVID-19');
                    }
                }
            }
        }
        const vm = this;
        //let firstMon = false;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const chart = c3.generate({
            bindto: '#linechart',
            data: {
                x: 'x',
                xFormat: '%Y-%m-%d',
                columns: [
                    this.periods,
                    this.trustScores,
                    this.impactedTrustScores
                ]
            },
            point: {
                r: 0,
                focus : { expand: { r:5}}
            },
            padding: {
                right: 12
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: function(d){
                            return (vm.pipe.transform(d, 'd MMM yy'));
                        },
                        fit: vm.fitValue,
                        rotate: 90,
                    },
                    height: 80,
                    label: {
                        text: 'Period',
                        position: 'outer-center',
                    }
                },
                y: {
                    show: true,
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle',
                    }
                }
            },
            // tooltip: {
            //     format: {
            //         title: function (d) {
            //             return (vm.pipe.transform(d, 'd. MMM'));
            //         }
                  
            //     }
            // },
            grid: {
                lines: {
                    front: false,

                },
                y: {
                    show: true,

                }
            }
        });

    }


    showPeriodicChart(periodId){
        this.supDetailloader = true;
        this.itemList = [];
        this.showBreakdown = [];
        this.pageOfItems = 1;
        this.impact_type = '';
        if(periodId === '1D'){
            $('#1D').addClass('active');
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
        }else if(periodId === '1M'){
            $('#1D').removeClass('active');
            $('#1M').addClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
        }else if(periodId === '3M'){
            $('#1D').removeClass('active');
            $('#1M').removeClass('active');
            $('#3M').addClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
        }else if(periodId === '6M'){
            $('#1D').removeClass('active');
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').addClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
        }else if(periodId === '9M'){
            $('#1D').removeClass('active');
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').addClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
        }else if(periodId === '1Y'){
            $('#1D').removeClass('active');
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').addClass('active');
            $('#2Y').removeClass('active');
        }else if(periodId === '2Y'){
            $('#1D').removeClass('active');
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').addClass('active');
        }
        if(this.showChart == false){
            this.getSupplierTrends(periodId);
        }else{
            this.getAllSuppliersData(periodId, '');
            this.selectedPeriod = periodId;
        }
    }


    getIcon(impact_type) {
        //this.loader = true;
        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };

        this.supplierService.getIconURL(data, impact_type).subscribe((res) => {
            if (res) {
                const path = res.Path;
                const storageRef = this.afStorage.storage.ref(path);
                storageRef.getDownloadURL().then(url => {

                    this.imageToShow = url;
                });
            }

            //this.createImageFromBlob(res.body);

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    onChangePage(event) {
        this.pageOfItems = event;
    }

    
    goBack(){
        const val = localStorage.getItem('navigated_from');
        if(val === 'roster'){
            this.router.navigate(['/home/roster']);
        }else if(val === 'scores'){
            this.router.navigate(['/home/scores']);
        }else if(val === 'trendChart'){
            this.router.navigate(['/home/trendChart']);
        }
        
    }


    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }


}
