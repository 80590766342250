/* eslint-disable no-unused-vars */
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RequestDialogComponent } from '../request-dialog/request-dialog.component';
import { MatDialogConfig, MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SignUpService } from '../sign-up/sign-up.service';
import { Observable } from 'rxjs';
import { NgSelectConfig } from '@ng-select/ng-select';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-request-demo',
    templateUrl: './request-demo.component.html',
    styleUrls: ['./request-demo.component.css']
})
export class RequestDemoComponent implements OnInit {
    requestDemoForm: FormGroup;
    organizations: any;
    filteredOptions: Observable<string[]>;
    phoneNumberInvalid: string;
    requestDemosrc: any;    

    constructor(private fb: FormBuilder,private matDialog: MatDialog, private signUpService: SignUpService,private config: NgSelectConfig,
                @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<RequestDemoComponent>) {
        this.config.notFoundText = 'Organization not found';
        this.config.placeholder = 'Select Organization'; 
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.requestDemosrc = environment.requestDemoPage;
        this.dialogRef.updateSize('50%');
        this.requestDemoForm = this.fb.group({
            organisation_name: ['', Validators.required],
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            job_title: ['', Validators.required],
            phone_number: ['', Validators.required],
            email: ['', Validators.required],
            question: ['']
        });

        // this.getOrganizationsList();
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.organizations.filter(option => option.toLowerCase().includes(filterValue));
    }

    getOrganizationsList() {
    const obj = {
      "Authorization": localStorage.getItem('auth_token')
    }

        this.signUpService.getOrganizationList(obj).subscribe(res => {
            this.organizations = [];
            //this.organizations = res.names;
            for(const org in res.names){
                this.organizations.push({'name': res.names[org]});
            }
            // this.requestDemoForm.controls.organisation_name.setValue(null);
            // this.filteredOptions = this.requestDemoForm.controls.organisation_name.valueChanges
            //   .pipe(
            //     startWith(''),
            //     map(value => this._filter(value))
            //   );

        });
    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();

        const message = 'Your demo request has been submitted successfully. Someone from FullConfidence team will reach out to you shortly.';
        const title = 'Requested Demo';
        const resetPassword = false;

        dialogConfig.data = {
            message,
            title,
            resetPassword
        };
        this.matDialog.open(RequestDialogComponent, dialogConfig);
    }


    validatePhoneNumber(p_no, action){
        const numbers = /^[0-9]+$/;
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.requestDemoForm.controls.phone_number.setValue(p_no);
        }
        if((p_no.length != 10 || !p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = 'Enter 10 digit contact number';
        }else if((p_no.length == 10 && p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = '';
        }else if(p_no.length == 10 && p_no.match(numbers) && action === 'addCode'){
            this.phoneNumberInvalid = '';
            const stringEnc = encodeURI('+');
            const phn_number = stringEnc + 1 + this.requestDemoForm.value.phone_number;
            this.requestDemoForm.controls.phone_number.setValue(phn_number);
        }
    }

    removeCode(p_no){
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.requestDemoForm.controls.phone_number.setValue(p_no);
        }
    }


    close(){
        this.dialogRef.close();
    }

}
