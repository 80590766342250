import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CompleteMappingDialogComponent } from '../complete-mapping-dialog/complete-mapping-dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { UploadSupplierDeliveryFileService } from '../upload-supplier-delivery-file/upload-supplier-delivery-file.service';
import { UploadSuccessComponent } from '../../impact-data-load/upload-success/upload-success.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-map-file',
    templateUrl: './map-file.component.html',
    styleUrls: ['./map-file.component.css']
})
export class MapFileComponent implements OnInit {
    mappingErrorMessage: any;
    authtoken: any;
    loader: boolean;
    unmappedColumns: any;
    invalidForm: boolean;

    showSupplierdd: boolean;
    showPart_Materialdd: boolean;
    showPurchasing_Documentdd: boolean;
    showItemdd: boolean;
    showLinedd: boolean;
    showQuantitydd: boolean;
    showStorage_locationdd: boolean;
    showQuantity_delivereddd: boolean;
    showCommitment_Datedd: boolean;
    showDelivery_datedd: boolean;
    showStill_to_be_delivereddd: boolean;
    showOpen_From_PO_Linedd: boolean;

    mapList = [];
    disableCompleteMapping = false;
    requiredErrorList = {};
    mapListOptional = [];


    @Input('xlsxFileData') xlsxFileData: any;
    @Input('uploadedxlsxfile') uploadedxlsxfile: any;
    mapFileForm: FormGroup;
    requiredMappingDefault = [];
    optionalMappingDefault = [];
    optionalMappingCustom = [];
    requiredMappingCustom = [];
    xlsxFileColumns: any;
    requiredMappingFileColumns = [];
    customeValues = [];
    defaultValues = [];
    mapListNew = [];
    obj = {};

    requiredMappingList = ['Supplier', 'Part/Material', 'Purchasing Document', 'Item', 'Line', 'Quantity', 'Storage location', 'Quantity delivered', 'Commitment Date', 'Delivery date(Required date)'];
    optionalMappingList = ['Still to be delivered (qty)', 'Open From PO Line'];

    mappedValues = {};
    @Output() cancelMappingFlag = new EventEmitter<string>();
    requiredMappingVal = ['Supplier-15', '111A-36', '123456', '170', '1', '1', 'abc', '2', '2020-2-2', '2020-3-4', '2020-2-1']
    lastloginatTimeDifference: any;
    constructor(private matDialog: MatDialog,
                private cookieServiceVariable: CookieService,
                private uploadSupplierDeliveryFileService: UploadSupplierDeliveryFileService, private fb: FormBuilder, private fbAuth: StratizantFirebaseAuthService) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.unmappedColumns = this.xlsxFileData[0].unmapped_columns;
        this.createMappingForm();
        this.defaultValues = Object.keys(this.xlsxFileData[0]);
        this.customeValues = Object.values(this.xlsxFileData[0]);
        this.mappValues();
        // this.getDefaultandCustomeValues();
        this.mapColumns();
      


    }



    changeMapping(value) {

        this.unmappedColumns.splice(parseInt(value), 1);
    }

    mappValues() {
        Object.keys(this.xlsxFileData[0]['mapped_columns']).forEach((key) => {
            if (key == 'Commitment Date') {
                this.mapFileForm.patchValue({
                    Commitment_Date: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Commitment_Date.disable();
            } else if (key === 'Item') {
                this.mapFileForm.patchValue({
                    Item: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Item.disable();

            } else if (key === 'Material') {
                this.mapFileForm.patchValue({
                    Part_Material: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Part_Material.disable();

            } else if (key === 'Open From PO Line') {
                this.mapFileForm.patchValue({
                    Open_From_PO_Line: this.xlsxFileData[0]['mapped_columns'][key]
                });

                this.mapFileForm.controls.Open_From_PO_Line.disable();

            } else if (key === 'Still to be delivered (qty)') {
                this.mapFileForm.patchValue({
                    Still_to_be_delivered: this.xlsxFileData[0]['mapped_columns'][key]
                });

                this.mapFileForm.controls.Still_to_be_delivered.disable();
            }
            else if (key === 'Purchasing Document') {
                this.mapFileForm.patchValue({
                    Purchasing_Document: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Purchasing_Document.disable();
            } else if (key === 'Quantity delivered') {
                this.mapFileForm.patchValue({
                    Quantity_delivered: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Quantity_delivered.disable();
            } else if (key === 'Schedule line') {
                this.mapFileForm.patchValue({
                    Line: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Line.disable();
            } else if (key == 'Storage location') {
                this.mapFileForm.patchValue({
                    Storage_location: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Storage_location.disable();
            } else if (key === 'Supplier') {
                this.mapFileForm.patchValue({
                    Supplier: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Supplier.disable();
            } else if (key === 'Delivery Date') {
                this.mapFileForm.patchValue({
                    Delivery_date: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Delivery_date.disable();
            } else if (key === 'Scheduled quantity') {
                this.mapFileForm.patchValue({
                    Quantity: this.xlsxFileData[0]['mapped_columns'][key]
                });
                this.mapFileForm.controls.Quantity.disable();
            }
        })

        // this.mapColumns();


        this.showDropDawn();


    }


    showDropDawn() {

        if (!this.mapFileForm.controls.Open_From_PO_Line.value) {
            this.showOpen_From_PO_Linedd = true;
        }

        if (!this.mapFileForm.controls.Still_to_be_delivered.value) {
            this.showStill_to_be_delivereddd = true;
        }

        if (!this.mapFileForm.controls.Quantity_delivered.value) {
            this.showQuantity_delivereddd = true;
        }

        if (!this.mapFileForm.controls.Supplier.value) {
            this.showSupplierdd = true;
        }

        if (!this.mapFileForm.controls.Part_Material.value) {
            this.showPart_Materialdd = true;
        }
        if (!this.mapFileForm.controls.Purchasing_Document.value) {
            this.showPurchasing_Documentdd = true;
        }

        if (!this.mapFileForm.controls.Item.value) {
            this.showItemdd = true;
        }

        if (!this.mapFileForm.controls.Line.value) {
            this.showLinedd = true;
        }

        if (!this.mapFileForm.controls.Quantity.value) {
            this.showQuantitydd = true;
        }

        if (!this.mapFileForm.controls.Storage_location.value) {
            this.showStorage_locationdd = true;
        }

        if (!this.mapFileForm.controls.Commitment_Date.value) {
            this.showCommitment_Datedd = true;
        }

        if (!this.mapFileForm.controls.Delivery_date.value) {
            this.showDelivery_datedd = true;
        }


    }

    mapColumns() {

        // this.obj =
        // {
        //     "Supplier": this.mapFileForm.controls.Supplier.value,
        //     "Material": this.mapFileForm.controls.Part_Material.value,
        //     "Purchasing Document": this.mapFileForm.controls.Purchasing_Document.value,
        //     "Item": this.mapFileForm.controls.Item.value,
        //     // "Schedule line": this.mapFileForm.controls.Line.value,
        //     "Scheduled quantity": this.mapFileForm.controls.Quantity.value,
        //     "Storage location": this.mapFileForm.controls.Storage_location.value,
        //     "Quantity delivered": this.mapFileForm.controls.Quantity_delivered.value,
        //     "Commitment Date": this.mapFileForm.controls.Commitment_Date.value,
        //     "Delivery Date": this.mapFileForm.controls.Delivery_date.value,
        // }



        if (this.showStill_to_be_delivereddd) {

            if (this.mapFileForm.controls.Still_to_be_delivereddd.value) {
                this.obj["Still to be delivered (qty)"] = this.mapFileForm.controls.Still_to_be_delivereddd.value;
            }
        } else if (this.mapFileForm.controls.Still_to_be_delivered.value) {
            this.obj["Still to be delivered (qty)"] = this.mapFileForm.controls.Still_to_be_delivered.value;
        }

        if (this.showOpen_From_PO_Linedd) {
            if (this.mapFileForm.controls.Open_From_PO_Linedd.value) {

                this.obj["Open From PO Line"] = this.mapFileForm.controls.Open_From_PO_Linedd.value;
            }
        }
        else if (this.mapFileForm.controls.Open_From_PO_Line.value) {

            this.obj["Open From PO Line"] = this.mapFileForm.controls.Open_From_PO_Line.value;
        }

        if (this.showDelivery_datedd) {
            this.obj["Delivery Date"] = this.mapFileForm.controls.Delivery_datedd.value
        } else {
            this.obj["Delivery Date"] = this.mapFileForm.controls.Delivery_date.value
        }

        if (this.showCommitment_Datedd) {
            this.obj["Commitment Date"] = this.mapFileForm.controls.Commitment_Datedd.value
        } else {
            this.obj["Commitment Date"] = this.mapFileForm.controls.Commitment_Date.value
        }

        if (this.showQuantity_delivereddd) {
            this.obj["Quantity delivered"] = this.mapFileForm.controls.Quantity_delivereddd.value
        } else {
            this.obj["Quantity delivered"] = this.mapFileForm.controls.Quantity_delivered.value
        }

        if (this.showStorage_locationdd) {
            this.obj["Storage location"] = this.mapFileForm.controls.Storage_locationdd.value
        } else {
            this.obj["Storage location"] = this.mapFileForm.controls.Storage_location.value
        }

        if (this.showQuantitydd) {
            this.obj["Scheduled quantity"] = this.mapFileForm.controls.Quantitydd.value
        } else {
            this.obj["Scheduled quantity"] = this.mapFileForm.controls.Quantity.value
        }

        if (this.showItemdd) {
            this.obj["Item"] = this.mapFileForm.controls.Itemdd.value
        } else {
            this.obj["Item"] = this.mapFileForm.controls.Item.value
        }

        if (this.showItemdd) {
            this.obj["Item"] = this.mapFileForm.controls.Itemdd.value
        } else {
            this.obj["Item"] = this.mapFileForm.controls.Item.value
        }

        if (this.showPurchasing_Documentdd) {
            this.obj["Purchasing Document"] = this.mapFileForm.controls.Purchasing_Documentdd.value
        } else {
            this.obj["Purchasing Document"] = this.mapFileForm.controls.Purchasing_Document.value
        }

        if (this.showSupplierdd) {
            this.obj["Supplier"] = this.mapFileForm.controls.Supplierdd.value
        } else {
            this.obj["Supplier"] = this.mapFileForm.controls.Supplier.value
        }

        if (this.showPart_Materialdd) {
            this.obj["Material"] = this.mapFileForm.controls.Part_Materialdd.value
        } else {
            this.obj["Material"] = this.mapFileForm.controls.Part_Material.value
        }


        if (this.showLinedd) {
            this.obj["Schedule line"] = this.mapFileForm.controls.Linedd.value;

        } else {
            this.obj["Schedule line"] = this.mapFileForm.controls.Line.value;
        }

        this.validateForm();
    }


    validateForm() {
        if (this.showSupplierdd) {
            if (this.mapFileForm.controls.Supplierdd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Supplier.value == '') {
            this.invalidForm = true;
        }

        if (this.showPart_Materialdd) {
            if (this.mapFileForm.controls.Part_Materialdd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Part_Material.value == '') {
            this.invalidForm = true;
        }



        if (this.showPurchasing_Documentdd) {
            if (this.mapFileForm.controls.Purchasing_Documentdd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Purchasing_Document.value == '') {
            this.invalidForm = true;
        }

        if (this.showItemdd) {
            if (this.mapFileForm.controls.Itemdd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Item.value == '') {
            this.invalidForm = true;
        }



        if (this.showLinedd) {
            if (this.mapFileForm.controls.Linedd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Line.value == '') {
            this.invalidForm = true;
        }


        if (this.showQuantitydd) {
            if (this.mapFileForm.controls.Quantitydd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Quantity.value == '') {
            this.invalidForm = true;
        }



        if (this.showStorage_locationdd) {
            if (this.mapFileForm.controls.Storage_locationdd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Storage_location.value == '') {
            this.invalidForm = true;
        }


        if (this.showQuantity_delivereddd) {
            if (this.mapFileForm.controls.Quantity_delivereddd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Quantity_delivered.value == '') {
            this.invalidForm = true;
        }

        if (this.showCommitment_Datedd) {
            if (this.mapFileForm.controls.Commitment_Datedd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Commitment_Date.value == '') {
            this.invalidForm = true;
        }


        if (this.showDelivery_datedd) {
            if (this.mapFileForm.controls.Delivery_datedd.value != "") {
                this.invalidForm = false;
            } else {
                this.invalidForm = true;
            }
        } else if (this.mapFileForm.controls.Delivery_date.value == '') {
            this.invalidForm = true;
        }

        let cnt = 0;
        for (const uc in this.unmappedColumns) {

            if ((this.unmappedColumns[uc] == this.mapFileForm.controls.Supplier.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Supplierdd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Part_Material.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Part_Materialdd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Purchasing_Document.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Purchasing_Documentdd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Item.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Itemdd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Line.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Linedd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Quantity.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Quantitydd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Storage_location.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Storage_locationdd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Quantity_delivered.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Quantity_delivereddd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Commitment_Date.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Commitment_Datedd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Delivery_date.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Delivery_datedd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Still_to_be_delivered.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Still_to_be_delivereddd.value) ||
                (this.unmappedColumns[uc] == this.mapFileForm.controls.Open_From_PO_Line.value) || (this.unmappedColumns[uc] == this.mapFileForm.controls.Open_From_PO_Linedd.value)
            ) {
                cnt++;
                // this.invalidForm = false;
            } else {
                // this.invalidForm = true;
            }

            if(cnt == this.unmappedColumns.length){
                this.invalidForm = false;
            }else{
                this.invalidForm = true;
            }


        }
    }


    createMappingForm() {
        this.mapFileForm = this.fb.group({
            Supplier: ['', Validators.required],
            Part_Material: ['', Validators.required],
            Purchasing_Document: ['', Validators.required],
            Item: ['', Validators.required],
            Line: ['', Validators.required],
            Quantity: ['', Validators.required],
            Storage_location: ['', Validators.required],
            Quantity_delivered: ['', Validators.required],
            Commitment_Date: ['', Validators.required],
            Delivery_date: ['', Validators.required],
            Still_to_be_delivered: [''],
            Open_From_PO_Line: [''],

            Supplierdd: ['', Validators.required],
            Part_Materialdd: ['', Validators.required],
            Purchasing_Documentdd: ['', Validators.required],
            Itemdd: ['', Validators.required],
            Linedd: ['', Validators.required],
            Quantitydd: ['', Validators.required],
            Storage_locationdd: ['', Validators.required],
            Quantity_delivereddd: ['', Validators.required],
            Commitment_Datedd: ['', Validators.required],
            Delivery_datedd: ['', Validators.required],
            Still_to_be_delivereddd: [''],
            Open_From_PO_Linedd: [''],

        });

    }

    cancel() {
        this.cancelMappingFlag.emit('true');
        this.invalidForm = true;
    }

    confirmMapping() {
        const dialogConfig = new MatDialogConfig();
        const data = {
            'mapping_columns': this.obj,
            'uploadedxlsxfile': this.uploadedxlsxfile,
        }
        dialogConfig.data = data;
        const dialogRef = this.matDialog.open(CompleteMappingDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.cancelMappingFlag.emit('false');

        });

    }


    completeMapping() {
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        const formData = new FormData();


        formData.append('file', this.uploadedxlsxfile);
        formData.append('mapped_columns', JSON.stringify(this.obj));
        formData.append('uploaded_by', localStorage.getItem('user'));
        this.uploadSupplierDeliveryFileService.completeMapping(obj, formData).subscribe((res) => {
            this.loader = false;
            this.uploadPredictionDatasetFile();
            this.openUploadSuccessComponent('');

            this.cancelMappingFlag.emit('false');
            // this.dialogRef.close(
            //     this.cancelMappingFlag
            // );


        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // Invalid date value in file
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
                 
            // } else 
            if (error && error.error == "Exception Occurred") {
                // this.mappingErrorMessage = error.error;
                this.openUploadSuccessComponent(error.error);
            } else if (error && error.error == "Column missing from file." && error.status == "400") {
                // this.mappingErrorMessage = error.error;
                this.openUploadSuccessComponent(error.error)
            } else if (error && error.status == "400") {
                // this.mappingErrorMessage = error.error;
                this.openUploadSuccessComponent(error.error)
            } else if (error && error.status == "401") {
                // this.mappingErrorMessage = error.error;
                this.openUploadSuccessComponent(error.error)
            }

        });
    }

   

    uploadPredictionDatasetFile() {
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        const formData = new FormData();
        formData.append('file', this.uploadedxlsxfile);
        formData.append('uploaded_by', localStorage.getItem('user'));
        this.uploadSupplierDeliveryFileService.uploadPredictionDatasetFile(obj, formData).subscribe(() => {
            this.loader = false;
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
                
            // } else 
            if (error && error.error == "Exception Occurred") {
                this.mappingErrorMessage = error.error;
            }
        });
    }

    openUploadSuccessComponent(message: any) {
        const dialogConfig = new MatDialogConfig();
        if (message) {
            const obj = {
                "error": true,
                "data": message
            }
            dialogConfig.data = obj;
        } else {
            const obj = {
                "error": false,
                "data": 'File Processed'
            }
            dialogConfig.data = obj;
        }

        const dialogRef = this.matDialog.open(UploadSuccessComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((value) => {

            if (value === 'false') {
                this.cancelMappingFlag.emit('false');
            }

        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();
        });

    }

}
