import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SelectedSubscriptionPlanService {
    basepath = environment.basePath;

    constructor(private http: HttpClient) {
    }

    checkout(data: any, paymentData: any, subscriptionId?:any): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer' + ' ' + data.Authorization,
            })
        };

        // const httpOptions = {
        //     withCredentials: true,
        //     observe: 'response' as 'response'
        // };

        if(subscriptionId){
            return this.http.put<any>(`${this.basepath + 'api/profile/checkout'}`, paymentData, httpOptions);
        }else{
            return this.http.post<any>(`${this.basepath + 'api/profile/checkout'}`, paymentData, httpOptions);

        }

    }


}
