import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class SubscrioptionLevelService {
    subscriptionLevelBasePath = environment.basePath + "api/profile/subscriptions";
    constructor(private http: HttpClient) { }





    getSubscriptionLevelDetails(data): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer'+' '+ data.Authorization,
            })
        }
        return this.http.get<any>(`${this.subscriptionLevelBasePath}`, httpOptions);

        // const httpOptions = {
        //     withCredentials: true,
        //     observe: 'response' as 'response'
        // };
        // return this.http.get<any>(`${this.subscriptionLevelBasePath}`);
        
    }

 
    saveSubscriptionLevelDetails(data,file_data): Observable<any> {
        
        const headers = new HttpHeaders({
            'Authorization': 'Bearer'+' '+ data.Authorization,
        })
       

        return this.http.put<any>(`${this.subscriptionLevelBasePath}` , file_data, {headers, responseType: 'text' as 'json'}).pipe(map((data: HttpResponse<any>) => {
            return data;
        }));


        // const httpOptions = {
        //     withCredentials: true,
        //     // observe: 'response' as 'response'
        // };

        // return this.http.put<any>(`${this.subscriptionLevelBasePath}` , file_data, {responseType: 'text' as 'json'});
      

    }

}
