import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MatDialogConfig, MatDialog, MatRadioChange } from '@angular/material';

// import { MatDialog, , MatPaginator, MatTableDataSource } from '@angular/material';
import { UploadSupplierDeliveryFileService } from './upload-supplier-delivery-file.service';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { UploadSuccessComponent } from '../../../admin-settings/impact-data-load/upload-success/upload-success.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-upload-supplier-delivery-file',
    templateUrl: './upload-supplier-delivery-file.component.html',
    styleUrls: ['./upload-supplier-delivery-file.component.css']
})
export class UploadSupplierDeliveryFileComponent implements OnInit {
    fileUploadForm: FormGroup;
    loader = false;
    fileToUpload: any;
    invalidcsvFile = false;
    xlsxFileData = {};
    xlsxColumns: any
    authtoken: any;
    invaidFileMessage: any;
    selectedfileType: any = 'prediction_dataset';
    name = 'This is XLSX TO JSON CONVERTER';
    lastloginatTimeDifference: any;
    constructor(private fb: FormBuilder, private matDialog: MatDialog, private service: UploadSupplierDeliveryFileService,
                public dialogRef: MatDialogRef<UploadSupplierDeliveryFileComponent>,
                private cookieServiceVariable: CookieService,
                private fbAuth: StratizantFirebaseAuthService) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.dialogRef.updateSize('50%');
        this.fileUploadForm = this.fb.group({
            uploaded_file: ['', Validators.required],
        });
       
    }


    close() {
        this.dialogRef.close();
    }

    upload() {
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        const formData = new FormData();

        formData.append('file', this.fileToUpload);
        this.service.mapnames(obj, formData).subscribe((res) => {
            this.loader = false;
            const filedata = {
                "fileToUpload": this.fileToUpload,
                "filedata": res
            }
            this.dialogRef.close(
                filedata
            );

            this.openUploadSuccessComponent();
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }

            if(error && error.error == 'Invalid file, please download template for reference.' && error.status == 400){
                this.invaidFileMessage = 'Invalid file, please download template for reference.'
            }
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();
        });

    }


    
    changeFileType(mrChange: MatRadioChange){
        this.selectedfileType = mrChange.value;
    }

    openUploadSuccessComponent() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = 'File';
        const dialogRef = this.matDialog.open(UploadSuccessComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
        });
    }


    getFileName(ev) {


        if (ev != null) {
            this.fileToUpload = ev.target.files[0];
        } else {
            this.fileToUpload = '';
        }
        if (!this.fileToUpload.name.includes('.csv')) {
            this.invalidcsvFile = true;
        } else {
            this.invalidcsvFile = false;
        }
    }
}
