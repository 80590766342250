import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import * as c3 from 'c3';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SupplierTrendChartService } from '../supplier-trend-chart.service';
import { DatePipe } from '@angular/common';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { AdminSettingsService } from '../../admin-settings/admin-settings.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { TableLevel1Service } from '../../table-level1/table-level1.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


declare let $: any;

@Component({
    selector: 'app-supplier-trend-analysis',
    templateUrl: './supplier-trend-analysis.component.html',
    styleUrls: ['./supplier-trend-analysis.component.css']
})
export class SupplierTrendAnalysisComponent implements OnInit {

    trendForm: FormGroup
    infoMsg: string;
    showChart = false;
    chartData: any;
    periods: any = [];
    trustScores: any = [];
    impactedTrustScores: any = [];
    authtoken: string;
    @Input('selectedDuration') selectedDuration: any;
    @Input('selectedSupplier') selectedSupplier: any;
    @Input('selectedSupplierCountry') selectedSupplierCountry: any;
    supplierTrendsData: any;
    loader = false;
    pipe = new DatePipe('en-US');
    currentDuration: any;
    suppliersList: any = [];
    removedDupCountries: any;
    countriesList: any = [];
    suppliersWithCountries: any = [];
    countryOfSelectedSupplier: any;
    errorMsg: boolean;
    noRecordsFoundMsg: boolean = false;
    fitValue: boolean;
    //@Input('supplierName') supplierName: any;
    // selectedSupplier: any;
    supplierName: any;
    fromSupplierDetail = false;
    getAllSuppliersFlag: any;

    // constructor(private fb: FormBuilder, private supplierTrendService: SupplierTrendChartService,
    //             private firebaseAuthService: StratizantFirebaseAuthService, private matDialog: MatDialog,
    //             private route: ActivatedRoute) { }
    currentImpactedScore: any;
    currentTrustScore: any;
    imageToShow: any;
    currentDurationSelected = {
        '1M': '1 month',
        '3M': '3 months',
        '6M': '6 months',
        '9M': '9 months',
        '1Y': '1 year',
        '2Y': '2 years'
    };
    is_default: any;
    getSupplierTrendsDuration: any;
    lastloginatTimeDifference: any;

    constructor(private fb: FormBuilder, private supplierTrendService: SupplierTrendChartService, private afStorage: AngularFireStorage,
                private firebaseAuthService: StratizantFirebaseAuthService,
                private adminService: AdminSettingsService,
                private cookieServiceVariable: CookieService,
                private level1Service: TableLevel1Service,private matDialog: MatDialog, private router: Router) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.trendForm = this.fb.group({
            supplier: [''],
            country: ['']
        });


        this.getAllSuppliers('');

      
        //this.getModeStatus();
        this.currentDuration = '1M';
      
    }

   


    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedSupplier && changes.selectedSupplier.currentValue) {
            // this.currentDuration = changes.selectedDuration.currentValue;
            this.getSupplierTrends(this.currentDuration);

        }
    }

    showPeriodicChart(periodId) {
        if (periodId === '1M') {
            $('#1M').addClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
            this.currentDuration = periodId;

        } else if (periodId === '3M') {
            $('#1M').removeClass('active');
            $('#3M').addClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');

            this.currentDuration = periodId;

        } else if (periodId === '6M') {
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').addClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
            this.currentDuration = periodId;

        } else if (periodId === '9M') {
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').addClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').removeClass('active');
            this.currentDuration = periodId;

        } else if (periodId === '1Y') {
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').addClass('active');
            $('#2Y').removeClass('active');
            this.currentDuration = periodId;

        } else if (periodId === '2Y') {
            $('#1M').removeClass('active');
            $('#3M').removeClass('active');
            $('#6M').removeClass('active');
            $('#9M').removeClass('active');
            $('#1Y').removeClass('active');
            $('#2Y').addClass('active');
            this.currentDuration = periodId;

        }
        this.getSupplierTrends(this.currentDuration);

    }

    showTrendChart(supplier) {
        //this.showChart = true;
        this.infoMsg = '';
        //this.trendForm.controls.country.setValue('USA');
        for (const data in this.suppliersWithCountries) {

            if (supplier === data) {
                this.countryOfSelectedSupplier = this.suppliersWithCountries[data];
            }
        }
        this.trendForm.controls['country'].setValue(this.countryOfSelectedSupplier);

        this.getSupplierTrends(this.currentDuration);
    }

    selectedCountry(country) {
        this.suppliersList = [];
        for (const data in this.suppliersWithCountries) {

            if (country === this.suppliersWithCountries[data]) {
                this.suppliersList.push(data);
            }
        }

    }

    getModeStatus() {

        const obj = {
            'Authorization': this.authtoken
        };

        this.adminService.getModeStatus(obj).subscribe(res => {
            const impacttypeArr = Object.entries(res).map(([type, value]) => ({ type, value }));
            for (const imp_type in impacttypeArr) {

                if (impacttypeArr[imp_type]['type'] === 'COVID-19') {
                    if (impacttypeArr[imp_type]['value']['API'] === true || impacttypeArr[imp_type]['value']['CSV'] === true || impacttypeArr[imp_type]['value']['Manual'] === true) {
                        this.getIcon(impacttypeArr[imp_type]['type']);
                    }
                }
            }
        }, (error) => {
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // } 
        }
        );

    }


    getIcon(impact_type) {
        //this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };

        this.level1Service.getIconURL(data, impact_type).subscribe((res) => {
            if (res) {
                const path = res.Path;
                const storageRef = this.afStorage.storage.ref(path);
                storageRef.getDownloadURL().then(url => {

                    this.imageToShow = url;
                });
            }
            //this.createImageFromBlob(res.body);

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    generateLineChart() {
        //this.showChart = true;
        if (this.supplierTrendsData) {
            this.periods = [];
            this.trustScores = [];
            this.impactedTrustScores = [];
            this.periods[0] = 'x';
            this.trustScores[0] = 'Trust Score';
            this.impactedTrustScores[0] = 'Impacted Trust Score';
            this.imageToShow = '';
            for (const trendsIndex in this.supplierTrendsData) {
                const trendsData = this.supplierTrendsData[trendsIndex];
                this.trustScores.push(trendsData['trust_score']);
                if (trendsData['impacted_trust_score']) {
                    this.impactedTrustScores.push(trendsData['impacted_trust_score']);
                } else {
                    this.impactedTrustScores.push(0);
                }
                const convDate = new Date(trendsIndex);
                let date = JSON.stringify(convDate)
                date = date.slice(1, 11);
                this.periods.push(date);

                const today = new Date();
                let nowDate = JSON.stringify(today);
                nowDate = nowDate.slice(1, 11);
                if (JSON.stringify(nowDate) === JSON.stringify(date)) {
                    this.currentTrustScore = trendsData['trust_score'];
                    this.currentImpactedScore = trendsData['impacted_trust_score'] ? trendsData['impacted_trust_score'] : 0;
                    if(this.currentImpactedScore && this.currentImpactedScore != 0){
                        this.getIcon('COVID-19');
                    }
                }
            }
        }
        const vm = this;
        //let firstMon = false;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const chart = c3.generate({
            bindto: '#lineChart',
            data: {
                x: 'x',
                xFormat: '%Y-%m-%d',
                columns: [
                    this.periods,
                    this.trustScores,
                    this.impactedTrustScores
                ]
            },
            point: {
                r: 0,
                focus: { expand: { r: 5 } }
            },
            padding: {
                right: 12
            },
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: function (d) {
                            return (vm.pipe.transform(d, 'd MMM yy'));
                        },
                        fit: vm.fitValue
                    },
                    // tick: {
                    //     format : function(d) {
                   
                    //         if (vm.currentDuration === '1M' && (new Date(d).getDate()) % 2 == 0){

                    //             return (vm.pipe.transform(d, 'd. MMM'));
                    //         } else if (vm.currentDuration === '3M' && (new Date(d).getDay() === 1)){
                    //             return (vm.pipe.transform(d, 'd. MMM'));
                    //         } else if (vm.currentDuration === '6M' && (new Date(d).getDay() === 1) && this.firstMon){
                    //             this.firstMon = false;
                    //             return (vm.pipe.transform(d, 'd. MMM'));
                    //         } else if (vm.currentDuration === '6M' && (new Date(d).getDay() === 1) && !this.firstMon){
                    //             this.firstMon = true;
                    //             return '';
                    //         } else if (vm.currentDuration === '9M' && (new Date(d).getDay() === 1) && this.firstMon){
                    //             this.firstMon = false;
                    //             return (vm.pipe.transform(d, 'd. MMM'));
                    //         } else if (vm.currentDuration === '9M' && (new Date(d).getDay() === 1) && !this.firstMon){
                    //             this.firstMon = true;
                    //             return '';
                    //         }  else if (vm.currentDuration === '1Y' && new Date(d).getDate() === 1){                
                    //             return (vm.pipe.transform(d, 'MMM. yy'));
                    //         } else if (vm.currentDuration === '2Y' && new Date(d).getDate() === 1){                
                    //             return (vm.pipe.transform(d, 'MMM. yy'));
                    //         } else {
                    //             return '';
                    //         }
                    //     },
                    //     fit: false
                    // },
                    height: 50,
                    label: {
                        text: 'Period',
                        position: 'outer-center',
                    }
                },
                y: {
                    show: true,
                    label: {
                        text: 'Trust Score',
                        position: 'outer-middle',
                    }
                }
            },
            // tooltip: {
            //     format: {
            //         title: function (d) {
            //             return (vm.pipe.transform(d, 'd. MMM'));
            //         }

            //     }
            // },
            grid: {
                lines: {
                    front: false,

                },
                y: {
                    show: true,

                }
            }
        });

    }

    clearSelection() {
        this.trendForm.reset();
        this.suppliersList = Object.keys(this.suppliersWithCountries);
        this.countriesList = Object.values(this.suppliersWithCountries);
        this.removedDupCountries = this.countriesList.filter((value, index) => this.countriesList.indexOf(value) === index);
        this.countriesList = this.removedDupCountries;
        this.supplierTrendsData = [];
        this.fitValue = true;
        this.noRecordsFoundMsg = false;
        this.generateLineChart();
    }

    getSupplierTrends(duration) {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.getSupplierTrendsDuration = duration;
        const supplier_name = this.selectedSupplier;
        const country = this.selectedSupplierCountry;

        this.supplierTrendService.getSupplierTrends(data, duration, supplier_name, country).subscribe((res) => {
            this.loader = false;
            if (Object.keys(res['legacy_trustscores']).length == 0) {
                this.fitValue = true;
                this.noRecordsFoundMsg = true;
            } else {
                this.fitValue = false;
                this.noRecordsFoundMsg = false;
            }
            this.is_default = res['is_default'];
            this.supplierTrendsData = res['legacy_trustscores'];
            this.generateLineChart();

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });

    }


    getAllSuppliers(flag) {

        const data = {
            'Authorization': localStorage.getItem('auth_token')
        };
        this.loader = true;
        this.getAllSuppliersFlag = flag;

        this.supplierTrendService.getAllSuppliers(data).subscribe((res) => {
            this.suppliersWithCountries = res;
            this.suppliersList = Object.keys(res);
            this.countriesList = Object.values(res);
            this.removedDupCountries = this.countriesList.filter((value, index) => this.countriesList.indexOf(value) === index);
            this.countriesList = this.removedDupCountries;
            if(flag === 'fromSupplierDetail' || this.fromSupplierDetail == true){
                for (const data in this.suppliersWithCountries) {
            
                    if (this.supplierName === data) {
                        this.countryOfSelectedSupplier = this.suppliersWithCountries[data];
                    }
                }
                
                this.trendForm.controls['country'].setValue(this.countryOfSelectedSupplier);
                this.getSupplierTrends(this.currentDuration);
            }else{
                this.trendForm.controls.supplier.setValue(this.suppliersList[0]);
                this.showTrendChart(this.suppliersList[0]);
            }
            
        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });

    }



    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.firebaseAuthService.signout();

        });

    }

    goToDetailPage(supplierName) {
        localStorage.setItem('navigated_from', 'trendChart');
        localStorage.setItem('selected_supplier', supplierName);
        localStorage.setItem('backToTrend', 'true');
        this.router.navigate(['/home/supplierDetail/', supplierName]);
    }


}
