import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
                private afAuth: StratizantFirebaseAuthService,
                public dialogRef: MatDialogRef<ErrorDialogComponent>) { }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
    }

    close() {
        this.dialogRef.close();
    }


    retry() {
        location.reload();
        this.dialogRef.close();
    }
        
    cancel(){
        this.afAuth.signout();
        this.dialogRef.close();
        this.router.navigate(['/login']).then(() => {
        });
    }
}
