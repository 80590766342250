import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialogConfig, MatDialog } from '@angular/material';
import { AddEditOrgModalComponent } from './add-edit-org-modal/add-edit-org-modal.component';
import { OrgManagementService } from './org-management.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SubscrioptionLevelService } from '../subscription-level/subscrioption-level.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-org-management',
    templateUrl: './org-management.component.html',
    styleUrls: ['./org-management.component.css']
})
export class OrgManagementComponent implements OnInit {

    public displayedColumns = ['org_id', 'org_domain_name', 'org_display_name', 'subscription_level', 'action'];
    public dataSource = new MatTableDataSource;
    @ViewChild('paginator', { static: false }) paginator: MatPaginator;
    loader = false;
    authtoken: string;
    organizations: any;
    @Input('orgTabClicked') orgTabClicked: any = [];
    filterForm: FormGroup;
    organizationDomainList: any[];
    organizationDisplayList: any[];
    noOrgData: boolean;
    selectedFilter: boolean = false;
    subscriptions: any = [];

    constructor(private matDialog: MatDialog, private orgService: OrgManagementService, 
                private fb: FormBuilder, private cookieServiceVariable: CookieService,
                private subscriptionService: SubscrioptionLevelService, private fbAuth: StratizantFirebaseAuthService) { }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.filterForm = this.fb.group({
            org_domain_name: [''],
            org_display_name: [''],
            subscription_level: ['']
        });
        this.getAllSubcriptionLevels();
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.orgTabClicked.currentValue != null && changes.orgTabClicked.currentValue != undefined) {
            if (this.filterForm) {
                this.filterForm.controls.org_domain_name.setValue('');
                this.filterForm.controls.org_display_name.setValue('');
                this.selectedFilter = false;
            }
            this.getOrganizationsList();
            this.getAllSubcriptionLevels();
        }
    }


    performAction(action, element) {
        this.openAddEditOrgModal(action, element);
    }


    getOrganizationsList() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }

        this.orgService.getOrganizationList(obj).subscribe(res => {

            if (res) {
                this.noOrgData = false;
                res = res.replace(/'/g, '"');
                this.organizations = JSON.parse(res);

                this.organizationDomainList = [];
                this.organizations.filter((value) => {
                    if (this.organizationDomainList.indexOf(value['domain_name']) == -1) {
                        this.organizationDomainList.push(value['domain_name']);
                    }
                });

                this.organizationDisplayList = [];
                this.organizations.filter((value) => {
                    if (this.organizationDisplayList.indexOf(value['display_name']) == -1) {
                        this.organizationDisplayList.push(value['display_name']);
                    }
                });

                const sortState = {
                    'active': "timestamp",
                    'direction': "desc"
                }

                this.customSort(sortState, this.organizations);
            } else {
                this.noOrgData = true;
                this.loader = false;
            }
        }, (error) => {
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            this.loader = false;
        });
    }



    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }


    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    customSort(val, dataArray) {
        const data = dataArray.slice();
        const sortedData = data.sort((a, b) => {
            const isAsc = val.direction === 'asc';
            switch (val.active) {
                case 'timestamp': return this.compare(new Date(a.timestamp), new Date(b.timestamp), isAsc);

                default: return 0;
            }
        });
        this.dataSource = new MatTableDataSource(sortedData);
        this.dataSource.paginator = this.paginator;
        this.loader = false;

        setTimeout(() =>
            this.dataSource.paginator = this.paginator);
    }


    openAddEditOrgModal(action, element) {

        const dialogConfig = new MatDialogConfig();
        if (action === 'View/Edit') {
            dialogConfig.data = {
                'formData': element,
                'action': action
            };
        } else if (action == 'Add') {
            dialogConfig.data = {
                'action': action
            };
        }
        const dialogRef = this.matDialog.open(AddEditOrgModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            if (value === false) {
                this.getOrganizationsList();
            }
        });

    }


    public doFilter = (value: string) => {

        this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.dataSource.paginator = this.paginator;
    }


    searchUser() {
        const filteredList = [];
        this.noOrgData = false;
        if (this.filterForm.controls.org_domain_name.value && this.filterForm.controls.org_display_name.value && this.filterForm.controls.subscription_level.value) {
            for (const orgIndex in this.organizations) {
                const org = this.organizations[orgIndex];
                if (org['domain_name'] == this.filterForm.controls.org_domain_name.value && org['display_name'] == this.filterForm.controls.org_display_name.value &&
                    org['subscription_level'] == this.filterForm.controls.subscription_level.value) {
                    filteredList.push(org);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        } else if (this.filterForm.controls.org_domain_name.value && this.filterForm.controls.subscription_level.value) {
            for (const orgIndex in this.organizations) {
                const org = this.organizations[orgIndex];
                if (org['domain_name'] == this.filterForm.controls.org_domain_name.value && org['subscription_level'] == this.filterForm.controls.subscription_level.value) {
                    filteredList.push(org);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        } else if (this.filterForm.controls.org_display_name.value && this.filterForm.controls.subscription_level.value) {
            for (const orgIndex in this.organizations) {
                const org = this.organizations[orgIndex];
                if (org['display_name'] == this.filterForm.controls.org_display_name.value && org['subscription_level'] == this.filterForm.controls.subscription_level.value) {
                    filteredList.push(org);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        } else if (this.filterForm.controls.org_domain_name.value) {
            for (const orgIndex in this.organizations) {
                const org = this.organizations[orgIndex];
                if (org['domain_name'] == this.filterForm.controls.org_domain_name.value) {
                    filteredList.push(org);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        } else if (this.filterForm.controls.org_display_name.value) {
            for (const orgIndex in this.organizations) {
                const org = this.organizations[orgIndex];
                if (org['display_name'] == this.filterForm.controls.org_display_name.value) {
                    filteredList.push(org);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        } else if (this.filterForm.controls.subscription_level.value) {
            for (const orgIndex in this.organizations) {
                const org = this.organizations[orgIndex];
                if (org['subscription_level'] == this.filterForm.controls.subscription_level.value) {
                    filteredList.push(org);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }


        if (filteredList.length == 0) {
            this.noOrgData = true;
        }
    }

    clearFilter() {
        this.filterForm.controls.org_display_name.setValue('');
        this.filterForm.controls.org_domain_name.setValue('');
        this.filterForm.controls.subscription_level.setValue('');
        this.selectedFilter = false;
        this.getOrganizationsList();
    }

    checkFilter() {
        if (this.filterForm.value.org_display_name || this.filterForm.value.org_domain_name || this.filterForm.value.subscription_level) {
            this.selectedFilter = true;
        } else {
            this.selectedFilter = false;
        }
    }

    getAllSubcriptionLevels() {
        const obj = {
            "Authorization": this.authtoken
        }
        //this.loader = true;
        this.subscriptionService.getSubscriptionLevelDetails(obj).subscribe((res) => {
            //this.loader = false;
            const subscribtionLevels = Object.entries(res).map(([type, value]) => ({ type, value }));

            for (const sub in subscribtionLevels) {
                const data = {
                    'sub_id': sub,
                    'subscription_level': subscribtionLevels[sub]['value']['subscription_level']
                }
                this.subscriptions.push(data);
            }
        }, (error) => {
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
            //this.loader = false;
        });
    }




}
