import {Component, OnInit, ViewChild, Input, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatPaginator, MatTableDataSource, MatDialog, MatDialogConfig} from '@angular/material';
import {UserManagementService} from './user-management.service'
import { AddUserModalComponent } from './add-user-modal/add-user-modal.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { RequestDialogService } from 'src/app/dash-board-login/request-dialog/request-dialog.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { RequestDialogComponent } from 'src/app/dash-board-login/request-dialog/request-dialog.component';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
    actionForm: FormGroup;
    action: any;
    currentUser: any;
    users: any = [];
    loader = false;
    public displayedColumns = ['first_name', 'last_name', 'email', 'organisation_domain', 'role_type', 'status', 'action'];
    public dataSource = new MatTableDataSource;
    @ViewChild('paginator', {static: false}) paginator: MatPaginator;
    @Input('userTabClicked') userTabClicked: any = [];

    userDetailsForm: FormGroup;
    validForm: boolean = false;
    showAddUserTab = false;
    authtoken: any;
    organizationList: any = [];
    filterForm: FormGroup;
    noUserData = false;
    selectedFilter: boolean = false;
    sendResetPasswordEmailData: any;
    updateUserAction: any;
    updateUserElementData: any;
    deleteUserId: any;
    lastloginatTimeDifference: any;

    constructor(private fb: FormBuilder, private userService: UserManagementService, private matDialog: MatDialog,
                private requestService: RequestDialogService,
                private cookieServiceVariable: CookieService,
                private fbAuth: StratizantFirebaseAuthService) {
    }

    ngOnInit() {
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.actionForm = this.fb.group({
            action: [''],
        });
        this.filterForm = this.fb.group({
            organization: [''],
            role: [''],
            status: ['']
        });
      
    }


 

    ngOnChanges(changes: SimpleChanges) {

        if (changes.userTabClicked.currentValue != null && changes.userTabClicked.currentValue != undefined) {
            if(this.filterForm){
                this.filterForm.controls.organization.setValue('');
                this.filterForm.controls.role.setValue('');
                this.filterForm.controls.status.setValue('');
                this.selectedFilter = false;
            }
            
            this.getAllUsersList();
        }
    }


    performAction(val, element) {
        if (val === 'viewedit') {
            this.action = 'Update';
            this.openAddUserModal('Update',element);
        }else if(val === 'suspend'){
            this.updateUser('Suspend',element);
        }else if(val === 'activate'){
            this.updateUser('Activate',element);
        }else if(val === 'delete'){
            this.openConfirmationModal('Delete', element);
        }else if(val === 'reset_password'){
            this.sendResetPasswordEmail(element);
        }
    }


    getAllUsersList() {
        this.loader = true;

        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.userService.getAllUsersList(obj).subscribe(res => {
           
            // const new_array = Object.entries(res).map(([type, value]) => ({ type, value }));
            if(Object.keys(res).length > 0){
                this.noUserData = false;
                const arr = Object.entries(res).map(([type, value]) => ({type,value}));
                let displayName = [];
                this.users = [];
                for(const userIndex in arr){
                    let firstName, lastName;

                    if (arr[userIndex]['value']['display_name']) {
                        displayName = arr[userIndex]['value']['display_name'].split(" ");
                        firstName = displayName[0];
                        lastName = displayName[1];
                    } else {
                        firstName = '';
                        lastName = '';
                    }
                    const user = {
                        "display_name": arr[userIndex]['value']['display_name'],
                        "first_name": firstName,
                        "last_name": lastName,
                        "email": arr[userIndex]['value']['email'],
                        "organisation_domain": arr[userIndex]['value']['organisation_domain'],
                        "organisation_id": arr[userIndex]['value']['organisation_id'],
                        "phone_number": arr[userIndex]['value']['phone_number'],
                        "role_type": arr[userIndex]['value']['role_type'],
                        "status": arr[userIndex]['value']['status'],
                        "timestamp": arr[userIndex]['value']['timestamp'],
                        "title": arr[userIndex]['value']['title'],
                        "uid": arr[userIndex]['type']
                    }
                    this.users.push(user)
                }
                this.organizationList = [];
                this.users.filter((value) => {
                    if(this.organizationList.indexOf(value['organisation_domain']) == -1){
                        this.organizationList.push(value['organisation_domain']);
                    }});

                this.actionForm.controls.action.setValue('');

                const sortState = {
                    'active': "timestamp",
                    'direction': "desc"
                }

                this.customSort(sortState, this.users);

            }else{
                this.noUserData = true;
                this.loader = false;
            }
            
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        })
    }

    compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    customSort(val, dataArray) {
        const data = dataArray.slice();
        const sortedData = data.sort((a, b) => {
            const isAsc = val.direction === 'asc';
            switch (val.active) {
                case 'timestamp': return this.compare(new Date(a.timestamp), new Date(b.timestamp), isAsc);

                default: return 0;
            }
        });
        this.dataSource = new MatTableDataSource(sortedData);
        // this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.loader = false;

        setTimeout(() =>
            this.dataSource.paginator = this.paginator);
    }




    openAddUserModal(action, element) {

        const dialogConfig = new MatDialogConfig();
        if(action === 'Update'){
            dialogConfig.data = {
                'formData' : element,
                'action': action
            };
        }else if(action == 'Add'){
            dialogConfig.data = {
                'action': action
            };
        }
        const dialogRef = this.matDialog.open(AddUserModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            if(value.flag === 'added'){
                this.openDialog(value.email);
                this.getAllUsersList();
            }else if(value.flag === 'updated'){
                this.getAllUsersList();
            }else{
                this.actionForm.controls.action.setValue('');
            }

        });

    }

    openDialog(email) {
        const dialogConfig = new MatDialogConfig();

        const message = 'Verification mail has been sent to';
        const title = '';
        const emailId = email;
        const resetPassword = false;

        dialogConfig.data = {
            message,
            title,
            emailId,
            resetPassword
        };
        const dialogRef = this.matDialog.open(RequestDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe();

    }



    updateUser(action, element){
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        const formData = new FormData();
        const stringEnc = encodeURI('+');
        let phn_number = '';
        this.updateUserAction = action;
        this.updateUserElementData = element;

        if(element.phone_number){
            const p_num = element.phone_number.replace('+1', '');
            phn_number = stringEnc + 1 + p_num;
        }
        
        let status = '';
        if(action === 'Suspend'){
            status = 'Suspended'
        }else if(action === 'Activate'){
            status = 'Active';
        }

        formData.append('first_name', element.first_name);
        formData.append('last_name', element.last_name);
        formData.append('email', element.email);
        formData.append('organisation_id', element.organisation_id);
        formData.append('role_type', element.role_type);
        formData.append('title', element.title);
        formData.append('phone_number', phn_number);
        formData.append('status', status);
        formData.append('uid', element.uid);


        this.userService.createorEditUserDetails(obj, formData, element.uid).subscribe(() => {
            this.loader = false;
            this.getAllUsersList();
        }, error =>{
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


    openConfirmationModal(action, element){

        const dialogConfig = new MatDialogConfig();
        if(action === 'Delete'){
            dialogConfig.data = {
                'uid' : element.uid,
                'action': action
            };
        }else if(action === 'Reset Password'){
            dialogConfig.data = {
                'action': action
            };
        }
        const dialogRef = this.matDialog.open(GenericModalComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(value => {
            if(value.action === 'Delete'){
                this.deleteUser(value.uid);
            }else{
                this.actionForm.controls.action.setValue('');
            }

        });

    }

    deleteUser(uid){
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.deleteUserId = uid;
        this.userService.deleteUser(obj, uid).subscribe(() => {
            this.loader = false;
            this.getAllUsersList();
        }, error =>{
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }

    sendResetPasswordEmail(element) {
        this.loader = true;
        this.sendResetPasswordEmailData = element
        this.requestService.resetPassword(element.email).then(() => {
            this.loader = false;
            this.openConfirmationModal('Reset Password', element)

        }).catch((error) => {
            this.loader = false;
            // if (error && error.error == 'Unauthorized Access') {
            //     this.openAuthDialog();
            // }
        });
    }


    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }

    public doFilter = (value: string) => {

        this.dataSource.filter = value.trim().toLocaleLowerCase();
        this.dataSource.paginator = this.paginator;
    }

    searchUser(){
        const filteredList = [];
        this.noUserData = false;
        if(this.filterForm.controls.organization.value && this.filterForm.controls.role.value && this.filterForm.controls.status.value){
            for(const userIndex in this.users){               
                const user = this.users[userIndex];
                if(user['organisation_domain'] == this.filterForm.controls.organization.value && user['role_type'] == this.filterForm.controls.role.value && user['status'] == this.filterForm.controls.status.value){
                    filteredList.push(user);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }else if(this.filterForm.controls.organization.value && this.filterForm.controls.role.value){
            for(const userIndex in this.users){
                const user = this.users[userIndex];
                if(user['organisation_domain'] == this.filterForm.controls.organization.value && user['role_type'] == this.filterForm.controls.role.value){
                    filteredList.push(user);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }else if(this.filterForm.controls.organization.value && this.filterForm.controls.status.value){
            for(const userIndex in this.users){
                const user = this.users[userIndex];
                if(user['organisation_domain'] == this.filterForm.controls.organization.value && user['status'] == this.filterForm.controls.status.value){
                    filteredList.push(user);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }else if(this.filterForm.controls.role.value && this.filterForm.controls.status.value){
            for(const userIndex in this.users){
                const user = this.users[userIndex];
                if(user['role_type'] == this.filterForm.controls.role.value && user['status'] == this.filterForm.controls.status.value){
                    filteredList.push(user);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }else if(this.filterForm.controls.organization.value){
            for(const userIndex in this.users){                
                const user = this.users[userIndex];
                if(user['organisation_domain'] == this.filterForm.controls.organization.value){
                    filteredList.push(user);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }else if(this.filterForm.controls.role.value){
            for(const userIndex in this.users){
                const user = this.users[userIndex];
                if(user['role_type'] == this.filterForm.controls.role.value){
                    filteredList.push(user);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }else if(this.filterForm.controls.status.value){
            for(const userIndex in this.users){
                const user = this.users[userIndex];
                if(user['status'] == this.filterForm.controls.status.value){
                    filteredList.push(user);
                    this.dataSource = new MatTableDataSource(filteredList);
                    this.dataSource.paginator = this.paginator;
                }

            }
        }
        if(filteredList.length == 0){
            this.noUserData = true;
        }
    }

    clearFilter(){
        this.filterForm.controls.organization.setValue('');
        this.filterForm.controls.role.setValue('');
        this.filterForm.controls.status.setValue('');
        this.selectedFilter = false;
        this.getAllUsersList();
    }


    checkFilter() {
        if (this.filterForm.value.organization || this.filterForm.value.role || this.filterForm.value.status) {
            this.selectedFilter = true;
        } else {
            this.selectedFilter = false;
        }
    }


}
