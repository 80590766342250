import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserManagementService } from '../user-management.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { CookieService } from 'ngx-cookie-service';
@Component({
    selector: 'app-add-user-modal',
    templateUrl: './add-user-modal.component.html',
    styleUrls: ['./add-user-modal.component.css']
})
export class AddUserModalComponent implements OnInit {

    userDetailsForm: FormGroup;
    authtoken: any;
    organizations: any = [];
    disableDropDown = false;
    loader = false;
    errorMsg: any;
    email: any;
    userDetails: any;
    role_type: any;
    disableBtn = false;
    phoneNumberInvalid: string;
    organization_id: any;
    lastloginatTimeDifference: any;

    constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<AddUserModalComponent>,
                private cookieServiceVariable: CookieService,
                private userService: UserManagementService, private matDialog: MatDialog, private fbAuth: StratizantFirebaseAuthService,
                private afAuth: AngularFireAuth) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.dialogRef.updateSize('60%');
        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
       

        this.userDetailsForm = this.fb.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            title: [''],
            email: ['', Validators.required],
            organisation_name: ['', Validators.required],
            role_type: ['', Validators.required],
            phone_number: ['', [Validators.required, Validators.pattern('^[0-9+]+$'), Validators.minLength(10), Validators.maxLength(12)]]
        });
        if (this.data.action === 'Update') {
            this.setData();
        }
        this.getOrganizationsList();
      

    }

   

    getOrganizationsList() {
        const obj = {
            "Authorization": this.authtoken
        }

        this.userService.getOrganizationList(obj).subscribe(res => {

            res = res.replace(/'/g, '"');
            this.organizations = JSON.parse(res);
           
            console.log("organisaions", this.organizations);

        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
                
        }
        );
    }

    setData() {
        this.userDetailsForm.patchValue({
            first_name: this.data.formData.first_name,
            last_name: this.data.formData.last_name,
            title: this.data.formData.title,
            email: this.data.formData.email,
            organisation_name: this.data.formData.organisation_id,
            role_type: this.data.formData.role_type,
            phone_number: this.data.formData.phone_number,
            status: this.data.formData.status
        });

        this.email = this.userDetailsForm.value.email;

        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));

        if (this.userDetails.email === this.userDetailsForm.value.email) {
            this.organization_id = this.data.formData.organisation_id;
            this.role_type = this.userDetailsForm.value.role_type;
            this.userDetailsForm.controls.email.disable();
            this.userDetailsForm.controls.role_type.disable();
            this.userDetailsForm.controls.organisation_name.disable();
        } else if (this.data.formData.role_type === 'Super Admin') {
            this.userDetailsForm.controls.first_name.disable();
            this.userDetailsForm.controls.last_name.disable();
            this.userDetailsForm.controls.title.disable();
            this.userDetailsForm.controls.email.disable();
            this.userDetailsForm.controls.phone_number.disable();
            this.userDetailsForm.controls.role_type.disable();
            this.userDetailsForm.controls.organisation_name.disable();
            this.disableBtn = true;
        } else {

            this.userDetailsForm.controls.email.disable();
        }
    }


    close() {
        this.dialogRef.close('cancel');
    }

    saveUser() {
        this.loader = true;
        const obj = {
            "Authorization": this.authtoken
        }
        this.errorMsg = '';
        let uid = '';
        let email,role_type;
        if (this.data.action === 'Update') {
            uid = this.data.formData.uid;
            email = this.email;
            if(this.role_type){
                role_type = this.role_type;
            }else{
                role_type = this.userDetailsForm.value.role_type;
            }
        } else {
            email = this.userDetailsForm.value.email;
            role_type = this.userDetailsForm.value.role_type;
        }

        const formData = new FormData();

        formData.append('first_name', this.userDetailsForm.value.first_name);
        formData.append('last_name', this.userDetailsForm.value.last_name);
        formData.append('email', email);        
        formData.append('role_type', role_type);
        formData.append('title', this.userDetailsForm.value.title);
        formData.append('phone_number', this.userDetailsForm.value.phone_number);

        if (this.data.action === 'Add') {
            let org_name: string;
            for(const org in this.organizations){
                if(this.organizations[org].org_id === this.userDetailsForm.value.organisation_name){
                    org_name = this.organizations[org].display_name;
                }
            }
            formData.append('password', 'Stratizant12345');
            formData.append('status', 'Pending Activation');
            formData.append('organisation_name', org_name);
        } else {
            if(this.organization_id && this.userDetails.email === this.email){
                formData.append('organisation_id', this.organization_id);
            }else{
                formData.append('organisation_id', this.userDetailsForm.value.organisation_name);
            }
            formData.append('status', this.data.formData.status);
            formData.append('uid', uid);
            
        }

        this.userService.createorEditUserDetails(obj, formData, uid).subscribe(() => {
            // this.loader = false;


            if (this.data.action === 'Add') {

                const vm = this;
                this.userService.signIn(email, 'Stratizant12345').then((result) => {
                    //this.loader = false;
                    if (result) {
                        const actionCodeSettings = {
                            url: 'https://fullconfidence.stratizant.com/login',
                        };
                        //this.afAuth.onAuthStateChanged((user) => {
                        // if (user) {

                        result['user'].sendEmailVerification(actionCodeSettings).then(function () {
                            vm.loader = false;
                            const data = {
                                flag: 'added',
                                email: email
                            }
                            vm.dialogRef.close(data);
                        });
                        //   }
                        // }).then(() => { });
                    }
                });
            } else {
                this.loader = false;
                const data = {
                    flag: 'updated'
                }
                this.dialogRef.close(data);
            }

        }, error => {
            this.loader = false;
            if (error && error.error && error.error.Error) {
                this.errorMsg = error.error.Error;
            }
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            //  else if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.dialogRef.close('cancel');
            //     this.openAuthDialog();
               
            // }
        });
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }



    validatePhoneNumber(p_no, action){
        const numbers = /^[0-9]+$/;
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.userDetailsForm.controls.phone_number.setValue(p_no);
        }
        if((p_no.length != 10 || !p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = 'Enter 10 digit contact number';
        }else if((p_no.length == 10 && p_no.match(numbers)) && action === 'validation'){
            this.phoneNumberInvalid = '';
        }else if(p_no.length == 10 && p_no.match(numbers) && action === 'addCode'){
            this.phoneNumberInvalid = '';
            const stringEnc = encodeURI('+');
            const phn_number = stringEnc + 1 + this.userDetailsForm.value.phone_number;
            this.userDetailsForm.controls.phone_number.setValue(phn_number);
        }
    }
    get f() { return this.userDetailsForm.controls; }

    removeCode(p_no){
        if(p_no.includes('+1')){
            p_no = p_no.replace('+1','');
            this.userDetailsForm.controls.phone_number.setValue(p_no);
        }
    }



}
