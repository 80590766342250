import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { MaterialModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TabularLobComponent } from './home/tabular-lob/tabular-lob.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleModule } from 'angular-user-idle';

import {
    MAT_DIALOG_DATA,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatDialogRef,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatOptionModule,
    // MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule
    // MatRadioModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoaderComponent } from './loader/loader.component';
import { TableLevel1Component } from './home/table-level1/table-level1.component';
import { StratizantFireStoreService } from './services/stratizant-fire-store.service';
import { StratizantFirebaseAuthService } from './services/stratizant-firebase-auth.service';
import { DashBoardLoginComponent } from './dash-board-login/dash-board-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StratizantFirebaseService } from './services/stratizant-firebase.service';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireAuthModule, AngularFireAuth } from "@angular/fire/auth";
import { PoPredictionDetailsComponent } from './home/po-prediction-details/po-prediction-details.component';

import { Level1AnalysisComponent } from './home/table-level1/level1-analysis/level1-analysis.component';

import { IncorrectRouteComponent } from './incorrect-route/incorrect-route.component';
import { AdminSettingsComponent } from './home/admin-settings/admin-settings.component';
import { UploadDialogComponent } from './home/admin-settings/upload-dialog/upload-dialog.component';
import { AdminSettingsService } from './home/admin-settings/admin-settings.service';
import { TableLevel1Service } from './home/table-level1/table-level1.service';
import { AuthDialogComponent } from './shared/auth-dialog/auth-dialog/auth-dialog.component';
import { ChartViewComponent } from './home/chart-view/chart-view.component';
import { AllSupAnalysisComponent } from './home/chart-view/all-sup-analysis/all-sup-analysis.component';
import { UserManagementComponent } from './home/admin-settings/user-management/user-management.component';
import { ImpactDataLoadComponent } from './home/admin-settings/impact-data-load/impact-data-load.component';
import { RequestDialogComponent } from './dash-board-login/request-dialog/request-dialog.component';
import { ResetPasswordComponent } from './dash-board-login/reset-password/reset-password.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { UserManagementService } from './home/admin-settings/user-management/user-management.service';
import { MatTabsModule } from '@angular/material/tabs';
import { AddUserModalComponent } from './home/admin-settings/user-management/add-user-modal/add-user-modal.component';
import { GoogleAnalyticsService } from './google-analytics.service';
import { SignUpComponent } from './dash-board-login/sign-up/sign-up.component';
import { RequestDemoComponent } from './dash-board-login/request-demo/request-demo.component';
import { GenericModalComponent } from './home/admin-settings/user-management/generic-modal/generic-modal.component';
import { ImpactTypeComponent } from './home/admin-settings/impact-data-load/impact-type/impact-type.component';
import { UserProfileComponent } from './home/user-profile/user-profile.component';
import { ContactDetailsComponent } from './home/user-profile/contact-details/contact-details.component';
import { ChangePasswordComponent } from './home/user-profile/change-password/change-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserProfileService } from './home/user-profile/user-profile.service';
import { OrgManagementComponent } from './home/admin-settings/org-management/org-management.component';
import { AddEditOrgModalComponent } from './home/admin-settings/org-management/add-edit-org-modal/add-edit-org-modal.component';
import { RecaptchaModule } from 'angular-google-recaptcha';
import { AddManualImpactDataModalComponent } from './home/admin-settings/impact-data-load/add-manual-impact-data-modal/add-manual-impact-data-modal.component';
import { AddNewImpactTypeComponent } from './home/admin-settings/impact-data-load/add-new-impact-type/add-new-impact-type.component';
import { UploadSuccessComponent } from './home/admin-settings/impact-data-load/upload-success/upload-success.component';
import { SupplierTrendChartComponent } from './home/supplier-trend-chart/supplier-trend-chart.component';
import { SupplierTrendAnalysisComponent } from './home/supplier-trend-chart/supplier-trend-analysis/supplier-trend-analysis.component';
import { UploadSupplierDelivryDataComponent } from './home/admin-settings/upload-supplier-delivry-data/upload-supplier-delivry-data.component';
import { UploadSupplierDeliveryFileComponent } from './home/admin-settings/upload-supplier-delivry-data/upload-supplier-delivery-file/upload-supplier-delivery-file.component';
import { MapFileComponent } from './home/admin-settings/upload-supplier-delivry-data/map-file/map-file.component';
import { CompleteMappingDialogComponent } from './home/admin-settings/upload-supplier-delivry-data/complete-mapping-dialog/complete-mapping-dialog.component';
import { SupplierDetailComponent } from './home/po-prediction-details/supplier-detail/supplier-detail.component';
import { SupplierDataService } from './services/supplier-data.service'
import { SubscriptionLevelComponent } from './home/admin-settings/subscription-level/subscription-level.component';
import { UpdateSubscriptionLevelUserIpComponent } from './home/admin-settings/subscription-level/update-subscription-level-user-ip/update-subscription-level-user-ip.component';
import { SubscriptionSuccessDialogComponent } from './home/admin-settings/subscription-level/subscription-success-dialog/subscription-success-dialog.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { NextPageComponent } from './dash-board-login/sign-up/next-page/next-page.component';
import { SafePipe } from './shared/safe/safe.pipe';
import { CommonModule } from '@angular/common';
import { UpgradeSubscriptionComponent } from './home/upgrade-subscription/upgrade-subscription.component';
import { OwlModule } from 'ngx-owl-carousel';
import { SupplierAnalysisComponent } from './home/po-prediction-details/supplier-detail/supplier-analysis/supplier-analysis.component';
import { FileUploadModule } from 'ng2-file-upload';

import { SelectedSubscriptionPlanComponent } from './home/selected-subscription-plan/selected-subscription-plan.component';
import { PlanDetailsComponent } from './home/user-profile/plan-details/plan-details.component';
import { SubscriptionSuccessComponent } from './home/selected-subscription-plan/subscription-success/subscription-success.component';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { DeletesubscriptionModalComponent } from './home/user-profile/plan-details/deletesubscription-modal/deletesubscription-modal.component';
import { DeleteSuccessModalComponent } from './home/user-profile/plan-details/deletesubscription-modal/delete-success-modal/delete-success-modal.component';
import { InitSessionErrorDialogueComponent } from './shared/init-session-error-dialogue/init-session-error-dialogue.component';
import { FileCountReachDialogComponent } from './home/admin-settings/upload-supplier-delivry-data/file-count-reach-dialog/file-count-reach-dialog.component';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CookieService } from 'ngx-cookie-service';


@NgModule({
    declarations: [
        AppComponent,
        TabularLobComponent,
        HomeComponent,
        LoaderComponent,
        TableLevel1Component,
        DashBoardLoginComponent,
        PoPredictionDetailsComponent,
        Level1AnalysisComponent,
        IncorrectRouteComponent,
        AdminSettingsComponent,
        UploadDialogComponent,
        SubscriptionSuccessDialogComponent,
        AuthDialogComponent,
        ChartViewComponent,
        AllSupAnalysisComponent,
        UserManagementComponent,
        ImpactDataLoadComponent,
        RequestDialogComponent,
        ResetPasswordComponent,
        AddUserModalComponent,
        SignUpComponent,
        RequestDemoComponent,
        GenericModalComponent,
        ImpactTypeComponent,
        UserProfileComponent,
        ContactDetailsComponent,
        ChangePasswordComponent,
        OrgManagementComponent,
        AddEditOrgModalComponent,
        AddManualImpactDataModalComponent,
        AddNewImpactTypeComponent,
        UploadSuccessComponent,
        SupplierTrendChartComponent,
        SupplierTrendAnalysisComponent,
        UploadSupplierDelivryDataComponent,
        UploadSupplierDeliveryFileComponent,
        MapFileComponent,
        CompleteMappingDialogComponent,
        SupplierDetailComponent,
        NextPageComponent,
        SubscriptionLevelComponent,
        UpdateSubscriptionLevelUserIpComponent,
        SubscriptionSuccessDialogComponent,
        SafePipe,
        UpgradeSubscriptionComponent,
        SupplierAnalysisComponent,
        SelectedSubscriptionPlanComponent,
        PlanDetailsComponent,
        SubscriptionSuccessComponent,
        ErrorDialogComponent,
        DeletesubscriptionModalComponent,
        DeleteSuccessModalComponent,
        InitSessionErrorDialogueComponent,
        FileCountReachDialogComponent,
    ],
    imports: [
        RouterModule.forRoot([]),
        UserIdleModule.forRoot({idle:5, timeout:10, ping:60}),
        NgIdleKeepaliveModule.forRoot(),
        NgbModule,
        // MaterialModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSortModule,
        BrowserAnimationsModule,
        MatSlideToggleModule,
        MatDialogModule,
        AngularFireModule.initializeApp(StratizantFirebaseService.config),
        AngularFireDatabaseModule,
        AngularFireAnalyticsModule,
        MatButtonModule,
        MatCardModule,
        MatProgressSpinnerModule,
        AngularFireAuthModule,
        // MatDialogModule,
        HttpClientModule,
        AngularFireStorageModule,
        MatTabsModule,
        MatOptionModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxPaginationModule,
        MatStepperModule,
        RecaptchaModule.forRoot({
            siteKey: '6LcoWwEVAAAAADxzP8mznyKGDbEMghy8vXjrkibi',
        }),
        OwlModule,
        FileUploadModule
    ],
    providers: [
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: HttpErrorInterceptor,
        //     multi: true
        // },
        AngularStripeService, CookieService,AngularFirestore, StratizantFireStoreService, StratizantFirebaseAuthService, StratizantFirebaseService, AngularFireAuth,
        AdminSettingsService, UserManagementService, TableLevel1Service, ScreenTrackingService, UserTrackingService, GoogleAnalyticsService, {
            provide: MatDialogRef,
            useValue: {},

        }, { provide: MAT_DIALOG_DATA, useValue: [] }, UserProfileService, SupplierDataService],
    bootstrap: [AppComponent],
    entryComponents: [
        CompleteMappingDialogComponent,
        UploadDialogComponent,
        UploadSupplierDeliveryFileComponent,
        UploadSuccessComponent,
        FileCountReachDialogComponent,
        AuthDialogComponent,
        InitSessionErrorDialogueComponent,
        RequestDialogComponent,
        AddUserModalComponent,
        GenericModalComponent,
        ImpactTypeComponent,
        AddEditOrgModalComponent,
        AddManualImpactDataModalComponent,
        AddNewImpactTypeComponent,
        UpdateSubscriptionLevelUserIpComponent,
        SubscriptionSuccessDialogComponent,
        SignUpComponent,
        NextPageComponent,
        DeletesubscriptionModalComponent,
        DeleteSuccessModalComponent,
        RequestDemoComponent, ContactDetailsComponent, SubscriptionSuccessComponent, ErrorDialogComponent],
    // entryComponents: [UploadDialogComponent,UploadSuccessComponent, AuthDialogComponent, RequestDialogComponent, AddUserModalComponent, GenericModalComponent,
    //     ImpactTypeComponent, AddEditOrgModalComponent, SubscriptionSuccessDialogComponent, UpdateSubscriptionLevelUserIpComponent, AddManualImpactDataModalComponent, AddNewImpactTypeComponent],
    exports: [AngularFireDatabaseModule, MatProgressSpinnerModule, MatDatepickerModule,
        MatNativeDateModule, AppRoutingModule,
        ContactDetailsComponent, ChangePasswordComponent, MatRadioModule, LoaderComponent,
        MatButtonModule,
        MatCardModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatOptionModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule
        // MaterialModule
    ]
})
export class AppModule {
}
