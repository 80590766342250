import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { SessionManagementServiceService } from 'src/app/services/session-management-service.service';
import {Router} from '@angular/router';
@Component({
    selector: 'app-auth-dialog',
    templateUrl: './auth-dialog.component.html',
    styleUrls: ['./auth-dialog.component.css']
})
export class AuthDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private router: Router,
                private sessionManagementServiceService:SessionManagementServiceService,
                public dialogRef: MatDialogRef<AuthDialogComponent>) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.dialogRef.updateSize('50%');
    }

    close() {
        this.dialogRef.close();
        this.endSession();
    }

    endSession() {
        this.sessionManagementServiceService.endSession().subscribe((res) => {
            console.log(res);
            this.router.navigate(['/login']).then(() => {
            });
        }, (error) => {
            console.log(error);
        });
    }

}
