import {
    Component,
    ViewChild,
    ElementRef,
    ChangeDetectorRef, OnInit
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { SubscrioptionLevelService } from '../admin-settings/subscription-level/subscrioption-level.service';
import { AuthDialogComponent } from 'src/app/shared/auth-dialog/auth-dialog/auth-dialog.component';
import { StratizantFirebaseAuthService } from 'src/app/services/stratizant-firebase-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service'
import Stepper from 'bs-stepper';
import { SelectedSubscriptionPlanService } from './selected-subscription-plan.service';
import { SubscriptionSuccessComponent } from './subscription-success/subscription-success.component';
import { UserProfileService } from '../user-profile/user-profile.service';
import { OrgManagementService } from '../admin-settings/org-management/org-management.service';
import { UserManagementService } from '../admin-settings/user-management/user-management.service';
import { CookieService } from 'ngx-cookie-service';

//import { SubscriptionSuccessComponent } from './subscription-success/subscription-success.component';
declare let $: any;

@Component({
    selector: 'app-selected-subscription-plan',
    templateUrl: './selected-subscription-plan.component.html',
    styleUrls: ['./selected-subscription-plan.component.css']
})
// AfterViewInit
export class SelectedSubscriptionPlanComponent implements OnInit {
    cdNumber: any;

    hideNext = false;
    takeOffForm = false;
    enterpriseForm = false;

    private stepper: Stepper;
    loader = false;

    take_off_subscription_level: any;
    initiator_subscription_level: any;
    enterprise_subscription_level: any;

    initiatorUserCnt: any;
    takeoffUserCnt: any;
    enterPriseUserCnt: any;

    initiator_prediction_file_count_limit: any;
    takeoff_prediction_file_count_limit: any;
    enterprise_prediction_file_count_limit: any;

    initiator_total_file_storage_space: any;
    takeoff_total_file_storage_space: any;
    enterprise_total_file_storage_space: any;

    initiator_configAdmin_Cost: any;
    takeoff_configAdmin_Cost: any;
    enterprise_configAdmin_Cost: any;


    initiator_dashboard_Cost: any;
    takeoff_dashboard_Cost: any;
    enterprise_dashboard_Cost: any;

    initiator_dashboard_user_count: any;
    takeoff_dashboard_user_count: any;
    enterprise_dashboard_user_count: any;

    initiatorTodaysTotal: any;
    takeoffTodaysTotal: any;
    enterPriseTodaysTotal: any;



    initiator_configAdmin_User_Cnt: any;
    initiator_dashboard_User_Cnt: any;

    takeoff_configAdmin_User_Cnt: any;
    takeoff_dashboard_User_Cnt: any;

    enterprise_configAdmin_User_Cnt: any;
    enterprise_dashboard_User_Cnt: any;
    todaysTotal: any;
    selectedPlan: any;

    @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;

    cardNumber: any;
    cardExpiry: any;
    postalCode: any;
    cardCvc: any;



    card: any;
    phno: any;
    cardHandler = this.onChange.bind(this);
    error: string;
    title = 'stripePoc';
    successMsg: any;
    errorMsg: any;
    stripe;
    invalidForm: boolean;
    additionalUserForm: FormGroup;

    authtoken: any;
    organisationId: any;
    subscriptionId: any;
    changeSubscription: boolean;
    allowed_additional_user_count: any;

    sameOrganisationUsersList: any = [];
    organizations: any;
    trial_period_count: any;
    planname: any;

    sameOrganisationUserCnt: any;
    remainingEnterpriseUserCnt: any;
    diableNextButton: boolean;
    lastloginatTimeDifference: any;
    userDetails: any;

    constructor(private subscriptionLevelService: SubscrioptionLevelService,
                private fbAuth: StratizantFirebaseAuthService,
                private matDialog: MatDialog,
                private route: ActivatedRoute,
                private stripeService: AngularStripeService,
                private cd: ChangeDetectorRef,
                private fb: FormBuilder,
                private router: Router,
                private selectedPlanService: SelectedSubscriptionPlanService,
                private userProfileService: UserProfileService,
                private orgService: OrgManagementService,
                private userService: UserManagementService,
                private cookieServiceVariable: CookieService

    ) { }

    ngOnInit() {
        this.getSubscriptionLevelDetails();
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
        this.route.params.subscribe(params => {
            this.selectedPlan = params['selectedSubscription'];

        });
        $("#stepdiv").addClass("orange");
        $("#draftnum").addClass("active");
        $("#offerDiv").addClass("offer-div-css");

        this.additionalUserForm = this.fb.group({
            additionalUser: [0, Validators.required],
        });
        

        this.createCardForm();

        // this.authtoken = localStorage.getItem('auth_token');

        this.authtoken = this.cookieServiceVariable.get('auth_token_1');
        this.organisationId = localStorage.getItem('organisation_id');
        if(this.router.url.includes('changeSelectedSubscriptionPlan')){
            this.getStripeSubscriptionDetails();
            this.changeSubscription = true;
        }else{
            this.changeSubscription = false;
        }
        this.getSameOrganisationList();
        this.getAllUsersList();

        this.planname = localStorage.getItem('Planname');
        // const tempUserCnt = '0';
        // localStorage.setItem('additionalEnterPriseUser', tempUserCnt);
        const additionalEnterPriseUser = localStorage.getItem('additionalEnterPriseUser');

        if(additionalEnterPriseUser){
            this.additionalUserForm.controls.additionalUser.setValue(additionalEnterPriseUser);
        }else{
            this.additionalUserForm.controls.additionalUser.setValue('0');
        }

        

    }

    


    showForm() {
        if (this.selectedPlan == 'initiator') {
            this.hideNext = true;
            $("#initiatorBr").addClass("hideBr");
        } else if (this.selectedPlan == 'takeoff') {
            this.hideNext = true;
            $("#takeoffBr").addClass("hideBr");
        } else if (this.selectedPlan == 'enterprise') {
            this.hideNext = true;
            $("#enterpriseBr").addClass("hideBr");
            $("#additionalUser").addClass("disabledInput");

        }

    }


    cancelSelectedPlan() {
        if(this.changeSubscription){
            this.router.navigate(['/home/changeSubscription/',this.selectedPlan]);
        }else{
            this.router.navigate(['/home/upgradeSubscription']);
        }
    }

    getSubscriptionLevelDetails() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.subscriptionLevelService.getSubscriptionLevelDetails(obj).subscribe(res => {
            this.loader = false;
            const subscribtiondetails = Object.entries(res).map(([type, value]) => ({ type, value }));

            for (const subindex in subscribtiondetails) {

                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Initiator" && this.selectedPlan === 'initiator') {
                    this.initiator_subscription_level = subscribtiondetails[subindex];
                    this.setInitiatorSubscriptionLevelValue();

                } else if (subscribtiondetails[subindex]['value']['subscription_level'] === "Take-Off" && this.selectedPlan === "takeoff") {
                    this.take_off_subscription_level = subscribtiondetails[subindex];
                    this.setTakeOffSubscriptionLevelValue();
                }
                if (subscribtiondetails[subindex]['value']['subscription_level'] === "Enterprise" && this.selectedPlan === "enterprise") {
                    this.enterprise_subscription_level = subscribtiondetails[subindex];
                    this.setEnterpriseSubscriptionLevelValue();
                }

            }

        }, (error) => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


    setInitiatorSubscriptionLevelValue() {
        this.initiator_configAdmin_User_Cnt = parseInt(this.initiator_subscription_level.value.config_admin_user_count);
       

        this.initiator_dashboard_User_Cnt = parseInt(this.initiator_subscription_level.value.dashboard_user_count);


        // this.initiator_dashboard_user_count = parseInt(this.initiator_subscription_level.value.dashboard_user_count);


        this.initiatorUserCnt = parseInt(this.initiator_subscription_level.value.dashboard_user_count) + parseInt(this.initiator_subscription_level.value.config_admin_user_count);

        this.initiator_prediction_file_count_limit = parseInt(this.initiator_subscription_level.value.prediction_file_count_limit);
        this.initiator_total_file_storage_space = parseInt(this.initiator_subscription_level.value.total_file_storage_space) / 1000;

        this.initiator_configAdmin_Cost = parseInt(this.initiator_subscription_level.value.config_admin_user_cost);
       
        this.initiator_dashboard_Cost = parseInt(this.initiator_subscription_level.value.dashboard_user_cost);

        this.todaysTotal = (parseInt(this.initiator_subscription_level.value.dashboard_user_count) * parseInt(this.initiator_subscription_level.value.dashboard_user_cost)) +
        (parseInt(this.initiator_subscription_level.value.config_admin_user_count) * parseInt(this.initiator_subscription_level.value.config_admin_user_cost));
    

    }

    setTakeOffSubscriptionLevelValue() {

        this.takeoff_configAdmin_User_Cnt = parseInt(this.take_off_subscription_level.value.config_admin_user_count);
        this.takeoff_dashboard_User_Cnt = parseInt(this.take_off_subscription_level.value.dashboard_user_count);

        this.takeoff_dashboard_user_count = parseInt(this.take_off_subscription_level.value.dashboard_user_count);

        this.takeoffUserCnt = parseInt(this.take_off_subscription_level.value.dashboard_user_count) + parseInt(this.take_off_subscription_level.value.config_admin_user_count);
        this.takeoff_prediction_file_count_limit = parseInt(this.take_off_subscription_level.value.prediction_file_count_limit);
        this.takeoff_total_file_storage_space = parseInt(this.take_off_subscription_level.value.total_file_storage_space) / 1000;

        this.takeoff_configAdmin_Cost = parseInt(this.take_off_subscription_level.value.config_admin_user_cost);
        this.takeoff_dashboard_Cost = parseInt(this.take_off_subscription_level.value.dashboard_user_cost);

        this.todaysTotal = (parseInt(this.take_off_subscription_level.value.dashboard_user_count) * parseInt(this.take_off_subscription_level.value.dashboard_user_cost)) +
            (parseInt(this.take_off_subscription_level.value.config_admin_user_count) * parseInt(this.take_off_subscription_level.value.config_admin_user_cost))

    }

    setEnterpriseSubscriptionLevelValue() {
        this.enterprise_configAdmin_User_Cnt = parseInt(this.enterprise_subscription_level.value.config_admin_user_count);
        this.enterprise_dashboard_User_Cnt = parseInt(this.enterprise_subscription_level.value.dashboard_user_count);

        this.enterprise_dashboard_user_count = parseInt(this.enterprise_subscription_level.value.dashboard_user_count);

        this.enterPriseUserCnt = parseInt(this.enterprise_subscription_level.value.dashboard_user_count) + parseInt(this.enterprise_subscription_level.value.config_admin_user_count);
        this.enterprise_prediction_file_count_limit = parseInt(this.enterprise_subscription_level.value.prediction_file_count_limit);
        this.enterprise_total_file_storage_space = parseInt(this.enterprise_subscription_level.value.total_file_storage_space) / 1000;

        this.enterprise_configAdmin_Cost = parseInt(this.enterprise_subscription_level.value.config_admin_user_cost);
        this.enterprise_dashboard_Cost = parseInt(this.enterprise_subscription_level.value.dashboard_user_cost);

        this.todaysTotal = (parseInt(this.enterprise_subscription_level.value.dashboard_user_count) * parseInt(this.enterprise_subscription_level.value.dashboard_user_cost)) +
            (parseInt(this.enterprise_subscription_level.value.config_admin_user_count) * parseInt(this.enterprise_subscription_level.value.config_admin_user_cost))

        // if(this.trial_period_count > this.enterPriseUserCnt){
        //     const additionalUserVal = this.trial_period_count - this.enterPriseUserCnt;
        //     this.additionalUserForm.controls.additionalUser.setValue(additionalUserVal);
        // }

        if(this.planname == 'Enterprise' && this.trial_period_count > this.enterPriseUserCnt){
            const additionalUserVal = this.trial_period_count - this.enterPriseUserCnt;
            this.additionalUserForm.controls.additionalUser.setValue(additionalUserVal);
        }else if(this.planname == 'Initiator' && this.trial_period_count > this.initiatorUserCnt){
            const additionalUserVal = this.trial_period_count - this.initiatorUserCnt;
            this.additionalUserForm.controls.additionalUser.setValue(additionalUserVal);
        }else if(this.planname == 'Take-Off' && this.trial_period_count > this.takeoffUserCnt){
            const additionalUserVal = this.trial_period_count - this.takeoffUserCnt;
            this.additionalUserForm.controls.additionalUser.setValue(additionalUserVal);
        }

    }

    getSameOrganisationList() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        this.orgService.getOrganizationList(obj).subscribe(res => {
            const filteredList = [];
            if(res){
                res = res.replace(/'/g, '"');
                this.organizations = JSON.parse(res);

                for(const userIndex in  this.organizations){               
                    const user = this.organizations[userIndex];
                    if(user['org_id'] == this.organisationId){
                        filteredList.push(user);
                    }
                }
                for (const row in filteredList) {
                    this.trial_period_count = filteredList[0]['trial_period_count']
                }
           

            }else{
                this.loader = false;
            }
        },(error)=>{
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
                
        });
    }

    calculateTodaysTotal() {

        this.todaysTotal = (parseInt(this.enterprise_subscription_level.value.dashboard_user_count) * parseInt(this.enterprise_subscription_level.value.dashboard_user_cost)) +
            (parseInt(this.enterprise_subscription_level.value.config_admin_user_count) * parseInt(this.enterprise_subscription_level.value.config_admin_user_cost)) +
            (225 * this.additionalUserForm.value.additionalUser);
       

        if(this.selectedPlan == 'enterprise' && parseInt(this.additionalUserForm.value.additionalUser) < this.remainingEnterpriseUserCnt){
            this.diableNextButton = true;
        }else {
            this.diableNextButton = false;
        }
        localStorage.setItem('additionalEnterPriseUser',this.additionalUserForm.value.additionalUser);
    }

    openAuthDialog() {
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.matDialog.open(AuthDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {
            this.fbAuth.signout();

        });

    }


    onChange({ error }) {
        if (error) {
            this.error = error.message;
           
        } else {
            this.error = null;
           
        }
        this.cd.detectChanges();
    }

    // ngAfterViewInit
    createCardForm() {
        this.stripeService.setPublishableKey('pk_test_KFI69xH9C8pxiaEh6SqClceM00ww6CArgx').then(
            stripe => {
                this.stripe = stripe;
                const elements = stripe.elements();

                this.card = elements.create('card');
                this.card.mount(this.cardInfo.nativeElement);
                this.card.addEventListener('change', this.cardHandler);

                // this.card = elements.create('card');

                // const style = {
                //     base: {
                //         iconColor: '#666EE8',
                //         color: '#31325F',
                //         lineHeight: '40px',
                //         fontWeight: 300,
                //         fontFamily: 'Helvetica Neue',
                //         fontSize: '15px',

                //         '::placeholder': {
                //             color: '#CFD7E0',
                //         },
                //     },
                // };


                // this.cardNumber = elements.create('cardNumber', {
                //     style: style,
                // });
                // this.cardNumber.mount('#card-number-element');

                // this.cardExpiry = elements.create('cardExpiry', {
                //     style: style
                // });
                // this.cardExpiry.mount('#card-expiry-element');

                // this.cardCvc = elements.create('cardCvc', {
                //     style: style
                // });
                // this.cardCvc.mount('#card-cvc-element');

                // this.postalCode = elements.create('postal-code', {
                //   style: style
                // });
                // this.postalCode.mount('#postal-code');
                // this.card.mount(this.cardInfo.nativeElement);


                //this.cardNumber.addEventListener('change', this.cardHandler);
            });


    }

    // ngOnDestroy() {
    //     this.cardNumber.removeEventListener('change', this.cardHandler);
    //     this.cardNumber.destroy();
    // }

    async onSubmit(val) {
        
        // localStorage.setItem('Planname',val);
        // this.loader = true;
        this.errorMsg = '';
        this.loader = true;
        const { token, error } = await this.stripe.createToken(this.card);

        // if (this.cardCvc.complete === false || this.cardExpiry.complete === false || this.cardNumber.complete === false) {
        //     this.invalidForm = true;
      
        // } else {
        //     this.invalidForm = false;
      
        // }

       


        if (error) {

            this.error = '';
            this.error = error.message;
            this.successMsg = '';
            this.loader = false;
        } else if (token) {
            this.errorMsg = '';

            const { paymentMethod, payment_error } = await this.stripe.createPaymentMethod({
                type: 'card',
                card: this.card,
                billing_details: {
                    'email': localStorage.getItem('user')
                }
            });

            if (payment_error) {
                this.error = payment_error.message;
                this.successMsg = '';
                this.loader = false;

            } else {
                const formData = new FormData();
              
                // const paymentData = {
                //     'email': localStorage.getItem('user'),
                //     'payment_method_id': paymentMethod.id,
                //     'price_amt': this.initiatorTodaysTotal
                // }


                let selectedPlan;
                if(this.selectedPlan == 'initiator'){
                    selectedPlan = 'Initiator';
                    this.allowed_additional_user_count = 0;
                }else if(this.selectedPlan == 'takeoff'){
                    selectedPlan = 'Take-Off';
                    this.allowed_additional_user_count = 0;
                }else if(this.selectedPlan == 'enterprise'){
                    selectedPlan = 'Enterprise';
                    this.allowed_additional_user_count = this.additionalUserForm.value.additionalUser;
                }
                if(this.router.url.includes('changeSelectedSubscriptionPlan') && this.subscriptionId){
                    formData.append('email', localStorage.getItem('user'));
                    formData.append('organisation_id', localStorage.getItem('organisation_id'));
                    formData.append('subscription_id', this.subscriptionId);
                    formData.append('plan_name', selectedPlan);
                    formData.append('price_amt', this.todaysTotal);
                    formData.append('payment_method_id', paymentMethod.id);
                    //formData.append('old_payment_method_id', localStorage.getItem('payment_method_id'));
                    formData.append('allowed_additional_user_count', this.allowed_additional_user_count);
                   
                }else{
                    formData.append('email', localStorage.getItem('user'));
                    formData.append('payment_method_id', paymentMethod.id);
                    formData.append('price_amt', this.todaysTotal);
                    formData.append('organisation_id', localStorage.getItem('organisation_id'));
                    formData.append('plan_name', selectedPlan);
                    formData.append('allowed_additional_user_count', this.allowed_additional_user_count);
                }

                const obj = {
                    "Authorization": localStorage.getItem('auth_token')
                }

                this.selectedPlanService.checkout(obj, formData, this.subscriptionId).subscribe(res => {

                    this.loader = false;
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.data = {'message': res.message}
                    this.matDialog.open(SubscriptionSuccessComponent, dialogConfig);
                    this.getAllUsersList();
                    // location.reload();
                }, error => {
                    console.log("er", error);
                    this.loader = false
                });
            }

            //this.successMsg = "Your payment has done successfully";
            // this.router.navigate(['/home/myProfile'])
            //const dialogConfig = new MatDialogConfig();
            //this.matDialog.open(SubscriptionSuccessComponent, dialogConfig);
        }
    }


    getStripeSubscriptionDetails() {
        this.loader = true;
        const data = {
            'Authorization': this.authtoken
        };
        this.userProfileService.getStripeSubscriptionDetails(data).subscribe(res => {
            this.loader = false;
            const planDetails = [];
            for (const row in res.data) {
                if (res.data) {
                    if (res.data[row]['metadata']['organisation_id'] == this.organisationId) {
                        planDetails.push(res.data[row]);
                    }
                }
            }

            for (const row in planDetails) {
                this.subscriptionId = planDetails[0]['id'];
            }

        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        });
    }


    getAllUsersList() {
        this.loader = true;
        const obj = {
            "Authorization": localStorage.getItem('auth_token')
        }
        const filteredList = [];
        this.userService.getAllUsersList(obj).subscribe(res => {
            if(Object.keys(res).length > 0){
                const arr = Object.entries(res).map(([type, value]) => ({type,value}));
                

                for (const row in arr) {
                    if (arr[row]['value']['organisation_id'] == this.organisationId) {
                        filteredList.push(arr);
                    } 
                }
                console.log("filteredList", filteredList[0]);
                for(const user in filteredList[0]){
                    if(this.userDetails.email === filteredList[0][user]['value']['email']){
                        localStorage.setItem('roleType',  filteredList[0][user]['value']['role_type']);
                        break;
                    }
                }
            }else{
                this.loader = false;
            }
            this.loader = false;
            this.sameOrganisationUserCnt = filteredList.length;

            if(this.selectedPlan == 'enterprise' && this.sameOrganisationUserCnt > this.enterPriseUserCnt){
                this.remainingEnterpriseUserCnt = this.sameOrganisationUserCnt - this.enterPriseUserCnt
                this.additionalUserForm.controls.additionalUser.setValue(this.remainingEnterpriseUserCnt);
            }
        }, error => {
            this.loader = false;
            if (error && (error.status === 401)) {
                // this.openAuthDialog();
            }
            // if (error && (error.error == 'No Session Cookie' || error.error == 'Invalid Session Cookie')) {
            //     this.openAuthDialog();
            // }
        })
    }
}
